webClient.controller('B2C.headController', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {
    $window.$headScope = $scope;
    $scope.site = $window.site;
    $scope.siteType = $window.site.type;
    $scope.isLoginRequired = $window.site.isLoginRequired;
    $scope.isLogged = $window.site.isLogged;
    $scope.loginInfo = $window.site.loginInfo;
    $scope.homeUrl = $window.site.loginUrl;
    //******************************************************************
    if ($window.site.multiGroup)
        $scope.multiGroup = $window.site.multiGroup.Sites;
    else
        $scope.multiGroup = null;
    $scope.multiSite = $window.site.multiSite;
    $scope.multiLanguage = $window.site.multiLanguage;
    $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
}]);