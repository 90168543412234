webClient.controller('B2B.accountEmissionController', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {
    $scope.getText = function (key) { return $window.culture.texts[key]; };
    var apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
    $scope.defaultValues = {
        n: true,
        AcceptConditions: false,
        CabinClass: "",
        Adults: "",
        Children: "",
        Infants: "",
        LastName: "",
        Farebasis: ""

    };
    $scope.IATARequest = Object.assign({}, $scope.defaultValues);
    $scope.market = $window.market;

    $scope.validateSearch = function (obj, typeCheck, field) {
        if (field == "" || field == undefined) {
            $(obj).attr('data-error-date-min', "error");
            $(obj).attr('data-check', "{check:" + "'" + typeCheck + "'}");
            $(obj).attr('data-error-addon', "2");
            return false;
        }

        if (obj == "#i-amount") {
            $(obj).attr('data-error-date-min', "error");
            $(obj).attr('data-check', "{check:" + "'" + typeCheck + "'}");
            $(obj).attr('data-error-addon', "2");
            return false;
        }
    };
    $scope.removeValidations = function (obj) {
        $(obj).removeAttr('data-error-date-min');
        $(obj).removeAttr('data-check');
        $(obj).removeAttr('data-error-addon');
    };

    $scope.formatDate = function (dateVal) {
        if (dateVal) {
            var formatedDate = new Date();
            formatedDate.setDate(Number(dateVal.substring(0, 2)));
            formatedDate.setMonth(Number(dateVal.substring(3, 5)) - 1);
            formatedDate.setFullYear(Number(dateVal.substring(6)));
            formatedDate.setHours(Number($scope.IATARequest.hh));
            formatedDate.setMinutes(Number($scope.IATARequest.mm));
            formatedDate.setSeconds(0);
            formatedDate = moment(formatedDate);
            return formatedDate;
        } else {
            return new Date();
        }
    }

    $scope.checkHours = function () {
        if ($("#n-date").val() && $scope.IATARequest.hh && $scope.IATARequest.mm) {
            //check if the date and hours < 3 hours
            var currentDate = moment(new Date()).second(0);
            currentDate.add(3, 'h');

            var selectedDate = $scope.formatDate($("#n-date").val());

            //selected Date < 3 hours
            if (selectedDate < currentDate) {
                FPop({
                    oField: $("#i-hh-text")[0],
                    iPop: 'i-age-alert-pop',
                    iLoft: 'parent'
                });
            }
            else {
                FPopHide({ iPop: 'i-age-alert-pop' });
            }
        }
    };

    $("#n-date").change(function () {
        $scope.checkHours();
    });

    $scope.newBilletIATA = function (IATARequest, com) {

        var x = $scope.validateSearch("#i-pnr-reference", "required", $scope.IATARequest.Pnr);
        x = $scope.validateSearch("#i-classe-text", "required", $scope.IATARequest.CabinClass);
        var selectDate = $("#n-date").val();
        if (selectDate || $scope.IATARequest.hh || $scope.IATARequest.mm) {
            x = $scope.validateSearch("#n-date", "date", selectDate);
            x = $scope.validateSearch("#i-hh-text", "required", $scope.IATARequest.hh);
            x = $scope.validateSearch("#i-mm-text", "required", $scope.IATARequest.mm);
        }
        else {
            $scope.removeValidations("#n-date");
            $scope.removeValidations("#i-hh-text");
            $scope.removeValidations("#i-mm-text");
        }

        x = $scope.validateSearch("#i-amount", "decimal", $scope.IATARequest.Amount);
        if ($("#n-date").val()) {
            $scope.IATARequest.date = $("#n-date").val();
            $scope.IATARequest.Datetime = new Date();
            $scope.IATARequest.Datetime.setDate(Number($scope.IATARequest.date.substring(0, 2)));
            $scope.IATARequest.Datetime.setMonth(Number($scope.IATARequest.date.substring(3, 5)) - 1);
            $scope.IATARequest.Datetime.setFullYear(Number($scope.IATARequest.date.substring(6)));
            $scope.IATARequest.Datetime.setHours(Number($scope.IATARequest.hh));
            $scope.IATARequest.Datetime.setMinutes(Number($scope.IATARequest.mm));
            $scope.IATARequest.Datetime.setSeconds(0);
            $scope.IATARequest.Datetime = moment($scope.IATARequest.Datetime).toISOString();
        }
        else {
            $scope.IATARequest.Datetime = moment(new Date(null)).toISOString();
        }


        if (!$scope.IATARequest.Adults) {
            $scope.IATARequest.Adults = "0";
        }
        if (!$scope.IATARequest.Children) {
            $scope.IATARequest.Children = "0";
        }
        if (!$scope.IATARequest.Infants) {
            $scope.IATARequest.Infants = "0";
        }
        if (!$scope.IATARequest.LastName) {
            $scope.IATARequest.LastName = "--";
        }
        if (!$scope.IATARequest.Farebasis) {
            $scope.IATARequest.Farebasis = "--";
        }

        var arr = [];

        for (var prop in $scope.IATARequest) {
            arr.push({ key: prop, value: $scope.IATARequest[prop] });
        }

    

        var request = { requestType: null, values: Array[0] };

        request.requestType = "IataRequest";
        request.values = arr;

        if (XLFormValid({ iCont: com })) {


            if ($scope.IATARequest.AcceptConditions) {

                //defaults values if empty

              

                $window.FLoading({ vAction: 'show' });

                $scope.apiClient.B2B.sendRequests(request).success(function (response) {
                    $window.FLoading({ vAction: 'hide' });

                    if (response) {
                        $window.XLConfirm({
                            vTitle: "Votre demande a bien été prise en compte et sera traitée dans les plus brefs délais par nos équipes. Une nouvelle réservation apparaîtra dans l'onglet 'MES RESAS'",
                            vTextYes: 'OK Merci',
                            vHideNo: true
                        });
                        $scope.IATARequest = Object.assign({}, $scope.defaultValues);
                        $("#i-gds-text").html("");
                        $("#i-classe-text").html("");
                        $("#i-adults-text").html("");
                        $("#i-children-text").html("");
                        $("#i-babies-text").html("");
                        $("#i-hh-text").html("");
                        $("#i-mm-text").html("");
                        $("#n-date").val("");
                        $('div').removeClass('c-field-ok');
                    }
                    else {
                        $window.XLConfirm({
                            vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                            vTextYes: 'OK Merci',
                            vHideNo: true
                        });
                    }
                });
            }
            else {
                $window.XLConfirm({ vTitle: "Veuillez cocher les conditions d'emission", vTextYes: 'OK', vHideNo: true });
            }
        }
    };

    //This is only for debug
    $window.$scope = $scope;
    $window.$scope.apiClient = apiClient;
}]);