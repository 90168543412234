webClient.directive('ngBookingBrandedFaresIncludedServices', ['$window', 'templateService', function ($window, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        services: "=",
        displayname: "=",
        purchaseresponse: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_BookingBrandedFaresIncludedServices.html');

        //Initialize Variables
        $scope.getUrl = $window.getUrl;
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.firstTime = true;

        $scope.modal = function () {
            if ($scope.firstTime) {
                //Remodal workaround
                $(document).find(".remodal").each(function (a, c) {
                    var d = $(c)
                        , e = d.data("remodal-options");
                    e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                        d["remodal"](e)
                });
                $scope.firstTime = false;
            }
        }
    };
    return directive;
}]);