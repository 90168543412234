webClient.controller('B2B.accountUMRequestController', ['$scope', '$window', '$timeout', '$i18next', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, $i18next, tseApiClient, tseUtil, airHelper) {
    //RestApi client object
    var apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
    $scope.getStaticFolder = $window.getStaticFolder;
    $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
    $scope.getTypeStaticFolder = $window.getTypeStaticFolder;

    $scope.roundTripType = true;
    $scope.adultAller = {};
    $scope.adultRetour = {};
    $scope.data = {};
    $scope.retour = "retour";
    $scope.aller = "aller";
    $scope.arrChildrens = [{ birthday: "" }];
    $scope.arrChildrens.length = 1;
    $scope.position = 0;
    $scope.flight = {};
    $scope.flight.numberCompanyRetour = "";
    $scope.flight.scheduleRetour = "";
    $scope.isDelete = false;

    var link = 'https://help-center.misterflypro.com/faq-details?articleId=80000964390&categoryId=80000414592&folderId=80000683160&product=FLIGHT';
        
    if (window.market === 'BE') {
        if (lng === 'nl-BE') {
            link = 'https://help.misterflypro.be/article/737-mijn-klant-wil-een-verzoek-voor-een-alleen-reizend-kind-indienen';
        }
        else {
            link = 'https://aide.misterflypro.be/article/774-mon-client-veut-faire-voyager-un-enfant-seul-demande-dum';
        }
    }

    if (lng === 'es-ES') {
        link = 'https://ayudar.misterflypro.es/article/878-enviar-de-viaje-a-un-menor-de-edad';
    }

    if (lng === 'it-IT') {
        link = 'https://www.misterflypro.it/page/travelformalities';
    }


    $scope.requestumdesc = $i18next.t('b2baccountumrequest.requestumdesc').format(link);

    $scope.makeArray = function () {
        $scope.arrChildrens.push({birthday: "" });
        var cabinClass = $("#i-search-cabinClass-pop li.c-on").data("value");
        var searchType = $("#i-search-type-pop li.c-on").data("value");
        $scope.position++;
    };

    $scope.FPop = function (textField, popDiv, style, fill) {
        var target = $("#" + textField).get(0);
        $window.FPop({ oTarget: target, iPop: popDiv, vStyle: style, vFill: fill });
    };

    $scope.getTypeFlight = function () {
        if ($("#i-search-type-pop li.c-on").data("value") == 'oneWay') {
            $scope.roundTripType = false;
            $scope.adultRetour = {};

            $("#i-title-r2-text").html("");
            $("#i-title-r3-text").html("");
            $("#i-country-codes-r1-text").html("");
            $("#i-country-codes-r2-text").html("");

            $("#i-title-r2-text").parent().removeClass("c-field-ok");
            $("#i-title-r3-text").parent().removeClass("c-field-ok");
            $("#i-country-codes-r1-text").parent().parent().removeClass("c-field-ok");
            $("#i-country-codes-r2-text").parent().parent().removeClass("c-field-ok");
        }
        else
            $scope.roundTripType = true;
    };

    $scope.validateSearch = function (obj, typeCheck, field) {
        if (field == "" || field == undefined) {
            $(obj).attr('data-error-date-min', "error");
            $(obj).attr('data-check', "{check:" + "'" + typeCheck + "'}");
            $(obj).attr('data-error-addon', "2");
            return false;
        }
    };

    $scope.getAirlines = function () {
        apiClient.getAirlines().success(function (result) {
            $scope.arrAirlines = result;
            $scope.arrAirlines = $scope.arrAirlines.orderBy(function (item) { return item.name; });
        });
    };


    $.getJSON("/Views/misterfly_umbraco/_countriescodes/" + lng.substring(0, 2) + "_CountriesCode.json", function (countriesCode) {
        $scope.arrCountries = countriesCode;
    });


    $scope.getFidelityCards = function () {
        apiClient.getFidelityCards().success(function (result) {
            $scope.arrFidelityCards = result;
            $scope.arrFidelityCards = $scope.arrFidelityCards.orderBy(function (item) { return item.name; });
        });
    };

    $scope.getAirlines();
    $scope.getFidelityCards();


    //Input date birthday
    var dateString = moment($(".c-search input[name='returnDate']").val(), "DD-MM-YYYY").toISOString();
    var dateMin = moment(dateString).subtract(17, 'years');
    var dateMax = moment(dateString).subtract(2, 'years');
    dateMin = dateMin.add(1, 'days');
    $scope.dateMin = dateMin.format('DD/MM/YYYY');
    $scope.dateMax = dateMax.format('DD/MM/YYYY');

    $scope.sendUMRequest = function () {
        var search = saveSearchFormObject();
        var arr = [];

        for (var t in $scope.adultAller) {
            for (var i in $scope.adultAller[t]) {
                if (i.toUpperCase() == "MOBILE")
                {
                    if (t == "departure") {
                        var mobileAux = "+" + $scope.adultAller.departure.MobilePhoneCountryCode + $scope.adultAller[t][i];
                        arr.push({ key: 'adultAller' + t.capitalize() + i, value: mobileAux });
                    }
                    else if (t == "arrival") {
                        var mobileAux = "+" + $scope.adultAller.arrival.MobilePhoneCountryCode + $scope.adultAller[t][i];
                        arr.push({ key: 'adultAller' + t.capitalize() + i, value: mobileAux });
                    }
                }
                else if (i.toUpperCase() == "PHONE") {
                    if (t == "departure") {
                        var landLineAux = "+" + $scope.adultAller.departure.LandLinePhoneCountryCode + $scope.adultAller[t][i];
                        arr.push({ key: 'adultAller' + t.capitalize() + i, value: landLineAux });
                    }
                    else if (t == "arrival")
                    {
                        var landLineAux = "+" + $scope.adultAller.arrival.LandLinePhoneCountryCode + $scope.adultAller[t][i];
                        arr.push({ key: 'adultAller' + t.capitalize() + i, value: landLineAux });
                    }
                }
                else
                    arr.push({ key: 'adultAller' + t.capitalize() + i, value: $scope.adultAller[t][i] });
            }
        }

        if ($scope.roundTripType) {
            for (var t in $scope.adultRetour)
                for (var i in $scope.adultRetour[t])
                    if (i.toUpperCase() == "MOBILE") {
                        if (t == "departure") {
                            var mobileAux = "+" + $scope.adultRetour.departure.MobilePhoneCountryCode + $scope.adultRetour[t][i];
                            arr.push({ key: 'adultRetour' + t.capitalize() + i, value: mobileAux });
                        }
                        else if (t == "arrival") {
                            var mobileAux = "+" + $scope.adultRetour.arrival.MobilePhoneCountryCode + $scope.adultRetour[t][i];
                            arr.push({ key: 'adultRetour' + t.capitalize() + i, value: mobileAux });
                        }
                    }
                    else if (i.toUpperCase() == "PHONE") {
                        if (t == "departure") {
                            var landLineAux = "+" + $scope.adultRetour.departure.LandLinePhoneCountryCode + $scope.adultRetour[t][i];
                            arr.push({ key: 'adultRetour' + t.capitalize() + i, value: landLineAux });
                        }
                        else if (t == "arrival") {
                            var landLineAux = "+" + $scope.adultRetour.arrival.LandLinePhoneCountryCode + $scope.adultRetour[t][i];
                            arr.push({ key: 'adultRetour' + t.capitalize() + i, value: landLineAux });
                        }
                    }
                    else
                    arr.push({ key: 'adultRetour' + t.capitalize() + i, value: $scope.adultRetour[t][i] });
        }

        for (var c = 0; c < $scope.arrChildrens.length; c++) {
            var child = $scope.arrChildrens[c];
            for (var cP in child)
                arr.push({ key: 'child_' + c + '_' + cP, value: child[cP] });
        }
        arr.push({ key: 'totalChildrens', value: $scope.arrChildrens.length });

        var isValid = true;

        if (!$window.XLFormValid({ iCont: 'c-search-um' })) return false;

        for (var i = 0; i < $scope.arrChildrens.length; i++) {
            if (!$window.XLFormValid({ iCont: 'c-form-cont-child-' + i })) return false;
        }

        if (!$window.XLFormValid({ iCont: 'c-form-cont-adult-departure-aller' })) return false;
        else if (!$window.XLFormValid({ iCont: 'c-form-cont-adult-arrival-aller' })) return false;

        if (1 == 1) {

            if (search.type != "1") {
                if (!$window.XLFormValid({ iCont: 'c-form-cont-adult-departure-retour' }) || !$window.XLFormValid({ iCont: 'c-form-cont-adult-arrival-retour' }))
                    isValid = false;
                var isValidSearchOneWay = $scope.validateSearch("#returnDate", "date", search.returnDate);
            }

            var isValidSearch = $scope.validateSearch("#i-where-origin", "text", search.origin);
            isValidSearch = $scope.validateSearch("#i-where-destination", "text", search.destination);
            isValidSearch = $scope.validateSearch("#goDate", "date", search.goDate);

            $window.XLFormValid({ iCont: 'c-search-um' });

            if (isValid) {
                arr.push({ key: 'destination', value: search.destination });
                arr.push({ key: 'destination_value', value: search.destination_value });
                arr.push({ key: 'origin', value: search.origin });
                arr.push({ key: 'origin_value', value: search.origin_value });
                arr.push({ key: 'typeflight', value: search.type });
                arr.push({ key: 'cabinClass', value: search.cabin });
                arr.push({ key: 'goDate', value: search.goDate });
                arr.push({ key: 'returnDate', value: search.returnDate });
                arr.push({ key: 'origin2', value: search.origin2 });
                arr.push({ key: 'origin2_value', value: search.origin2_value });
                arr.push({ key: 'destination2', value: search.destination2 });
                arr.push({ key: 'destination2_value', value: search.destination2_value });
                arr.push({ key: 'goDate2', value: search.goDate2 });
                arr.push({ key: 'airline', value: $scope.flight.company });
                arr.push({ key: 'flightsNumberCompanyAller', value: $scope.flight.numberCompanyAller });
                arr.push({ key: 'flightsNumberCompanyRetour', value: $scope.flight.numberCompanyRetour });
                arr.push({ key: 'PreferenceSchedulesAller', value: $scope.flight.scheduleAller });
                arr.push({ key: 'PreferenceSchedulesRetour', value: $scope.flight.scheduleRetour });

                var request = { requestType: null, values: Array[0] };
                request.requestType = "UmRequest";
                request.values = arr;

                if ($window.XLFormValid({ iCont: 'c-search-um' })) {
                    $window.FLoading({ vAction: 'show' });
                    if(search.origin_value != null && search.origin_value != "" && search.origin_value != undefined
                    && search.destination_value != null && search.destination_value != "" && search.destination_value!=undefined){
                        $scope.apiClient.B2B.sendRequests(request).success(function (response) {
                            $window.FLoading({ vAction: 'hide' });
                            if (response) {
                                $window.XLConfirm({
                                    vTitle: "Votre demande a bien été prise en compte et sera traitée dans les plus brefs délais par nos équipes. Une nouvelle réservation apparaîtra dans l'onglet 'MES RESAS'",
                                    vTextYes: 'OK Merci',
                                    vHideNo: true
                                });
                                $scope.adultAller = {};
                                $scope.adultRetour = {};
                                $scope.position = 0;
                                $scope.flight = {};
                                $('div').removeClass('c-field-ok');
                                $scope.arrChildrens = [];
                                $scope.arrChildrens.length = 1;

                                //search
                                $("#goDate").val('');
                                $("#returnDate").val('');
                                $("#i-where-origin").val('');
                                $("#i-where-destination").val('');
                                $("#i-company-text").html("");

                                //countrys
                                $("#i-country-codes-0-text").html("");
                                $("#i-country-codes-r1-text").html("");
                                $("#i-country-codes-r2-text").html("");
                                $("#i-country-codes-a1-text").html("");
                                $("#i-country-codes-a2-text").html("");

                                //titles
                                $("#i-title-0-text").html("");
                                $("#i-title-a2-text").html("");
                                $("#i-title-a3-text").html("");
                                $("#i-title-r2-text").html("");
                                $("#i-title-r3-text").html("");

                            }
                            else {
                                $window.XLConfirm({
                                    vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                                    vTextYes: 'OK Merci',
                                    vHideNo: true
                                });
                            }
                        }).error(function () {
                            $window.XLConfirm({
                                vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                                vTextYes: 'OK Merci',
                                vHideNo: true
                            });
                        });
                    }
                    else {
                        $window.FLoading({ vAction: 'hide' });
                        $window.XLConfirm({
                            vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                            vTextYes: 'OK Merci',
                            vHideNo: true
                        });
                    }
                }
            }
        }
    };

    var saveSearchFormObject = function () {
        var searchType = $("#i-search-type-pop li.c-on").data("value");
        var cabinClass = $("#i-search-cabinClass-pop li.c-on").data("value");
        var origin = $(".c-search #i-where-origin").val();
        var origin_value = $(".c-search input[name='origin']").val();
        var destination = $(".c-search #i-where-destination").val();
        var destination_value = $(".c-search input[name='destination']").val();
        var goDate = $(".c-search input[name='goDate']").val();
        var returnDate = $(".c-search input[name='returnDate']").val();
        var origin2 = $(".c-search #i-where-origin-2").val() || null;
        var origin2_value = $(".c-search input[name='origin-2']").val() || null;
        var destination2 = $(".c-search #i-where-destination-2").val() || null;
        var destination2_value = $(".c-search input[name='destination-2']").val() || null;
        var goDate2 = $(".c-search input[name='goDate-2']").val() || null;

        return {
            type: searchType == "roundTrip" ? 0 : searchType == "oneWay" ? 1 : 2,
            cabin: cabinClass,
            origin: origin,
            origin_value: origin_value,
            destination: destination,
            destination_value: destination_value,
            goDate: goDate,
            returnDate: returnDate,
            origin2: origin2,
            origin2_value: origin2_value,
            destination2: destination2,
            destination2_value: destination2_value,
            goDate2: goDate2
        };
    };

    $scope.deleteChildMessage = function (index) {
        $window.XLConfirm({
            vTitle: 'Etes-vous certain de vouloir <br>supprimer l\'ajout de cet enfant ?',
            vTextNo: 'Non',
            vTextYes: 'Oui',
            fCallBackYes: function () {
                $scope.deleteChild(index);
                $window.XLConfirm({
                    vTitle: 'L\'ajout de cet enfant a bien été supprimé',
                    vTextNo: 'OK Merci',
                    vHideYes: true
                });
            }
        });
    };

    $scope.deleteChild = function (index) {
        $scope.arrChildrens.splice(index, 1);
        $scope.position -= 1;
        $scope.$apply();
    };

    //This is only for debug
    $window.$scope = $scope;
    $window.$scope.apiClient = apiClient;
}]);
