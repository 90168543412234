/**
* @desc the ngBookingAddBaggages provides support for collect the information of additional baggages in step 1 of the booking process
* @example <div ng-if="hasAdditionalBaggages" ng-booking-add-baggages request="request" paxinfo="paxinfo" addbaggage="proposal.additionalBaggages" selection="selection"></div>
*/

webClient.directive('ngBookingAdditionalBaggages', ['$window', '$timeout', '$i18next', 'templateService', 'trackingService', 'gtmModel', 'gtmProduct',
    function ($window, $timeout, $i18next, templateService, trackingService, gtmModel, gtmProduct) {
        var directive = {};
        directive.restrict = 'EA';
        directive.scope = {
            request: "=",
            paxinfo: "=",
            itineraries: "=",
            proposal: "=",
            triptype: "=",
            istrainonly: "=",
            iskiwi: "="
        };
        directive.link = function ($scope, $element) {

            templateService.compile($scope, $element, 'tpl/_BookingAdditionalBaggages.html');
            $scope.getUrl = $window.getUrl;
            $scope.getTemplateUrl = $window.getTemplateUrl;
            $scope.siteType = $window.site.type;
            var adultCount = 0;
            var childCount = 0;
            $scope.totalPrice = 0;
            $scope.nbSyncPassagerAdded = 0;
            $scope.nbSyncItinariesAdded = 0;
            $scope.showLuggageSpecialRuleSyncPassager = false;
            $scope.showLuggageSpecialRuleSyncItinaries = false;
            $scope.hideSparePhrase = false;
            $scope.luggageSpecialRuleSyncItinariesCarrierName = "";
            $scope.luggageSpecialRuleSyncPassagerCarrierName = "";
            $scope.currencyManagerEnabled = typeof (CurrencyManager) !== "undefined" && CurrencyManager.isEnabled() === true;

            $scope.getMessageSyncItinaries = function () {
                return $i18next.t('airbooking.warningluggageaddedforallitinaries').format($scope.luggageSpecialRuleSyncItinariesCarrierName);
            }

            $scope.getMessageSyncPassager = function () {
                return $i18next.t('airbooking.warningluggageaddedforallpassengers').format($scope.luggageSpecialRuleSyncPassagerCarrierName);
            }
            $scope.isServair = window.isServair;

            $scope.segmentsCabinLuggage = [];
            $scope.listIATABaggagesSyncAllPassengerAllItineraries = ['FR', 'OE'];
            $scope.listIATABaggagesSyncAllItineraries = ['U2'];
            $scope.counter = 0;
            function updatePrice() {
                $scope.totalPrice = 0;
                $scope.paxinfo.each(function (pax) {
                    for (var item in pax.baggageByItinerary) {
                        if (pax.baggageByItinerary[item].quantity > 0)
                            $scope.totalPrice += pax.baggageByItinerary[item].totalPrice.value;
                    }
                });

                //Tracking
                try {
                    //GTM
                    if (trackingService.isActivated()) {
                        trackingService.removeProduct('BAGGAGE');//we must have only one baggage product !
                        if ($scope.totalPrice > 0) {
                            trackingService.addProduct(gtmProduct.parseBaggage($scope.totalPrice));
                        }
                    }
                }
                catch (ex) {
                    console.log(ex.message);
                    $window.trackingError = ex.message;
                }
            }




            $scope.setHideSparePhrase = function (value) {
                $scope.hideSparePhrase = value;
                console.log('hideSparePhrase: ' + $scope.hideSparePhrase);
            };


            $scope.itineraries.each(function (it, index) {
                it.segments.each(function (itSegment) {

                    //Segment with Cabin luggage to pay
                    if (itSegment.airline.iata.toLowerCase() === "fr" && moment(itSegment.departureDateTime, "YYYY-MM-DD").isAfter('2018-11-01', 'day')) {
                        $scope.segmentsCabinLuggage.push({
                            airlineIata: itSegment.airline.iata,
                            airlineName: itSegment.airline.name
                        });
                    }
                });
            });

            $scope.warnSegmentCabinLuggage = $scope.segmentsCabinLuggage.any();

            //Dictionary [itineraryKey+PaxType]
            $scope.includeBaggagesbyItinerary = {};

            var nbFaresWithIncludedLuggage = 0;
            var nbFares = 0;
            $scope.hideServairLuggageMessage = false;
            $scope.proposal.fares.each(function (f) {
                f.itineraries.each(function (fareItinerary) {
                    $scope.includeBaggagesbyItinerary[fareItinerary.key + f.paxType] = {
                        itineraryKey: fareItinerary.key,
                        baggageQuantity: fareItinerary.baggageQuantity,
                        baggageWeight: fareItinerary.baggageWeight,
                        paxType: f.paxType
                    };

                    if ($scope.includeBaggagesbyItinerary[fareItinerary.key + f.paxType].baggageQuantity > 0) {
                        nbFaresWithIncludedLuggage++;
                    }
                    nbFares++;
                })
            })
         
            if (nbFaresWithIncludedLuggage == nbFares) {
                $scope.hideSparePhrase = true;
                $scope.hideServairLuggageMessage = true;
            }

            //call because needed on page reload
            updatePrice();


            //Prepare passenger data
            $scope.bagPaxInfo = $scope.paxinfo.grep(function (pax) { return pax.paxType == "0" || pax.paxType == "1"; }).map(function (pax) {
                var text = null;
                if (pax.paxType == "0")
                    text = $i18next.t("airbooking.luggage.adult") + " " + (++adultCount);
                if (pax.paxType == "1")
                    text = $i18next.t("airbooking.luggage.child") + " " + (++childCount);

                if (!pax.baggageByItinerary)
                    pax.baggageByItinerary = {};

                return { text: text, inner: pax }
            });

            //Get Culture text's
            $scope.getText = function (key) {
                return window.culture.texts[key];
            };

            function checkLuggageWithSpecialRules() {

                var counterSyncItinaries = 0;
                var counterSyncPassengers = 0;

                $scope.itineraries.each(function (it) {
                    if ($scope.listIATABaggagesSyncAllPassengerAllItineraries.includes(it.marketingCarrier.iata)) {
                        $scope.bagPaxInfo.each(function (paxitem) {
                            if (paxitem.inner.baggageByItinerary[it.key] != null) {
                                if (paxitem.inner.baggageByItinerary[it.key].quantity > 0) {
                                    counterSyncPassengers++;
                                    $scope.luggageSpecialRuleSyncPassagerCarrierName = it.marketingCarrier.name;
                                }
                            }
                        });
                    }

                    if ($scope.listIATABaggagesSyncAllItineraries.includes(it.marketingCarrier.iata)) {
                        $scope.bagPaxInfo.each(function (paxitem) {
                            if (paxitem.inner.baggageByItinerary[it.key] != null) {
                                if (paxitem.inner.baggageByItinerary[it.key].quantity > 0 && $scope.itineraries.filter(function (iti) { return iti.marketingCarrier.iata == it.marketingCarrier.iata; }).length > 1) {
                                    counterSyncItinaries++;
                                    $scope.luggageSpecialRuleSyncItinariesCarrierName = it.marketingCarrier.name;
                                }
                            }
                        });
                    }

                });

                $scope.showLuggageSpecialRuleSyncItinaries = counterSyncItinaries > 1;
                $scope.showLuggageSpecialRuleSyncPassager = counterSyncPassengers > 1;
            }

            checkLuggageWithSpecialRules();

            //Fpop
            $scope.FPop = function (textField, popDiv, style) {
                var target = $("#" + textField).get(0);
                $window.FPop({ oTarget: target, iPop: popDiv, vStyle: style });
            };

            //FSelect
            $scope.FSelect = function (name, target, _event) {
                var obj = $("#" + name + "-pop").find("ul").get(0);
                if (target)
                    $window.FSelect({ e: { target: target }, myObj: obj, vName: name, ignoreFlagsReplace: true });
                else
                    $window.FSelect({ e: _event, myObj: obj, vName: name, ignoreFlagsReplace: true });
            };



            //Update additionals baggages price
            $scope.updatePrice = function () {
                updatePrice();
                $scope.$emit("addBaggagesChanged", {});
            };

            var innerBaggageSelected = function (pax, bag, itKey) {
                //delete pax.inner.baggageByItinerary[itKey];
                if (typeof (CurrencyManager) !== "undefined" && CurrencyManager.isEnabled() === true) {
                    if (!bag.eurodescription) {
                        bag.eurodescription = bag.description;
                    }
                    bag.description = CurrencyManager.getPriceItemSpecificBaggage(bag.eurodescription);
                    setTimeout(function () {
                        CurrencyManager.updatePriceItemsSpecificBaggages();
                    }, 500);
                }
                pax.inner.baggageByItinerary[itKey] = bag;
            };


            //Select a baggage
            $scope.selectBaggage = function (pax, bag, itineraryKey) {
                //innerBaggageSelected(pax, bag, itineraryKey);

                var itinaryObject = $scope.itineraries.find(function (el) { return el.key === itineraryKey });


                if (itinaryObject) {

                    //a t-on des regles particulieres à appliquer sur ce segment? 
                    if ($scope.listIATABaggagesSyncAllPassengerAllItineraries.includes(itinaryObject.marketingCarrier.iata) || $scope.listIATABaggagesSyncAllItineraries.includes(itinaryObject.marketingCarrier.iata)) {

                        $scope.itineraries.each(function (it) {

                            var selectedBagUniqueKey = String(bag.location) + String(bag.weight) + String(bag.quantity);

                            var selectedBag = it.additionalBaggages.find(function (el) { return String(el.location) + String(el.weight) + String(el.quantity) === selectedBagUniqueKey });

                            if (selectedBag) {

                                var itiCurrent = it.marketingCarrier.iata;

                                if (itiCurrent == "OE") {
                                    itiCurrent = "FR";
                                }

                                var itiSelected = itinaryObject.marketingCarrier.iata;

                                if (itiSelected == "OE") {
                                    itiSelected = "FR";
                                }


                                if ($scope.listIATABaggagesSyncAllPassengerAllItineraries.includes(it.marketingCarrier.iata) && itiCurrent === itiSelected) {

                                    $scope.bagPaxInfo.each(function (paxitem) {
                                        innerBaggageSelected(paxitem, selectedBag, it.key);

                                    });
                                }
                                else if ($scope.listIATABaggagesSyncAllItineraries.includes(it.marketingCarrier.iata) && itiCurrent === itiSelected) {
                                    innerBaggageSelected(pax, selectedBag, it.key);
                                }
                            }

                        });
                    }
                    else {
                        innerBaggageSelected(pax, bag, itineraryKey);
                    }


                    $scope.updatePrice();
                    checkLuggageWithSpecialRules();

                }

            };

            //Reset baggages to cero
            $scope.$on("onNoAddBaggagesSelection", function (event, args) {
                $scope.bagPaxInfo.each(function (pax) {
                    $scope.itineraries.each(function (it) {
                        var auxNoBaggage = {
                            key: null,
                            quantity: 0,
                            totalPrice: { value: 0 },
                            weight: 0,
                            description: ($i18next.t('airbooking.aucunbagage') + '').format('0', '€'),
                            bf: true
                        };
                        $scope.selectBaggage(pax, auxNoBaggage, it.key);
                    })
                })
            });



        };
        return directive;
    }]);