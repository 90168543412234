webClient.controller('AirPaypal', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'trackingService', 'gtmProduct', 'omnitureService',
    function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper, trackingService, gtmProduct, omnitureService) {
    $scope.getText = function (key) { return window.culture.texts[key]; };
    $scope.getTemplateUrl = $window.getTemplateUrl;
    $scope.getStaticFolder = $window.getStaticFolder;
    $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
    $scope.getTypeStaticFolder = $window.getTypeStaticFolder;

    $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
    var searchUrl = $window.site.currentAirRequest.searchUrl + "?" + tseUtil.Location.getQueryString();

    var bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);
    $scope.bkgData = bkgData;
    window.$scope = $scope;
    $scope.paypalToken = null;
    $scope.paypalModule = null;
    $scope.paypalRunning = false;

    //Tracking
    try {
        //GTM
        if (trackingService.isActivated()) {
            trackingService.initDataLayer('checkout');
            trackingService.loadSessionData('step', 3);
            trackingService.setOption($scope.bkgData.bookingResponse.paymentMethod.paymentMethodName);
            trackingService.parseScope($scope);
            trackingService.pushDataLayer('step', 4);
        }
        //omniture
        //if (omnitureService.isActivated()) {
        //    omnitureService.sendEvent(omnitureService.PURCHASE_4_VOL, omnitureService.FLIGHT_TUNNEL, null);
        //}
    }
    catch (ex) {
        console.log(ex.message);
        $window.trackingError = ex.message;
    }

    if (bkgData) {
        $scope.dossierNumber = bkgData.bookingResponse.bookingNumber;

        var keyGenerated = "";
        keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;            
        

        bkgData.bookingRequest = tseUtil.Session.get("bookingRequest_" + keyGenerated);
        if (bkgData.bookingRequest != null && bkgData.bookingRequest.requestId == bkgData.request.requestId) {
            var _insuranceSelected = tseUtil.Session.get("insurancesSelected_" + keyGenerated);
            bkgData.insurancesSelected = _insuranceSelected;
            $scope.$broadcast("onBookingSummaryUpdate");

            var _type = -1;
            if (bkgData.bookingRequest.paymentMode == 5)
                _type = 4;

            if (bkgData.paypalToken) {
                $scope.paypalToken = bkgData.paypalToken;
                if ($scope.paypalToken) {
                    if ($scope.paypalModule != null) {
                        $scope.paypalModule.teardown(function () {
                            $scope.paypalModule = null;
                        });
                    }
                    braintree.client.create({ authorization: $scope.paypalToken }, function (clientErr, clientInstance) {
                        if (clientErr) {
                            window.location.href = $window.site.currentAirRequest.finishErrorUrl;
                            //console.error('Error creating client:', clientErr);
                            return;
                        }
                        braintree.paypal.create({ client: clientInstance }, function (paypalErr, paypalInstance) {
                            // Stop if there was a problem creating PayPal.
                            // This could happen if there was a network error or if it's incorrectly configured.
                            if (paypalErr) {
                                window.location.href = $window.site.currentAirRequest.finishErrorUrl;
                                //console.error('Error creating PayPal:', paypalErr);
                                return;
                            }
                            $scope.paypalModule = paypalInstance;
                        });
                    });
                }
                else {
                    window.location.href = $window.site.currentAirRequest.finishErrorUrl;
                }
            }
            else {
                window.location.href = $window.site.currentAirRequest.finishErrorUrl;
            }

            $scope.goTo = function (item) {
                //$window.sendAnalytics('/Flightsearch/Payment/ConfirmationPaypal', 'Payment Confirmation Paypal');
                //Tracking
                try {
                    //GTM
                    if (trackingService.isActivated()) {
                        trackingService.pushPageData('/Flightsearch/Payment/ConfirmationPaypal', 'Payment Confirmation Paypal', lng, false);
                    }
                }
                catch (ex) {
                    console.log(ex.message);
                    $window.trackingError = ex.message;
                }

                $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Payment/ConfirmationPaypal'));

                if ($scope.paypalRunning == false) {
                    if ($scope.paypalModule != null) {
                        $scope.paypalRunning = true;
                        $window.FLoading({ vAction: 'show' });

                        $scope.paypalModule.tokenize({
                            flow: 'checkout',
                            amount: bkgData.prixTotal.value,
                            currency: 'EUR',
                            locale: 'fr_FR',
                            displayName: 'la boutique'
                        }, function (tokenizeErr, payload) {
                            // Stop if there was an error.
                            if (tokenizeErr) {
                                if (tokenizeErr.type !== 'CUSTOMER') {
                                    window.location.href = $window.site.currentAirRequest.finishErrorUrl;
                                    //console.error('Error tokenizing:', tokenizeErr);
                                }
                                else {
                                    $window.FLoading({ vAction: 'hide' });
                                    $scope.paypalRunning = false;
                                }
                                return;
                            }

                            // Tokenization succeeded!
                            $window.FLoading({ vAction: 'show' });
                            //$window.sendAnalytics('/Flightsearch/Payment/ConfirmationPaypalReceived', 'Payment Confirmation Paypal Received');
                            //Tracking
                            try {
                                //GTM
                                if (trackingService.isActivated()) {
                                    trackingService.pushPageData('/Flightsearch/Payment/ConfirmationPaypalReceived', '/Flightsearch/Payment/ConfirmationPaypalReceived', lng, false);
                                }
                            }
                            catch (ex) {
                                console.log(ex.message);
                                $window.trackingError = ex.message;
                            }

                            $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Payment/ConfirmationPaypalReceived'));

                            $scope.apiClient.processPayment({
                                "purchaseId": $scope.bkgData.purchaseResponse.purchaseId,
                                "paymentId": bkgData.bookingResponse.paymentId,                                
                                "paypalNonce": payload.nonce,
                                "paymentMethod": $scope.bkgData.bookingRequest.paymentMethod,
                                "transactionMethod": $scope.bkgData.bookingRequest.paymentMethod.transactionMethod
                            }).success(function (data) {
                                var finishUrl = tseUtil.Url.appendValueToUrlBefore($window.site.currentAirRequest.finishUrl, "prqid", data.paymentRequestId);
                                window.location.href = finishUrl;

                                //$window.sendAnalytics('/Flightsearch/Payment/PaypalPaymentSuccess', 'Paypal Payment Success');

                                //Tracking
                                try {
                                    //GTM
                                    if (trackingService.isActivated()) {
                                        trackingService.pushPageData('/Flightsearch/Payment/PaypalPaymentSuccess', 'Paypal Payment Success', lng, false);
                                    }
                                }
                                catch (ex) {
                                    console.log(ex.message);
                                    $window.trackingError = ex.message;
                                }

                                $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Payment/PaypalPaymentSuccess'));
                            }).error(function () {
                                window.location.href = $window.site.currentAirRequest.finishErrorUrl;
                            });
                        });
                    }
                }
            };
        }
        else {
            $window.FLoading({ vAction: 'show' });
            window.location.href = searchUrl;
        }
    }
    else {
        $window.FLoading({ vAction: 'show' });
        window.location.href = searchUrl;
    }

    //Taxes details click
    $scope.$on("displayTaxesDetailsClick", function (event, args) {
        $scope.displayTaxesDetailModal = true;
        $scope.modalTaxesKey = args.key.replaceAll(":", "-");
        $scope.modalTaxesKey = $scope.modalTaxesKey.replaceAll("\\|", "-");
        $scope.modalTaxesKeyAux = $scope.modalTaxesKey + "-" + args.paxFares.paxType;
        $scope.modalTaxesPaxFares = args.paxFares;
        $scope.$broadcast("updateTaxesDetailModalData", { key: $scope.modalTaxesKey, fares: $scope.modalTaxesPaxFares });

        //Remodal workaround
        $(document).find(".remodal").each(function (a, c) {
            var d = $(c), e = d.data("remodal-options");
            e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                d["remodal"](e)
        });

        $timeout(function () {
            $("[data-remodal-id='taxes-detail-modal-" + $scope.modalTaxesKeyAux + "']").remodal().open();
        },
            200, false);

    });

}]);