webClient.controller('WorldTourFormAirNewZealand', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {
    $window.$scope = $scope;
    $scope._util = tseUtil;
    $scope.getText = function (key) { return window.culture.texts[key]; };
    $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
    $scope.sent = 0;
    $scope.worldTour = { segments: [{ departure: "", arrival: "", date: "" }] };
    $scope.worldTour.reference = "00006";

    //Stops:
    // 3 -> 454
    // 4 -> 620
    // 4.1 -> 632
    // 5 -> 764
    // 6 -> 1130
    $scope.stops = parseInt(tseUtil.Location.getQueryStringVar("s"), 8) / 100;
    $scope.way = window.location.href.indexOf("Asia") != -1 ? 0: 1;

    $(document).ready(function () {
        $scope.init();
    });    
    $scope.init = function () {        
        var dateMinAux = new Date();
        var dateMin = new Date(2018, 10, 1);

        if ($scope.isDateAfter(dateMinAux, dateMin))
            dateMin = dateMinAux;

        dateMin = moment(dateMin).format('DD/MM/YYYY');
        var dateMax = new Date(2019, 11, 31);
        dateMax = moment(dateMax).format('DD/MM/YYYY');
        var aDepartureInputs = $("[name='i-date-departure']");
        for (var i = 0; i < aDepartureInputs.length; i++) {
            aDepartureInputs[i].id = "i-date-departure-" + i;
            aDepartureInputs[i].classList.remove("c-field-ok");
            aDepartureInputs[i].classList.remove("c-field-ko");
            aDepartureInputs[i].children[1].setAttribute("ng-change", "validateSegmentsDate(" + aDepartureInputs[i].children[1].attributes['ng-model'].value + ",'#i-date-departure-" + i + "')");
            aDepartureInputs[i].children[1].setAttribute('data-check', "{check:'date',date_min:'" + dateMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
        }
    };

    $scope.selectWay = function () {
        var stopsNumber = tseUtil.Location.getQueryStringVar("s");
        if ($scope.worldTour.Way === "Asie") {
            var url = "/page/worldtourb2c/FormAirNewZealandAsia?s=" + stopsNumber;
            window.location.href = url;
        }
        else if ($scope.worldTour.Way === "America") {
            var url = "/page/worldtourb2c/FormAirNewZealandAmerica?s=" + stopsNumber;
            window.location.href = url;
        }
    };

    $scope.callDrop = function (_event, _type) {
        var varIsCountry = (_type == "country") ? 1 : 0;
        var name = "i-" + _type;
        var value = $("#ul-" + _type).get(0);
        $window.FSelect({ e: _event, myObj: value, vName: name, isCountry: varIsCountry });
    };

    $scope.callTitlePop = function (_event, _type) {
        var value = _event.target;
        var name = "i-" + _type + "-pop";
        $window.FPop({ oTarget: value, iPop: name, vStyle: "classic"});
    };

    $scope.validateSegmentsDate = function (dateSegmentMin, idDateCalendar) {
        var dateMax = new Date(2019, 11, 31);
        dateMax = moment(dateMax).format('DD/MM/YYYY');
        var fields = document.getElementsByName("i-date-departure");
        for (var i = 0; i < fields.length; i++) {
            if ($('input', '#i-date-departure-' + i).val() != "") {
                var nextInput = i + 1;
                var dateSegmentMin2 = $('input', '#i-date-departure-' + i).val();
                dateSegmentMin2 = moment(dateSegmentMin2, "DD/MM/YYYY");
                dateSegmentMin2 = dateSegmentMin2.add(1, 'days');
                dateSegmentMin2 = moment(dateSegmentMin2).format('DD/MM/YYYY');
                $('input', '#i-date-departure-' + nextInput).attr('data-check', "{check:'date',date_min:'" + dateSegmentMin2 + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
                if ($('input', '#i-date-departure-' + nextInput).val() != "") {
                    if ($scope.isDateAfter(dateSegmentMin2, $('input', '#i-date-departure-' + nextInput).val())) {
                        $('input', '#i-date-departure-' + nextInput).parent().removeClass("c-field-ok");
                        $('input', '#i-date-departure-' + nextInput).parent().addClass("c-field-ko");
                    }
                    else {
                        $('input', '#i-date-departure-' + nextInput).parent().removeClass("c-field-ko");
                        $('input', '#i-date-departure-' + nextInput).parent().addClass("c-field-ok");
                    }
                }
            }
        }
    };

    $scope.isDateAfter = function (arg1, arg2) {
        var mArg1, mArg2;
        mArg1 = (typeof (arg1) === "string") ? moment(arg1, "DD/MM/YYYY") : moment(arg1);
        mArg2 = (typeof (arg2) === "string") ? moment(arg2, "DD/MM/YYYY") : moment(arg2);
        return mArg1.isAfter(mArg2);
    };
    $scope.$watch(function () {
        return $scope.worldTour.pacifiqueDepartureCity;
    }, function () {
        var oAmericaDateField = $("[name='goDateAmerica']").length>0 ? $("[name='goDateAmerica']")[0].parentElement : null;
        if ($scope.worldTour.pacifiqueDepartureCity === "Honolulu (Hawaïi)" && ($scope.stops === 5 || $scope.stops === 6) && $scope.way === 0) {            
            oAmericaDateField.children[0].innerHTML = "et repartir de Los Angeles le";
            document.getElementById("i-america-options-departure").style.display = "block";
            document.getElementById("i-america-options-departure").children[1].setAttribute("data-check", "{check:'required'}");
        }
        if ($scope.worldTour.pacifiqueDepartureCity && $scope.worldTour.pacifiqueDepartureCity !== "Honolulu (Hawaïi)" && ($scope.stops === 5 || $scope.stops === 6) && $scope.way === 0) {
            oAmericaDateField.children[0].innerHTML = "Je souhaiterais repartir de Los Angeles le";
            document.getElementById("i-america-options-departure").style.display = "none";
            document.getElementById("i-america-options-departure").children[1].setAttribute("data-check", "");
        }
        if ($scope.worldTour.pacifiqueDepartureCity && $scope.worldTour.pacifiqueDepartureCity === "Papeete (Polynésie)" && $scope.stops === 4.1) {
            oAmericaDateField.children[0].innerHTML = "Je souhaiterais repartir de Los Angeles (vol via Londres) le";
        }
        else if ($scope.worldTour.pacifiqueDepartureCity && $scope.worldTour.pacifiqueDepartureCity === "Rarotonga (Îles Cook)" && $scope.stops === 4.1) {
            oAmericaDateField.children[0].innerHTML = "Je souhaiterais repartir de Los Angeles le";
        }
    });
    $scope.sendRequest = function () {
        var i = 0;
        for (i = 0 ; i <= $scope.stops ; i++) {
            var idDateCalendar = "#i-date-departure-" + i;
            var attr = JSON.parse(JSON.stringify(eval("(" + $('input', idDateCalendar).attr('data-check') + ")")));
            attr.birthday = false;
            $('input', idDateCalendar).attr('data-check', JSON.stringify(attr));
        }
        $scope.worldTour.error = !$window.XLFormValid({ iCont: 'i-form-tour' });

        if ($scope.worldTour.error)
            return;

        // Asia Way
        if ($scope.way == 0) {
            $scope.worldTour.segments = [{ departure: '', arrival: '', date: '' }];
            $scope.worldTour.segments[0].departure = 'Paris';
            $scope.worldTour.segments[0].arrival = $scope.worldTour.AsianArrivalCity;
            $scope.worldTour.segments[0].date = $scope.worldTour.dateDepartureParis;


            $scope.worldTour.segments.push(
                {
                    departure: $scope.worldTour.AsianDepartureCity,
                    arrival: "Auckland",
                    date: $scope.worldTour.AsianDate
                });
            var departureNZCity = "Auckland";
            var departureNZDate = $scope.worldTour.NZdate;

            if ($scope.stops == 3) {
                if ($scope.worldTour.optionNZ) {
                    $scope.worldTour.segments.push({ departure: "Auckland" , arrival: $scope.worldTour.stopNzCity, date: $scope.worldTour.optionNZdate });
                }
                $scope.worldTour.segments.push({ departure: departureNZCity, arrival: $scope.worldTour.AmericaArrivalCity, date: departureNZDate });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.AmericaDepartureCity, arrival: "Paris", date: $scope.worldTour.AmericaDateDeparture });
            }
            else if ($scope.stops == 4) {
                $scope.worldTour.segments.push({ departure: departureNZCity, arrival: $scope.worldTour.pacifiqueDepartureCity, date: departureNZDate });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.pacifiqueDepartureCity, arrival: departureNZCity, date: $scope.worldTour.PacifiqueDateDeparture });
                $scope.worldTour.segments.push({ departure: departureNZCity, arrival: $scope.worldTour.AmericaArrivalCity, date: $scope.worldTour.NZdate2 });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.AmericaDepartureCity, arrival: "Paris", date: $scope.worldTour.AmericaDateDeparture });
            }
            else if ($scope.stops == 4.1) {
                $scope.worldTour.segments.push({ departure: departureNZCity, arrival: $scope.worldTour.pacifiqueDepartureCity, date: departureNZDate });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.pacifiqueDepartureCity, arrival: "Los Angeles", date: $scope.worldTour.PacifiqueDateDeparture });
                $scope.worldTour.segments.push({ departure: "Los Angeles", arrival: "Paris", date: $scope.worldTour.AmericaDateDeparture });
            }
            else if ($scope.stops == 5) {
                $scope.worldTour.segments.push({ departure: departureNZCity, arrival: $scope.worldTour.pacifiqueDepartureCity, date: departureNZDate });
                $scope.worldTour.pacifiqueDepartureCity === "Honolulu (Hawaïi)" ? $scope.worldTour.segments.push({ departure: "Honolulu (Hawaïi)", arrival: $scope.worldTour.AmericaDepartureCity, date: $scope.worldTour.PacifiqueDateDeparture }) : $scope.worldTour.segments.push({ departure: $scope.worldTour.pacifiqueDepartureCity, arrival: "Los Angeles", date: $scope.worldTour.PacifiqueDateDeparture });
                $scope.worldTour.pacifiqueDepartureCity === "Honolulu (Hawaïi)" ? $scope.worldTour.segments.push({ departure: $scope.worldTour.AmericaDepartureCity, arrival: "Londres", date: $scope.worldTour.AmericaDateDeparture }) : $scope.worldTour.segments.push({ departure: "Los Angeles", arrival: "Londres", date: $scope.worldTour.AmericaDateDeparture });
                $scope.worldTour.segments.push({ departure: "Londres", arrival: "Paris", date: $scope.worldTour.londonDate });
            }
            else if ($scope.stops == 6) {
                $scope.worldTour.segments.push({ departure: departureNZCity, arrival: $scope.worldTour.australiaDepartureCity, date: departureNZDate });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.australiaDepartureCity, arrival: departureNZCity, date: $scope.worldTour.AustraliaDateDeparture });
                $scope.worldTour.segments.push({ departure: departureNZCity, arrival: $scope.worldTour.pacifiqueDepartureCity, date: $scope.worldTour.NZdate2 });
                $scope.worldTour.pacifiqueDepartureCity === "Honolulu (Hawaïi)" ? $scope.worldTour.segments.push({ departure: "Honolulu (Hawaïi)", arrival: $scope.worldTour.AmericaDepartureCity, date: $scope.worldTour.PacifiqueDateDeparture }) : $scope.worldTour.segments.push({ departure: $scope.worldTour.pacifiqueDepartureCity, arrival: "Los Angeles", date: $scope.worldTour.PacifiqueDateDeparture });
                $scope.worldTour.pacifiqueDepartureCity === "Honolulu (Hawaïi)" ? $scope.worldTour.segments.push({ departure: $scope.worldTour.AmericaDepartureCity, arrival: "Londres", date: $scope.worldTour.AmericaDateDeparture }) : $scope.worldTour.segments.push({ departure: "Los Angeles", arrival: "Londres", date: $scope.worldTour.AmericaDateDeparture });
                $scope.worldTour.segments.push({ departure: "Londres", arrival: "Paris", date: $scope.worldTour.londonDate });
            }
        }
        else {
            $scope.worldTour.segments = [];

            if ($scope.stops == 3) {
                $scope.worldTour.segments.push({ departure: 'Paris', arrival: $scope.worldTour.AmericaArrivalCity, date: $scope.worldTour.dateDepartureParis });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.AmericaDepartureCity, arrival: 'Auckland', date: $scope.worldTour.AmericaDateDeparture });
                var nzOut = "Auckland";
                var nzDate = $scope.worldTour.NZdate;
                if ($scope.worldTour.optionNZ) {
                    $scope.worldTour.segments.push({ departure: "Auckland", arrival: $scope.worldTour.stopNzCity, date: $scope.worldTour.optionNZDate });
                }
                $scope.worldTour.segments.push({ departure: nzOut, arrival: $scope.worldTour.AsianArrivalCity, date: nzDate });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.AsianDepartureCity, arrival: 'Paris', date: $scope.worldTour.AsianDateDeparture });
            }
            else if ($scope.stops == 4) {
                $scope.worldTour.segments.push({ departure: 'Paris', arrival: $scope.worldTour.AmericaArrivalCity, date: $scope.worldTour.dateDepartureParis });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.AmericaDepartureCity, arrival: 'Auckland', date: $scope.worldTour.AmericaDateDeparture });
                $scope.worldTour.segments.push({ departure: 'Auckland', arrival: $scope.worldTour.pacifiqueDepartureCity, date: $scope.worldTour.NZdate });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.pacifiqueDepartureCity, arrival: 'Auckland', date: $scope.worldTour.PacificDateDeparture });
                $scope.worldTour.segments.push({ departure: 'Auckland', arrival: $scope.worldTour.AsianArrivalCity, date: $scope.worldTour.NZdate2 });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.AsianDepartureCity, arrival: 'Paris', date: $scope.worldTour.AsianDateDeparture });                
            }
            else if ($scope.stops == 4.1) {
                $scope.worldTour.segments.push({ departure: 'Paris', arrival: 'Los Angeles', date: $scope.worldTour.dateDepartureParis });
                $scope.worldTour.segments.push({ departure: 'Los Angeles', arrival: $scope.worldTour.pacifiqueDepartureCity, date: $scope.worldTour.AmericaDateDeparture });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.pacifiqueDepartureCity, arrival: 'Auckland', date: $scope.worldTour.PacificDateDeparture });
                $scope.worldTour.segments.push({ departure: 'Auckland', arrival: $scope.worldTour.AsianArrivalCity, date: $scope.worldTour.NZdate });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.AsianDepartureCity, arrival: 'Paris', date: $scope.worldTour.AsianDateDeparture });
            }
            else if ($scope.stops == 5) {
                $scope.worldTour.segments.push({ departure: 'Paris', arrival: 'Londres', date: $scope.worldTour.dateDepartureParis });
                $scope.worldTour.segments.push({ departure: 'Londres', arrival: 'Los Angeles', date: $scope.worldTour.londonDate });
                $scope.worldTour.segments.push({ departure: 'Los Angeles', arrival: $scope.worldTour.pacifiqueDepartureCity, date: $scope.worldTour.AmericaDateDeparture });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.pacifiqueDepartureCity, arrival: 'Auckland', date: $scope.worldTour.PacifiqueDateDeparture });
                $scope.worldTour.segments.push({ departure: 'Auckland', arrival: $scope.worldTour.AsianArrivalCity , date: $scope.worldTour.NZdate });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.AsianDepartureCity, arrival: 'Paris', date: $scope.worldTour.AsianDateDeparture });
            }
            else if ($scope.stops == 6) {
                $scope.worldTour.segments.push({ departure: 'Paris', arrival: 'Londres', date: $scope.worldTour.dateDepartureParis });
                $scope.worldTour.segments.push({ departure: 'Londres', arrival: 'Los Angeles', date: $scope.worldTour.londonDate });
                $scope.worldTour.segments.push({ departure: 'Los Angeles', arrival: $scope.worldTour.pacifiqueDepartureCity, date: $scope.worldTour.AmericaDateDeparture });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.pacifiqueDepartureCity, arrival: 'Auckland', date: $scope.worldTour.PacifiqueDateDeparture });
                $scope.worldTour.segments.push({ departure: 'Auckland', arrival: $scope.worldTour.australiaDepartureCity, date: $scope.worldTour.NZdate });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.australiaDepartureCity, arrival: 'Auckland', date: $scope.worldTour.AustraliaDateDeparture });
                $scope.worldTour.segments.push({ departure: 'Auckland', arrival: $scope.worldTour.AsianArrivalCity, date: $scope.worldTour.NZdate2 });
                $scope.worldTour.segments.push({ departure: $scope.worldTour.AsianDepartureCity, arrival: 'Paris', date: $scope.worldTour.AsianDateDeparture });
            }
        }

        if (!$scope.worldTour.error) {
                $window.FLoading({ vAction: 'show' });
                $scope.apiClient.sendWorldTourForm($scope.worldTour).success(function (data) {
                    $window.FLoading({ vAction: 'hide' });
                    if (data.status == 0) {
                        $scope.sent = 1;
                        window.scrollTo(0, 0);
                    }
                    else {
                        $window.FLoading({ vAction: 'hide' });
                        $window.XLConfirm({
                            vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                            vTextYes: 'OK Merci',
                            vHideNo: true
                        });
                    }
                }).error(function () {
                    $window.FLoading({ vAction: 'hide' });
                    $window.XLConfirm({
                        vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                        vTextYes: 'OK Merci',
                        vHideNo: true
                    });
                });
        }
        else {
            $scope.init();
            $window.XLConfirm({
                vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                vTextYes: 'OK Merci',
                vHideNo: true
            });
        }
    };

    $scope.setOptionToNext = function (dateSegmentMin, next) {
        var dateMax = new Date(2019, 11, 31);
        dateMax = moment(dateMax).format('DD/MM/YYYY');

        dateSegmentMin = moment(dateSegmentMin, "DD/MM/YYYY");
        dateSegmentMin = dateSegmentMin.add(1, 'days');
        dateSegmentMin = moment(dateSegmentMin).format('DD/MM/YYYY');
        $('input', next).attr('data-check', "{check:'date',date_min:'" + dateSegmentMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");

        if ($('input', next).val() != "") {
            if ($scope.isDateAfter(dateSegmentMin, $('input', next).val())) {
                $('input', next).parent().removeClass("c-field-ok");
                $('input', next).parent().addClass("c-field-ko");
            }
            else {
                $('input', next).parent().removeClass("c-field-ko");
                $('input', next).parent().addClass("c-field-ok");
            }
        }
    };

    $scope.optionNZ = function (val,prevInputDate) {
        if (val) {
            if (!$scope.worldTour.optionNZ)
                FSmoothOpen({ oOpener: $('#i-radio-stop1-yes')[0], iSmooth: 'i-smooth-optionNZ' });

            $scope.worldTour.optionNZ = true;
            var dateMin = $(prevInputDate)[0].value;
            var dateMax = document.getElementsByName("goDateNZ")[0].value!=="" ? document.getElementsByName("goDateNZ")[0].value : "31/12/2019";

            $("#i-stopNz-text").attr('data-check', "{check:'required'}");            
            $('input', '#i-date-departure-optionNz').attr('data-check', "{check:'date',date_min:'" + dateMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
        }
        else {
            if ($scope.worldTour.optionNZ) {
                $scope.worldTour.optionNZ = false;
                $("#i-stopNz-text").removeAttr('data-check', "{check:'required'}");
                $("#i-date-departure-optionNz input").removeAttr('data-check');
                $scope.setOptionToNext($scope.worldTour.NZdate, '#i-date-departure-3');
                FSmoothOpen({ oOpener: $('#i-radio-stop1-no')[0], iSmooth: 'i-smooth-optionNZ' });
            }
        }
    };
    $(function () {
        stopsNum = document.getElementsByClassName("stop-num");
        for (var i = 0; i < stopsNum.length; i++) {
            if (i == 0) stopsNum[i].innerHTML = "1ère";
            else stopsNum[i].innerHTML = (i + 1) + "ème";
        }
    });
}]);
