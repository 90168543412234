webClient.directive('ngUpsellSummary', ['$window', 'templateService', 'tseAirResultsHelper', 'tseApiClient', 
    function ($window, templateService, tseAirResultsHelper, tseApiClient) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        bookingdata: "=",
        showpaxes: "=",
        showprint: "=",
        newversion:"="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_PurchaseSummary.html');

        //Initialize Variables
        $scope.forceDisplayPrice = true;
        $scope.getUrl = $window.getUrl;
        $scope.siteType = $window.site.type;
        $scope.getStaticFolder = $window.getStaticFolder;
        $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
        $scope.getTypeStaticFolder = $window.getTypeStaticFolder;
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        window.airHelper = tseAirResultsHelper;        
        $scope.hidePriceCondition = true;
        $scope.currentCabinClass = $window.site.currentAirRequest.summary.cabinClass;
        $scope.paxtype = ["Adulte", "Enfant", "Bébé"];
        $scope.paxamount = [$scope.bookingdata.request.adults, $scope.bookingdata.request.childs, $scope.bookingdata.request.infants];
        $scope.hasWarningRoundTrip = false;
        
        $scope.isProductTO = $scope.siteType === 2 && $scope.bookingdata.purchaseResponse.isProductTo;
        var showNotes = $scope.bookingdata.purchaseResponse.proposalsRequested.fareNotes.every(function (f, i) { return f.note != null });
        $scope.showNotes = $scope.siteType === 2 && showNotes;

        $scope.displayBrandedFaresSummary = $window.displayBrandedFaresSummary && ($scope.bookingdata.purchaseResponse.brandedFaresServices && $scope.bookingdata.purchaseResponse.brandedFaresServices.length > 0);

        $scope.auxItineraries = $window.objectClone($scope.bookingdata.purchaseResponse.itineraries);
        $scope.auxItineraries = $scope.auxItineraries.groupByKey("key");

        $scope.flightTypeIsOneWay = $scope.bookingdata.purchaseResponse.tripType === 1;
        $scope.flightTypeIsRoundTrip = $scope.bookingdata.purchaseResponse.tripType === 2;
        $scope.flightTypeIsOpenJaw = $scope.bookingdata.purchaseResponse.tripType === 3;
        $scope.flightTypeIsMultiDestinationation = $scope.bookingdata.purchaseResponse.tripType === 4;
        
        //Flags
        $scope.enableOption = $window.enableOption && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');
        $scope.hideMessageTO = $window.hideMessageTO;
        $scope.enableBrandedFares = $window.enableBrandedFares;
        $scope.firstTime = true;
        $scope.enableUpSellProducts = $window.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true';

        //Taxes details
        $scope.createTaxesDetailModal = function (key, paxFares) {

            var marketingCarriers = [];

            $scope.bookingdata.purchaseResponse.itineraries.each(function (value, key) {
                marketingCarriers.push(value.marketingCarrier.iata);
            });

            $scope.$emit("displayTaxesDetailsClick", { key: key.toString(), paxFares: paxFares, marketingCarriers: marketingCarriers});
        };    

        getBundleWidget("mrfly-widget-request-recap.js").done(function () {
            console.log("Loading done");
            //set params do mapping

            var airSearchRequest = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequest']();

            airSearchRequest.adultNumber = $scope.bookingdata.request.adults;
            airSearchRequest.childNumber = $scope.bookingdata.request.childs;
            airSearchRequest.infantNumber = $scope.bookingdata.request.infants;
            airSearchRequest.isOneWay = $scope.bookingdata.request.itineraries.length == 1;
            airSearchRequest.isRoundTrip = $scope.bookingdata.request.itineraries.length == 2 && $scope.bookingdata.request.itineraries[0].origin.cityIATA.localeCompare($scope.bookingdata.request.itineraries[1].destination.cityIATA) === 0;
            airSearchRequest.isOpenJaw = $scope.bookingdata.request.itineraries.length === 2 && $scope.bookingdata.request.itineraries[0].origin.cityIATA.localeCompare($scope.bookingdata.request.itineraries[1].destination.cityIATA) !== 0;
            airSearchRequest.isMultiDestination = $scope.bookingdata.request.itineraries.length > 2;
            airSearchRequest.cabinClass = $scope.bookingdata.request.cabinClass;

            //Map itineraries
            airSearchRequest.itineraries = [];
            $scope.bookingdata.request.itineraries.forEach(function (itinerary) {
                var ins = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequestItinerary']();

                ins.date = itinerary.goDate.date;

                ins.departureAirportIata = itinerary.origin.value;
                ins.departureCityIata = itinerary.origin.cityIATA;
                ins.departureName = itinerary.origin.cityName;

                ins.arrivalAirportIata = itinerary.destination.value;
                ins.arrivalCityIata = itinerary.destination.cityIATA;
                ins.arrivalName = itinerary.destination.cityName;

                airSearchRequest.itineraries.push(ins);
            });
            mrflyWidgets['mrfly-widget-request-recap'].airSearchRequest = airSearchRequest;
            mrflyWidgets['mrfly-widget-request-recap'].locale = $window.lng.substring(0, 2);
            mrflyWidgets['mrfly-widget-request-recap'].language = $window.lng;
        });

        getBundleWidget("mrfly-widget-itinerary-recap.js").done(function () {
            console.log("Loading done");
            $scope.bookingdata.purchaseResponse.itineraries.forEach(function (itinerary, key) {
                $scope.bookingdata.purchaseResponse.itineraries[key].isProductMasstock = $scope.bookingdata.purchaseResponse.masstock;
                $scope.bookingdata.purchaseResponse.itineraries[key].isProductTo = $scope.isProductTO;
            });
            //set params do mapping
            mrflyWidgets['mrfly-widget-itinerary-recap'].locale = $window.lng.substring(0, 2);
            mrflyWidgets['mrfly-widget-itinerary-recap'].language = $window.lng;
            mrflyWidgets['mrfly-widget-itinerary-recap'].updateItineraries($scope.bookingdata.purchaseResponse.itineraries);
            mrflyWidgets['mrfly-widget-itinerary-recap'].requestcabinclass = $scope.bookingdata.request.cabinClass;
            mrflyWidgets['mrfly-widget-itinerary-recap'].currentCabinClass = $scope.currentCabinClass;
            mrflyWidgets['mrfly-widget-itinerary-recap'].cabinclasses = $scope.bookingdata.cabinclasses;
            mrflyWidgets['mrfly-widget-itinerary-recap'].showbaggages = $scope.showpaxes != true;
            mrflyWidgets['mrfly-widget-itinerary-recap'].fares = $scope.bookingdata.purchaseResponse.proposalsRequested.fares;
            mrflyWidgets['mrfly-widget-itinerary-recap'].enableaddluggages = $scope.disableaddluggages ? false : true;
        });
        
        $scope.updateTemplate = function () {
            //Additional baggages
            $scope.addtionalBaggages = [];
            $scope.addBaggages = [];
            //if ($scope.bookingdata) {
            //    $scope.bookingdata.bookingRequest.passengers.each(function (p) {
            //        for (var item in p.baggageByItinerary) {
            //            if (p.baggageByItinerary[item].quantity > 0) {
            //                var auxOrigin = "";
            //                var auxDestination = "";

            //                $scope.bookingdata.purchaseResponse.itineraries.any(function(it) {
            //                    if (it.key == item) {
            //                        auxOrigin = it.origin.cityIata;
            //                        auxDestination = it.destination.cityIata;
            //                        return true;
            //                    }
            //                });

            //                var auxBag = {
            //                    quantity: p.baggageByItinerary[item].quantity,
            //                    weight: p.baggageByItinerary[item].weight,
            //                    itineraryKey: item,
            //                    origin: auxOrigin,
            //                    destination: auxDestination,
            //                    price: p.baggageByItinerary[item].totalPrice.value,
            //                    key: p.baggageByItinerary[item].key
            //                }

            //                $scope.addtionalBaggages.push(auxBag);
            //            }
            //        }
            //    });
            //}

            var baggagesItinerary = $scope.addtionalBaggages.groupByKey('key');

            //Comprobar estar rutina
            for (var key in baggagesItinerary) {
                if (!$window.isArray(baggagesItinerary[key]))
                   var auxBaggage = baggagesItinerary[key];
                else
                   var auxBaggage = baggagesItinerary[key][0];

                if (auxBaggage.quantity > 0) {
                    var quantity = baggagesItinerary[key].length != undefined ? baggagesItinerary[key].length : 1;

                    var bgType = {
                        price: auxBaggage.price,
                        quantity: auxBaggage.quantity,
                        weight: auxBaggage.weight,
                        text: auxBaggage.text,
                        key: key,
                        typeQuantity: baggagesItinerary[key].length != undefined ? baggagesItinerary[key].length : 1,
                        totalQuantity: quantity * auxBaggage.quantity,
                        totalPrice: quantity * auxBaggage.price,
                        origin: auxBaggage.origin,
                        destination: auxBaggage.destination
                    };
                    $scope.addBaggages.push(bgType);
                }
            }
            $scope.updateTotal();
            $scope.$emit("onBookingSummaryUpdateCompleted", $scope.bookingdata);
        };

        //Get Fares Notes
        $scope.GetNotes = function (obj, itKey) {
            if (obj && obj.iPop) {
                var iPropPre = $("#" + obj.iPop + " pre");
                iPropPre.text("");

                if ($scope.bookingdata.purchaseResponse.proposalsRequested) {
                    $window.FLoading({ vAction: "show" });
                    var note = "";
                    var fareNoteAux = $scope.bookingdata.purchaseResponse.proposalsRequested.fareNotes.getByKeyValue("key", itKey);

                    if (fareNoteAux != null)
                        note = fareNoteAux.note;

                    if (note != null && note != "")
                        iPropPre.text(note);

                    $scope.FPop(null, obj);
                    $window.FLoading({ vAction: "hide" });
                }

            }
        };
    
        //Update Total amounts
        $scope.updateTotal = function () {
            var rsTotals = $scope.updateTotals($scope.bookingdata.purchaseResponse.proposalsRequested.totalAmount.value);
            $scope.prixTotal = rsTotals[0];
            $scope.prixTotalWithoutMargin = rsTotals[1];
            $scope.bookingdata.prixTotal = $scope.prixTotal;
            $scope.bookingdata.prixTotalWithoutMargin = $scope.prixTotalWithoutMargin;
        };

        //Update totals Amounts
        $scope.updateTotals = function (baseFare) {
            //var counter = baseFare + $scope.proposal.totalFee.value + $scope.proposal.totalMargin.value;
            var counter = baseFare + $scope.bookingdata.purchaseResponse.proposalsRequested.totalAgencyMargin.value; //Check totalFee?
            
            //BestPrice
            //if ($scope.bookingdata.bookingRequest.bestPriceFare && !$scope.bookingdata.bookingRequest.brandKey)
            //    counter += $scope.proposal.totalBestPrice.value; ??Manuel

            //Flexy
            //if ($scope.bookingdata && $scope.bookingdata.bookingRequest.flexyFare)
            //    counter += $scope.bookingdata.purchaseResponse.proposalsRequested.totalFlexyAmount.value;

            ////Insurance Annulation
            //if ($scope.bookingdata.insurancesSelected.isAnnul && $scope.bookingdata.insurancesSelected.isAnnul.amount > 0)
            //    counter += $scope.bookingdata.insurancesSelected.isAnnul.amount;

            ////Insurance repatriament
            //if ($scope.bookingdata.insurancesSelected.isRepatriament && $scope.bookingdata.insurancesSelected.isRepatriament.amount > 0)
            //    counter += $scope.bookingdata.insurancesSelected.isRepatriament.amount;

            ////Insurance Pack
            //if ($scope.bookingdata.insurancesSelected.isAnnulRepatriament && $scope.bookingdata.insurancesSelected.isAnnulRepatriament.amount > 0)
            //    counter += $scope.bookingdata.insurancesSelected.isAnnulRepatriament.amount;

            ////Additional Baggages
            //if ($scope.bookingdata.bookingRequest && $scope.bookingdata.bookingRequest.passengers) {
            //    $scope.bookingdata.bookingRequest.passengers.each(function(p) {
            //        for (var item in p.baggageByItinerary) {
            //            if (p.baggageByItinerary[item].quantity > 0)
            //                counter += p.baggageByItinerary[item].totalPrice.value;
            //        }
            //    });
            //}

            ////Discount Code
            //if ($scope.bookingdata.discountInfo != null && $scope.bookingdata.discountInfo.applicableAmount != 0)
            //    counter += $scope.bookingdata.discountInfo.applicableAmount;

            var val0 = tseAirResultsHelper.getNumberObject(counter);
            var val1 = tseAirResultsHelper.getNumberObject(val0.value - $scope.bookingdata.purchaseResponse.proposalsRequested.totalAgencyMargin.value);
            return [val0, val1];            
        };

        //onBookingSummaryUpdate
        $scope.$on("onBookingSummaryUpdate", function (event, args) {
            $scope.updateTemplate();
        });
        $scope.updateTemplate();
        
        $scope.getCityNameOrIata=function(airportIata)
        {
            if (airportIata != undefined && airportIata!=null) {
                if ($scope.bookingdata.airports[airportIata].cityIata) {
                    if ($scope.bookingdata.cities[$scope.bookingdata.airports[airportIata].cityIata])
                    {
                        if($scope.bookingdata.cities[$scope.bookingdata.airports[airportIata].cityIata].length>1)
                            return $scope.bookingdata.cities[$scope.bookingdata.airports[airportIata].cityIata][0].name;
                        else
                            return $scope.bookingdata.cities[$scope.bookingdata.airports[airportIata].cityIata].name;
                    }
                }
                else {
                    if ($scope.bookingdata.airports[airportIata].name)
                        return $scope.bookingdata.airports[airportIata].name;
                    else
                        return airportIata;
                }
            }
        }

        //Product TO        
        if ($scope.isProductTO) {
            $scope.hasPreBook = $scope.bookingdata.purchaseResponse.preBookToDate != null;

            if ($scope.hasPreBook) {
                $scope.ticketing = moment(($scope.bookingdata.purchaseResponse.preBookToDate), moment.ISO_8601).toDate();
            }
        }

        //Fpop
        $scope.FPop = function (_event, obj) {
            if (_event) {
                var value = _event.target;
                obj.oTarget = value;
            }

            $window.FPop(obj);
        };

        //Branded Fares Services included
        $scope.serviceIncludedFilter = function (item) {
            var auxService = "";
            $scope.bookingdata.purchaseResponse.proposalsRequested.services.each(function(s) {
                if (s.index == item.index) {
                    auxService = s;
                }
            });

            if (auxService != "")
                return auxService.servicePricing == 0;

            return false;
        };

        $scope.getProposalIncludedServices = function () {
            var includedServices = [];
            $scope.bookingdata.purchaseResponse.proposalsRequested.services.each(function (s) {
                if (s.servicePricing == 0) {
                    var serviceAux = $scope.bookingdata.purchaseResponse.brandedFaresServices.find(function (service)
                    {
                        return service.index === s.index
                    });

                    includedServices.push(serviceAux);
                }
            })

            return includedServices;
        }

        $scope.proposalIncludedServices = $scope.getProposalIncludedServices();
      
        //This routine gets the booking details in order to retrieve the baggages information with the new decoding and display it in AirPaymentResponseBody
        //newversion only is true en AirPaymentResponseBody
        if ($scope.newversion) {
            if ($scope.bookingdata && $scope.bookingdata.bookingResponse && $scope.bookingdata.bookingResponse.bookingId) {
                $scope.apiClient.AIR.getBookingDetails([$scope.bookingdata.bookingResponse.bookingId]).success(function (data) {
                    if (data && data.status == 0) {
                        $scope.showDetail = true;
                        $scope.bkgDetails = tseAirResultsHelper.getViewForBookingDetailsEntityVersion2(data);
                    }
                });
            }
        }
   };
    return directive;
}]);