/**
* @desc the ngWorldTourPassengerInfo provides support for collect the information of a passenger of Tour du Monde in B2B. 
* @example <div ng-world-tour-passenger-info pax="pax" position="$index"></div>
*/

webClient.directive('ngWorldTourPassengerInfo', ['$window', 'templateService', function ($window, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        pax: "=",
        position: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_WorldTourPassengerInfo.html');

        $scope.getUrl = $window.getUrl;
        $scope.getStaticFolder = $window.getStaticFolder;
        $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
        $scope.getTypeStaticFolder = $window.getTypeStaticFolder;

        $window.XLFormInit({ iCont: 'i-form-tour' });

        $scope.callDrop = function (_event, type) {
            var varIsCountry = (type == 'country') ? 1 : 0;
            var name = 'i-' + type + '-' + $scope.position;
            var value = $("#ul-" + type + "-" + $scope.position).get(0);
            $window.FSelect({ e: _event, myObj: value, vName: name, isCountry: varIsCountry });
        };

        $scope.callTitlePop = function (_event, type) {
            var value = _event.target;
            var name = 'i-' + type + '-' + $scope.position + '-pop';
            $window.FPop({ oTarget: value, iPop: name, vStyle: 'classic' });
        };

        //DateMax BirthDate Passenger
        $scope.dateMax = moment();
        $scope.dateMax = moment($scope.dateMax).format('DD/MM/YYYY');
    };
    return directive;
}]);
