webClient.directive('ngProductMultiDestinationInfo', ['$window', '$timeout', 'templateService', 
    function ($window, $timeout, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        product: "=",
        itineraries: "=",
        summary: "=",
        airlines: "=",
        airports: "=",
        cities: "=",
        index: "=",
        equipments: "=",
        cabinclasses: "=",
        requestcabinclass: "=",
        isloadingco2: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_ProductMultiDestinationTPL.html');

        //Initialize Variables
        $scope.siteType = $window.site.type;
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.getStaticFolder = $window.getStaticFolder;
        $scope.enableFreeFlex = window.enableFreeFlex === true;

        $scope.product.itineraries.each(function (it) {
            it.itinerariesOptions.each(function (itOpt) {
                var itOptObj = $scope.itineraries[itOpt.key];
                if (itOptObj != null) {
                    if (itOptObj.productType == 1) {
                        $scope.product.to = true;
                    }
                }
            });
        });

        //Flags
        $scope.enableOption = $window.enableOption && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');        
        
        $scope.itineraryVisibleFilter = function (item) {
            return item.visible;
        };

        $scope.product.itineraries.each(function (i) {
             if(!i.auxItinerarySelected)
                 i.auxItinerarySelected = i.itinerariesOptions[0].key;

             if (!i.itinerarySelected)
                 i.itinerarySelected = i.itinerariesOptions[0].key;
        })

        //Asignar un proposal detail por defecto para mostrar el prebook y prebookType
        if ($scope.product.auxproposalDetailSelected == undefined) {
            $scope.product.auxproposalDetailSelected = "";

            //Recorremos los itinerarios y formamos la clave de proposal detail tomando la primera opcion de itinerariesOption
            $scope.product.itineraries.each(function (productItinerary) {
                $scope.product.auxproposalDetailSelected += productItinerary.itinerariesOptions[0].key + "|";
            })

            if ($scope.product.auxproposalDetailSelected && $scope.product.auxproposalDetailSelected.endsWith("|"))
                $scope.product.auxproposalDetailSelected = $scope.product.auxproposalDetailSelected.slice(0, -1);

            //Prebook
            $scope.product.preBookType = $scope.product.proposalDetail[$scope.product.auxproposalDetailSelected].preBookType;
            $scope.product.preBook = $scope.product.proposalDetail[$scope.product.auxproposalDetailSelected].preBook;
        }

        $scope.getText = function (key) {
            return window.culture.texts[key];
        };

        $scope.onBookingClick = function (_product, _inbound) {
            if (document.getElementById("i-pop-nofee") && document.getElementById("i-pop-nofee").classList.contains("c-show")) {
                document.getElementById("i-pop-nofee").classList.remove("c-show");
                console.log("on ferme");
            } else {
                console.log("on ouvre");
            }            
         };

        $scope.showItineraries = function () {
             if ($scope.product._lateItineraries === undefined) {
                 $scope.product._lateItineraries = $scope.product.itineraries;
                 $scope.product.showRetour = true;
             } else {
                 $scope.product.showRetour = !$scope.showRetour;
             }

         };

        $scope.ffback = function (idx, _event) {
            if ($scope.product.itineraries) {
                $scope.showItineraries();
                _event.stopPropagation();
                $timeout(function () {
                    $window.FFBack(idx);

                    //Remodal workaround
                    $(document).find(".remodal").each(function (a, c) {
                        var d = $(c)
                            , e = d.data("remodal-options");
                        e ? ("string" == typeof e || e instanceof String) && (e = parseOptions(e)) : e = {},
                            d["remodal"]({ hashTracking: false });
                    });
                }, 100, false);
            }
        };

        function parseOptions(str) {
            var obj = {};
            var arr;
            var len;
            var val;
            var i;
            // Remove spaces before and after delimiters
            str = str.replace(/\s*:\s*/g, ':').replace(/\s*,\s*/g, ',');
            // Parse a string
            arr = str.split(',');
            for (i = 0, len = arr.length; i < len; i++) {
                arr[i] = arr[i].split(':');
                val = arr[i][1];

                // Convert a string value if it is like a boolean
                if (typeof val === 'string' || val instanceof String) {
                    val = val === 'true' || (val === 'false' ? false : val);
                }

                // Convert a string value if it is like a number
                if (typeof val === 'string' || val instanceof String) {
                    val = !isNaN(val) ? +val : val;
                }

                obj[arr[i][0]] = val;
            }
            return obj;
        }

        $scope.fsmoothopen = function (_oOpener, _iSmooth, _iToggle, _vToggleClass) {
            if (_oOpener.target)
                _oOpener = _oOpener.target;
            var obj = { oOpener: _oOpener, iSmooth: _iSmooth };
            if (_iToggle) obj.iToggle = _iToggle;

            if (_vToggleClass) obj.vToggleClass = _vToggleClass;

            $window.FSmoothOpen(obj);
        };

        /**
        * ensureSegments
        * @desc To display segment details
        * @param {string} itinerary itineray.key in $scope.itineraries
        */
        $scope.ensureSegments = function (itinerary) {
            if ($scope.itineraries[itinerary]._lateSegments === undefined) {
                $scope.itineraries[itinerary]._lateSegments = $scope.itineraries[itinerary].segments;
            }
        };

        $scope.ffdetb = function (indexProduct, indexItineraryChoise, itineraryChoiseKey, _event) {
            $scope.ensureSegments(itineraryChoiseKey);
            $scope.showDetailsRetour = !$scope.showDetailsRetour;
            _event.stopPropagation();
            $timeout(function () {
                $window.FFDetB(indexProduct + "-" + indexItineraryChoise);
            }, 0, false);
        };

        $scope.$on("onFilterApplied", function (event, args) {
            for (var i = 0 ; i < $scope.product.itineraries.length; i++) {
                var j = 0;
                var firstOptionSet = false;
                while (j < $scope.product.itineraries[i].itinerariesOptions.length && !firstOptionSet) {
                    if ($scope.product.itineraries[i].itinerariesOptions[j].visible) {
                        $scope.selectItineraryChoice(i, $scope.product.itineraries[i].itinerariesOptions[j].key);
                        firstOptionSet = true;
                    }
                    j++;
                }
            }
        });

        /**
        * selectItineraryChoice
        * @desc When the user select an option from many itineraries options        
        * @param {int} indexItinerary The index of the itinerary in product.itineraries array
        * @param {string} indexItineraryChoise the itineraryKey in itinerariesOptions
        */
        $scope.selectItineraryChoice = function (indexItinerary, indexItineraryChoise) {            
            $scope.product.itineraries[indexItinerary].itinerariesOptions.each(function (opt) {                
                if (opt.key == indexItineraryChoise) {
                    $scope.product.auxproposalDetailSelected = "";
                    $scope.product.itineraries[indexItinerary].itinerarySelected = opt.key;
                    $scope.product.itineraries[indexItinerary].auxItinerarySelected = opt.key;
                    $scope.product.itineraries.each(function (iAux) {
                        $scope.product.auxproposalDetailSelected += iAux.auxItinerarySelected+"|";
                    })

                    if( $scope.product.auxproposalDetailSelected &&  $scope.product.auxproposalDetailSelected.endsWith("|"))
                    $scope.product.auxproposalDetailSelected = $scope.product.auxproposalDetailSelected.slice(0, -1);
                    $scope.product.preBookType = $scope.product.proposalDetail[$scope.product.auxproposalDetailSelected].preBookType;
                    $scope.product.preBook = $scope.product.proposalDetail[$scope.product.auxproposalDetailSelected].preBook;
                }
            })
        };

        /**
        * selectProduct
        * @desc Select a product to send the request to new purchase
        * @param {int} productIndex the index of the product in the general list of products
        */
        $scope.selectProduct = function (productIndex, _event) {            
            var productKey = [];
            var merovingioInfo = {};
            merovingioInfo.product = $scope.product;
            merovingioInfo.itineraries = [];

            //Build proposal key
            $scope.product.itineraries.each(function (pItinerary) {
                if (pItinerary.itinerariesOptions.length == 1) {
                    productKey.push(pItinerary.itinerariesOptions[0].key);
                    merovingioInfo.itineraries.push($scope.itineraries[pItinerary.itinerariesOptions[0].key]);
                }
                else {
                    if (pItinerary.itinerarySelected) {
                        productKey.push(pItinerary.itinerarySelected);
                        merovingioInfo.itineraries.push($scope.itineraries[pItinerary.itinerarySelected]);
                    }
                    else {
                        productKey = [];
                        merovingioInfo.itineraries = [];
                    }                                   
                }
            });                        

            if (productKey) {
                var productKeyRequested = productKey.join([separator = '|']);

                if ($scope.product.proposalDetail[productKeyRequested]) {                    
                    var purchaseRequest = {
                        index: $scope.product.proposalDetail[productKeyRequested].index,
                        group: $scope.product.proposalDetail[productKeyRequested].group,
                        proposal: $scope.product.proposalDetail[productKeyRequested].proposal,
                        brandedFares: true,
                        requestId: $scope.summary.requestId
                    };

                    //required to bind jQuery listener
                    $(_event.target).trigger("bookingSelected", { product: $scope.product, itineraries:  merovingioInfo.itineraries, productIndex: productIndex });

                    $scope.$emit("onProductBookingClick", { purchaseRequest: purchaseRequest, merovingioInfo: merovingioInfo });                    
                }                
            }
        };

        //Available Seat
        $scope.availableSeat = function (itinerary) {
            var seats = itinerary.availableSeats;
            if (seats > 0) {
                seats = Math.max(seats, $scope.summary.totalPaxes);
                if (seats > ($scope.summary.totalPaxes + 2))
                    seats = 0; //don't show the seats message
                return seats;
            };
            return seats;
        };
    };
    return directive;
}]);
