/**
* @desc the ngBookingPageThreeB provides support for collect and displaying information of payer and payment mode only in B2B. This template is used in AirBookingBody
* @example:
* <div ng-booking-page-three-b paxinfo="bkgData.bookingRequest.paxes" bookingrequest="bkgData.bookingRequest" proposal="proposal" bkgdata="bkgData"></div>
*/

webClient.directive('ngPurchaseStepThreeB', ['$window', '$timeout', 'templateService', 'tseApiClient',
    function ($window, $timeout, templateService, tseApiClient) {
        var directive = {};
        directive.restrict = 'EA';
        directive.scope = {
            paxinfo: "=",
            bookingrequest: "=",
            bkgdata: "=",
            hideinsurance: "="
        };

        directive.link = function ($scope, $element) {

            templateService.compile($scope, $element, 'tpl/_PurchaseStep3TPLB2B.html');

            var isUpsell = $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';
            
            if (typeof $window.marketCode === "undefined") {
                $scope.marketCode = '';
            } else {
                $scope.marketCode = $window.marketCode.toUpperCase();
            }

            $scope.getUrl = $window.getUrl;
            $scope.siteType = $window.site.type;
            $scope.getTemplateUrl = $window.getTemplateUrl;
            $scope.getStaticFolder = $window.getStaticFolder;
            $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
            $scope.getTypeStaticFolder = $window.getTypeStaticFolder;
            $scope.getText = function (key) { return $window.culture.texts[key]; };
            $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
            $scope.enableB2BLanguageSector = $window.enableB2BLanguageSector && isUpsell === false && $scope.marketCode === 'FR' || $scope.marketCode === 'BE';
            $scope.enableUpSellProducts = $window.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';

            //TOREVIEW
            $scope.hasFlightHotelFormSummary = hasCookie("FlightHotelFormSummary") && $window.getUrlVars()['hotelSummary'] === 'true';
            $scope.enableDpkMargin = $window.enableDpkMargin = $window.enableHotelMargin = $scope.hasFlightHotelFormSummary;
            if ($scope.loginInfo) {
                $scope.loginInfo.ProductDpk = true;
            }

            $scope.isKiwi = $scope.bkgdata.purchaseResponse.kiwi;
            $scope.discountPriceDisplay = 0;
            
            

            $scope.dynamicFields = [];

            var agentId = "";
            if (window.site.loginInfo !== null) {
                if (typeof window.site.loginInfo !== 'undefined') {
                    if (typeof window.site.loginInfo.Agent != 'undefined') {
                        if (typeof window.site.loginInfo.Agent.AgentId !== 'undefined') {
                            agentId = window.site.loginInfo.Agent.AgentId;
                        }
                    }
                }
            }


            $window.Typescript.CustomFields.Services.CustomFieldsService.getCustomFields(agentId).then(function (axiosResponse) {

                console.log(axiosResponse.data);
                axiosResponse.data.forEach(function (el) {
                    $scope.dynamicFields.push(el.values);
                });

                $scope.$apply();

                setTimeout(function () {
                    $scope.dynamicFields.forEach(function (el) {
                        $window.XLFormInit({ iCont: 'i-form-' + el[1] });
                    });
                }, 700);

            }, function (err) {

            });


            if ($scope.enableB2BLanguageSector === true) {

                getBundleWidget("mrfly-widget-language-selector.js").done(function () {
                    var langCaps = lng.substring(3);
                    mrflyWidgets['mrfly-widget-language-selector'].language = lng;
                    mrflyWidgets['mrfly-widget-language-selector'].defaultLanguage = langCaps;
                    mrflyWidgets['mrfly-widget-language-selector'].$on('countryLanguageChanged', function (new_language) {

                        $scope.$emit('countryLanguageChanged', new_language);
                    });
                }).fail(function (jqxhr, settings, exception) {
                    console.error('getScript fail! See below:');
                    console.error(exception);
                });

            }

            $scope.bookingrequest.purchaseId = $scope.bkgdata.purchaseResponse.purchaseId;

            var user = $window.site.loginInfo;
            $scope.commissionAuthorizedIncrease = user.Agent.ChangeAllowedMarginsIncrease;
            $scope.commissionAuthorizedReduce = user.Agent.ChangeAllowedMarginsReduce;
            $scope.bookingrequest.partnerMargin = $window.objectClone($scope.bkgdata.purchaseResponse.proposalsRequested.totalAgencyMargin.value);

            if ($scope.bkgdata.agencyMarginHotel) {
                $scope.bookingrequest.partnerHotelMargin = $window.objectClone($scope.bkgdata.agencyMarginHotel.margin);
            }

            $scope.displayDiscount = $scope.bkgdata.purchaseResponse.informationData["DisplayDiscount"];
            $scope.bookingrequest.milesAttack = user.Agent.MilesAttack;
            $scope.bookingRequestClientPaymentMode = user.Client.PaymentMode;


            $scope.hasPreBook = $scope.bkgdata.purchaseResponse.preBookToDate != null;
            $scope.isTOProduct = $scope.bkgdata.purchaseResponse.isProductTo;

            if (typeof marketCode !== "undefined" && marketCode === "BE") {
                $scope.minPostcode = 4;
            }
            else {
                $scope.minPostcode = 5;
            }

            //Flags
            $scope.enableOption = $window.enableOption && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');
            $scope.enableCarrefourFields = $window.enableCarrefourFields;
            $scope.enableQCNS = $window.enableQCNS;
            $scope.enableCruiseNumber = $window.enableCruiseNumber;

            if ($window.site.loginInfo) {

                //Gestour
                $scope.isGestour = $scope.enableCarrefourFields && $window.site.loginInfo.Gestour;

                if ($scope.isGestour) {
                    $timeout(function () { $window.XLFormInit({ iCont: 'i-form-gestour' }); }, 500);
                }

                //QCNS
                $scope.isQCNS = $scope.enableQCNS && $window.site.loginInfo.Qcns;

                if ($scope.isQCNS) {
                    $timeout(function () { $window.XLFormInit({ iCont: 'i-form-QCNS' }); }, 500);
                }

                //CruiseNumber
                $scope.isCruiseNumber = $scope.enableCruiseNumber && $window.site.loginInfo.CruiseNumber;

                if ($scope.isCruiseNumber) {
                    $timeout(function () { $window.XLFormInit({ iCont: 'i-form-CruiseNumber' }); }, 500);
                }
            }



            if (!$scope.bookingrequest.paymentMode)
                $scope.bookingrequest.paymentMode = user.Client.PaymentMode;



            if ($scope.bkgdata.paymentMethods != null) {
                $scope.optionId = { isOption: true };
                $scope.bkgdata.paymentMethods.each(function (item) {
                    if (item.paymentMethodName == "EN COMPTE" && item.enabled === true) {
                        $scope.compteAvailable = true;
                        $scope.compteId = item;
                    }
                    if (item.paymentMethodReference == "CB" && item.transactionMethod == 1 && item.enabled === true) {        // CB data in MF
                        $scope.internCBAvailable = true;
                        $scope.internCBCards = item.bankCard.bankCards.groupByKey('bankCardType');
                        $scope.internCBId = item;
                    	$scope.internCBId.pspId = item.bankCard.pspId;
                    	$scope.internCBId.pspAccountId = item.bankCard.pspAccountId;
                    	$scope.internCBId.paymentType = item.bankCard.bankCards[0].bankCardType;
                    }

                    if (item.paymentMethodId == "9e930451-b27a-4983-9fc8-4c26f087a4cd") {
                        $scope.prelevementId = item;
                    }
                    if (item.paymentMethodReference == "CB" && item.transactionMethod == 2 && item.enabled === true) {      // CB data in other page
                        $scope.externalCBAvailable = true;
                        $scope.externalCBCards = item.bankCard.bankCards.groupByKey('bankCardType');
                        $scope.externalCBId = item;
                    	$scope.internCBId.pspId = item.bankCard.pspId;
                    	$scope.internCBId.pspAccountId = item.bankCard.pspAccountId;
                    	$scope.internCBId.paymentType = item.bankCard.bankCards[0].bankCardType;
                    }
                });
            }


            if ($scope.hasPreBook) {
                $scope.ticketing = moment(($scope.bkgdata.purchaseResponse.preBookToDate), moment.ISO_8601).toDate();
                var auxTicketingDate = moment(($scope.bkgdata.purchaseResponse.preBookToDate), moment.ISO_8601);
                var now = moment();
                $scope.optionEndsToday = auxTicketingDate.diff(now, 'days') == 0 ? true : false;
            }

            $scope.$watch('$viewContentLoaded', function () {
                var payments = $('#i-form-payment [ng-click]');
                if (payments.length == 1) {
                    console.log("CHECKING DEFAULT PAYMENT");
                    setTimeout(function () { payments[0].click(); });
                }
                else {
                    console.log("CHECKING DEFAULT PAYMENT NOT NEEDED");
                }
            });




            $timeout(function () {
                $("#i-radio-f" + $scope.bookingrequest.paymentMode).attr("checked", true);
                if ($scope.bookingrequest.paymentMode == 0)
                    $scope.setDestination(0, $scope.compteId)
                if ($scope.bookingrequest.paymentMode == 1 && $scope.internCBAvailable)
                    $scope.setDestination(1, $scope.internCBId)
                if ($scope.bookingrequest.paymentMode == 1 && $scope.externalCBAvailable)
                    $scope.setDestination(1, $scope.externalCBId)
                if ($scope.bookingrequest.paymentMode == 2)
                    $scope.setDestination(2, $scope.prelevementId)
            }, 100);

            if ($scope.bookingrequest.paymentMode == 0 || $scope.bookingrequest.paymentMode == 2) {
                $("#i-form").attr("action", "../airPaymentResponse/" + $scope.bookingrequest.requestId);
            }

            $scope.setReady = function (item) {
                var go = true;
                if (item == 2) {
                    //Flexy
                    var proposal = $scope.bkgdata.purchaseResponse.proposalsRequested;
                    if (proposal.mandatoryFees && proposal.mandatoryFees.length > 0) { // Hack : mandatory fees must benn set server side
                        $scope.bkgdata.bookingRequest.flexyId = proposal.mandatoryFees[0].flexyId;
                    }
                    else {
                        try {
                            var flexyId = $window.sessionStorage.getItem("inssurance.flexy.selectedOffer");
                            var requestId = $window.sessionStorage.getItem("inssurance.flexyRequest.selectedOffer");
                            if (flexyId != "null" && requestId != "null" && $scope.bkgdata.request.requestId == requestId) {
                                $scope.bkgdata.bookingRequest.flexyId = flexyId;
                            }
                        }
                        catch (error) {
                            console.log(error);
                        }
                    }
                    $scope.dynamicFields.forEach(function (el) {

                        if (!$window.XLFormValid({ iCont: 'i-form-' + el[1] })) {
                            go = false;
                        }

                        //Comprobacion si el campo tiene data invalida
                        if ($window.XLFormValid({ iCont: 'i-form-' + el[1] })) {
                            if ($scope.bookingrequest.customFields) {
                                if (!$scope.bookingrequest.customFields[el[1]]) {
                                    $("#i-login" + el[1]).removeClass("c-field-ok");
                                    $("#i-login" + el[1]).addClass("c-field-ko");
                                    $scope.bookingrequest[el[1]] = null;
                                    go = false;
                                }
                            }
                        }

                    });

                    if ($scope.nextStep() != 0) {
                        go = false;
                    }
                }
                else {
                    $scope.$emit("setReadyStep", { step: item });
                    go = false;
                }
                if (go && $scope.validateMargin()) {
                    $scope.updateMargin();
                    if ($scope.enableUpSellProducts) {
                        $scope.updateHotelMargin();
                    }
                    $scope.$emit("createBooking", { request: $scope.bookingrequest });
                }
            };


            //Validate margin value > '0' and < '999'
            $scope.ensureMargin = function () {
                if ($scope.bookingrequest.partnerMargin < 0 || typeof $scope.bookingrequest.partnerMargin == "undefined" || $scope.bookingrequest.partnerMargin == null)
                    $scope.bookingrequest.partnerMargin = 0;

                //if ($scope.bookingrequest.partnerMargin > 999)
                //    $scope.bookingrequest.partnerMargin = 999;

                if (!($scope.bkgdata.purchaseResponse.proposalsRequested.initialMargin))
                    $scope.bkgdata.purchaseResponse.proposalsRequested.initialMargin = $window.objectClone($scope.bkgdata.purchaseResponse.proposalsRequested.totalAgencyMargin.value);
            };

            $scope.validateMargin = function () {
                if ($scope.bookingrequest.partnerMargin > $scope.bkgdata.purchaseResponse.proposalsRequested.initialMargin && !$scope.commissionAuthorizedIncrease) {
                    $scope.bookingrequest.partnerMargin = $scope.bkgdata.purchaseResponse.proposalsRequested.initialMargin;
                    $scope.bkgdata.purchaseResponse.proposalsRequested.totalAgencyMargin.value = $scope.bkgdata.purchaseResponse.proposalsRequested.initialMargin;

                    $window.XLConfirm({
                        vTitle: "Vous n'êtes pas autorisé à augmenter la marge, par votre chef d'agence.", vTextYes: "OK", vHideNo: true, fCallBackYes: function () { }
                    });

                    return false;
                }

                if ($scope.bookingrequest.partnerMargin < $scope.bkgdata.purchaseResponse.proposalsRequested.initialMargin && !$scope.commissionAuthorizedReduce) {
                    $scope.bookingrequest.partnerMargin = $scope.bkgdata.purchaseResponse.proposalsRequested.initialMargin;
                    $scope.bkgdata.purchaseResponse.proposalsRequested.totalAgencyMargin.value = $scope.bkgdata.purchaseResponse.proposalsRequested.initialMargin;

                    $window.XLConfirm({
                        vTitle: "Vous n'êtes pas autorisé à minorer la marge, par votre chef d'agence.", vTextYes: "OK", vHideNo: true, fCallBackYes: function () { }
                    });

                    return false;
                }

                return true;
            };

            $scope.updateMargin = function () {
                $scope.ensureMargin();
                $('#i-commission').val($scope.bookingrequest.partnerMargin * 1);
                $scope.bkgdata.purchaseResponse.proposalsRequested.totalAgencyMargin.value = $scope.bookingrequest.partnerMargin;
                $scope.$emit("updateTotal", {});
            };

            $scope.updateHotelMargin = function () {
                $scope.ensureMargin();
                $('#i-hotel-commission').val($scope.bookingrequest.partnerHotelMargin * 1);

                $scope.bkgdata.purchaseResponse.proposalsRequested.totalAgencyHotelMargin = $window.objectClone($scope.bkgdata.purchaseResponse.proposalsRequested.totalAgencyMargin);
                $scope.bkgdata.purchaseResponse.proposalsRequested.totalAgencyHotelMargin.value = $scope.bookingrequest.partnerHotelMargin;

                console.log("UPDATE HOTEL MARGIN");

                $scope.$emit("updateTotal", { partnerHotelMargin: $scope.bookingrequest.partnerHotelMargin });
            };

            $scope.setDestination = function (item, data) {
                if (data && data.isOption)
                    $scope.bookingrequest.option = true;
                else
                    $scope.bookingrequest.option = false;

                $scope.bookingrequest.paymentMethod = data;
                $scope.bookingrequest.pspAccountId = data.pspAccountId;
                $scope.bookingrequest.pspId = data.pspId;
                $scope.bookingrequest.paymentMethodId = data.paymentMethodId;

                if (item == 0 || item == 2 || item == 4) {
                    $("#i-form").attr("action", "../airPaymentResponse/" + $scope.bookingrequest.requestId);
                }
                if (item == 1) {
                    $("#i-form").attr("action", "../airPayment/" + $scope.bookingrequest.requestId);
                }
            };

            $scope.nextStep = function () {
                return 0;
            };

            $scope.discountCodeState = {
                isLoading: false,
                isEmpty: false,
                isError: false,
                discountCode: $scope.bookingrequest.discountCode
            }


            $scope.checkDiscountCode = function (enable) {
                $scope.bkgdata.discountInfo = null;
                if ($scope.discountCodeState.discountCode == null || $scope.discountCodeState.discountCode == "") {
                    $scope.discountCodeState.isEmpty = true
                    return;
                }
                $scope.discountCodeState.isEmpty = false;
                $scope.discountCodeState.isLoading = true;
                if (enable) {
                    try {
                        var flexyId = $window.sessionStorage.getItem("inssurance.flexy.selectedOffer");
                        var requestId = $window.sessionStorage.getItem("inssurance.flexyRequest.selectedOffer");
                        if (flexyId != "null" && requestId != "null" && $scope.bkgdata.request.requestId == requestId && $scope.bkgdata.bookingRequest.flexyId == null) {
                            $scope.bkgdata.bookingRequest.flexyId = flexyId;
                        }
                    }
                    catch (error) {
                        console.log(error);
                    }
                    var discountCodeRq = {
                        requestId: $scope.bkgdata.purchaseRequest.requestId,
                        purchaseId: $scope.bkgdata.purchaseResponse.purchaseId,
                        flexyId: $scope.bkgdata.bookingRequest.flexyId,
                        //flexy: $scope.bkgdata.bookingRequest.flexyFare,
                        index: $scope.bkgdata.purchaseResponse.proposalsRequested.index,
                        insuranceId: $scope.bkgdata.bookingRequest.insurance != null ? $scope.bkgdata.bookingRequest.insurance.id : null,
                        //insuranceCompany: $scope.bkgdata.bookingRequest.insurance != null && $scope.bkgdata.bookingRequest.insurance.company != null ? $scope.bkgdata.bookingRequest.insurance.company : null,
                        //insuranceReference: $scope.bkgdata.bookingRequest.insurance != null && $scope.bkgdata.bookingRequest.insurance.reference != null ? $scope.bkgdata.bookingRequest.insurance.reference : null,
                        discountCode: $scope.discountCodeState.discountCode.toUpperCase(),
                        passengers: []
                    };

                    //Procesar maletas adicionales por pasajero
                    $scope.bkgdata.bookingRequest.passengers.each(function (p) {
                        var auxPaxPassportExpireDate = null;
                        var auxPaxBirthDate = moment(p.birthDate, "DD-MM-YYYY");

                        if (p.passportExpireDate != undefined)
                            auxPaxPassportExpireDate = moment(p.passportExpireDate, "DD-MM-YYYY");

                        var auxPasenger = {
                            firstName: p.firstName,
                            lastName: p.lastName,
                            birthDate: auxPaxBirthDate.format("YYYY-MM-DD"),
                            index: p.index,
                            type: p.paxType,
                            phoneCountryCode: p.phoneCountryCode,
                            ptc: p.ptc,
                            title: p.title === "1" ? 1 : p.title === "2" ? 2 : 0,
                            passportNumber: p.passportNumber,
                            passportCountry: p.passportCountry,
                            additionalBaggages: [],
                            fidelityCardNumber: p.fidelityCardNumber,
                            fidelityCard: p.fidelityCardType
                        }

                        if (p.phoneCountryCode != undefined && p.phone != undefined)
                            auxPasenger.phone = libphonenumber.parsePhoneNumberFromString("+{0}{1}".format(p.phoneCountryCode, p.phone)).format("INTERNATIONAL").replace(/\s/g, "");

                        if (auxPaxPassportExpireDate != undefined)
                            auxPasenger.passportExpiry = auxPaxPassportExpireDate.format("YYYY-MM-DD");

                        if (p.baggageByItinerary != null) {
                            for (var bag in p.baggageByItinerary) {
                                if (p.baggageByItinerary.hasOwnProperty(bag)) {
                                    if (p.baggageByItinerary[bag].quantity > 0) {
                                        auxPasenger.additionalBaggages.push({
                                            itineraryKey: bag,
                                            baggageKey: p.baggageByItinerary[bag].key,
                                            baggageId: p.baggageByItinerary[bag].baggageId
                                        });
                                    }
                                }
                            }
                        }
                        discountCodeRq.passengers.push(auxPasenger);
                    });

                    //Add contact data
                    
                    discountCodeRq.contact = {
                        mail: $window.site.loginInfo.Agent.Account,
                        phone: null,// no phone number in B2B
                    }

                    $scope.apiClient.PURCHASE.checkDiscount(discountCodeRq).success(function (data) {
                        if (data.status == 0) {
                            $scope.bkgdata.discountInfo = data;
                            $scope.bkgdata.discountInfo.discountCode = discountCodeRq.discountCode;
                            if (data.discountMessage != null) {
                                msg = data.discountMessage;
                                $scope.bkgdata.discountInfo.errorMessage = msg;
                                //$scope.bookingrequest.discountCode = null;
                                $scope.bkgdata.discountInfo.discountCode = null;
                                $window.FSellitTog('i-promo-0', 0);
                                $("#i-pport-3").removeAttr("disabled");
                            }
                            else {
                                $scope.discountPriceDisplay = $scope.bkgdata.discountInfo.applicableAmount;
                                $scope.bkgdata.discountInfo.applicableAmount *= -1;
                                $scope.bkgdata.discountInfo.errorMessage = "";
                                $scope.bookingrequest.discountCode = discountCodeRq.discountCode;
                                //$("#i-pport-3").attr("disabled", "");
                                $scope.$emit("getBanqueCasinoSchedule");
                                $scope.$emit("updateTotal");
                            }
                        }
                        else {
                            $scope.bkgdata.discountInfo = null;
                            $scope.bookingrequest.discountCode = null;
                            FSellitTog('i-promo-0', 0);
                        }
                    }).error(function (code) {
                        $window.FSellitTog('i-promo-0', 0);
                        if (code == 400) {
                            $window.XLConfirm({
                                vTitle: "Désolé, votre session est perdue. Merci de bien vouloir relancer une recherche.", vTextYes: "OK", vHideNo: true, fCallBackYes: function () {
                                    $window.location.href = $window.site.loginUrl;
                                }
                            });
                        }
                        if (code == 410) {
                            $window.XLConfirm({
                                vTitle: "Désolé, votre session a expiré. Merci de bien vouloir relancer une recherche.", vTextYes: "OK", vHideNo: true, fCallBackYes: function () {
                                    $window.redoSearch();
                                }
                            });
                        }
                        if (code == 412) {
                            $window.XLConfirm({ vTitle: "Veuillez vérifier les données de formulaire et relancer votre réservation.", vTextYes: "OK", vHideNo: true });
                        }
                    }).finally(function () {
                        $scope.discountCodeState.isLoading = false;
                    });
                }
                else {
                    $scope.bkgdata.discountInfo = null;
                    $scope.bookingrequest.discountCode = null;
                    $scope.discountCodeState.isLoading = false;
                    FSellitTog('i-promo-0', 0);
                    $("#i-pport-3").removeAttr("disabled");
                    $scope.$emit("getBanqueCasinoSchedule");
                    $scope.$emit("updateTotal");
                }
            };

            $scope.cancelDiscountCode = function () {
                $scope.bkgdata.discountInfo = null;
                $scope.bookingrequest.discountCode = null;
                $scope.$emit("getBanqueCasinoSchedule");
                $scope.$emit("updateTotal");
            }

            if ($scope.bookingrequest.discountCode != "" && $scope.bookingrequest.discountCode != null) {
                $timeout(function () { $window.FSellitTog('i-promo-0', 1); }, 500);
                $scope.checkDiscountCode(true);
            }

            $scope.$on("getBanqueCasinoScheduleCallback", function (event, args) {
                $scope.prepareBanqueCasinoData();
            });


            $timeout(function () {
                if ($scope.commissionAuthorizedIncrease || $scope.commissionAuthorizedReduce)
                    $window.XLFormInit({ iCont: 'c-form' }, 200);
            });
            $scope.updateMargin();
            //getBundleWidget("mrfly-widget-disclaimers.js").done(function () {
            //    mrflyWidgets["mrfly-widget-disclaimers"].isB2B =true;
            //    mrflyWidgets["mrfly-widget-disclaimers"].purchaseResponse = $scope.bkgdata.purchaseResponse;
            //}).fail(function (jqxhr, settings, exception) {
            //    console.log(exception);
            //});
        };
        return directive;
    }]);
