webClient.controller('PostalAddress', ['$scope', '$window', '$http', function ($scope, $window, $http) {
        
        $scope.getStaticFolder = $window.getStaticFolder;

        $scope.init = function (contact) {
            $scope.model = contact;
            $scope.setCountryName(contact.country);
            $scope.updated = false;
        };

        $scope.validate = function (key) {
            if ($window.XLFormValid({ iCont: 'i-form' }) === true) {
                $http.post('/api/api/postalcheck/update', {
                    key: key,
                    addressLine1: $scope.model.addressLine1,
                    addressLine2: $scope.model.addressLine2,
                    addressLine3: $scope.model.addressLine3,
                    city: $scope.model.city,
                    zipCode: $scope.model.zipCode,
                }).then(function (response) { //success => switch template
                    $scope.updated = true;
                },
                function () {//error
                });
            }
        }

        $scope.setCountryName = function (countryName) {
            $.ajax({
                url: "/Views/misterfly_umbraco/_countriescodes/" + lng.substring(0, 2) + "_CountriesCode.json",
                success: function (response) {
                      countriesCode = response;  
                    },
                async: false //synchronous call to avoid error in phone coutry extension drop down list
            });

            $scope.countryName = countryName;

            var langItem = $window.countriesCode.filter(function (item) { return item.iso.toLowerCase() === countryName.toLowerCase(); }).first();

            $scope.countryCode = langItem.iso;
            $scope.countryName = langItem.name;

            if (countryName === "BE") {
                $scope.minPostcode = 4;
            }
            else {
                $scope.minPostcode = 5;
            }
        };
        //This is only for debug
        $window.$scope = $scope;
}]);
