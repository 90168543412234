
//**************************************************************************************************
//Api Results Helper Service
tseModule.service('tseAirResultsHelper', ['$timeout', 'tseUtil', '$window', '$i18next', function ($timeout, util, $window, $i18next) {
    var self = this;
    var _root = this;

    //Traduction
    function getT(key) {
        if (!key || typeof (key) == 'undefined') return "";
        return $i18next.t(key.toLowerCase());
    };

    this.getTimeObject = function (minutes, autoUpdate) {
        if (!window.isDefined(autoUpdate))
            autoUpdate = false;

        var timeObject = function () {
            var that = this;
            var _oldValue = -1;
            that.value = minutes;
            that.update = function () {
                if (_oldValue != that.value) {
                    var tHours = that.value / 60;
                    that.totalHours = parseFloat(tHours.toFixed(4));
                    that.HH = Math.floor(tHours).padLeft("00");
                    that.mm = Math.round((tHours - Math.floor(tHours)) * 60).padLeft("00");
                    _oldValue = that.value;
                }
                if (autoUpdate)
                    setTimeout(function () { that.update(); }, 250);
            };
            that.update();
        };
        return new timeObject();
    };
    this.getNumberObject = function (number, autoUpdate) {
        if (number!=null) {
            if (!window.isDefined(autoUpdate))
                autoUpdate = false;
            if (window.isDefined(number.value))
                number = number.value;

            var numberObject = function () {
                var that = this;
                var _oldValue = null;
                that.value = Math.round((number + 0.00001) * 100) / 100;
                that.update = function () {
                    if (_oldValue != that.value) {
                        that.int = that.value.getIntegers();
                        that.dec = that.value.getDecimals().padLeft("00");
                        _oldValue = that.value;
                    }
                    if (autoUpdate)
                        setTimeout(function () { that.update(); }, 250);
                };
                that.update();
            };
            return new numberObject();
        }
    };
    this.prepareLists = function (obj) {
        if (obj.airlines) obj.airlines = obj.airlines.groupByKey("iata");
        if (obj.airports) {
            obj.airportsByCity = obj.airports.groupByKey("cityIata");
            for (var key in obj.airportsByCity) {
                if (!Array.isArray(obj.airportsByCity[key]))
                    obj.airportsByCity[key] = [obj.airportsByCity[key]];
            }
            obj.airports = obj.airports.groupByKey("iata")
        };
        if (obj.cities) obj.cities = obj.cities.groupByKey("iata");
        if (obj.equipments) obj.equipments = obj.equipments.groupByKey("type");
        if (obj.cabinClasses) obj.cabinClasses = obj.cabinClasses.groupByKey("reference");        
    };
    this.prepareSummary = function (summary) {
        if (summary.totalFare == null)
            return;
        if (summary.adultFare != null)
            summary.adultFare.from = self.getNumberObject(summary.adultFare.from);
        summary.averageFare.from = self.getNumberObject(summary.averageFare.from);
        summary.averageFareFeeMargin.from = self.getNumberObject(summary.averageFareFeeMargin.from);
        summary.totalFare.from = self.getNumberObject(summary.totalFare.from);
        summary.totalFareFeeMargin.from = self.getNumberObject(summary.totalFareFeeMargin.from);

        if (summary.adultFare != null)
            summary.adultFare.to = self.getNumberObject(summary.adultFare.to);
        summary.averageFare.to = self.getNumberObject(summary.averageFare.to);
        summary.averageFareFeeMargin.to = self.getNumberObject(summary.averageFareFeeMargin.to);
        summary.totalFare.to = self.getNumberObject(summary.totalFare.to);
        summary.totalFareFeeMargin.to = self.getNumberObject(summary.totalFareFeeMargin.to);

        if (summary.outbound != null) {
            if (summary.outbound.arrivalTime) {
                summary.outbound.arrivalTime.from = this.getTimeObject(summary.outbound.arrivalTime.from);
                summary.outbound.arrivalTime.to = this.getTimeObject(summary.outbound.arrivalTime.to);
            }
            if (summary.outbound.departureTime) {
                summary.outbound.departureTime.from = this.getTimeObject(summary.outbound.departureTime.from);
                summary.outbound.departureTime.to = this.getTimeObject(summary.outbound.departureTime.to);
            }
            if (summary.outbound.flightTime) {
                summary.outbound.flightTime.from = this.getTimeObject(summary.outbound.flightTime.from);
                summary.outbound.flightTime.to = this.getTimeObject(summary.outbound.flightTime.to);
            }
            if (summary.outbound.totalTime) {
                summary.outbound.totalTime.from = this.getTimeObject(summary.outbound.totalTime.from);
                summary.outbound.totalTime.to = this.getTimeObject(summary.outbound.totalTime.to);
            }
            if (summary.outbound.transitTime) {
                summary.outbound.transitTime.from = this.getTimeObject(summary.outbound.transitTime.from);
                summary.outbound.transitTime.to = this.getTimeObject(summary.outbound.transitTime.to);
            }
        }
        if (summary.inbound != null) {
            if (summary.inbound.arrivalTime) {
                summary.inbound.arrivalTime.from = this.getTimeObject(summary.inbound.arrivalTime.from);
                summary.inbound.arrivalTime.to = this.getTimeObject(summary.inbound.arrivalTime.to);
            }
            if (summary.inbound.departureTime) {
                summary.inbound.departureTime.from = this.getTimeObject(summary.inbound.departureTime.from);
                summary.inbound.departureTime.to = this.getTimeObject(summary.inbound.departureTime.to);
            }
            if (summary.inbound.flightTime) {
                summary.inbound.flightTime.from = this.getTimeObject(summary.inbound.flightTime.from);
                summary.inbound.flightTime.to = this.getTimeObject(summary.inbound.flightTime.to);
            }
            if (summary.inbound.totalTime) {
                summary.inbound.totalTime.from = this.getTimeObject(summary.inbound.totalTime.from);
                summary.inbound.totalTime.to = this.getTimeObject(summary.inbound.totalTime.to);
            }
            if (summary.inbound.transitTime) {
                summary.inbound.transitTime.from = this.getTimeObject(summary.inbound.transitTime.from);
                summary.inbound.transitTime.to = this.getTimeObject(summary.inbound.transitTime.to);
            }
        }
    };
    this.prepareSummaryFromRaw = function (rawResults) {
        var summary = rawResults.summary;
        var airlines = rawResults.airlines;
        summary.marketingCarriers = summary.marketingCarriers.orderBy(function (item) { return airlines[item].name; });
        self.prepareSummary(summary);
    };
    this.prepareItineraryTimes = function (itinerary) {
        if (itinerary.arrivalTime>=0) itinerary.arrivalTime = self.getTimeObject(itinerary.arrivalTime);
        if (itinerary.departureTime>=0) itinerary.departureTime = self.getTimeObject(itinerary.departureTime);
        if (itinerary.flightTime) itinerary.flightTime = self.getTimeObject(itinerary.flightTime);
        if (itinerary.totalTime) itinerary.totalTime = self.getTimeObject(itinerary.totalTime);
        if (itinerary.transitTime) itinerary.transitTime = self.getTimeObject(itinerary.transitTime);
        if (itinerary.segments != null) {
            itinerary.segments.each(function (segment) {
                if (segment.departure.time>=0) segment.departure.time = self.getTimeObject(segment.departure.time);
                if (segment.arrival.time>=0) segment.arrival.time = self.getTimeObject(segment.arrival.time);
                if (segment.flightTime) segment.flightTime = self.getTimeObject(segment.flightTime);
            });
        }
        if (itinerary.stops != null) {
            itinerary.stops.each(function (stop) {
                if (stop.time) stop.time = self.getTimeObject(stop.time);
            });
        }
    };
    this.prepareBaggagesText = function (equipmentType, baggages, additionalBaggage)
    {
        if (!equipmentType)
            equipmentType = 1;
        var result = { include : false, text: "", addText: null };
        var i = 0;
        if (baggages != null) {
            if (baggages.any(function (b) { return b.quantity > 0; })) {
                result.include = true;
                baggages.each(function (item) {
                    if (item.quantity > 0) {
                        if (item.weight > 0) {
                            if (item.quantity == 1)
                                result.text += "{0} kg {1}".format(item.weight, (item.paxType == 0) ? getT("airbooking.luggage.perad") : (item.paxType == 1) ? getT("airbooking.luggage.perchi") : (item.paxType == 2) ? getT("airbooking.luggage.perinf") : "");
                            else
                                result.text += "{0} x {1} kg {2}".format(item.quantity, item.weight, (item.paxType == 0) ? "(par ad.)" : (item.paxType == 1) ? "(par enf.)" : (item.paxType == 2) ? "(par bébé)" : "");
                        }
                        else {
                            if (item.quantity == 1)
                                result.text += (getT("airbooking.luggage.checkedluggage")).format(item.quantity, item.weight, equipmentType == 1 ? getT("airbooking.luggage.ensoute") : "", (item.paxType == 0) ? getT("airbooking.luggage.perad") : (item.paxType == 1) ? getT("airbooking.luggage.perchi") : (item.paxType == 2) ? getT("airbooking.luggage.perinf") : "");
                            else
                                result.text += (getT("airbooking.luggage.checkedluggages")).format(item.quantity, item.weight, equipmentType == 1 ? getT("airbooking.luggage.ensoute") : "", (item.paxType == 0) ? getT("airbooking.luggage.perad") : (item.paxType == 1) ? getT("airbooking.luggage.perchi") : (item.paxType == 2) ? getT("airbooking.luggage.perinf") : "");
                        }
                        if (i != (baggages.length - 1))
                            result.text += " - ";
                    }
                    i++;
                });
            }
            else {
                result.include = false;
                result.text = (getT("airbooking.luggage.nocheckedluggage")).format(equipmentType == 1 ? getT("airbooking.luggage.ensoute") : "");
                if (additionalBaggage && additionalBaggage != null) {
                    result.addText = (getT("airbooking.luggage.estimatedextraluggage")).format(additionalBaggage.price);
                }
            }
        }
        return result;
    }
    this.prepareItineraryBaggagesText = function (rawResults, itinerary, proposal) {
        var baggage = itinerary.baggages.first(function (b) { return b.paxType == 0 }); //AdultBaggage
        //var baggage = itinerary.baggages.maxObj(function (b) { return b.quantity; }).first(); //Max Baggage per pax
        var cheapBaggage = null;

        var itBaggages = proposal.additionalBaggages.getByKeyValue("key", itinerary.key);
        if (itBaggages) {
            var addBaggages = itBaggages.baggages;
            if (addBaggages.any(function (b) { return b.location === 0; })) {
                cheapBaggage = addBaggages.filter(function (b) { return b.location === 0; }).minObj(function (b) { return b.price; }).first();
            }
        }
        var itiEquipmentType = 1;
        itinerary.segments.each(function (segment) {
            var equipmentObj = rawResults.equipments[segment.equipment];
            var equipmentType = (equipmentObj) ? equipmentObj.equipmentType : 1;
            
            if (equipmentType != 1)
                itiEquipmentType = equipmentType;
        });

        itinerary.baggage = self.prepareBaggagesText(itiEquipmentType, itinerary.baggages, cheapBaggage);
        itinerary.baggage.quantity = baggage.quantity;
        itinerary.baggage.weight = baggage.weight;
        itinerary.baggage.include = baggage.quantity > 0;

        if (itinerary.segments != null) {
            itinerary.segments.each(function (segment) {
                var equipmentObj = rawResults.equipments[segment.equipment];
                var equipmentType = (equipmentObj) ? equipmentObj.equipmentType : 0;
                if (equipmentType == 0)
                    equipmentType = 1;
                segment.baggage = self.prepareBaggagesText(equipmentType, segment.baggages);
            });
        }
    }
    this.prepareProposal = function (proposal, product) {
        proposal.averageFare = self.getNumberObject(proposal.averageFare);
        proposal.averageFareFeeMargin = self.getNumberObject(proposal.averageFareFeeMargin);
        proposal.totalFare = self.getNumberObject(proposal.totalFare);
        proposal.totalFareFeeMargin = self.getNumberObject(proposal.totalFareFeeMargin);
        proposal.totalFee = self.getNumberObject(proposal.totalFee);
        proposal.totalFlexy = self.getNumberObject(proposal.totalFlexy);
        proposal.totalMargin = self.getNumberObject(proposal.totalMargin);
        proposal.totalTax = self.getNumberObject(proposal.totalTax);
        proposal.totalFareFeeMarginFlexy = self.getNumberObject(proposal.totalFareFeeMarginFlexy);
        proposal.totalFee = self.getNumberObject(proposal.totalFee);
        proposal.totalMargin = self.getNumberObject(proposal.totalMargin);
        if(proposal.totalBestPrice>=0)
            proposal.totalBestPrice = self.getNumberObject(proposal.totalBestPrice);
        
        //****
        if (proposal.totalTaxSummary != null) {
            proposal.totalTaxSummary.fuel = proposal.totalTaxSummary.fuel >= 0 ? self.getNumberObject(proposal.totalTaxSummary.fuel) : null;
            proposal.totalTaxSummary.airport = proposal.totalTaxSummary.airport >= 0 ? self.getNumberObject(proposal.totalTaxSummary.airport) : null;
        }

        //**** Supplements
        if (product.minAverageFare)
            proposal.supplementAverageFare = self.getNumberObject(proposal.averageFare.value - product.minAverageFare.value);
        if (product.minAverageFareFeeMargin)
            proposal.supplementAverageFareFeeMargin = self.getNumberObject(proposal.averageFareFeeMargin.value - product.minAverageFareFeeMargin.value);
        if (product.minTotalFare)
            proposal.supplementTotalFare = self.getNumberObject(proposal.totalFare.value - product.minTotalFare.value);
        if (product.minTotalFareFeeMargin)
            proposal.supplementTotalFareFeeMargin = self.getNumberObject(proposal.totalFareFeeMargin.value - product.minTotalFareFeeMargin.value);

        //**** Fares
        if (window.isArray(proposal.fares)) {
            proposal.fares.each(function (fare) {
                fare.fare = self.getNumberObject(fare.fare);
                fare.fee = self.getNumberObject(fare.fee);
                fare.flexy = self.getNumberObject(fare.flexy);
                fare.margin = self.getNumberObject(fare.margin);
                fare.tax = self.getNumberObject(fare.tax);
                fare.totalFare = self.getNumberObject(fare.totalFare);
                fare.totalFareByPax = self.getNumberObject(fare.totalFareByPax);
                fare.totalFareFlexyByPax = self.getNumberObject(fare.totalFareFlexyByPax);
                fare.totalFee = self.getNumberObject(fare.totalFee);
                fare.totalFlexy = self.getNumberObject(fare.totalFlexy);
                fare.totalMargin = self.getNumberObject(fare.totalMargin);
                fare.totalTax = self.getNumberObject(fare.totalTax);
                fare.unitFare = self.getNumberObject(fare.unitFare);
                fare.unitFareFlexy = self.getNumberObject(fare.unitFareFlexy);
                //****
                if (fare.taxSummary != null) {
                    fare.taxSummary.fuel = fare.taxSummary.fuel >= 0 ? self.getNumberObject(fare.taxSummary.fuel) : null;
                    fare.taxSummary.airport = fare.taxSummary.airport >= 0 ? self.getNumberObject(fare.taxSummary.airport) : null;
                }
                //****
                if (fare.totalTaxSummary != null) {
                    fare.totalTaxSummary.fuel = fare.totalTaxSummary.fuel >= 0 ? self.getNumberObject(fare.totalTaxSummary.fuel) : null;
                    fare.totalTaxSummary.airport = fare.totalTaxSummary.airport >= 0 ? self.getNumberObject(fare.totalTaxSummary.airport) : null;
                }
            });
            proposal.fares = proposal.fares.groupByKey("paxType");
        }

        product.currency = proposal.currency;
    };

    //Function to get terminal displayName for all segments in a product (outbound and inbound)
    this.prepareTerminalInformation = function (product, rawResults) {        
        if (product) {
            //Outbound
            if (product.outbound && product.outbound.segments) {
                product.outbound.segments.each(function(seg) {
                    if (seg.departure && seg.departure.airport && seg.departure.terminal) {
                        seg.departure.terminalDisplayName = self.getTerminalName(seg.departure.airport, seg.departure.terminal, rawResults);
                    }
                    if (seg.arrival && seg.arrival.airport && seg.arrival.terminal) {
                        seg.arrival.terminalDisplayName = self.getTerminalName(seg.arrival.airport, seg.arrival.terminal, rawResults);
                    }
                });
            }

           //Inbounds
            if (product.inbounds)
                product.inbounds.each(function(inb) {
                    if (inb.segments) {
                        inb.segments.each(function(seg) {
                            if (seg.departure && seg.departure.airport && seg.departure.terminal) {
                                seg.departure.terminalDisplayName = self.getTerminalName(seg.departure.airport,
                                    seg.departure.terminal,
                                    rawResults);
                            }
                            if (seg.arrival && seg.arrival.airport && seg.arrival.terminal) {
                                seg.arrival.terminalDisplayName =
                                    self.getTerminalName(seg.arrival.airport, seg.arrival.terminal, rawResults);
                            }
                        });
                    }
                });
        }
    };

    //Return terminal name
    this.getTerminalName = function (airportCode, terminalCode, rawResults) {      
        if (rawResults && rawResults.airports) {
                if (rawResults.airports[airportCode] && rawResults.airports[airportCode].terminal && rawResults.airports[airportCode].terminal.length>0) {                    
                    var listTerminalsAux = rawResults.airports[airportCode].terminal;

                    if (listTerminalsAux) {
                        listTerminalsAux = listTerminalsAux.groupByKey('code');

                        if (listTerminalsAux[terminalCode]) {
                           return listTerminalsAux[terminalCode].name;
                        }
                    }
                }
            }
    }

    this.getViewForBookingDetailsEntity = function (bookingDetails) {
        var booking = bookingDetails.bookings[0];
        var pnrs = booking.pnrs.orderBy(function(item) { return moment(item.itineraries[0].date).toDate().getTime() });
        var view = {};
        view._innerData = bookingDetails;

        view.option = bookingDetails.bookings[0].option;
        view.status = booking.status;
        view.bookingNumber = booking.bookingNumber;
        view.bookingId = booking.bookingId;
        view.recordLocator = pnrs.map(function (item) { return item.recordLocator; });
        view.reservationLocator = pnrs.map(function (item) { return item.reservationLocator; });
        view.providersReference = pnrs.map(function (item) { return item.providerDisplayName + ": " + (item.recordLocator != null ? item.recordLocator : ""); }).join(", ");
        view.providerVendorLocatorTFU = pnrs.map(function (item) {
            if (item.provider && item.provider.toUpperCase() == "TFU") {
                return "Ref: {0} {1}".format(item.platingCarrierName, (item.reservationLocator != null ? item.reservationLocator : ""));
            };
        }).join(", ");
        

        view.agent = booking.agent;
        view.timestamp = booking.timestamp;
        view.payments = booking.payments;
        view.paxes = pnrs.map(function (item) { return item.passengers; }).toSimpleArray();
        //Workaround to engine error
        view.paxes.each(function (item) {
            if (item.firstName.substr(-4) == " MRS") {
                if(item.title==0)
                    item.title = 2;
                item.firstName = item.firstName.replace("MRS", "").trim();
            }
            if (item.firstName.substr(-3) == " MR") {
                if (item.title == 0)
                    item.title = 1;
                item.firstName = item.firstName.replace("MR", "").trim();
            }
        });
        //Merge pnr passengers
        var paxMergeFunction = function (array) {
            return {
                amount: parseFloat(array.sum(function (item) { return item.amount; }).toFixed(2)),
                baggages: array.map(function (item) { return item.baggages; }).toSimpleArray().removeNulls(),
                birthDate: array.map(function (item) { return item.birthDate ? item.birthDate : null; }).removeNulls().first(),
                eTicket: array.map(function (item) { return item.eTicket ? item.eTicket : null; }).removeNulls().join(", "),
                fidelityCard: array.map(function (item) { return item.fidelityCard ? item.fidelityCard : null; }).removeNulls().join(", "),
                fidelityCardNumber: array.map(function (item) { return item.fidelityCardNumber ? item.fidelityCardNumber : null; }).removeNulls().join(", "),
                firstName: array[0].firstName,
                lastName: array[0].lastName,
                paxType: array[0].paxType,
                ptc: array.map(function (item) { return item.ptc; }).distinct(),
                title: array[0].title,
                phone: array[0].phone,
            }
        };
        view.paxes = view.paxes.merge(function (item) { return item.paxType + item.firstName + item.lastName }, paxMergeFunction);
        view.insurances = booking.insurances;

        view.airlines = bookingDetails.airlines;
        view.airports = bookingDetails.airports;
        view.cities = bookingDetails.cities;
        view.equipments = bookingDetails.equipments;
        view.cabinClasses = bookingDetails.cabinClasses;
        view.itineraries = pnrs.map(function (item) { return item.itineraries; }).toSimpleArray();

        view.ptcs = [];
        view.paxes.each(function (pax) {
            var bagByIt = pax.baggages.groupByKey("itineraryId");
            pax.baggageByItinerary = [];

            for (var i = 0; i < view.itineraries.length; i++)
                if ($window.isDefined(bagByIt[view.itineraries[i].itineraryId]))
                    pax.baggageByItinerary[i] = bagByIt[view.itineraries[i].itineraryId];
                else
                    pax.baggageByItinerary[i] = null;

            pax.ptc.each(function (itemPtc) {
                view.ptcs[itemPtc] = { ptc: itemPtc, paxType: pax.paxType };
            });
        });

        self.prepareLists(view);

        if (view.itineraries.length > 0) {
            view.itineraries = view.itineraries.map(function (itinerary) {
                var firstSegment = itinerary.segments.first();
                var lastSegment = itinerary.segments.last();

                var nItinerary = {};
                nItinerary.productType = pnrs[0].productType.toUpperCase()=="TO"?1:0;
                nItinerary.itineraryId = itinerary.itineraryId;
                nItinerary.origin = itinerary.origin;
                nItinerary.destination = itinerary.destination;
                nItinerary.departure = firstSegment.departureAirport;
                nItinerary.departureDate = firstSegment.departureDatetime;
                nItinerary.departureTime = moment(nItinerary.departureDate).hours() * 60 + moment(nItinerary.departureDate).minutes();
                nItinerary.arrival = lastSegment.arrivalAirport;
                nItinerary.arrivalDate = lastSegment.arrivalDatetime;
                nItinerary.arrivalTime = moment(nItinerary.arrivalDate).hours() * 60 + moment(nItinerary.arrivalDate).minutes();
                nItinerary.marketingCarrier = itinerary.marketingCarrier;                
                nItinerary.availableSeats = 9;
                nItinerary.dayChange = Math.floor(moment(nItinerary.arrivalDate.substr(0,10)).diff(nItinerary.departureDate.substr(0,10), 'days', true));
                nItinerary.cabinClass = firstSegment.cabinClass;
                nItinerary.baggages = [];
                nItinerary.info = null;
                nItinerary.stops = [];
                nItinerary.proposal = null;
                nItinerary.segments = itinerary.segments.map(function (segment) {
                    var nSegment = {};
                    nSegment.departure = {
                        airport: segment.departureAirport,
                        terminal: segment.departureTerminal,
                        dateTime: segment.departureDatetime,
                        time: moment(segment.departureDatetime).hours() * 60 + moment(segment.departureDatetime).minutes()
                    };
                    nSegment.arrival = {
                        airport: segment.arrivalAirport,
                        terminal: segment.arrivalTerminal,
                        dateTime: segment.arrivalDatetime,
                        time: moment(segment.arrivalDatetime).hours() * 60 + moment(segment.arrivalDatetime).minutes()
                    };;
                    nSegment.airline = segment.airline;
                    nSegment.flightNumber = segment.flightNumber;
                    nSegment.operatingAirline = segment.operatingAirline;
                    nSegment.operatingFlightNumber = null;
                    nSegment.flightTime = segment.flightTime;
                    nSegment.miles = segment.miles;
                    nSegment.stops = segment.stops;
                    nSegment.stopReason = segment.stopReason;
                    nSegment.equipment = segment.equipment;
                    nSegment.dayChange = Math.floor(moment(segment.arrivalDatetime.substr(0,10)).diff(segment.departureDatetime.substr(0,10), 'days', true));
                    nSegment.cabinClass = segment.cabinClass;
                    nSegment.baggages = [];

                    if (segment.baggages && segment.baggages.length > 0) {
                        nSegment.baggages = segment.baggages.map(function (baggage) {
                            var nBaggage = {};
                            nBaggage.quantity = baggage.baggagePC;
                            nBaggage.weight = baggage.baggageKG;
                            nBaggage.paxType = view.ptcs[baggage.paxType].paxType;
                            return nBaggage;
                        });
                    }

                    var equipmentObj = view.equipments[nSegment.equipment];
                    var equipmentType = (equipmentObj) ? equipmentObj.equipmentType : 0;
                    if (equipmentType == 0)
                        equipmentType = 1;
                    nSegment.baggage = airHelper.prepareBaggagesText(equipmentType, nSegment.baggages);

                    return nSegment;
                });

                var segWBaggages = nItinerary.segments.first(function (s) { return s.baggages.length > 0 });
                nItinerary.baggages = segWBaggages != null ? segWBaggages.baggages : [];
                nItinerary.flightTime = nItinerary.segments.sum(function (s) { return s.flightTime });
                

                if (nItinerary.segments.length > 1) {
                    for (var i = 0; i < nItinerary.segments.length - 1; i++) {
                        var current = nItinerary.segments[i];
                        var next = nItinerary.segments[i + 1];

                        var currentEquipment = view.equipments[current.equipment];
                        var nextEquipment = view.equipments[next.equipment];
                        var equipmentChange = false;
                        if (currentEquipment != null && nextEquipment != null)
                            equipmentChange = currentEquipment.equipmentType != nextEquipment.equipmentType;

                        nItinerary.stops.push({
                            key: current.arrival.airport,
                            time: moment(next.departure.dateTime).diff(current.arrival.dateTime, 'minutes'),
                            terminalChange: current.arrival.terminal != next.departure.terminal,
                            airportChange: current.arrival.airport != next.departure.airport,
                            equipmentChange: equipmentChange
                        });
                    }
                }
                nItinerary.stopsCount = nItinerary.segments.sum(function (s) { return s.stops; }) + nItinerary.stops.length;

                //Fix travel Time problem
                var totalTransitTime = nItinerary.stops.sum(function (e) { return e.time });                
                nItinerary.transitTime = totalTransitTime;
                nItinerary.totalTime = nItinerary.flightTime + nItinerary.transitTime;
                //end

                airHelper.prepareItineraryTimes(nItinerary);
                nItinerary.baggage = airHelper.prepareBaggagesText(1, nItinerary.baggages);
                if (nItinerary.baggages.length > 0) {
                    nItinerary.baggage.quantity = nItinerary.baggages[0].quantity;
                    nItinerary.baggage.weight = nItinerary.baggages[0].weight;
                }
                return nItinerary;
            });


            view.request = {};
            view.request.oneWay = true;
            view.request.roundTrip = false;
            view.request.openJaw = false;

            view.request.origin = {}
            var originAirport = view.airports[view.itineraries[0].departure];
            var originCity = view.cities[view.itineraries[0].origin];
            view.request.origin.airportIATA = originAirport.iata;
            view.request.origin.airportName = originAirport.name;
            view.request.origin.cityIATA = originAirport.cityIata;
            view.request.origin.cityName = originCity.name;
            view.request.origin.continent = originCity.continent;
            view.request.origin.countryISO = originCity.countryIso3166;
            view.request.origin.countryName = originCity.countryName;

            view.request.destination = {}
            var destinationAirport = view.airports[view.itineraries[0].arrival];
            var destinationCity = view.cities[view.itineraries[0].destination];
            if (destinationAirport) {
                view.request.destination.airportIATA = destinationAirport.iata;
                view.request.destination.airportName = destinationAirport.name;
                view.request.destination.cityIATA = destinationAirport.cityIata;
            }
            if (destinationCity) {
                view.request.destination.cityName = destinationCity.name;
                view.request.destination.continent = destinationCity.continent;
                view.request.destination.countryISO = destinationCity.countryIso3166;
                view.request.destination.countryName = destinationCity.countryName;
            }

            var goDate = moment(view.itineraries[0].departureDate);
            view.request.goDate = {
                dateString: goDate.format("DD/MM/YYYY"),
                date: goDate.toDate(),
                day: goDate.toArray()[2],
                month: goDate.toArray()[1] + 1,
                year: goDate.toArray()[0]
            };
            
            if (view.itineraries[1]) {

                view.request.origin2 = {}
                var origin2Airport = view.airports[view.itineraries[1].departure];
                var origin2City = view.cities[view.itineraries[1].origin];
                if (origin2Airport) {
                    view.request.origin2.airportIATA = origin2Airport.iata;
                    view.request.origin2.airportName = origin2Airport.name;
                    view.request.origin2.cityIATA = origin2Airport.cityIata;
                }
                if (origin2City) {
                    view.request.origin2.cityName = origin2City.name;
                    view.request.origin2.continent = origin2City.continent;
                    view.request.origin2.countryISO = origin2City.countryIso3166;
                    view.request.origin2.countryName = origin2City.countryName;
                }

                view.request.destination2 = {}
                var destination2Airport = view.airports[view.itineraries[1].arrival];
                var destination2City = view.cities[view.itineraries[1].destination];
                if (destination2Airport) {
                    view.request.destination2.airportIATA = destination2Airport.iata;
                    view.request.destination2.airportName = destination2Airport.name;
                    view.request.destination2.cityIATA = destination2Airport.cityIata;
                }
                if (destination2City) {
                    view.request.destination2.cityName = destination2City.name;
                    view.request.destination2.continent = destination2City.continent;
                    view.request.destination2.countryISO = destination2City.countryIso3166;
                    view.request.destination2.countryName = destination2City.countryName;
                }

                var goDate2 = moment(view.itineraries[1].departureDate);
                view.request.goDate2 = {
                    dateString: goDate2.format("DD/MM/YYYY"),
                    date: goDate2.toDate(),
                    day: goDate2.toArray()[2],
                    month: goDate2.toArray()[1] + 1,
                    year: goDate2.toArray()[0]
                };

                view.request.oneWay = false;
                view.request.roundTrip = (view.request.origin.cityIATA == view.request.destination2.cityIATA && view.request.destination.cityIATA == view.request.origin2.cityIATA);
                view.request.openJaw = !view.request.roundTrip;
            }

            view.paxType = view.paxes.groupByKey("paxType");
            view.paxAmount = [0, 0, 0];
            for (var i = 0; i < view.paxAmount.length; i++) {
                if (view.paxType[i]) {
                    if (window.isArray(view.paxType[i])) view.paxAmount[i] = view.paxType[i].length;
                    else view.paxAmount[i] = 1;
                }
            }

            view.request.adults = view.paxAmount[0];
            view.request.childs = view.paxAmount[1];
            view.request.infants = view.paxAmount[2];
            view.request.totalPaxes = view.paxAmount.sum();
        }
        
        view.existETicket = view.paxes.any(function (pax) { return pax.eTicket != null });
        view.isFlexy = false;

        view.baggagesPerPrice = view.paxes.map(function (p) { return p.baggageByItinerary; }).toSimpleArray().groupByKey("totalPrice");

        view.fares = { total: 0, items : []};
        view.fares.items = booking.fares.map(function (item) {
            view.fares.total += (item.amount * item.quantity);
            item.showQuantity = false;
            if (item.type == 10) {
                if (item.paxType == 0) item.name = getT("airbooking.luggage.adult");
                if (item.paxType == 1) item.name = getT("airbooking.luggage.child");
                if (item.paxType == 2) item.name = getT("airbooking.luggage.Infant");
            }
            if (item.type == 11) item.name = getT("airbooking.luggage.Hotel");
            if (item.type == 12) item.name = getT("airbooking.luggage.CarRental");
            if (item.type == 20) {
                item.name = getT("airbooking.luggage.cargoadded");
                item.showQuantity = false;
                var bag = view.baggagesPerPrice[item.amount];
                if ($window.isDefined(bag)) {
                    if ($window.isArray(bag)) bag = bag.first();
                    if (bag.quantity > 1)
                        item.name = getT("airbooking.luggage.setofbaggages").format(bag.quantity, bag.weight);
                    else
                        item.name = getT("airbooking.luggage.kgadded").format(bag.quantity, bag.weight);
                }
            }
            if (item.type == 40) item.name = getT("airbooking.repatriationins");
            if (item.type == 41) {
                item.name = getT("airbooking.luggage.Flexyticket");
            }

            if (item.type == 42) {
                item.name = getT("airbooking.insurancebestprice");
            }

            if (item.type == 60) item.name = getT("airbooking.luggage.ServiceFee");
            if (item.type == 61) item.name = getT("airbooking.luggage.ModificationFee");
            if (item.type == 62) item.name = getT("airbooking.luggage.CancellationFee");
            if (item.type == 70) item.name = getT("airbooking.luggage.Margeagence")
            return item;
        });
        view.fares.total = self.getNumberObject(view.fares.total);
        return view;
    }

    //New function to get booking details including new baggages allowances decoding
    this.getViewForBookingDetailsEntityVersion2 = function (bookingDetails) {
        var booking = bookingDetails.bookings[0];
        var pnrs = booking.pnrs;
        /*
        var pnrs = booking.pnrs.orderBy(function (item) {
                return moment(item.itineraries[0].date).toDate().getTime()
        });*/
        var view = {};
        view._innerData = bookingDetails;

        if (bookingDetails.bookings[0].additionalData != null &&
            bookingDetails.bookings[0].additionalData != undefined) {
            view.bookingAdditionalData = bookingDetails.bookings[0].additionalData.groupByKey("key");
        }
        view.insurances = [];
        view.option = bookingDetails.bookings[0].option;
        view.preBook = bookingDetails.bookings[0].preBook;
        view.status = booking.status;
        view.subStatus = booking.subStatus;
        view.bookingNumber = booking.bookingNumber;
        view.bookingId = booking.bookingId;
        view.recordLocator = pnrs.map(function (item) { return item.recordLocator; });
        view.reservationLocator = pnrs.map(function (item) { return item.reservationLocator; });
        view.providersReference = pnrs.map(function (item) { return item.providerDisplayName + ": " + (item.recordLocator != null ? item.recordLocator : ""); }).join(", ");
        view.providerVendorLocatorTFU = pnrs.map(function (item) {
            if (item.provider && item.provider.toUpperCase() == "TFU") {
                return "Ref: {0} {1}".format(item.platingCarrierName, (item.reservationLocator != null ? item.reservationLocator : ""));
            };
        }).join(", ");

        view.agent = booking.agent;
        view.timestamp = booking.timestamp;
        view.payments = booking.payments;
        view.paxes = pnrs.map(function (item) { return item.passengers; }).toSimpleArray();



        if (booking.qcns)
            view.qcns = booking.qcns;

        if (booking.cruiseNumber)
            view.cruiseNumber = booking.cruiseNumber;

        //Workaround to engine error
        view.paxes.each(function (item) {
            if (item.firstName.substr(-4) == " MRS") {
                if (item.title == 0)
                    item.title = 2;
                item.firstName = item.firstName.replace("MRS", "").trim();
            }
            if (item.firstName.substr(-3) == " MR") {
                if (item.title == 0)
                    item.title = 1;
                item.firstName = item.firstName.replace("MR", "").trim();
            }
        });

        //Merge pnr passengers
        var paxMergeFunction = function (array) {
            return {
                amount: parseFloat(array.sum(function (item) { return item.amount; }).toFixed(2)),
                baggages: array.map(function (item) { return item.baggages; }).toSimpleArray().removeNulls(),
                birthDate: array.map(function (item) { return item.birthDate ? item.birthDate : null; }).removeNulls().first(),
                eTicket: array.map(function (item) { return item.eTicket ? item.eTicket : null; }).removeNulls().join(", "),
                fidelityCard: array.map(function (item) { return item.fidelityCard ? item.fidelityCard : null; }).removeNulls().join(", "),
                fidelityCardNumber: array.map(function (item) { return item.fidelityCardNumber ? item.fidelityCardNumber : null; }).removeNulls().join(", "),
                firstName: array[0].firstName,
                lastName: array[0].lastName,
                paxType: array[0].paxType,
                ptc: array.map(function (item) { return item.ptc; }).distinct(),
                title: array[0].title,
                itineraries: array.map(function (item) { return item.itineraries; }).toSimpleArray().removeNulls(),
                phone: array[0].phone,
                segmentSeats: array.map(function (item) { return item.segmentSeats; }).toSimpleArray().removeNulls(),
                index: array[0].index,
            };
        };
        
        for (var i = 0; i < view.paxes.length; i++) {
            view.paxes[i].index = i;
        }
        
        view.paxes = view.paxes.merge(function (item) { return item.paxType + item.firstName + item.lastName; }, paxMergeFunction);        

        if (view.paxes.length > 0) {
            //sort paxes if first one is not an adult

            if (view.paxes[0].index !== 0) {
                view.paxes.reverse();
            }
        }

        for (var i = 0; i < view.paxes.length; i++) {
            view.paxes[i].index = i;
        }


        view.airlines = bookingDetails.airlines;
        view.airports = bookingDetails.airports;
        view.cities = bookingDetails.cities;
        view.equipments = bookingDetails.equipments;
        view.cabinClasses = bookingDetails.cabinClasses;
        view.itineraries = pnrs.map(function (item) { return item.itineraries; }).toSimpleArray();
        view.isCharter = pnrs.any(function (item) { return item.isCharter; });
        view.ptcs = [];      

        //Baggages
        view.paxes.each(function (pax) {
            if (pax.itineraries)
            {
                //Pax Itineraries Titles
                if (pax.itineraries.length == 2)
                {
                    if (pax.itineraries[0].date < pax.itineraries[1].date) {
                        pax.itineraries[0].itineraryTitle = $i18next.t("airbooking.paxsresume.departure");
                        pax.itineraries[1].itineraryTitle = $i18next.t("airbooking.paxsresume.return");
                    }
                }
                else if (pax.itineraries.length == 1)
                    pax.itineraries[0].itineraryTitle = "Aller";
                else if (pax.itineraries.length > 2)
                {
                    for (var i = 0 ; i < pax.itineraries.length; i++) {
                        pax.itineraries[i].itineraryTitle = "Vol "+(i+1);
                    }
                }

                //Pax Itineraries baggages
                pax.itineraries.each(function (itinerary) {
                    if (itinerary.baggages && itinerary.baggages.length>0) {
                        itinerary.baggages.each(function (baggage) {
                            //Includes baggages
                            if (!baggage.additionnal) {
                                if (baggage.bagType == 1 && baggage.bagPolicy != 1 && baggage.quantity > 0 && baggage.weight > 0)
                                {
                                    //When Type = 1, Policy != 1, Quantity = X with X>0, Weight = Y with Y > 0
                                    baggage.bagText = (getT('resultpage.dekilos')).format(baggage.quantity, baggage.quantity == 1 ? getT('resultpage.piece') : baggage.quantity > 1 ? getT('resultpage.pieces') : '', baggage.weight);
                                }
                                else if(baggage.bagType == 1 && baggage.bagPolicy != 1 && (baggage.quantity == 0 || baggage.quantity == null) && baggage.weight > 0)
                                {
                                    //When Type = 1, Policy != 1, Quantity = NULL, Weight = X with X > 0
                                    baggage.bagText = baggage.weight + " " + getT('resultpage.kilosmaximum');
                                }
                                else if (baggage.bagType == 1 && baggage.bagPolicy == 1 && baggage.quantity > 0 && baggage.weight > 0)
                                {
                                    //When Type = 1, Policy = 1, Quantity = X with X > 0, Weight = Y with Y > 0
                                    baggage.bagText = (getT('resultpage.nexidantpas')).format(baggage.quantity, baggage.quantity == 1 ? getT('resultpage.piece') : baggage.quantity > 1 ? getT('resultpage.pieces') : '', baggage.weight);
                                }

                                else if (baggage.bagType == 1 && baggage.bagPolicy == 0 && baggage.quantity >0 && (baggage.weight == null || baggage.weight == 0)) {
                                    //When Policy = 0 (PieceConcept), quantity != null and weight = null then you should consider it as XPC
                                    baggage.bagText = "{0} en soute".format(baggage.quantity == 1 ? $i18next.t("resultpage.bagage") : baggage.quantity > 1 ? baggage.quantity + getT('resultpage.bagages') : '');
                                }
                                else if (baggage.type && baggage.type.toUpperCase() == "PC" && baggage.bagPolicy == 0 && baggage.quantity > 0)
                                {
                                    //PC or K
                                    if (baggage.weight == null || baggage.weight == 0)
                                        baggage.bagText = "{0} {1} en soute".format(baggage.quantity, baggage.quantity == 1 ? 'Bagage' : baggage.quantity > 1 ? 'Bagages':'');
                                }
                                else if (baggage.type && baggage.type.toUpperCase() == "K" && baggage.bagPolicy == 0 && baggage.quantity >0)
                                    baggage.bagText = "{0} {1} de {2} kg en soute".format(baggage.quantity, baggage.quantity == 1 ? 'pièce' : baggage.quantity > 1 ? 'pièces' : '',baggage.weight);
                            }
                            else {
                            //Additionnal baggages
                                if (baggage.quantity != null && baggage.quantity == 1) {
                                    if(baggage.weight)
                                        baggage.bagText = ($i18next.t("resultpage.bagagedeadded")).format(baggage.weight);

                                    if(!baggage.weight)
                                        baggage.bagText = $i18next.t("resultpage.bagageadded");
                                }
                                else if (baggage.quantity != null && baggage.quantity > 1){
                                    if (baggage.weight)
                                        baggage.bagText = ($i18next.t("resultpage.lotbagageswillbeadded")).format(baggage.quantity, baggage.weight);

                                    if (!baggage.weight)
                                        baggage.bagText = ($i18next.t("resultpage.lotbagages")).format(baggage.quantity);
                                }
                            }
                        });
                    }
                    else
                        itinerary.noBaggages = true;
                });
            }
        });

        self.prepareLists(view);

        if (view.itineraries.length > 0) {
            view.itineraries = view.itineraries.map(function (itinerary) {
                var firstSegment = itinerary.segments.first();
                var lastSegment = itinerary.segments.last();

                var nItinerary = {};
                nItinerary.productType = pnrs[0].productType.toUpperCase() == "TO" ? 1 : 0;
                nItinerary.itineraryId = itinerary.itineraryId;
                nItinerary.origin = itinerary.origin;
                nItinerary.destination = itinerary.destination;
                nItinerary.departure = firstSegment.departureAirport;
                nItinerary.departureDate = firstSegment.departureDatetime;
                nItinerary.departureTime = moment(nItinerary.departureDate).hours() * 60 + moment(nItinerary.departureDate).minutes();
                nItinerary.arrival = lastSegment.arrivalAirport;
                nItinerary.arrivalDate = lastSegment.arrivalDatetime;
                nItinerary.arrivalTime = moment(nItinerary.arrivalDate).hours() * 60 + moment(nItinerary.arrivalDate).minutes();
                nItinerary.marketingCarrier = itinerary.marketingCarrier;
                nItinerary.availableSeats = 9;
                nItinerary.dayChange = Math.floor(moment(nItinerary.arrivalDate.substr(0, 10)).diff(nItinerary.departureDate.substr(0, 10), 'days', true));
                nItinerary.cabinClass = firstSegment.cabinClass;
                nItinerary.baggages = [];
                nItinerary.info = null;
                nItinerary.stops = [];
                nItinerary.proposal = null;
                nItinerary.segments = itinerary.segments.map(function (segment) {
                    var nSegment = {};
                    nSegment.key = segment.key;//don't remove the key !
                    nSegment.departure = {
                        airport: segment.departureAirport,
                        terminal: segment.departureTerminal,
                        dateTime: segment.departureDatetime,
                        time: moment(segment.departureDatetime).hours() * 60 + moment(segment.departureDatetime).minutes()
                    };
                    nSegment.arrival = {
                        airport: segment.arrivalAirport,
                        terminal: segment.arrivalTerminal,
                        dateTime: segment.arrivalDatetime,
                        time: moment(segment.arrivalDatetime).hours() * 60 + moment(segment.arrivalDatetime).minutes()
                    };;
                    nSegment.airline = segment.airline;
                    nSegment.flightNumber = segment.flightNumber;
                    nSegment.operatingAirline = segment.operatingAirline;
                    nSegment.operatingFlightNumber = null;
                    nSegment.flightTime = segment.flightTime;
                    nSegment.miles = segment.miles;
                    nSegment.stops = segment.stops;
                    nSegment.stopReason = segment.stopReason;
                    nSegment.equipment = segment.equipment;
                    nSegment.dayChange = Math.floor(moment(segment.arrivalDatetime.substr(0, 10)).diff(segment.departureDatetime.substr(0, 10), 'days', true));
                    nSegment.cabinClass = segment.cabinClass;
                    nSegment.baggages = [];
                    for (var i = 0; i < segment.baggages.length; i++) {
                        //Check if bag is valid
                        var bag = segment.baggages[i];
                        if (((bag.baggagePC != 0) || (bag.baggageKG != 0)) && (bag.quantity > 0))
                            nSegment.baggages.push(bag);
                    }
                    var equipmentObj = view.equipments[nSegment.equipment];
                    var equipmentType = (equipmentObj) ? equipmentObj.equipmentType : 0;
                    if (equipmentType == 0)
                        equipmentType = 1;
                    nSegment.baggage = airHelper.prepareBaggagesText(equipmentType, nSegment.baggages);

                    return nSegment;
                });

                var segWBaggages = nItinerary.segments.first(function (s) { return s.baggages.length > 0 });
                nItinerary.baggages = segWBaggages != null ? segWBaggages.baggages : [];
                nItinerary.flightTime = nItinerary.segments.sum(function (s) { return s.flightTime });


                if (nItinerary.segments.length > 1) {
                    for (var i = 0; i < nItinerary.segments.length - 1; i++) {
                        var current = nItinerary.segments[i];
                        var next = nItinerary.segments[i + 1];

                        var currentEquipment = view.equipments[current.equipment];
                        var nextEquipment = view.equipments[next.equipment];
                        var equipmentChange = false;
                        if (currentEquipment != null && nextEquipment != null)
                            equipmentChange = currentEquipment.equipmentType != nextEquipment.equipmentType;

                        nItinerary.stops.push({
                            key: current.arrival.airport,
                            time: moment(next.departure.dateTime).diff(current.arrival.dateTime, 'minutes'),
                            terminalChange: current.arrival.terminal != next.departure.terminal,
                            airportChange: current.arrival.airport != next.departure.airport,
                            equipmentChange: equipmentChange
                        });
                    }
                }
                nItinerary.stopsCount = nItinerary.segments.sum(function (s) { return s.stops; }) + nItinerary.stops.length;

                //Fix travel Time problem
                var totalTransitTime = nItinerary.stops.sum(function (e) { return e.time });
                nItinerary.transitTime = totalTransitTime;
                nItinerary.totalTime = nItinerary.flightTime + nItinerary.transitTime;
                //end

                airHelper.prepareItineraryTimes(nItinerary);
                nItinerary.baggage = airHelper.prepareBaggagesText(1, nItinerary.baggages);
                if (nItinerary.baggages.length > 0) {
                    nItinerary.baggage.quantity = nItinerary.baggages[0].quantity;
                    nItinerary.baggage.weight = nItinerary.baggages[0].weight;
                }
                return nItinerary;
            });

            //Prepare Request
            view.request = {};
            view.request.oneWay = view.itineraries.length == 1;
            view.request.roundTrip = false;
            view.request.openJaw = false;
            view.request.multi = view.itineraries.length > 2;

            if (view.request.multi) {
                view.request.itineraries = [];
                view.itineraries.each(function (auxItinerary) {
                    view.request.itineraries.push({
                        origin: {
                            airportIATA: auxItinerary.departure,
                            airportName: '',
                            cityIATA: view.cities[view.airports[auxItinerary.departure].cityIata].iata,
                            cityName: view.cities[view.airports[auxItinerary.departure].cityIata].name
                        }, destination: {
                            airportIATA: auxItinerary.arrival,
                            airportName: '',
                            cityIATA: view.cities[view.airports[auxItinerary.arrival].cityIata].iata,
                            cityName: view.cities[view.airports[auxItinerary.arrival].cityIata].name
                        }, goDate: {
                            date: auxItinerary.departureDate.format("DD/MM/YYYY"),
                        }
                    });
                })
            }
            else {
                view.request.origin = {}
                var originAirport = view.airports[view.itineraries[0].departure];
                var originCity = view.cities[view.itineraries[0].origin];

                if (originAirport) {
                    view.request.origin.airportIATA = originAirport.iata;
                    view.request.origin.airportName = originAirport.name;
                    view.request.origin.cityIATA = originAirport.cityIata;
                }
                if (originCity) {
                    view.request.origin.cityName = originCity.name;
                    view.request.origin.continent = originCity.continent;
                    view.request.origin.countryISO = originCity.countryIso3166;
                    view.request.origin.countryName = originCity.countryName;
                }


                view.request.destination = {}
                var destinationAirport = view.airports[view.itineraries[0].arrival];
                var destinationCity = view.cities[view.itineraries[0].destination];
                if (destinationAirport) {
                    view.request.destination.airportIATA = destinationAirport.iata;
                    view.request.destination.airportName = destinationAirport.name;
                    view.request.destination.cityIATA = destinationAirport.cityIata;
                }
                if (destinationCity) {
                    view.request.destination.cityName = destinationCity.name;
                    view.request.destination.continent = destinationCity.continent;
                    view.request.destination.countryISO = destinationCity.countryIso3166;
                    view.request.destination.countryName = destinationCity.countryName;
                }

                var goDate = moment(view.itineraries[0].departureDate);
                view.request.goDate = {
                    dateString: goDate.format("DD/MM/YYYY"),
                    date: goDate.toDate(),
                    day: goDate.toArray()[2],
                    month: goDate.toArray()[1] + 1,
                    year: goDate.toArray()[0]
                };

                if (view.itineraries[1]) {

                    view.request.origin2 = {}
                    var origin2Airport = view.airports[view.itineraries[1].departure];
                    var origin2City = view.cities[view.itineraries[1].origin];
                    if (origin2Airport) {
                        view.request.origin2.airportIATA = origin2Airport.iata;
                        view.request.origin2.airportName = origin2Airport.name;
                        view.request.origin2.cityIATA = origin2Airport.cityIata;
                    }
                    if (origin2City) {
                        view.request.origin2.cityName = origin2City.name;
                        view.request.origin2.continent = origin2City.continent;
                        view.request.origin2.countryISO = origin2City.countryIso3166;
                        view.request.origin2.countryName = origin2City.countryName;
                    }

                    view.request.destination2 = {}
                    var destination2Airport = view.airports[view.itineraries[1].arrival];
                    var destination2City = view.cities[view.itineraries[1].destination];
                    if (destination2Airport) {
                        view.request.destination2.airportIATA = destination2Airport.iata;
                        view.request.destination2.airportName = destination2Airport.name;
                        view.request.destination2.cityIATA = destination2Airport.cityIata;
                    }
                    if (destination2City) {
                        view.request.destination2.cityName = destination2City.name;
                        view.request.destination2.continent = destination2City.continent;
                        view.request.destination2.countryISO = destination2City.countryIso3166;
                        view.request.destination2.countryName = destination2City.countryName;
                    }

                    var goDate2 = moment(view.itineraries[1].departureDate);
                    view.request.goDate2 = {
                        dateString: goDate2.format("DD/MM/YYYY"),
                        date: goDate2.toDate(),
                        day: goDate2.toArray()[2],
                        month: goDate2.toArray()[1] + 1,
                        year: goDate2.toArray()[0]
                    };

                    view.request.oneWay = false;
                    view.request.roundTrip = (view.request.origin.cityIATA == view.request.destination2.cityIATA && view.request.destination.cityIATA == view.request.origin2.cityIATA);
                    view.request.openJaw = !view.request.roundTrip;
                }
            }

          
          
            view.paxType = view.paxes.groupByKey("paxType");
            view.paxAmount = [0, 0, 0];
            for (var i = 0; i < view.paxAmount.length; i++) {
                if (view.paxType[i]) {
                    if (window.isArray(view.paxType[i])) view.paxAmount[i] = view.paxType[i].length;
                    else view.paxAmount[i] = 1;
                }
            }

            view.request.adults = view.paxAmount[0];
            view.request.childs = view.paxAmount[1];
            view.request.infants = view.paxAmount[2];
            view.request.totalPaxes = view.paxAmount.sum();
        }

        view.existETicket = view.paxes.any(function (pax) { return pax.eTicket != null });
        view.isFlexy = false;

        view.baggagesPerPrice = view.paxes.map(function (p) { return p.baggageByItinerary; }).toSimpleArray().groupByKey("totalPrice");

        //Wonderbox
        view.wonderbox = booking.wonderbox;
        view.wonderboxRemainingAmount = booking.wonderboxRemainingAmount;

        view.fares = { total: 0, items: [] };

        //Wonderbox
        if (booking.wonderboxRemainingAmount > 0)
            view.fares.total = booking.wonderboxRemainingAmount;

        view.fares.items = booking.fares.map(function (item) {
            view.fares.total += (item.amount * item.quantity);
            item.showQuantity = false;
            if (item.type == 10) {
                if (item.paxType == 0) item.name = getT("airbooking.luggage.adult");
                if (item.paxType == 1) item.name = getT("airbooking.luggage.child");
                if (item.paxType == 2) item.name = getT("airbooking.luggage.Infant");
            }
            if (item.type == 11) item.name = getT("mrfly-widget-accommodation-price-detail.hotelamount");
            if (item.type == 12) item.name = getT("airbooking.luggage.CarRental");
            if (item.type == 20) {
                item.name = getT("airbooking.luggage.cargoadded");
                item.showQuantity = false;
                var bag = view.baggagesPerPrice[item.amount];
                if ($window.isDefined(bag)) {
                    if ($window.isArray(bag)) bag = bag.first();
                    if (bag.quantity > 1)
                        item.name = getT("airbooking.luggage.setofbaggages").format(bag.quantity, bag.weight);
                    else
                        item.name = getT("airbooking.luggage.kgadded").format(bag.quantity, bag.weight);
                }
            }
            if (item.type == 40) item.name = getT("airbooking.repatriationins");
            if (item.type == 41) {
                if (item.subtype === 44) {
                    item.name = getT("mrfly-widget-basket.bookingfees");
                } else {
                    item.name = getT("airbooking.luggage.Flexyticket");
                }
            }
            if (item.type == 60) item.name = getT("airbooking.luggage.ServiceFee");
            if (item.type == 61) item.name = getT("airbooking.luggage.ModificationFee");
            if (item.type == 62) item.name = getT("airbooking.luggage.CancellationFee");
            if (item.type == 70) { 
                if (item.subtype === 900) {
                    item.name = getT("airbooking.flightcommission");
                }
                else if (item.subtype === 901) {
                    item.name = getT("airbooking.hotelcommission");
                }
                else {
                    item.name = getT("airbooking.luggage.Margeagence");
                }
            }
            if (item.type == 42) {
                item.name = $i18next.t("airbooking.insurancebestprice");
            }

            return item;
        });
        view.fares.total = self.getNumberObject(view.fares.total);

        //Masstock
        view.masstock = booking.pnrs.any(function (pnr) { return pnr.masstock === true });

        return view;
    }


    this.prepareRawResultsData = function (rawResults) {
    
        //***************************************************************************************************
        //Do transformations on rawResults for easy process
        var swTrans = util.StopWatch.createNew();
        self.prepareLists(rawResults);
        self.prepareSummaryFromRaw(rawResults);
        
        //Process all products
        for (var p = 0; p < rawResults.products.length ; p++) {
            var product = rawResults.products[p];
            product.outbound.visible = true;
            var proposal = null;
            if (product.inbounds != null && product.inbounds.length > 0)
                proposal = product.inbounds[0].proposal;
            else
                proposal = product.outbound.proposal;
            self.prepareItineraryTimes(product.outbound);
            self.prepareItineraryBaggagesText(rawResults, product.outbound, proposal);
            //
            console.log(product);

            if (product.inbounds != null && product.inbounds.length > 0) {
                product.minAverageFare = self.getNumberObject(product.inbounds.min(function (i) { return i.proposal.averageFare; }));
                product.minAverageFareFeeMargin = self.getNumberObject(product.inbounds.min(function (i) { return i.proposal.averageFareFeeMargin; }));
                product.minTotalFare = self.getNumberObject(product.inbounds.min(function (i) { return i.proposal.totalFare; }));
                product.minTotalSubvention = self.getNumberObject(product.inbounds.min(function (i) { return i.proposal.totalSubvention; }));
                product.minTotalFareFeeMargin = self.getNumberObject(product.inbounds.min(function (i) { return i.proposal.totalFareFeeMargin; }));
                for (var i = 0; i < product.inbounds.length; i++) {
                    var inbound = product.inbounds[i];
                    inbound.visible = true;
                    self.prepareItineraryTimes(inbound);
                    self.prepareItineraryBaggagesText(rawResults, inbound, inbound.proposal);
                    self.prepareProposal(inbound.proposal, product);
                    self.prepareTerminalInformation(product, rawResults);
                }

                product.isTimeBefore7pm30 = false;
                var now = new Date();
                if (now.getHours() < 19) {
                    product.isTimeBefore7pm30 = true;
                } else if (now.getHours() < 20) {
                    if (now.getMinutes() < 30) {
                        product.isTimeBefore7pm30 = true;
                    }
                }

                product.hasAnyOptionEnabled = product.inbounds.any(function (i) { return i.proposal.preBookType != 0 });
                product.hasAnyOptionDisabled = product.inbounds.any(function (i) { return i.proposal.preBookType == 0 });
                product.hasAnyBestPrice = product.inbounds.any(function (i) { return i.proposal.totalBestPrice && i.proposal.totalBestPrice.value > 0 });
                product.hasAnyMasstock = product.inbounds.any(function (i) { return i.proposal.masstock === true });
                product.isCharter = product.inbounds.any(function (i) { return i.proposal.isCharter});

                //PreBook
                if (product.hasAnyOptionEnabled) {
                    if (product.outbound.productType == 1) {
                        product.listTicketingDates = product.inbounds.select(function (i) { return i.proposal.preBook }).distinct().toSimpleArray();

                        if (product.listTicketingDates && product.listTicketingDates.length == 1) {
                            product.optionSingleTicketingDate = product.listTicketingDates[0];
                        }
                    }
                }
            }
            else {
                //reset inbounds if emty array
                product.inbounds = null;

                product.isTimeBefore7pm30 = false;
                var now = new Date();
                if (now.getHours() < 19) {
                    product.isTimeBefore7pm30 = true;
                } else if (now.getHours() < 20) {
                    if (now.getMinutes() < 30) {
                        product.isTimeBefore7pm30 = true;
                    }
                }

                product.hasAnyOptionEnabled = product.outbound.proposal.optionEnabled;
                product.hasAnyBestPrice = product.outbound.proposal.totalBestPrice > 0;
                product.hasAnyMasstock = product.outbound.proposal.masstock === true;
                product.isCharter = product.outbound.proposal.isCharter;

                //Prebook
                if (product.hasAnyOptionEnabled) {
                    if (product.outbound.productType == 1) {
                        product.optionSingleTicketingDate = product.outbound.proposal.ticketing;
                    }
                }

                product.minAverageFare = self.getNumberObject(product.outbound.proposal.averageFare);
                product.minAverageFareFeeMargin = self.getNumberObject(product.outbound.proposal.averageFareFeeMargin);
                product.minTotalFare = self.getNumberObject(product.outbound.proposal.totalFare);
                product.minTotalSubvention = self.getNumberObject(product.outbound.proposal.totalSubvention);
                product.minTotalFareFeeMargin = self.getNumberObject(product.outbound.proposal.totalFareFeeMargin);
                self.prepareProposal(product.outbound.proposal, product);
            }
        }
        rawResults.minPriceProduct = rawResults.products.minObj(function (a) { return a.minAverageFareFeeMargin.value }).first();

        swTrans.stop();
        util.Debug.log("Transformations: {0}ms".format(swTrans.getElapsed()));

        //***************************************************************************************************

        return rawResults;
    };

    this.createNonPagedHelper = function (rawResults, onDataChange, initialFilters, sortFunction, orderFunction) {

        var adapter = function () {
            var self = this;
            rawResults = _root.prepareRawResultsData(rawResults);

            self.view = {
                data: rawResults,
                totalProductsCount: rawResults.products.length,
                productsCount: 0,
                productsRemovedCount: 0,
                appliedFiltersCount: 0,
                appliedFilters: null,
                appliedFiltersValue: null,
                initialFiltersValue: util.getOrDefault(rawResults.summary, null),
                minPriceProduct : rawResults.minPriceProduct,
                minPriceProductVisible: null,
                minPriceProductDelta : _root.getNumberObject(0)
            };

            self.currentSortFunction = util.getOrDefault(sortFunction, null);
            self.currentOrderByFunction = util.getOrDefault(orderFunction, null);
            self.updateTimeout = null;

            //Wonderbox
            if (self.view.data && self.view.data.products) {
                self.view.data.isAnyProductIncluded = self.view.data.products.any(function (p) {
                    return (p.minTotalFareFeeMargin && p.minTotalFareFeeMargin.value == 0);
                });
                self.view.data.isAnyProductNotIncluded = self.view.data.products.any(function (p) {
                    return (p.minTotalFareFeeMargin && p.minTotalFareFeeMargin.value > 0);
                });
            }

            var updateData = function (callBack) {
                util.Debug.log("Data Updated");
                if (self.updateTimeout != null)
                    $timeout.cancel(self.updateTimeout);
                if (onDataChange) {
                    self.updateTimeout = $timeout(function () {
                        var tmpView = $window.objectClone(self.view, 25);
                        onDataChange(tmpView);
                        if (callBack)
                            callBack(tmpView);
                    }, 0);
                }
            };

            //Update RawResults
            self.updateRawResults = function (newRawResults) {
                self.view.data = newRawResults;
            };

            //Sort
            self.sort = function (sortFunction, callBack) {
                var _sw = util.StopWatch.createNew();
                if (sortFunction)
                    self.currentSortFunction = sortFunction;

                if (self.currentSortFunction != null) {
                    //sort stuff
                    if ($window.isFunction(self.currentSortFunction))
                        self.view.data.products.sort(self.currentSortFunction);
                }
                util.Debug.log("Sort: {0}ms".format(_sw.getElapsed()));
                updateData(callBack);
            };

            //OrderBy
            self.orderBy = function (orderFunctions, callBack) {
                var _sw = util.StopWatch.createNew();
                if (orderFunctions)
                    self.currentOrderByFunction = orderFunctions;
                if (self.currentOrderByFunction != null)
                    self.view.data.products = self.view.data.products.orderBy(self.currentOrderByFunction);
                util.Debug.log("OrderBy: {0}ms".format(_sw.getElapsed()));
                updateData(callBack);
            };

            //Apply Filters
            self.applyFilters = function (summaryFilters, sortFunction, orderFunction, callBack) {
                var _sw = util.StopWatch.createNew();
                innerRemoveFilters();
                var filteredProducts = [];
                var summaryFilters = summaryFilters || initialFilters;

                self.view.productsRemovedCount = 0;
                for (var i = 0; i < self.view.data.products.length; i++) {
                    var product = self.view.data.products[i];
                    if (summaryFilters != null)
                        product.outbound.visible = innerCheckProduct(self.view.data, product, summaryFilters, self.view.appliedFilters);
                    else
                        product.outbound.visible = true;
                    if (!product.outbound.visible) self.view.productsRemovedCount++;
                }
                if (self.view.productsRemovedCount > 0)
                    self.view.appliedFiltersCount++;

                self.view.appliedFiltersValue = summaryFilters;
                self.view.productsCount = self.view.data.products.length - self.view.productsRemovedCount;
                self.view.anyAppliedFilters = $window.objectToArray(self.view.appliedFilters, false).any();
                self.view.minPriceProductVisible = self.view.data.products.orderBy(function (p) { return p.minAverageFareFeeMargin.value; }).first(function (p) { return p.outbound.visible; });
                if (self.view.minPriceProductVisible != null && self.view.minPriceProduct != null)
                    self.view.minPriceProductDelta = _root.getNumberObject(self.view.minPriceProductVisible.minAverageFareFeeMargin.value - self.view.minPriceProduct.minAverageFareFeeMargin.value);

                util.Debug.log('Total products count: ' + self.view.totalProductsCount);
                //util.Debug.log('Current products count: ' + self.view.productsCount);
                util.Debug.log('Current products removed: ' + self.view.productsRemovedCount);
                //util.Debug.log('There is a filter applied?: ' + self.view.anyAppliedFilters);
                //util.Debug.log('How many times a filters were applied since the first load: ' + self.view.appliedFiltersCount);
                util.Debug.log("Total time applying the filters: {0}ms".format(_sw.getElapsed()));

                if (sortFunction || self.currentSortFunction)
                    self.sort(sortFunction, callBack);
                else if (orderFunction || self.currentOrderByFunction)
                    self.orderBy(orderFunction, callBack);
                else
                    updateData(callBack);
            };

            //Remove Filters
            self.removeFilters = function (callBack) {
                //put all visible
                innerRemoveFilters();
                updateData(callBack);
            };

            var innerRemoveFilters = function () {
                //util.Debug.log("innerRemoveFilters");
                self.view.data.products.each(function (product) {
                    product.outbound.visible = true;
                    if (product.inbounds != null && product.inbounds.length > 0) {
                        product.inbounds.each(function (inbound) {
                            inbound.visible = true;
                        });
                    }
                });

                self.view.appliedFilters = {
                    stops: false, marketingCarrier: false, arrivalIATA: false, arrivalTime: false, departureIATA: false,
                    baggages: false, departureTime: false, flightTime: false, transitTime: false, totalTime: false, adultFare: false, totalFare: false, totalFareFeeMargin: false,
                    averageFare: false, averageFareFeeMargin: false
                };

            };

            var innerCheckProduct = function (data, product, filter, appliedFilters) {
                //exclude train
                if (filter.excludeTrain) {

                    var exclude = false; 

                    product.outbound.segments.each(function (segment) {
                        if (railHelper.IsTrain(segment.equipment)) {
                            exclude = true;
                        }              
                    });

                    if (product.inbounds != null && product.inbounds.length > 0) {
                        product.inbounds.each(function (inbound) {
                            inbound.segments.each(function (segment) {
                                if (railHelper.IsTrain(segment.equipment)) {
                                    exclude = true;
                                }
                            });
                        });
                    }

                    if (exclude === true)
                        return false;
                }

                var shouldBeHidden = false;
                var hasWrongStop = false;
                var wasFilteredByStop = false;
                var isNotSelectedMarketingCarrier = false;
                var wasFilteredByAirline = false;
                product.wasNotFilteredByAirline = false;

                //Stops
                if (!filter.stops.contains(product.outbound.stopsCount)) {
                    appliedFilters['stops'] = true;
                    hasWrongStop = true;
                    if (filter.stops.contains(-2)) {
                        wasFilteredByStop = true;
                    }
                }
                //Marketing Airline
                if (!filter.marketingCarriers.contains(product.outbound.marketingCarrier)) {
                    appliedFilters['marketingCarrier'] = true;
                    isNotSelectedMarketingCarrier = true;
                    if (filter.expandMarketingCarriers) {
                        wasFilteredByAirline = true;
                    }
                } else {
                    product.wasNotFilteredByAirline = true;
                }
                //Arrival IATA
                if (!filter.outbound.arrival.contains(product.outbound.arrival)) {
                    if ($window.site.partnerId === "europa") {
                        appliedFilters['arrivalIATA'] = false;
                    }
                    else {
                        appliedFilters['arrivalIATA'] = true;
                        shouldBeHidden = true;
                    }
                    
                }
                //Arrival Time
                if (product.outbound.arrivalTime.value < filter.outbound.arrivalTime.from.value || product.outbound.arrivalTime.value > filter.outbound.arrivalTime.to.value) {
                    appliedFilters['arrivalTime'] = true;
                    shouldBeHidden = true;
                }
                //Departure IATA
                if (!filter.outbound.departure.contains(product.outbound.departure)) {
                    if ($window.site.partnerId === "europa") {
                        appliedFilters['departureIATA'] = false;
                    }
                    else {
                        appliedFilters['departureIATA'] = true;
                        shouldBeHidden = true;
                    }
                }
                //Baggages
                if (filter.outbound.baggages[0] > product.outbound.baggage.quantity) {
                    appliedFilters['baggages'] = true;
                    shouldBeHidden = true;
                }
                //Departure Time
                if (product.outbound.departureTime.value < filter.outbound.departureTime.from.value || product.outbound.departureTime.value > filter.outbound.departureTime.to.value) {
                    appliedFilters['departureTime'] = true;
                    shouldBeHidden = true;
                }
                //Flight Time
                if (product.outbound.flightTime.value < filter.outbound.flightTime.from.value || product.outbound.flightTime.value > filter.outbound.flightTime.to.value) {
                    appliedFilters['flightTime'] = true;
                    shouldBeHidden = true;
                }
                //Transit Time
                if (product.outbound.transitTime.value < filter.outbound.transitTime.from.value || product.outbound.transitTime.value > filter.outbound.transitTime.to.value) {
                    appliedFilters['transitTime'] = true;
                    shouldBeHidden = true;
                }
                //Total Time
                if (product.outbound.totalTime.value < filter.outbound.totalTime.from.value || product.outbound.totalTime.value > filter.outbound.totalTime.to.value) {
                    appliedFilters['totalTime'] = true;
                    shouldBeHidden = true;
                }

                //Fares Type **TO**
                if (filter.productTypes && !filter.productTypes.contains(product.outbound.productType)) {
                    appliedFilters['productTypes'] = true;
                    shouldBeHidden = true;
                }

                if (shouldBeHidden || (!wasFilteredByStop && hasWrongStop) || (!wasFilteredByAirline && isNotSelectedMarketingCarrier)) {
                    return false;
                }

                var visibleInbounds = 0;

                if (product.inbounds != null && product.inbounds.length > 0) {

                    var inboundChecker = function (item) {
                        var shouldHideInbound = false;

                        //Arrival IATA
                        if (!filter.inbound.arrival.contains(item.arrival)) {
                            if ($window.site.partnerId === "europa") {
                                appliedFilters['arrivalIATA'] = false;
                            }
                            else {
                                appliedFilters['arrivalIATA'] = true;
                                shouldHideInbound = true;
                            }
                        }
                        //Arrival Time
                        if (item.arrivalTime.value < filter.inbound.arrivalTime.from.value || item.arrivalTime.value > filter.inbound.arrivalTime.to.value) {
                            appliedFilters['arrivalTime'] = true;
                            shouldHideInbound = true;
                        }
                        //Departure IATA
                        if (!filter.inbound.departure.contains(item.departure)) {
                            if ($window.site.partnerId === "europa") {
                                appliedFilters['departureIATA'] = false;
                            }
                            else {
                                appliedFilters['departureIATA'] = true;
                                shouldHideInbound = true;
                            }
                        }
                        //Baggages
                        if (filter.inbound.baggages[0] > item.baggage.quantity) {
                            appliedFilters['baggages'] = true;
                            shouldHideInbound = true;
                        }
                        //Departure Time
                        if (item.departureTime.value < filter.inbound.departureTime.from.value || item.departureTime.value > filter.inbound.departureTime.to.value) {
                            appliedFilters['departureTime'] = true;
                            shouldHideInbound = true;
                        }
                        //Flight Time
                        if (item.flightTime.value < filter.inbound.flightTime.from.value || item.flightTime.value > filter.inbound.flightTime.to.value) {
                            appliedFilters['flightTime'] = true;
                            shouldHideInbound = true;
                        }
                        //Stop Time
                        if (item.transitTime.value < filter.inbound.transitTime.from.value || item.transitTime.value > filter.inbound.transitTime.to.value) {
                            appliedFilters['transitTime'] = true;
                            shouldHideInbound = true;
                        }
                        //Total Time
                        if (item.totalTime.value < filter.inbound.totalTime.from.value || item.totalTime.value > filter.inbound.totalTime.to.value) {
                            appliedFilters['totalTime'] = true;
                            shouldHideInbound = true;
                        }

                        if ($window.isServair) {
                             //Average fare fee margin
                             if (filter.averageFareFeeMargin) {
                                var proposal = item.proposal;
                                if (proposal.totalSubvention < filter.averageFareFeeMargin.from.value || proposal.totalSubvention > filter.averageFareFeeMargin.to.value) {
                                    appliedFilters['averageFareFeeMargin'] = true;
                                    shouldHideInbound = true;
                                }
                            }
                        
                        }
                        else{
                            //Adult fare
                            if (filter.adultFare) {
                                var adtFare = item.proposal.fares['0'];
                                if (adtFare.fare.value < filter.adultFare.from.value || adtFare.fare.value > filter.adultFare.to.value) {
                                    appliedFilters['adultFare'] = true;
                                    shouldHideInbound = true;
                                }
                            }
                            //Total fare
                            if (filter.totalFare) {
                                var proposal = item.proposal;
                                if (proposal.totalFare.value < filter.totalFare.from.value || proposal.totalFare.value > filter.totalFare.to.value) {
                                    appliedFilters['totalFare'] = true;
                                    shouldHideInbound = true;
                                }
                            }
                            //Total fare fee margin
                            if (filter.totalFareFeeMargin) {
                                var proposal = item.proposal;
                                if (proposal.totalFareFeeMargin.value < filter.totalFareFeeMargin.from.value || proposal.totalFareFeeMargin.value > filter.totalFareFeeMargin.to.value) {
                                    appliedFilters['totalFareFeeMargin'] = true;
                                    shouldHideInbound = true;
                                }
                            }

                            //Average fare
                            if (filter.averageFare) {
                                var proposal = item.proposal;
                                if (proposal.averageFare.value < filter.averageFare.from.value || proposal.averageFare.value > filter.averageFare.to.value) {
                                    appliedFilters['averageFare'] = true;
                                    shouldHideInbound = true;
                                }
                            }
                            //Average fare fee margin
                            if (filter.averageFareFeeMargin) {
                                var proposal = item.proposal;
                                if (proposal.averageFareFeeMargin.value < filter.averageFareFeeMargin.from.value || proposal.averageFareFeeMargin.value > filter.averageFareFeeMargin.to.value) {
                                    appliedFilters['averageFareFeeMargin'] = true;
                                    shouldHideInbound = true;
                                }
                            }
                        }

                        return !shouldHideInbound;
                    };

                    var hasAnyDirectInbound = false;
                    var outboundIsDirect = product.outbound.stopsCount == 0;

                    product.inbounds.each(function (item) {
                        if (item.stopsCount == 0) {
                            hasAnyDirectInbound = true;
                        }
                        var inboundChecked = inboundChecker(item);
                        var keepTheInbound = true;
                        if (inboundChecked) {
                            //Stops by inbound
                            if (!filter.stops.contains(item.stopsCount)) {
                                appliedFilters['stops'] = true;
                                keepTheInbound = false;
                                if (wasFilteredByStop && (item.stopsCount == 0 || outboundIsDirect)) {
                                    keepTheInbound = true;
                                }
                            }

                            //Marketing Airline
                            if (!filter.marketingCarriers.contains(item.marketingCarrier)) {
                                appliedFilters['marketingCarrier'] = true;
                                keepTheInbound = false;
                                if (filter.expandMarketingCarriers && product.wasNotFilteredByAirline) {
                                    keepTheInbound = true;
                                }
                            }

                        } else {
                            keepTheInbound = false;
                        }
                        item.visible = keepTheInbound;
                        if (item.visible)
                            visibleInbounds++;
                    });

                    if (wasFilteredByStop) {
                        if (!hasAnyDirectInbound && !outboundIsDirect) {
                            return false;
                        }
                    }

                    if($scope.isServair){
                        product.inbounds = product.inbounds.orderBy(
                            function (inbound) { return inbound.proposal.totalSubvention; },
                            function (inbound) { return inbound.departureTime.value; },
                            function (inbound) { return inbound.arrivalTime.value; }
                        );
                    }
                    else{
                        product.inbounds = product.inbounds.orderBy(
                            function (inbound) { return inbound.proposal.averageFare.value; },
                            function (inbound) { return inbound.departureTime.value; },
                            function (inbound) { return inbound.arrivalTime.value; }
                        );
                    }
              

                    //***********
                    //var baggages = product.minProposals.additionalBaggages.map(function (b) { return b.baggages }).toSimpleArray();
                    //product.minAdditionalBaggage = baggages.minObj(function (b) { return b.price; }).first();

                    if (product.inbounds.any(function (i) { return i.visible; }))
                    {
                        product.minAverageFare = _root.getNumberObject(product.inbounds.min(function (i) { return i.visible ? i.proposal.averageFare.value : 9999999999; }));
                        product.minAverageFareFeeMargin = _root.getNumberObject(product.inbounds.min(function (i) { return i.visible ? i.proposal.averageFareFeeMargin.value : 9999999999; }));
                        product.minTotalFare = _root.getNumberObject(product.inbounds.min(function (i) { return i.visible ? i.proposal.totalFare.value : 9999999999; }));
                        product.minTotalFareFeeMargin = _root.getNumberObject(product.inbounds.min(function (i) { return i.visible ? i.proposal.totalFareFeeMargin.value : 9999999999; }));
                        product.minProposal = product.inbounds.first(function (i) { return i.visible; }).proposal;
                        if ($window.isServair && product.minProposal.totalSubvention){
                            product.minProposal.subvention = {};
                            product.minProposal.subvention.total = product.minProposal.totalSubvention;
                            product.minProposal.subvention.int = product.minProposal.totalSubvention.toString().split('.')[0];
                            product.minProposal.subvention.dec = product.minProposal.totalSubvention.toString().split('.')[1];
                        }
                        product.minTotalSubvention = _root.getNumberObject(product.inbounds.min(function (i) { return i.visible ? i.proposal.totalSubvention : 9999999999; }));

                    }
                    else
                    {
                        product.minAverageFare = _root.getNumberObject(product.inbounds.min(function (i) { return i.proposal.averageFare.value; }));
                        product.minAverageFareFeeMargin = _root.getNumberObject(product.inbounds.min(function (i) { return i.proposal.averageFareFeeMargin.value; }));
                        product.minTotalFare = _root.getNumberObject(product.inbounds.min(function (i) { return i.proposal.totalFare.value; }));
                        product.minTotalSubvention = _root.getNumberObject(product.inbounds.min(function (i) { return i.proposal.totalSubvention; }));
                        product.minTotalFareFeeMargin = _root.getNumberObject(product.inbounds.min(function (i) { return i.proposal.totalFareFeeMargin.value; }));
                        product.minProposal = product.inbounds.first().proposal;
                        if ($window.isServair && product.minProposal.totalSubvention){
                            product.minProposal.subvention = {};
                            product.minProposal.subvention.total = product.minProposal.totalSubvention;
                            product.minProposal.subvention.int = product.minProposal.totalSubvention.toString().split('.')[0];
                            product.minProposal.subvention.dec = product.minProposal.totalSubvention.toString().split('.')[1];
                        }
                    }

                    ////***********
                    _root.prepareItineraryBaggagesText(rawResults, product.outbound, product.minProposal);

                    product.inbounds.each(function (i) {
                        _root.prepareItineraryBaggagesText(rawResults, i, i.proposal);
                        _root.prepareProposal(i.proposal, product);
                    });
                }
                else {
                    product.inbounds = null;
                    var item = product.outbound;
                    //Fares
                    if ($window.isServair) {
                        //Average fare fee margin
                        if (filter.averageFareFeeMargin) {
                            var proposal = item.proposal;
                            if (proposal.totalSubvention < filter.averageFareFeeMargin.from.value || proposal.totalSubvention > filter.averageFareFeeMargin.to.value) {
                                appliedFilters['averageFareFeeMargin'] = true;
                                //return false;
                            }
                        }

                    }
                    else {

                        //Adult fare
                        if (filter.adultFare) {
                            var adtFare = item.proposal.fares['0'];
                            if (adtFare.fare.value < filter.adultFare.from.value || adtFare.fare.value > filter.adultFare.to.value) {
                                appliedFilters['adultFare'] = true;
                                //return false;
                            }
                        }
                        //Total fare
                        if (filter.totalFare) {
                            var proposal = item.proposal;
                            if (proposal.totalFare.value < filter.totalFare.from.value || proposal.totalFare.value > filter.totalFare.to.value) {
                                appliedFilters['totalFare'] = true;
                                //return false;
                            }
                        }
                        //Total fare fee margin
                        if (filter.totalFareFeeMargin) {
                            var proposal = item.proposal;
                            if (proposal.totalFareFeeMargin.value < filter.totalFareFeeMargin.from.value || proposal.totalFareFeeMargin.value > filter.totalFareFeeMargin.to.value) {
                                appliedFilters['totalFareFeeMargin'] = true;
                                //return false;
                            }
                        }
                        //Average fare
                        if (filter.averageFare) {
                            var proposal = item.proposal;
                            if (proposal.averageFare.value < filter.averageFare.from.value || proposal.averageFare.value > filter.averageFare.to.value) {
                                appliedFilters['averageFare'] = true;
                                //return false;
                            }
                        }
                        //Average fare fee margin
                        if (filter.averageFareFeeMargin) {
                            var proposal = item.proposal;
                            if (proposal.averageFareFeeMargin.value < filter.averageFareFeeMargin.from.value || proposal.averageFareFeeMargin.value > filter.averageFareFeeMargin.to.value) {
                                appliedFilters['averageFareFeeMargin'] = true;
                                //return false;
                            }
                        }
                    }

                    //***********
                    //var baggages = product.minProposals.additionalBaggages.map(function (b) { return b.baggages }).toSimpleArray();
                    //product.minAdditionalBaggage = baggages.minObj(function (b) { return b.price; }).first();

                    //var baggages = product.minProposals.ECO.additionalBaggages.map(function (b) { return b.baggages }).toSimpleArray();
                    //var baggagesMinPrice = baggages.min(function (b) { return b.totalPrice; });
                    //product.minAdditionalBaggage = baggages.first(function (b) { return b.totalPrice == baggagesMinPrice; });

                    product.minAverageFare = _root.getNumberObject(product.outbound.proposal.averageFare.value);
                    product.minAverageFareFeeMargin = _root.getNumberObject(product.outbound.proposal.averageFareFeeMargin.value);
                    product.minTotalFare = _root.getNumberObject(product.outbound.proposal.totalFare.value);
                    product.minTotalFareFeeMargin = _root.getNumberObject(product.outbound.proposal.totalFareFeeMargin.value);
                    ////***********

                    product.minProposal = product.outbound.proposal;

                    if($window.isServair){
                        product.minProposal.subvention = {};
                        product.minProposal.subvention.total = product.minProposal.totalSubvention;
                        product.minProposal.subvention.int = product.minProposal.totalSubvention.toString().split('.')[0];
                        product.minProposal.subvention.dec = product.minProposal.totalSubvention.toString().split('.')[1];
                    }

                    ////***********
                    _root.prepareItineraryBaggagesText(rawResults, product.outbound, product.minProposal);
                    _root.prepareProposal(product.outbound.proposal, product);

                }
                //Remove product with no inbounds
                if (product.inbounds != null && visibleInbounds == 0)
                    return false;

                return true;
            };

            self.applyFilters(initialFilters, self.currentSortFunction, self.currentOrderByFunction);
        };

        return new adapter();
    };

    this.prepareBaggagesTextForResultsPage = function (baggages) {
        if (baggages) {
            var paxTypes = [];
            paxTypes[0] = "ad." //Adulte
            paxTypes[1] = "enf."; //enfant
            paxTypes[2] = "Bébé"; //Bebe
            baggages.each(function (bag) {
                if (bag.quantity > 0) {
                    bag.displayText = "{0} baggages en soute (par {1})".format(bag.quantity, paxTypes[bag.paxType]);
                }
                else {
                    bag.displayText = getT('airbooking.paxsresume.nobaggages');
                }
            })
        }
    }

    var prepareRawResultsDataMultiDestination = function (rawResults) {
        
        var swTrans = util.StopWatch.createNew();
        self.prepareSummaryFromRaw(rawResults);
                
        for (var p = 0; p < rawResults.products.length; p++) {
            var product = rawResults.products[p];
            product.averageFare = self.getNumberObject(product.averageFare);
            product.averageFareFeeMargin = self.getNumberObject(product.averageFareFeeMargin);
            product.visible = true;
        }

        rawResults.minPriceProduct = rawResults.products.minObj(function (a) { return a.averageFareFeeMargin.value }).first();

        swTrans.stop();
        util.Debug.log("Transformations: {0}ms".format(swTrans.getElapsed()));

        return rawResults;
    };

    this.createNonPagedHelperMultiDestination = function (rawResults, onDataChange, initialFilters, sortFunction, orderFunction) {

        var adapter = function () {
            var self = this;
            rawResults = prepareRawResultsDataMultiDestination(rawResults);

            self.view = {
                data: rawResults,
                totalProductsCount: rawResults.products.length,
                productsCount: 0,
                productsRemovedCount: 0,
                appliedFiltersCount: 0,
                appliedFilters: null,
                appliedFiltersValue: null,
                initialFiltersValue: util.getOrDefault(rawResults.summary, null),
                minPriceProduct: rawResults.minPriceProduct,
                minPriceProductVisible: null,
                minPriceProductDelta: _root.getNumberObject(0)
            };

            self.view.initialFiltersValue.airports = [];
            initialFilters.airports = [];

            for (var i = 0; i < initialFilters.itinerariesSummary.length; i++) {
                var it = initialFilters.itinerariesSummary[i];

                for (var j = 0; j < it.arrival.length; j++) {
                    var airport = it.arrival[j];
                    self.view.initialFiltersValue.airports.push(airport);
                    initialFilters.airports.push(airport);
                }

                for (var j = 0; j < it.departure.length; j++) {
                    var airport = it.departure[j];
                    self.view.initialFiltersValue.airports.push(airport);
                    initialFilters.airports.push(airport);
                }
            }
            self.view.initialFiltersValue.airports = self.view.initialFiltersValue.airports.distinct();
            initialFilters.airports = initialFilters.airports.distinct();

            self.currentSortFunction = util.getOrDefault(sortFunction, null);
            self.currentOrderByFunction = util.getOrDefault(orderFunction, null);
            self.updateTimeout = null;

            var updateData = function (callBack) {
                util.Debug.log("Data Updated");
                if (self.updateTimeout != null)
                    $timeout.cancel(self.updateTimeout);
                if (onDataChange) {
                    self.updateTimeout = $timeout(function () {
                        var tmpView = $window.objectClone(self.view, 25);
                        onDataChange(tmpView);
                        if (callBack)
                            callBack(tmpView);
                    }, 0);
                }
            };

            //Sort
            self.sort = function (sortFunction, callBack) {
                var _sw = util.StopWatch.createNew();
                if (sortFunction)
                    self.currentSortFunction = sortFunction;

                if (self.currentSortFunction != null) {
                    //sort stuff
                    if ($window.isFunction(self.currentSortFunction))
                        self.view.data.products.sort(self.currentSortFunction);
                }
                util.Debug.log("Sort: {0}ms".format(_sw.getElapsed()));
                updateData(callBack);
            };

            //OrderBy
            self.orderBy = function (orderFunctions, callBack) {
                var _sw = util.StopWatch.createNew();

                if (orderFunctions)
                    self.currentOrderByFunction = orderFunctions;

                if (self.currentOrderByFunction != null)
                    self.view.data.products = self.view.data.products.orderBy(self.currentOrderByFunction);

                util.Debug.log("OrderBy: {0}ms".format(_sw.getElapsed()));
                updateData(callBack);
            };

            //Apply Filters
            self.applyFilters = function (summaryFilters, sortFunction, orderFunction, callBack) {
                var _sw = util.StopWatch.createNew();
                innerRemoveFilters();
                var filteredProducts = [];
                var summaryFilters = summaryFilters || initialFilters;

                self.view.productsRemovedCount = 0;
                for (var i = 0; i < self.view.data.products.length; i++) {
                    var product = self.view.data.products[i];
                    if (summaryFilters != null) {
                        product.visible = innerCheckProduct(self.view.data, product, summaryFilters, self.view.appliedFilters);
                    }
                    else
                        product.visible = true;
                    if (!product.visible) self.view.productsRemovedCount++;
                }
                if (self.view.productsRemovedCount > 0)
                    self.view.appliedFiltersCount++;

                self.view.appliedFiltersValue = summaryFilters;
                self.view.productsCount = self.view.data.products.length - self.view.productsRemovedCount;
                self.view.anyAppliedFilters = $window.objectToArray(self.view.appliedFilters, false).any();
                self.view.minPriceProductVisible = self.view.data.products.orderBy(function (p) { return p.averageFareFeeMargin.value; }).first(function (p) { return p.visible; });
                if (self.view.minPriceProductVisible != null && self.view.minPriceProduct != null)
                    self.view.minPriceProductDelta = _root.getNumberObject(self.view.minPriceProductVisible.averageFareFeeMargin.value - self.view.minPriceProduct.averageFareFeeMargin.value);

                util.Debug.log('Total products count: ' + self.view.totalProductsCount);
                //util.Debug.log('Current products count: ' + self.view.productsCount);
                util.Debug.log('Current products removed: ' + self.view.productsRemovedCount);
                //util.Debug.log('There is a filter applied?: ' + self.view.anyAppliedFilters);
                //util.Debug.log('How many times a filters were applied since the first load: ' + self.view.appliedFiltersCount);
                util.Debug.log("Total time applying the filters: {0}ms".format(_sw.getElapsed()));

                if (sortFunction || self.currentSortFunction)
                    self.sort(sortFunction, callBack);
                else if (orderFunction || self.currentOrderByFunction)
                    self.orderBy(orderFunction, callBack);
                else
                    updateData(callBack);
            };

            //Remove Filters
            self.removeFilters = function (callBack) {
                //put all visible
                innerRemoveFilters();
                updateData(callBack);
            };

            var innerRemoveFilters = function () {
                //util.Debug.log("innerRemoveFilters");
                self.view.data.products.each(function (product) {
                    product.visible = true;
                    if (product.inbounds != null && product.inbounds.length > 0) {
                        product.inbounds.each(function (inbound) {
                            inbound.visible = true;
                        });
                    }
                });

                self.view.appliedFilters = {
                    stops: false, marketingCarrier: false, arrivalIATA: false, arrivalTime: false, departureIATA: false,
                    baggages: false, departureTime: false, flightTime: false, transitTime: false, totalTime: false, adultFare: false, totalFare: false, totalFareFeeMargin: false,
                    averageFare: false, averageFareFeeMargin: false
                };

            };

            var innerCheckProduct = function (data, product, filter, appliedFilters) {
                //exclude train
                if (filter.excludeTrain) {

                    var exclude = false;

                    product.outbound.segments.each(function (segment) {
                        if (railHelper.IsTrain(segment.equipment)) {
                            exclude = true;
                        }
                    });

                    if (product.inbounds != null && product.inbounds.length > 0) {
                        product.inbounds.each(function (inbound) {
                            inbound.segments.each(function (segment) {
                                if (railHelper.IsTrain(segment.equipment)) {
                                    exclude = true;
                                }
                            });
                        });
                    }

                    if (exclude === true)
                        return false;
                }

                //Adult fare
                if (filter.adultFare) {
                    var adtFare = product.fares['0'];
                    if (adtFare.fare.value < filter.adultFare.from.value || adtFare.fare.value > filter.adultFare.to.value) {
                        appliedFilters['adultFare'] = true;
                        return false;
                    }
                }
                //Total fare
                if (filter.totalFare) {
                    if (product.totalFare.value < filter.totalFare.from.value || product.totalFare.value > filter.totalFare.to.value) {
                        appliedFilters['totalFare'] = true;
                        return false;
                    }
                }
                //Total fare fee margin
                if (filter.totalFareFeeMargin) {
                    if (product.totalFareFeeMargin.value < filter.totalFareFeeMargin.from.value || product.totalFareFeeMargin.value > filter.totalFareFeeMargin.to.value) {
                        appliedFilters['totalFareFeeMargin'] = true;
                        return false;
                    }
                }
                //Average fare
                if (filter.averageFare) {
                    if (product.averageFare.value < filter.averageFare.from.value || product.averageFare.value > filter.averageFare.to.value) {
                        appliedFilters['averageFare'] = true;
                        return false;
                    }
                }
                //Average fare fee margin
                if (filter.averageFareFeeMargin) {
                    if (product.averageFareFeeMargin.value < filter.averageFareFeeMargin.from.value || product.averageFareFeeMargin.value > filter.averageFareFeeMargin.to.value) {
                        appliedFilters['averageFareFeeMargin'] = true;
                        return false;
                    }
                }

                var temItineraries = self.view.data.itineraries;
                var itIndex = -1;
                var filterApplied = product.itineraries.any(function (it) {
                    itIndex++;
                    return it.itinerariesOptions.any(function (itOption) {
                        itOption.visible = true;
                        //Stops
                        if (!filter.stops.contains(temItineraries[itOption.key].stopsCount)) {
                            appliedFilters['stops'] = true;
                            itOption.visible = false;
                            //return true;
                        }

                        //Marketing Airline
                        if (!filter.marketingCarriers.contains(temItineraries[itOption.key].marketingCarrier)) {
                            appliedFilters['marketingCarrier'] = true;
                            itOption.visible = false;
                            //return true;
                        }

                        //Baggages
                        if (filter.baggages[0] > temItineraries[itOption.key].baggage.quantity) {
                            itOption.visible = false;
                            appliedFilters['baggages'] = true;
                            //return true;
                        }
                        
                        if (temItineraries[itOption.key].departureTime.value < filter.itinerariesSummary[itIndex].departureTime.from.value || temItineraries[itOption.key].departureTime.value > filter.itinerariesSummary[itIndex].departureTime.to.value) {
                            appliedFilters['departureTime'] = true;
                            itOption.visible = false;
                            //return true;
                        }
                        //Departure IATA
                        if (!filter.airports.contains(temItineraries[itOption.key].departure)) {
                            appliedFilters['departureIATA'] = true;
                            itOption.visible = false;
                            //return true;
                        }
                        //Arrival IATA
                        if (!filter.airports.contains(temItineraries[itOption.key].arrival)) {
                            appliedFilters['arrivalIATA'] = true;
                            itOption.visible = false;
                            //return true;
                        }

                    })
                })
                //if (filterApplied)
                {
                    return product.itineraries.every(function (pI) {
                        return pI.itinerariesOptions.any(function (op) {
                            if (op.visible)
                                return true;
                        }) == true;
                    })
                }
                return true;
            };

            self.applyFilters(initialFilters, self.currentSortFunction, self.currentOrderByFunction);
        };

        return new adapter();
    };
    
    this.prepareSummaryMultiDestination = function (summary) {
        
        if (summary.totalFare == null)
            return;

        if (summary.adultFare != null)
            summary.adultFare.from = self.getNumberObject(summary.adultFare.from);

        summary.averageFare.from = self.getNumberObject(summary.averageFare.from);
        summary.averageFareFeeMargin.from = self.getNumberObject(summary.averageFareFeeMargin.from);
        summary.totalFare.from = self.getNumberObject(summary.totalFare.from);
        summary.totalFareFeeMargin.from = self.getNumberObject(summary.totalFareFeeMargin.from);

        if (summary.adultFare != null)
            summary.adultFare.to = self.getNumberObject(summary.adultFare.to);

        summary.averageFare.to = self.getNumberObject(summary.averageFare.to);
        summary.averageFareFeeMargin.to = self.getNumberObject(summary.averageFareFeeMargin.to);
        summary.totalFare.to = self.getNumberObject(summary.totalFare.to);
        summary.totalFareFeeMargin.to = self.getNumberObject(summary.totalFareFeeMargin.to);

        for (var i = 0; i < summary.itinerariesSummary.length; i++) {
            if (summary.itinerariesSummary[i].arrivalTime) {
                summary.itinerariesSummary[i].arrivalTime.from = this.getTimeObject(summary.itinerariesSummary[i].arrivalTime.from);
                summary.itinerariesSummary[i].arrivalTime.to = this.getTimeObject(summary.itinerariesSummary[i].arrivalTime.to);
            }

            if (summary.itinerariesSummary[i].departureTime) {
                summary.itinerariesSummary[i].departureTime.from = this.getTimeObject(summary.itinerariesSummary[i].departureTime.from);
                summary.itinerariesSummary[i].departureTime.to = this.getTimeObject(summary.itinerariesSummary[i].departureTime.to);
            }

            if (summary.itinerariesSummary[i].flightTime) {
                summary.itinerariesSummary[i].flightTime.from = this.getTimeObject(summary.itinerariesSummary[i].flightTime.from);
                summary.itinerariesSummary[i].flightTime.to = this.getTimeObject(summary.itinerariesSummary[i].flightTime.to);
            }

            if (summary.itinerariesSummary[i].totalTime) {
                summary.itinerariesSummary[i].totalTime.from = this.getTimeObject(summary.itinerariesSummary[i].totalTime.from);
                summary.itinerariesSummary[i].totalTime.to = this.getTimeObject(summary.itinerariesSummary[i].totalTime.to);
            }

            if (summary.itinerariesSummary[i].transitTime) {
                summary.itinerariesSummary[i].transitTime.from = this.getTimeObject(summary.itinerariesSummary[i].transitTime.from);
                summary.itinerariesSummary[i].transitTime.to = this.getTimeObject(summary.itinerariesSummary[i].transitTime.to);
            }
        }
    }

    this.prepareBaggagesTextMultidestination = function (equipmentType, baggages, additionalBaggage) {
        if (!equipmentType)
            equipmentType = 1;
        var result = { include: false, text: "", addText: null };
        var i = 0;
        if (baggages != null) {
            if (baggages.any(function (b) { return b.quantity > 0; })) {
                result.include = true;
                baggages.each(function (item) {
                    if (item.quantity > 0) {
                        if (item.weight > 0) {
                            if (item.quantity == 1)
                                result.text += "{0} kg {1}".format(item.weight, (item.paxType == 0) ? "(par ad.)" : (item.paxType == 1) ? "(par enf.)" : (item.paxType == 2) ? "(par bébé)" : "");
                            else
                                result.text += "{0} x {1} kg {2}".format(item.quantity, item.weight, (item.paxType == 0) ? "(par ad.)" : (item.paxType == 1) ? "(par enf.)" : (item.paxType == 2) ? "(par bébé)" : "");
                        }
                        else {
                            if (item.quantity == 1)
                                result.text += "{0} bagage {2} {3}".format(item.quantity, item.weight, equipmentType == 1 ? "en soute" : "", (item.paxType == 0) ? "(par ad.)" : (item.paxType == 1) ? "(par enf.)" : (item.paxType == 2) ? "(par bébé)" : "");
                            else
                                result.text += "{0} bagages {2} {3}".format(item.quantity, item.weight, equipmentType == 1 ? "en soute" : "", (item.paxType == 0) ? "(par ad.)" : (item.paxType == 1) ? "(par enf.)" : (item.paxType == 2) ? "(par bébé)" : "");
                        }
                        if (i != (baggages.length - 1))
                            result.text += " | ";
                    }
                    i++;
                });
            }
            else {
                result.include = false;
                result.text = getT('airbooking.luggage.nocheckedluggage').format(equipmentType == 1 ? "en soute" : "");

                if (additionalBaggage && additionalBaggage != null && additionalBaggage.price != null) {
                    result.addText = (getT("airbooking.luggage.estimatedextraluggage")).format(additionalBaggage.price);
                }
            }
        }
        return result;
    }

    this.getViewForFligthResultsPage = function (results) {
        //Prepare list
        self.prepareLists(results);

        //Prepare product price
        if (results.products) {
            results.products.each(function (product) {

                if (product.itineraries) {
                    var itinerariesAux = results.itineraries.groupByKey("key");
                    product.itineraries.each(function (pItinerary) {
                        pItinerary.cityDepartureName = results.cities[results.airports[itinerariesAux[pItinerary.itinerariesOptions[0].key].departure].cityIata].name;
                        pItinerary.cityArrivalName = results.cities[results.airports[itinerariesAux[pItinerary.itinerariesOptions[0].key].arrival].cityIata].name;
                        pItinerary.departureDate = itinerariesAux[pItinerary.itinerariesOptions[0].key].departureDate;
                    })
                }
            })
        }

        if (results.itineraries) {
            results.itineraries.each(function (itinerary) {

                //Prepare itineraries baggages text
                if (itinerary.baggages) {
                    var baggage = itinerary.baggages.first(function (b) { return b.paxType == 0 }); //AdultBaggage                   

                    var itBaggages = itinerary.additionalBaggages.getByKeyValue("key", itinerary.key);

                    //Select the cheapest luggage to display price information in web
                    var cheapBaggage = {};
                    if (itBaggages) {
                        var addBaggages = itBaggages.baggages;

                        cheapBaggage = addBaggages.minObj(function (b) { return b.price.value; }).first();
                    }

                    var displayText = self.prepareBaggagesTextMultidestination(1, itinerary.baggages, cheapBaggage);

                    itinerary.baggage = {
                        quantity: baggage.quantity,
                        include: baggage.quantity > 0,
                        displayText: displayText.text,
                        additionalLuggageInformation: displayText.addText
                    };

                    self.prepareBaggagesTextForResultsPage(itinerary.baggages);
                }
            })

            results.itineraries = results.itineraries.groupByKey("key");
        }
    }

    //Prepare purchase Data
    this.preparePurchaseData = function (data) {
        //Prepare object with no baggage
        var noBaggage = {
            key: null,
            quantity: 0,
            totalPrice: { value: 0 },
            weight: 0,
            description: getT('airbooking.aucunbagage').format('0', window.culture.texts["_CurrencySymbol" + data.currency])
        };

        //Itineraries
        if (data.itineraries) {
            data.itineraries.each(function (i) {
                i.departureDateTime = i.segments[0].departureDateTime;
                var lastSegment = i.segments.last();
                i.arrivalDateTime = lastSegment.arrivalDateTime;
                i.dayChange = Math.floor(moment(i.arrivalDateTime.substr(0, 10)).diff(i.departureDateTime.substr(0, 10), 'days', true));
                var currencySymbol = window.culture.texts["_CurrencySymbol" + data.currency];
                console.log("i.additionalBaggages");
                //Itinerary additional baggages
                if (i.additionalBaggages.any()) {
                    i.additionalBaggages.each(function (itAdditionalBag) {
                        if (itAdditionalBag.quantity > 0 && itAdditionalBag.weight >= 0) {

                            if (itAdditionalBag.location == 0) {
                                var description = (itAdditionalBag.quantity > 1 ? getT('airbooking.luggage.luggages') : getT('airbooking.luggage.luggage')) + ' ' + getT('airbooking.luggage.ensoute');

                                itAdditionalBag.description = "{0} {1} ({2}x{3}kg) : {4} {5}".format(
                                itAdditionalBag.quantity,
                                description,
                                itAdditionalBag.quantity,
                                itAdditionalBag.weight,
                                itAdditionalBag.totalPrice.value,
                                currencySymbol);
                            }
                            else if (itAdditionalBag.location == 1) {
                                var description = (itAdditionalBag.quantity > 1 ? getT('airbooking.luggage.cabinluggages') : getT('airbooking.luggage.cabinluggage')).toLowerCase();

                                itAdditionalBag.description = "{0} {1} ({2}x{3}kg) : {4} {5}".format(
                                itAdditionalBag.quantity,
                                description,
                                itAdditionalBag.quantity,
                                itAdditionalBag.weight,
                                itAdditionalBag.totalPrice.value,
                                currencySymbol);
                            }
                            else if (itAdditionalBag.location == 9) {

                                var cabinQuery = i.additionalBaggages.filter(function (b) { return b.location === 1; });
                                var cabinBag = cabinQuery != null ? cabinQuery[0] : null;

                                if (cabinBag != null) {
                                    var description1 = "{0} {1} ({2}x{3}kg)".format(
                                    cabinBag.quantity,
                                    (cabinBag.quantity > 1 ? getT('airbooking.luggage.cabinluggages') : getT('airbooking.luggage.cabinluggage')).toLowerCase(),
                                    cabinBag.quantity,
                                    cabinBag.weight);

                                    var description2 = "{0} {1} ({2}x{3}kg) : {4} {5}".format(
                                    itAdditionalBag.quantity,
                                    (itAdditionalBag.quantity > 1 ? getT('airbooking.luggage.luggages') : getT('airbooking.luggage.luggage')) + ' ' + getT('airbooking.luggage.ensoute'),
                                    itAdditionalBag.quantity,
                                    itAdditionalBag.weight,
                                    itAdditionalBag.totalPrice.value,
                                    currencySymbol);

                                    itAdditionalBag.description = description1;
                                    itAdditionalBag.description2 = description2;
                                    itAdditionalBag.cabinQuantity = cabinBag.quantity;
                                    itAdditionalBag.checkedQuantity = itAdditionalBag.quantity;
                                    itAdditionalBag.cabinWeight = cabinBag.weight;
                                    itAdditionalBag.checkedWeight = itAdditionalBag.weight;
                                }
                            }
                        }
                    });
                    i.additionalBaggages.unshift($window.objectClone(noBaggage));
                }
            });
        }

        //Proposals
        if (data.proposals) {
            data.proposals.each(function (proposal) {
                proposal.flexies = proposal.flexies.groupByKey("flexyId");
                //Check if proposal include baggages
                proposal.fareWithoutBaggage = proposal.fares.any(function(f) {
                    return f.itineraries.any(function(i) {
                        return i.baggageQuantity == 0;
                    });
                });

                //Check if at least one proposal include lugage. Added fot thomascook GTM
                proposal.fareWithLugage = proposal.fares.any(function (f) {
                    return f.itineraries.any(function (i) {
                        return i.baggageQuantity > 0;
                    });
                });

                if (proposal.brandDisplayName)
                    proposal.brandDisplayName = proposal.brandDisplayName.capitalize();

                //Taxes
                proposal.fares.each (function (fare) {
                    if (fare.totalTax != null && fare.totalTax != undefined) {
                        fare.totalTax = self.getNumberObject(fare.totalTax);
                    }

                    if (fare.taxSummary != null && fare.taxSummary != undefined) {
                        if (fare.taxSummary.fuel) 
                            fare.taxSummary.fuel = self.getNumberObject(fare.taxSummary.fuel);

                        if (fare.taxSummary.airport) 
                            fare.taxSummary.airport = self.getNumberObject(fare.taxSummary.airport);
                    }

                    if (fare.totalTaxSummary) {
                        if (fare.totalTaxSummary.airport)
                            fare.totalTaxSummary.airport = self.getNumberObject(fare.totalTaxSummary.airport);

                        if (fare.totalTaxSummary.fuel)
                            fare.totalTaxSummary.fuel = self.getNumberObject(fare.totalTaxSummary.fuel);
                    }

                    if (fare.tax) {
                        fare.tax = self.getNumberObject(fare.tax);
                    }
                });
            });
            this.selectProposal(0, data);
        }
    }

    //Select Proposal proposalIndex, data: purchaseResponse
    this.selectProposal = function (proposalIndex, data) {
        data.proposals.each(function(prop) {
            if (prop.index == proposalIndex) {
                data.proposalsRequested = {};
                data.proposalsRequested.index = prop.index;
                data.proposalsRequested.insurances = prop.insurances;
                data.proposalsRequested.flexies = prop.flexies;
                data.proposalsRequested.mandatoryFees = prop.mandatoryFees;
                data.proposalsRequested.fares = prop.fares;
                data.proposalsRequested.fareNotes = prop.fareNotes;
                data.proposalsRequested.totalAgencyMargin = prop.totalAgencyMargin;
                data.proposalsRequested.totalAmount = prop.totalAmount;
                data.proposalsRequested.totalFlexyAmount = prop.totalFlexyAmount;
                data.proposalsRequested.totalFlexyAverage = prop.totalFlexyAverage;
                data.proposalsRequested.totalFlexyPenalty = prop.totalFlexyPenalty;
                data.proposalsRequested.fareWithoutBaggage = prop.fareWithoutBaggage;
                data.proposalsRequested.fareWithLugage = prop.fareWithLugage;//GTM, added for thomascook
                data.proposalsRequested.services = prop.services;
                data.proposalsRequested.brandDisplayName = prop.brandDisplayName;
                data.proposalsRequested.oldTotalAmount = prop.oldTotalAmount;

            }
        });
    }

    //Prepare Branded Fares Services Data
    this.prepareBrandedFaresServicesData = function (services) {
        if (services != null) {
            services.each(function (item) {
                item.displayName = $i18next.t(item.displayName);
                if (item.description && item.description.includes("K") && item.displayName.includes("{weight}")) {
                    item.displayName = item.displayName.replace('{weight}', item.description.replace("K", ""));
                }
                item.cssClass = (item.rficGroup + (item.rficSubGroup ? "-" + item.rficSubGroup : "")).toLowerCase();
            });
        }
    }

    //Prepare Passenget PTC and index
    this.preparePassengersData = function (paxes, purchaseResponse) {
        if (purchaseResponse && purchaseResponse.proposals && paxes) {
            var contPax = 0;
            paxes.each(function(pax) {
                var auxFare = purchaseResponse.proposals[0].fares.first(function(x) {
                    return x.paxType == pax.paxType;
                });
                pax.ptc = auxFare.ptc;
                pax.index = contPax;
                contPax++;

            });
        }
    }

    this.prepareTaxesObjectForBkgDetails = function(fareItem) {
        if (fareItem) {
            var faresTaxes = {
                taxSummary: {
                    airport: {
                        value: fareItem.airportTax
                    },
                    fuel: {
                        value: fareItem.surchargeTax
                    }
                },
                fare: {
                    value: fareItem.amount
                },
                totalTaxSummary: {
                    airport: { value: fareItem.airportTax * fareItem.quantity },
                    fuel: { value: fareItem.surchargeTax * fareItem.quantity }
                },
                tax: {
                    value: fareItem.airportTax + fareItem.surchargeTax
                },
                totalTax: {
                    value: (fareItem.airportTax + fareItem.surchargeTax) * fareItem.quantity
                },
                totalFare: { value: fareItem.amount * fareItem.quantity }
            }

            return faresTaxes;
        }
        return false;
    };
}]);
