var event;

window.i18next.use(window.i18nextXHRBackend);
if (typeof forceHost == 'undefined' || !forceHost) {
    forceHost = "";
}
window.i18next.init({
    debug: isDebug,
    keySeparator: '_',
    backend: {
        loadPath: forceHost + '/umbraco/backoffice/api/dictionary/getresources?lng=' + lng,
        crossDomain: true
    }
}, function (err, t) {

    startApplication();
    //Message Ce site utilise des cookies.
    //FCook();
    translateErrors();
});



function bookingNumber() {
    return function (input) {
        return input.replace(/^0+/, '');
    };
}

webClient.filter('bookingNumber', bookingNumber);
webClient.filter('strReplace', function () {
    return function (input, from, to) {
        input = input || '';
        from = from || '';
        to = to || '';
        return input.replace(new RegExp(from, 'g'), to);
    };
});


function unique() {

    return function (input, property) {

        if (typeof input === 'undefined') {
            return input;
        }

        var map = new Map();
        var result = [];
        for (var i = 0; i < input.length; i++) {
            if (!map.has(input[i][property])) {
                map.set(input[i][property], true);
                result.push(input[i]);
            }
        }
        return result;
    };
}

webClient.filter('unique', unique);

function productType() {
    return function (input, product, bypass) {
        if (input) {

            if (bypass === true) {
                return input;
            }

            if (product === 'flight') {
                return input.filter(function (b) { return !(b.accommodations && b.accommodations.length > 0 || b.bookingAccommodationId !== null); });
            }
            else if (product === 'basket') {
                return input.filter(function (b) { return b.accommodations && b.accommodations.length > 0 || b.bookingAccommodationId !== null; });
            }
            else {
                return input;
            }
        }
    };
}

webClient.filter('productType', productType);

//function getScriptWithCache (url, callback) {
//    $.ajax({
//        type: "GET",
//        url: url,
//        success: callback,
//        dataType: "script",
//        cache: true
//    });
//};

function getBundleWidget(widgetName, options) {
    var url = widgetName;
    if (window.bundleVersionUrl == '') {
        url = "/global/js/widgets/" + widgetName;
    } else {
        url = window.bundleVersionUrl + "/widgets/" + widgetName; 
    }
    return getScriptWithCache(url, options);
}

function getScriptWithCache (url, options) {

    // Allow user to set any option except for dataType, cache, and url
    options = $.extend(options || {}, {
        dataType: "script",
        cache: true,
        url: url
    });


    // Use $.ajax() since it is more flexible than $.getScript
    // Return the jqXHR object so we can chain callbacks
    return jQuery.ajax(options);
};

function startApplication() {
    
    angular.element(document).ready(function () {
        angular.bootstrap(document, ['webClient']);

        if ($('[component-name="mrfly-widget-header"]').length > 0) {
            if (window.bundleVersionUrl == '') {
                $.getScript("/global/js/widgets/mrfly-widget-header.js").done(function () {
                }).fail(function (jqxhr, settings, exception) {
                    console.log(exception);
                });
            } else {
                getScriptWithCache(window.bundleVersionUrl + "/widgets/mrfly-widget-header.js").done(function () {
                }).fail(function (jqxhr, settings, exception) {
                    console.log(exception);
                });

            }
        }

        if ($('mrfly-widget-newsletter').length > 0) {
            if (window.bundleVersionUrl == '') {
                $.getScript("/Global/js/widgets/mrfly-widget-newsletter.js");
            } else {
                getScriptWithCache(window.bundleVersionUrl + "/widgets/mrfly-widget-newsletter.js");
            }
        }

        if ($('#search-engine-widget').length > 0) {
            if (window.bundleVersionUrl == '') {
                $.getScript("/Global/js/widgets/mrfly-widget-search-engine.js").done(function () {
                    $("#misterflySite").trigger("widgetVolLoaded");
                });
            } else {
                getScriptWithCache(window.bundleVersionUrl + "/widgets/mrfly-widget-search-engine.js").done(function () {
                    $("#misterflySite").trigger("widgetVolLoaded");
                });
            }
        }
    });
}

