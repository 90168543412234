webClient.directive('ngTourOperatorInformation', ['$window', 'templateService', function ($window, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_TourOperatorInformationTPL.html');

        //Initialize Variables
        $scope.getUrl = $window.getUrl;
        $scope.siteType = $window.site.type;
        $scope.getStaticFolder = $window.getStaticFolder;
        $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
        $scope.getTypeStaticFolder = $window.getTypeStaticFolder;
        $scope.getText = function (key) { return $window.culture.texts[key]; };
    };
    return directive;
}]);
