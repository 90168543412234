webClient.controller('AirProductsBodyRedirectBasket', ['$scope', '$window', '$timeout', '$i18next', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'trackingService', 'gtmProduct', 'omnitureService',
    function ($scope, $window, $timeout, $i18next, tseApiClient, tseUtil, airHelper, trackingService, gtmProduct, omnitureService) {
        $window.$scope = $scope;
        $scope.MeanOutbound = null;
        $scope.MeanInbound = null;
        $scope.resultCO2 = null;
        $scope.CO2Map = null;
        $scope.isloadingco2 = null;
        $scope.enableCO2 = false;
        $scope.nbCallCo2 = 0;
        $scope._util = tseUtil;
        $scope.enableCalendar = $window.enableCalendar;
        $scope.site = $window.site;
        $scope.getText = function (key) { return window.culture.texts[key]; };
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        $scope.requestSummary = $window.site.currentAirRequest.summary;
        $scope.searchForm = {};
        $scope.initialPriceValue = 0;
        $scope.searchForm.type = $scope.requestSummary.roundTrip ? 0 : $scope.requestSummary.oneWay ? 1 : 2;
        $scope.searchForm.cabin = $scope.requestSummary.cabinClass;
        $scope.searchForm.origin = $scope.requestSummary.origin.name;
        $scope.searchForm.origin_value = $scope.requestSummary.origin.value;
        $scope.searchForm.destination = $scope.requestSummary.destination.name;
        $scope.searchForm.destination_value = $scope.requestSummary.destination.value;
        $scope.searchForm.goDate = $scope.requestSummary.goDate.dateString;
        $scope.searchForm.adults = $scope.requestSummary.adults;
        $scope.searchForm.childrens = $scope.requestSummary.childs;
        $scope.searchForm.infants = $scope.requestSummary.infants;
        $scope.isTurkmenistan = window.isTurkmenistan;
        $scope.hideFlexy = window.enableFlexy === false;
        $scope.enableFreeFlex = window.enableFreeFlex === true;
        $scope.enableMarketBanner = window.enableMarketBanner === true;
        $scope.market = window.market;
        $scope.hideAirportFilter = $window.site.partnerId === "europa";
        $scope.enableKiwiCheap = $window.enableKiwiCheap;
        $scope.partnerName = $window.partnerName;
        $scope.isServair = window.site.partnerId === "servair";
        $scope.currentLng = lng;
        if ($window.isWonderBox) $scope.isWonderBox = $window.isWonderBox;
        if ($scope.requestSummary.roundTrip)
            $scope.searchForm.returnDate = $scope.requestSummary.goDate2.dateString;
        if ($scope.requestSummary.openJaw) {
            $scope.searchForm.origin2 = $scope.requestSummary.origin2.name;
            $scope.searchForm.origin2_value = $scope.requestSummary.origin2.value;
            $scope.searchForm.destination2 = $scope.requestSummary.destination2.name;
            $scope.searchForm.destination2_value = $scope.requestSummary.destination2.value;
            $scope.searchForm.goDate2 = $scope.requestSummary.goDate2.dateString;
        }


          $scope.SendInspecletUsage = function () {
            //Send inspectlet tag of use Modifier Button
            $window.sendInspectlet('tagSession', { UseModifierButton: true });
        };

    
        //Traduction
        $scope.getT = function (key) {
            var res = $i18next.t(key.toLowerCase());
            return res;
        };



        //reset upsell flag on new search
        window.sessionStorage.removeItem('forceUpsell');
        //reset total flex flag on new search
        window.sessionStorage.removeItem("inssurance.totalflex.selected");
        window.sessionStorage.removeItem("inssurance.totalflex.selectedOffer");
        //reset repatriation flag on new search
        window.sessionStorage.removeItem("inssurance.repatriation.selected");
        window.sessionStorage.removeItem("inssurance.repatriation.isChecked");

        $scope.currentCabinClass = $window.site.currentAirRequest.summary.cabinClass;
        $scope.view = null;
        $scope.minPriceOnOutbound = false;
        $scope.minPriceOnOutboundDeparture = false;
        $scope.minPriceOnOutboundArrival = false;
        $scope.minPriceAirportIATA = null;

        $scope.enableOption = $window.enableOption && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');


        getScriptWithCache("/Global/js/widgets/mrfly-widget-wait.js").done(function () {

            var airSearchRequest = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequest']();

            airSearchRequest.adultNumber = window.site.currentAirRequest.summary.adults;
            airSearchRequest.childNumber = window.site.currentAirRequest.summary.childs;
            airSearchRequest.infantNumber = window.site.currentAirRequest.summary.infants;
            airSearchRequest.isOneWay = window.site.currentAirRequest.summary.itineraries.length == 1;
            airSearchRequest.isRoundTripOrOpenJaw = window.site.currentAirRequest.summary.itineraries.length == 2;
            airSearchRequest.isMultiDestination = window.site.currentAirRequest.summary.itineraries.length > 2;
            airSearchRequest.cabinClass = window.site.currentAirRequest.summary.cabinClass;

            //Map itineraries
            airSearchRequest.itineraries = [];
            window.site.currentAirRequest.summary.itineraries.forEach(function (itinerary) {
                var ins = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequestItinerary']();

                ins.date = itinerary.goDate.date;

                ins.departureAirportIata = itinerary.origin.value;
                ins.departureCityIata = itinerary.origin.cityIATA;
                ins.departureName = itinerary.origin.cityName;

                ins.arrivalAirportIata = itinerary.destination.value;
                ins.arrivalCityIata = itinerary.destination.cityIATA;
                ins.arrivalName = itinerary.destination.cityName;

                airSearchRequest.itineraries.push(ins);
            });


            mrflyWidgets['mrfly-widget-wait'].airSearchRequest = airSearchRequest;
            mrflyWidgets['mrfly-widget-wait'].siteType = window.site.type;
            mrflyWidgets['mrfly-widget-wait'].sitePartnerId = window.site.partnerId;
            mrflyWidgets['mrfly-widget-wait'].enableFreeFlex = window.enableFreeFlex;
            mrflyWidgets['mrfly-widget-wait'].partnerName = window.partnerName;
            mrflyWidgets['mrfly-widget-wait'].resources = i18next.store.data.dev.translation;
            mrflyWidgets['mrfly-widget-wait'].productType = "basket";

            if (typeof window.market !== undefined && window.market !== null) {
                mrflyWidgets['mrfly-widget-wait'].market = window.market;
            }
            else {
                mrflyWidgets['mrfly-widget-wait'].market = '';
            }
        }).fail(function (jqxhr, settings, exception) {
            console.log(exception);
        });

        getScriptWithCache("/Global/js/widgets/mrfly-widget-request-recap.js").done(function () {
            console.log("Loading done");
            //set params do mapping

            var airSearchRequest = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequest']();

            airSearchRequest.adultNumber = window.site.currentAirRequest.summary.adults;
            airSearchRequest.childNumber = window.site.currentAirRequest.summary.childs;
            airSearchRequest.infantNumber = window.site.currentAirRequest.summary.infants;
            airSearchRequest.isOneWay = window.site.currentAirRequest.summary.itineraries.length == 1;
            airSearchRequest.isRoundTrip = window.site.currentAirRequest.summary.itineraries.length == 2 && window.site.currentAirRequest.summary.itineraries[0].origin.cityIATA.localeCompare(window.site.currentAirRequest.summary.itineraries[1].destination.cityIATA) === 0;
            airSearchRequest.isOpenJaw = window.site.currentAirRequest.summary.itineraries.length === 2 && window.site.currentAirRequest.summary.itineraries[0].origin.cityIATA.localeCompare(window.site.currentAirRequest.summary.itineraries[1].destination.cityIATA) !== 0;
            airSearchRequest.isMultiDestination = window.site.currentAirRequest.summary.itineraries.length > 2;
            airSearchRequest.cabinClass = window.site.currentAirRequest.summary.cabinClass;

            //Map itineraries
            airSearchRequest.itineraries = [];
            window.site.currentAirRequest.summary.itineraries.forEach(function (itinerary) {
                var ins = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequestItinerary']();

                ins.date = itinerary.goDate.date;

                ins.departureAirportIata = itinerary.origin.value;
                ins.departureCityIata = itinerary.origin.cityIATA;
                ins.departureName = itinerary.origin.cityName;

                ins.arrivalAirportIata = itinerary.destination.value;
                ins.arrivalCityIata = itinerary.destination.cityIATA;
                ins.arrivalName = itinerary.destination.cityName;

                airSearchRequest.itineraries.push(ins);
            });
            mrflyWidgets['mrfly-widget-request-recap'].airSearchRequest = airSearchRequest;
            mrflyWidgets['mrfly-widget-request-recap'].locale = $window.lng.substring(0, 2);
            mrflyWidgets['mrfly-widget-request-recap'].language = $window.lng;
            mrflyWidgets['mrfly-widget-request-recap'].resources = i18next.store.data.dev.translation;
        });
        $scope.productVisibleFilter = function (item) {
            return item.outbound.visible;
        };




    }]);
