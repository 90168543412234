webClient.directive('ngLuggageDetailModal', ['$window', '$timeout', 'templateService', function ($window, $timeout, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        bagtype: "=",
        showmargin: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_LuggageDetailModalTPL.html');

        $scope.getUrl = $window.getUrl;

        //Close the modal
        $scope.closeModal = function() {
            $scope.$emit("closeLuggageDetailsModal");
        };

        //Update modal data
        $scope.$on("updateLuggageDetailModalData", function(event, args) {
            $scope.bagtype = args.bagType;
        });

        //Refresh
        $timeout(function () {
                //Remodal workaround
                $(document).find(".remodal").each(function (a, c) {
                    var d = $(c), e = d.data("remodal-options");
                    e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                        d["remodal"]({ hashTracking: false }) //prevent hash to be added
                });
            },
            0,
            false);
        
    };
    return directive;
}]);
