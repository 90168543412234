/**
* @desc the ngPassengerInfoResumeDetails provides support for displaying the information associated with a passenger in BookingDetails page (including new baggage decoding)
* @example <div ng-repeat="pax in view.paxes" ng-passenger-info-resume-details paxinfo="pax" cards="cards" request="view.request"></div>
*/

webClient.directive('ngPassengerInfoResumeDetails', ['$window', 'templateService', function ($window, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        paxinfo: "=",
        cards: "=",
        request: "=",
        itineraries: "=",
        istrainonly: "="
    };

    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_BookingPaxsResumeDetails.html');

        $scope.getUrl = $window.getUrl;
        $scope.siteType = $window.site.type;
        $scope.enableBlockAgencyRemarks = $window.enableBlockAgencyRemarks;
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.enableSeatMap = !(typeof $window.accountNumber !== 'undefined' && typeof $window.seatMapAccountNumber !== 'undefined' && $window.accountNumber != $window.seatMapAccountNumber);
        $scope.seatSelection = null;

        $scope.updateTemplate = function () {
            $scope.cardName = "";
            if ($scope.paxinfo.fidelityCardType && $scope.cards != null)
            {
                $scope.cards = $scope.cards.groupByKey('reference');
                $scope.cardName = $scope.cards[$scope.paxinfo.fidelityCardType].name;
            }
        };

        console.log("ITINERARIES");
        console.log($scope.itineraries);

        $scope.baggagesbyItinerary = {};

        //reassign itineraryId if needed. Use the key ( ex PARMAD1115 )to do so
        if (!$scope.itineraries[0].itineraryId) {
            $scope.paxinfo.itineraries.each(function (it) {
                var key = it.origin + it.destination + it.date.substr(5, 2) + it.date.substr(8, 2);
                $scope.itineraries.filter(function (i) { return i.key === key; })[0].itineraryId = it.itineraryId;
            });
        }

        $scope.paxinfo.itineraries.each(function (it) {
            if (it.baggages.length > 0) {
                $scope.baggagesbyItinerary[it.itineraryId] = {
                    itineraryKey: it.itineraryId,
                    baggages: it.baggages
                };

                $scope.baggagesbyItinerary[it.itineraryId].baggages.each(function (b) {
                    if (b.quantity === null && b.weight > 0) {
                        b.quantity = 1;
                    }
                });
            }
        });
        

        //Seats
        if ($scope.enableSeatMap && $scope.paxinfo.segmentSeats && $scope.paxinfo.segmentSeats.length > 0) {
            $scope.seatSelection = {};
            $scope.paxinfo.segmentSeats.each(function (s) {
                $scope.seatSelection[s.segmentKey] = s;
            });
        }

        $scope.updateTemplate();

        $scope.$on("onBookingSummaryUpdate", function (event, args) {
            $scope.updateTemplate();
        });
    };
    return directive;
}]);
