webClient.controller('AirPayment3DS', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper, bookingRequest, insurancesSelected) {
    $scope.getText = function (key) { return window.culture.texts[key]; };
    $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
    var searchUrl = $window.site.currentAirRequest.searchUrl + "?" + tseUtil.Location.getQueryString();

    var bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);
    $scope.bkgData = bkgData;
    window.$scope = $scope;

    if (bkgData != null && bkgData.bookingResponse != null) {        
        var pType = bkgData.bookingRequest.paymentMode == 3 ? 2 : 1;
        $scope.apiClient.processPayment3DS({ "paymentId": bkgData.bookingResponse.paymentId, "type": pType, "returnParameters": $window.fData, "paymentMethod": bkgData.bookingRequest.paymentMethod }).success(function (data) {
            var finishUrl = tseUtil.Url.appendValueToUrlBefore($window.site.currentAirRequest.finishUrl, "prqid", data.paymentRequestId);
            window.location.href = finishUrl;
        });
    }
    else {
        $window.FLoading({ vAction: 'show' });
        window.location.href = searchUrl;
    }
}]);