webClient.controller('AirSimplePaymentModule', ['$scope', '$window', '$timeout', '$i18next', '$http', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper',
    function ($scope, $window, $timeout, $i18next, $http, tseApiClient, tseUtil, airHelper, bookingRequest, insurancesSelected){
    $scope.getText = function (key) { return window.culture.texts[key]; };
    $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
    $scope.view = {};
    $scope.payment = {};
    $scope.bookingIdOk = false;
    $scope.meetsAllConditions = false;
    var apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
    window.airHelper = airHelper;
    $window.FLoading({ vAction: 'show' });

    $window.$scope = $scope;
    $scope.enableOption = $window.enableOption && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');
    $scope.paymentMethod = null;
    $scope.cardsAccepted = [];
    $scope.bankCards = [];
    $scope.bankCardsKey = {};
    $scope.isBlue = false;


    var paymentTransactionErrorMessage = $i18next.t("simplepayment.transactionerror");

        $scope.init = function (bookingId, pspAccountId) {

        $window.FLoading({ vAction: 'show' });

        $scope.isBlue = pspAccountId != null;
        $scope.pspAccountId = pspAccountId;
        $scope.getBookingDetails(bookingId);

        if ($scope.isBlue) {
            getBundleWidget("mrfly-widget-loader.js").done(function () {});
        }


        if ($window.location.hash == '#error') {
            $window.XLConfirm({
                vTitle: paymentTransactionErrorMessage, vTextYes: 'OK Merci', vHideNo: true,
                fCallBackYes: function () {
                    var finishUrl = getUrl("account/reservations#" + bookingId);
                    window.location.href = finishUrl;
                }
            });
        }
    }

    $scope.$on('data-loaded', function (data) {
        if ($scope.isBlue) {
            var widgetInterval = setInterval(function () {
                if (typeof BluePaymentWidget !== 'undefined' && $("#payment-form form").length == 0 && $scope.payment != null) {
                    $scope.displayWidgetForm();
                    //required because of FF
                    var formInterval = setInterval(function () {
                        $("div.c-main").fadeIn();
                        $("#frm-widget").fadeIn();
                        if ($("#frm-widget").is(":visible")) {
                            clearInterval(formInterval);
                            $window.FLoading({ vAction: 'hide' });
                        }
                    }, 300);
                    clearInterval(widgetInterval);
                }
            }, 300);
        }
        else {
            $window.FLoading({ vAction: 'hide' });
        }
    });

    //Get City Name or Iata
    $scope.getCityNameOrIata = function (airportIata) {
        if ($scope.view.airports[airportIata].cityIata) {
            if ($scope.view.cities[$scope.view.airports[airportIata].cityIata])
                return $scope.view.cities[$scope.view.airports[airportIata].cityIata].name;
        }
        else {
            if ($scope.view.airports[airportIata].name)
                return $scope.view.airports[airportIata].name;
            else
                return airportIata;
        }
    };

    $scope.getBookingDetails = function (bookingId) {

        apiClient.AIR.getBookingDetails([bookingId]).success(function (data) {
            if (data && data.status == 0) {
                $scope.showDetail = true;
                $scope.bookingIdOk = true;
                $scope.view = airHelper.getViewForBookingDetailsEntityVersion2(data);
                $scope.isProductTO = $scope.view.itineraries[0].productType == 1;                

                if ($scope.view && $scope.view.status == 4 && $scope.view.payments) {

                    $scope.payment = $scope.view.payments.first(function (payment) { return (payment.status == 0) });
                    if (!$scope.payment) {
                        var finishUrl = getUrl("account/reservations");
                        window.location.href = finishUrl;
                    }
                    else {
                        if ($scope.bookingIdOk && $scope.payment && $scope.payment.status == 0) {

                            if ($scope.isBlue === false) {

                                var paymentMethodRq = {
                                    bookingId: $scope.view.bookingId
                                };

                                apiClient.getSimplePaymentMethods(paymentMethodRq).success(function (data) {
                                    if (data) {
                                        $window.FLoading({ vAction: 'hide' });

                                        var selected = data.paymentMethods.first(function (i) {
                                            return i.paymentMethodReference == "CB" && i.transactionMethod == 1;
                                        });

                                        $scope.paymentMethod = selected;
                                        $scope.meetsAllConditions = true;

                                        $scope.hostedFields = false;
                                        if (selected.bankCard.apiKeyId != null && selected.bankCard.apiKeyValue != null) {
                                            $scope.hostedFields = true;
                                            $scope.startHostedFields();
                                        }

                                        getBundleWidget("mrfly-widget-request-recap.js").done(function () {
                                            console.log("Loading done");
                                            //set params do mapping

                                            var airSearchRequest = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequest']();

                                            airSearchRequest.adultNumber = $scope.view.request.adults;
                                            airSearchRequest.childNumber = $scope.view.request.childs;
                                            airSearchRequest.infantNumber = $scope.view.request.infants;
                                            airSearchRequest.isOneWay = $scope.view.request.oneWay;
                                            airSearchRequest.isRoundTrip = $scope.view.request.roundTrip;
                                            airSearchRequest.isOpenJaw = $scope.view.request.openJaw;
                                            airSearchRequest.isMultiDestination = $scope.view.request.multi;

                                            //Map itineraries
                                            airSearchRequest.itineraries = [];
                                            $scope.view.itineraries.forEach(function (itinerary) {
                                                var ins = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequestItinerary']();

                                                ins.date = itinerary.departureDate;

                                                ins.departureAirportIata = itinerary.departure;
                                                ins.departureCityIata = itinerary.origin;
                                                ins.departureName = $scope.getCityNameOrIata(itinerary.departure);

                                                ins.arrivalAirportIata = itinerary.arrival;
                                                ins.arrivalCityIata = itinerary.destination;
                                                ins.arrivalName = $scope.getCityNameOrIata(itinerary.arrival);

                                                airSearchRequest.itineraries.push(ins);
                                            });
                                            mrflyWidgets['mrfly-widget-request-recap'].airSearchRequest = airSearchRequest;
                                            mrflyWidgets['mrfly-widget-request-recap'].locale = $window.lng.substring(0, 2);
                                            mrflyWidgets['mrfly-widget-request-recap'].language = $window.lng;
                                            mrflyWidgets['mrfly-widget-request-recap'].resources = i18next.store.data.dev.translation;
                                        });
                                        setTimeout(function () {
                                            $('html, body').animate({
                                                scrollTop: $("#i-tabs-payement").offset().top
                                            }, 1000);
                                        }, 100);
                                    }
                                }).error(function () { });
                            }
                        }
                    }

                    $scope.$emit('data-loaded', data);
                }
                else {
                    var finishUrl = getUrl("account/reservations");
                    window.location.href = finishUrl;
                }
            }
            else {
                var finishUrl = getUrl("account/reservations");
                window.location.href = finishUrl;
            }
        });
    };

    $scope.displayWidgetForm = function () {

        var defaultOptions = {
            "placeholder": { // placeholder of the input hosted fields
                "holdername": $i18next.t('simplepayment.cardowner'),
                "card": $i18next.t('simplepayment.cardnumber'),
                "expiry": $i18next.t('simplepayment.date'),
                "cryptogram": $i18next.t('simplepayment.ccv')
            },
            "labels": { // label of the hosted fields
                "cardHolder": "",
                "brand": "",
                "card": "",
                "expiry": "",
                "validity": "",
                "submit": $scope.payment.amount ? $i18next.t('simplepayment.acceptandpay') + " " + $scope.payment.amount.toLocaleString() + " €" : "",
            },
            "style": { // define the style of the hosted-fields input
                "card": {
                    "input": { // css syntax
                        "font-family": "Arial,sans-serif",
                        "font-size": "14px",
                        "line-height": "43px",
                        "background-color": "transparent"
                    }
                },
                "expiry": {
                    "input": { // css syntax
                        "font-family": "Arial,sans-serif",
                        "font-size": "14px",
                        "line-height": "43px",
                        "background-color": "transparent"
                    }
                },
                "cryptogram": {
                    "input": { // css syntax
                        "font-family": "Arial,sans-serif",
                        "font-size": "14px",
                        "line-height": "43px",
                        "background-color": "transparent"
                    }
                },
                "holdername": {
                    "input": { // css syntax
                        "font-family": "Arial,sans-serif",
                        "font-size": "14px",
                        "line-height": "43px",
                        "background-color": "transparent"
                    }
                }
            },
            "submitCallback": function () {

                //Tracking
                try {
                    //GTM
                    if (trackingService.isActivated()) {
                        trackingService.pushPageData('/Flightsearch/Payment/CBwait', 'Payment CB Wait', lng, false);
                    }
                }
                catch (ex) {
                    console.log(ex.message);
                    $window.trackingError = ex.message;
                }

                $window.site.pushNoBackOnHistory();
                window.mrflyWidgets['airbooking-process-loader'].$store.commit("showLoader");

                var hfToken = $("#hf-token").val();
                var selectedBrand = $("#selected-brand").val();
                var cardHolder = $('[name=card-holder-name]').val();


                $http.post('/api/api/payment/processoption', {
                    requestId: $scope.view.bookingAdditionalData.RequestId.value,
                    bookingId: $scope.view.bookingId,
                    paymentId: $scope.payment.paymentId,//TODO replace by bankCardType
                    pspAccountId: $scope.pspAccountId,
                    hostedFieldToken: hfToken,
                    selectedBrand: selectedBrand,
                    cardHolder: cardHolder,
                    agentId: $window.site.loginInfo.Agent.AgentId,
                }).then(function (response) {
                    //check response status
                    if (response.status === 200) {
                        window.location.href = response.data;//the redirection url
                    }
                    else {
                        window.location.href = window.site.currentAirRequest.finishErrorUrl;
                    }
                }).catch(function (err) { //on exception, redirect on error page
                    window.location.href = window.site.currentAirRequest.finishErrorUrl;
                });
            }
        };

        var BluePayementJS = BluePaymentWidget.default;

        var blue = new BluePayementJS("payment-form", defaultOptions);
        blue.init();
        $("<p class='brand-form-group'></p>").insertAfter(".card-form-group");
        $(".brand-form-group").append($('.brand-container'));
        //set GRID
        $(".hosted-fields-form").addClass("row md-gutters");
        $(".card-form-group").addClass("col-9");
        $(".brand-form-group").addClass("col-3");
        $(".expiry-form-group").addClass("col-12 col-sm-6");
        $(".cvv-form-group").addClass("col-12 col-sm-6");
        $(".cardholder-form-group").addClass("col-12");
        $(".blue-widget-submit").addClass("col-12");
        $(".blue-widget-submit-input").addClass("btn btn--flex no-transform btn--primary c-w-100");

        $window.FLoading({ vAction: 'hide' });
    };

    

    $scope.setBankCardReference = function (key) {
        $scope._cardReference = key;
    };

    window.tokenizeHandler = function () {
        var card = $scope.paymentMethod.bankCard.bankCards.first(function (item) { return item.bankCardReference == $scope._cardReference });
        var cardName = $("#i-sec-owner").val();
        if (card == null || card == undefined || cardName == null || cardName == undefined || cardName == "") {
            $window.XLConfirm({ vTitle: $i18next.t("simplepayment.checkdata"), vTextYes: "OK", vHideNo: true });
            return;
        }

        hfields.createToken(function (result) {
            console.log(result);
            if (result.execCode == '0000') {
                // Add token to form request
                $scope.processPaymentHostedFields(result.hfToken);
            }
            else {
                $window.XLConfirm({ vTitle: "Error", vTextYes: "OK", vHideNo: true });
            }
        });
        return false;
    }

    $scope.processPaymentHostedFields = function (item) {


        $window.site.pushNoBackOnHistory();
        var card = $scope.paymentMethod.bankCard.bankCards.first(function (item) { return item.bankCardReference == $scope._cardReference });
        var cardName = $("#i-sec-owner").val();

        $window.FLoading({ vAction: 'show' });


        var ReturnParameters = [];
        ReturnParameters[0] = { "Key": "HfToken", "Value": item };
        //ReturnParameters[1] = { "Key": "AliasCreated", "Value": $("#i-check-f1")[0].checked ? "1" : "0" };
        $scope.apiClient.processSimplePayment({
            "paymentId": $scope.payment.paymentId,
            "returnUrl": $window.returnUrlFor3DS,
            "transactionMethod": $scope.paymentMethod.transactionMethod,
            "paymentMethod": $scope.paymentMethod,
            "bankcard": {
                "bankCardId": card.bankCardId,
                "reference": $scope._cardReference,
                "cardholder": cardName,
            },
            "returnParameters": ReturnParameters
        }).success(function (data) {
            // Success = 1
            if (data.paymentStatus == 1) {
                var finishUrl = getUrl("account/reservations#" + bookingId);
                window.location.href = finishUrl;
            }

            // Error = 2, ErrorPnr = 3
            if (data.paymentStatus == 2 || data.paymentStatus == 3) {

                $window.FLoading({ vAction: 'hide' });

                $window.XLConfirm({
                    vTitle: paymentTransactionErrorMessage, vTextYes: 'OK Merci', vHideNo: true,
                    fCallBackYes: function () {
                        var finishUrl = getUrl("account/reservations#" + bookingId);
                        window.location.href = finishUrl;
                    }
                });
            }
        }).error(function () {
            $window.FLoading({ vAction: 'hide' });
            $window.XLConfirm({
                vTitle: paymentTransactionErrorMessage, vTextYes: 'OK Merci',
                fCallBackYes: function () {
                    var finishUrl = getUrl("account/reservations#" + bookingId);
                    window.location.href = finishUrl;
                }
            });
        });

    };

    $scope.processPayment = function () {
        var dateNow = new Date();
        var isValidBankCard = false;
        var cardMonth = parseInt($("#i-card-mm-text").html());
        var cardYear = parseInt($("#i-card-aa-text").html());
        var cardDate = new Date(cardYear, cardMonth, 1, 0, 0, 0, 0);
        cardDate.setDate(cardDate.getDate() - 1);

        isInvalidBankCard = (cardMonth < 0 || cardYear < 0) || ((cardMonth < dateNow.getUTCMonth() + 1) && (cardYear <= dateNow.getUTCFullYear()));

        if (isValidBankCard) {
            $("#i-mm-card").removeClass("c-field-ok");
            $("#i-mm-card").addClass("c-field-ko");
        }
        else {
            if (XLFormValid({ iCont: 'i-tabs-screen-1' })) {
                var cardReference = $scope._cardReference;
                var card = $scope.paymentMethod.bankCard.bankCards.first(function (item) { return item.bankCardReference == cardReference });
                var cardId = card.bankCardId;
                var cardName = $("#i-sec-owner").val();
                var cardNumber = $("#i-sec-num").val();
                var cardDateMM = $("#i-card-mm-text").html();
                var cardDateAA = $("#i-card-aa-text").html();
                var cardCVV = $("#i-sec-ccv").val();

                $window.FLoading({ vAction: 'show' });
                $window.site.pushNoBackOnHistory();

                $scope.apiClient.processSimplePayment({
                    "paymentId": $scope.payment.paymentId,
                    "type": 1,
                    "returnUrl": $window.returnUrlFor3DS,
                    "transactionMethod": $scope.paymentMethod.transactionMethod,
                    "paymentMethod": $scope.paymentMethod,
                    "bankcard": {
                        "bankCardId": cardId,
                        "reference": cardReference,
                        "cardholder": cardName,
                        "number": cardNumber.replace(/ /g, ""),
                        "expiryMonth": (cardDateMM) ? cardDateMM.substr(0, 2) : null,
                        "expiryYear": (cardDateAA) ? cardDateAA.substr(2, 2) : null,
                        "cvv": cardCVV
                    }
                }).success(function (data) {

                    // Success = 1
                    if (data.paymentStatus == 1) {
                        var finishUrl = getUrl("account/reservations#" + bookingId);
                        window.location.href = finishUrl;
                    }

                    // Error = 2, ErrorPnr = 3
                    if (data.paymentStatus == 2 || data.paymentStatus == 3) {

                        $window.FLoading({ vAction: 'hide' });

                        $window.XLConfirm({
                            vTitle: paymentTransactionErrorMessage, vTextYes: 'OK Merci', vHideNo: true,
                            fCallBackYes: function () {
                                var finishUrl = getUrl("account/reservations#" + bookingId);
                                window.location.href = finishUrl;
                            }
                        });
                    }

                }).error(function () {
                    $window.FLoading({ vAction: 'hide' });
                    $window.XLConfirm({
                        vTitle: paymentTransactionErrorMessage, vTextYes: 'OK Merci',
                        fCallBackYes: function () {
                            var finishUrl = getUrl("account/reservations#" + bookingId);
                            window.location.href = finishUrl;
                        }
                    });
                });
            }
        }
    };


    $scope.startHostedFields = function () {
        setTimeout(function () {
            //initialize b2bill
            console.log("initializing be2bill");

            var id = $scope.paymentMethod.bankCard.apiKeyId;
            var value = $scope.paymentMethod.bankCard.apiKeyValue;
            var style = {
                "input": {
                    "font-size": "14px",
                    "line-height": "45px",
                },
                "::placeholder": {
                    "font-size": "14px",
                    "color": "#999999",
                }
            };
            hfields = be2bill.hostedFields({
                key: {
                    id: id,
                    value: value
                },
                fields: {
                    'card': {
                        id: 'card',
                        placeholder: $i18next.t('simplepayment.cardnumber'),
                        style: style
                    },
                    'expiry': {
                        id: 'expiry',
                        placeholder: $i18next.t('simplepayment.date'),
                        style: style
                    },
                    'cryptogram': {
                        id: 'cryptogram',
                        placeholder: $i18next.t('simplepayment.ccv'),
                        style: style
                    },
                },
                location: "fr"
            });
            hfields.load();
        }, 1000);
    };

}]);