webClient.directive('ngUmRequest', ['$window', 'templateService', function ($window, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        data: "=",
        adult: "=",
        name: "=",
        adultoaller: "=",
        arrcountries: "=",
        index: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_AccountUMRequest.html');

        $scope.getUrl = $window.getUrl;
        $scope.getStaticFolder = $window.getStaticFolder;
        var listCountries = $window.objectClone($scope.arrcountries);
        $scope.arrCountries = $window.objectClone($scope.arrcountries);
        $scope.listCountries = listCountries.groupByKey("code");
        $scope.siteType = $window.site.type;
        //$scope.adult = {};

        var langCaps = window.market ? window.market : lng.substring(3);


        var langItem = $scope.arrcountries.filter(function (item) { return item.iso.toLowerCase() === langCaps.toLowerCase(); }).first();

        $scope.adult.departure = {
            MobilePhoneCountryCode: langItem.code,
            LandLinePhoneCountryCode: langItem.code
        };
        $scope.adult.arrival = {
            MobilePhoneCountryCode: langItem.code,
            LandLinePhoneCountryCode: langItem.code
        };

        $scope.adult.departure.samePerson = false;
        $scope.adult.arrival.samePerson = false;


        

        $scope.callTitlePop = function (_event, type, direction) {
            var value = _event.target.parentNode;
            var name = 'i-' + type + '-' + $scope.name + $scope.index + direction+'-pop';
            $window.FPop({ oTarget: value, iPop: name, vStyle: 'classic' });
        };

        $scope.callDrop = function (_event, type, direction) {
            var varIsCountry = (type == 'country') ? 1 : 0;
            var name = 'i-' + type + '-' + $scope.name + $scope.index +direction;
            var value = $("#ul-" + type + "-" + $scope.name +$scope.index+direction).get(0);
            $window.FSelect({ e: _event, myObj: value, vName: name, isCountry: varIsCountry, vMode: 'phone-codes', ignoreFlagsReplace: true });
        };

        $scope.searchInList = function (_event, type) {
            $window.searchInList(_event.target, type, 2);
        }

        $scope.FPop = function (textField, popDiv, style, fill) {
            var target = $("#" + textField).get(0);
            $window.FPop({ oTarget: target, iPop: popDiv, vStyle: style, vFill: fill });
        };

        $scope.FSelect = function (name, target, _event) {
            var obj = $("#" + name + "-pop").find("ul").get(0);
            if (target)
                $window.FSelect({ e: { target: target }, myObj: obj, vName: name });
            else
                $window.FSelect({ e: _event, myObj: obj, vName: name });
        };

        $scope.change = function (type) {
            if (type == "departure")
            {
                if (!$scope.adult.departure.samePerson) {
                    $scope.adult.departure.samePerson = false;
                    $scope.adult.departure.Title = "";
                    $scope.adult.departure.FirstName = "";
                    $scope.adult.departure.LastName = "";
                    $scope.adult.departure.Relation = "";
                    $scope.adult.departure.Mobile = "";
                    $scope.adult.departure.Phone = "";
                    $scope.adult.departure.Address = "";
                    $scope.adult.departure.CP = "";
                    $scope.adult.departure.City = "";
                    $("#i-title-r2-text").html("");
                    $("#i-title-r2-text").parent().removeClass("c-field-ok");
                    $("#i-country-codes-r1-text").html("");
                    $("#i-country-codes-r1-text").parent().parent().removeClass("c-field-ok");
                }
                else
                {
                    angular.copy($scope.adultoaller.arrival, $scope.adult.departure);
                    $("#i-title-r2-text").html($scope.adult.departure.Title);
                    $("#i-title-r2-text").parent().addClass("c-field-ok");
                    $("#i-country-codes-r1-text").html($("#i-country-codes-a2-text").html());
                    if ($("#i-country-codes-r1-text").html() != "&nbsp;")
                    {
                        $("#i-country-codes-r1-text").parent().parent().addClass("c-field-ok");
                    }
                    $scope.adult.departure.samePerson = true;
                }
            }
            else if (type == "arrival")
            {
                if (!$scope.adult.arrival.samePerson) {
                    $scope.adult.arrival.samePerson = false;
                    $scope.adult.arrival.Title = "";
                    $scope.adult.arrival.FirstName = "";
                    $scope.adult.arrival.LastName = "";
                    $scope.adult.arrival.Relation = "";
                    $scope.adult.arrival.Mobile = "";
                    $scope.adult.arrival.Phone = "";
                    $scope.adult.arrival.Address = "";
                    $scope.adult.arrival.CP = "";
                    $scope.adult.arrival.City = "";
                    $("#i-title-r3-text").html("");
                    $("#i-title-r3-text").parent().removeClass("c-field-ok");
                    $("#i-country-codes-r2-text").html("");
                    $("#i-country-codes-r2-text").parent().parent().removeClass("c-field-ok");
                }
                else
                {
                    angular.copy($scope.adultoaller.departure, $scope.adult.arrival);
                    $("#i-title-r3-text").html($scope.adult.arrival.Title);
                    $("#i-title-r3-text").parent().addClass("c-field-ok");
                    $("#i-country-codes-r2-text").html($("#i-country-codes-a1-text").html());
                    if ($("#i-country-codes-r2-text").html() != "&nbsp;")
                    {
                        $("#i-country-codes-r2-text").parent().parent().addClass("c-field-ok");
                    }
                    $scope.adult.arrival.samePerson = true;
                }
            }
        };

        setTimeout(function () {
            XLFormInit({ iCont: 'i-form-adult-departure-' + $scope.name });
            XLFormInit({ iCont: 'i-form-adult-arrival-' + $scope.name });
        }, 500);
    };
    return directive;
}]);
