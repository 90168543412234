webClient.directive('ngWonderboxPriceDetails', ['$window', '$i18next', 'templateService', 'tseApiClient', 
    function ($window, $i18next, templateService, tseApiClient) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        fareitems: "=",
        faretotal: "=",
        wonderboxremainingamount: "=",
        request: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_WonderboxPriceDetails.html');

        $scope.getUrl = $window.getUrl;
        $scope.siteType = $window.site.type;
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        $scope.passengersDescription = "";

        if ($scope.request.adults > 0)
            $scope.passengersDescription = "{0} {1}".format($scope.request.adults, $scope.request.adults === 1 ? $i18next.t('bestprice.adult') : $i18next.t('resultpage.adults'));

        if ($scope.request.childs > 0)
            $scope.passengersDescription = "{0} + {1} {2}".format($scope.passengersDescription, $scope.request.childs, $scope.request.childs === 1 ? $i18next.t('resultpage.child') : $i18next.t('loadingpage.children'));

        if ($scope.request.infants > 0)
            $scope.passengersDescription = "{0} + {1} {2}".format($scope.passengersDescription, $scope.request.infants, $scope.request.infants === 1 ? $i18next.t('resultpage.infant'): $i18next.t('resultpage.infants'));

        $scope.passengersDescription = "({0})".format($scope.passengersDescription);

        //Add new item in fares
        $scope.fareitems.push({
            amount: $scope.wonderboxremainingamount,
            quantity: 1,
            name: $scope.passengersDescription,
            type:"wonderbox"
        });

        //Fare filter
        $scope.fareFilter = function (item) {
            if (item.type === 10)
                return false;
            return true;
        };
    };
    return directive;
}]);
