/*
    A model used to embed GTM datalayers
    Is stored into $window.sessionStorage to be persistent
*/
webClient.factory('gtmModel', ['$window', function ($window) {

    function gtmModel(name) {
        this._node = name;
        this.ecommerce = {};

        this.ecommerce[name] = {
            actionField: {},
            products: []
        };
    }

    gtmModel.prototype.setAction = function (name, value) {
        this.ecommerce[this._node].actionField[name] = value;
    };

    gtmModel.prototype.setVar = function (name, value) {
        this[name] = value;
    };

    /*
        Used to get some model stored in $window.sessionStorage
        The key is base on action field (for instance action: step / value: 2)
    */
    gtmModel.prototype.loadSessionData = function (action, value) {
        var storedJSON = $window.sessionStorage.getItem('gtmModel-' + action + '-' + value);
        if (storedJSON) {
            var storedObject = JSON.parse(storedJSON);

            this.ecommerce = storedObject.ecommerce;
        }

        return this;
    },

    gtmModel.prototype.addProduct = function (product) {
        this.ecommerce[this._node].products.push(product);
    }

    gtmModel.prototype.removeProduct = function (id) {
        for (var i = this.ecommerce[this._node].products.length - 1; i >= 0; --i) {
            if (this.ecommerce[this._node].products[i].id == id) {
                this.ecommerce[this._node].products.splice(i, 1);
            }
        }
    }

    gtmModel.prototype.convertToPurchase = function (response) {
        var purchaseNode = {
            purchase: {
                actionField: {
                    id: response.numbers[0].bookingNumber,
                    affiliation: this.ecommerce.checkout.products[0].name,
                    revenue: response.paymentAmount
                },
                products: this.ecommerce.checkout.products
            }
        };

        purchaseNode.purchase.products[0].dimension20 = this.ecommerce.checkout.actionField.option;

        this.ecommerce = purchaseNode;

        return this;
    };

    return gtmModel;

}]);