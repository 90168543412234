$(document).ready(function() { 
    
    if (typeof window.dataLayer !== 'undefined' && window.dataLayer) {

        //input error tooltips
        $(document).on('input-error', function (e, target, text) { 
        
            var input = target.name || target.id;

            var event = {
                event: 'ERROR',
                category:'InputError|' + window.gtmPageName +  '|' + input,
                action: text,
                label: $(target).val()
            };

            console.log(JSON.stringify(event));

            dataLayer.push(event);
        });

        $(document).on('datalayer-pushed', function () { 

            if(window.gtmPageName.toLowerCase() === 'error') {
                var message = $('.c-warn-msg .c-npad').text().replace(/\s+/g, ' ');
                var errorCode = window.location.pathname.match(/[0-9]+/g)[0];

                var event = {
                    event: 'ERROR',
                    category:'BookingError|' + window.gtmPageName +  '|' + errorCode,
                    action: message,
                    label: document.referrer
                };

                console.log(JSON.stringify(event));

                dataLayer.push(event);
            }
        });

        $(document).on('no-result-filter-error', function () { 
        
            var event = {
                event: 'ERROR',
                category:'BookingError|' + window.gtmPageName +  '|NoresultAfterFiltering',
                action: $('.c-warn-msg').text(),
                label: ''
            };

            console.log(JSON.stringify(event));

            dataLayer.push(event);
        });

        $(document).on('booking-confirmation-error', function () { 
        
            var event = {
                event: 'ERROR',
                category:'BookingError|' + window.gtmPageName +  '|BookingWarning',
                action: $('.c-title:eq(0)').text(),
                label: ''
            };

            console.log(JSON.stringify(event));

            dataLayer.push(event);
        });

        $(document).on('casino-not-available', function () { 
        
            var event = {
                event: 'ERROR',
                category:'CasinoError|' + window.gtmPageName + '|PaymentOptions',
                action: 'Casino_not_available',
                label: ''
            };

            console.log(JSON.stringify(event));

            dataLayer.push(event);
        });

        $(document).on('casino-scoring-ko', function () { 
        
            var event = {
                event: 'ERROR',
                category:'CasinoError|' + window.gtmPageName + '|PaymentOptions',
                action: 'Casino_scoring_ko',
                label: ''
            };

            console.log(JSON.stringify(event));

            dataLayer.push(event);
        });
    }

    
    $(window).bind('beforeunload', function() { //implicitly accept cookie on home leave
        if ($('.c-cook-show').length > 0) {
            window._uxa = window._uxa || [];
            window._uxa.push(['trackConsentGranted']);
            console.log('cookie accepté');
            return undefined;//no alert
        }
    });

    $(document).on('cookie-accepted', function () {// accept cookie on click
        window._uxa = window._uxa || [];
        window._uxa.push(['trackConsentGranted']);
        console.log('cookie accepté');
    });

    if (/m\\.misterfly\\.com/g.test(window.location.href)) {
        window._uxa = window._uxa || [];
        window._uxa.push(['trackConsentGranted']);
    }
});

isConsentGranted = function () {
    return /_cs_c=2/i.test(document.cookie);
};
