/**
* @desc the ngProductBookingInfo provides support for displaying the fligth details in templates 
* @example <div ng-product-booking-info ng-repeat="itinerary in view.itineraries" index="$index+1" product="itinerary" cities="view.cities" airlines="view.airlines" airports="view.airports" equipments="view.equipments" cabinclasses="view.cabinClasses" requestcabinclass="currentCabinClass"></div>
*/

webClient.directive('ngProductBookingInfo', ['$window', '$i18next', 'templateService', function ($window, $i18next, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        product: "=",
        segments: "=",
        summary: "=",
        airlines: "=",
        airports: "=",
        cities: "=",
        equipments: "=",
        cabinclasses: "=",
        index: "=",
        showbaggages: "=",
        requestcabinclass: "=",
        itinerariescount: "=",
        masstock: "=",
        istrainonly: "=",
        isloadingco2: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_BookingProductTPL.html');

        $scope.getUrl = $window.getUrl;
        $scope.siteType = $window.site.type;
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.getStaticFolder = $window.getStaticFolder;
        $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
        $scope.getTypeStaticFolder = $window.getTypeStaticFolder;
        $scope.enableOption = $window.enableOption && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');


        //Traduction
        $scope.getT = function (key) {
            var res = $i18next.t(key.toLowerCase());
            return res;
        };

        //TO Product: if site is b2b and product is TO
        $scope.isProductTO = $scope.siteType == 2 && $scope.product.productType == 1;

        //Verificar si ya se han calculado los datos de branded fares
        if ($window.$scope && $window.$scope.bkgData) {
            $scope.brandedSelectedIncludeBaggage = $window.$scope.bkgData.brandedSelectedIncludeBaggage;
            $scope.brandedSelectedBaggageText = $window.$scope.bkgData.brandedSelectedBaggageText;
            $scope.brandedSelectedBaggageDataTip = $window.$scope.bkgData.brandedSelectedBaggageDataTip;
        }

        $scope.getText = function (key) {
            return $window.culture.texts[key];
        };
        $scope.inboundVisibleFilter = function (item) {
            return item.visible;
        };
        $scope.showRetour = false;
        $scope.ffdet = function (idx) {
            $scope.showDetails = !$scope.showDetails;
            $window.FFDet(idx);
        };
        $scope.fsmoothopen = function (_oOpener, _iSmooth, _iToggle, _vToggleClass) {
            if (_oOpener.target)
                _oOpener = _oOpener.target;
            var obj = { oOpener: _oOpener, iSmooth: _iSmooth };
            if (_iToggle) obj.iToggle = _iToggle;
            if (_vToggleClass) obj.vToggleClass = _vToggleClass;
            $window.FSmoothOpen(obj);
        };
        $scope.airportChange = false;
        $scope.equipmentChange = false;
        if ($scope.product != null && $scope.product.stops != null) {
            $scope.airportChange = $scope.product.stops.any(function (i) { return i.airportChange });
            $scope.equipmentChange = $scope.product.stops.any(function (i) { return i.equipmentChange });
            if ($scope.equipmentChange)
                $scope.airportChange = false;
        }

        //cabin class
        $scope.alertCabinClass = function (segmentCabinClass) {
            if ($scope.requestcabinclass == segmentCabinClass)
                return true;

            if ($scope.requestcabinclass == "C")
                if (segmentCabinClass == "C" || segmentCabinClass == "J") return true;

            if ($scope.requestcabinclass == "F")
                if (segmentCabinClass == "F" || segmentCabinClass == "P") return true;

            return false;
        }

        //updateProductTemplate: Funcion para revisar si la brand fare seleccionada por el usuario incluye maleta
        $scope.updateProductTemplate = function () {
            if ($window.$scope.bkgData.bookingRequest.brandKey) {
                $scope.brandedSelectedIncludeBaggage = $window.$scope.bkgData.brandedFares && $window.$scope.bkgData
                    && $window.$scope.bkgData.bookingRequest.brandFareSelected && $window.$scope.bkgData.bookingRequest.brandFareSelected.includeBaggage;

                if ($scope.brandedSelectedIncludeBaggage && $window.$scope.bkgData && window.$scope.bkgData.request) {
                    //Prepare text to display properly
                    $scope.brandedSelectedBaggageText = $window.$scope.bkgData.bookingRequest.brandFareSelected.baggageText;
                    $scope.brandedSelectedBaggageDataTip = $window.$scope.bkgData.bookingRequest.brandFareSelected.baggageDataTip;
                }
                //Mantener el valor de las variables en el bkgData
                $window.$scope.bkgData.brandedSelectedIncludeBaggage = $scope.brandedSelectedIncludeBaggage;
                $window.$scope.bkgData.brandedSelectedBaggageText = $scope.brandedSelectedBaggageText;
                $window.$scope.bkgData.brandedSelectedBaggageDataTip = $scope.brandedSelectedBaggageDataTip;
            }
        };

        //Actualizar los detalles del producto si hubo un cambio de branded fares        
        $scope.$on("onProductDetailsUpdate", function (event, args) {
            $scope.updateProductTemplate();
        });
    };
    return directive;
}]);
