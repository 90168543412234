webClient.controller('WorldTourForm', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {
    $window.$scope = $scope;
    $scope._util = tseUtil;
    $scope.getText = function (key) { return window.culture.texts[key]; };
    $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
    $scope.worldForms = $window.worldForms;
    // 3 -> 454
    // 5 -> 764
    // 6 -> 1130
    $scope.stops = parseInt(tseUtil.Location.getQueryStringVar("s"), 8) / 100;
    $scope.actWay = 1;
    $scope.sent = 0;
    $scope.worldTourDirection = angular.copy($scope.worldForms[$scope.stops]);
    $scope.worldTour = { segments: [{ departure: '', arrival: '', date: '' }] };

    $scope.selectWay = function(way)
    {
        if (way != $scope.actWay)
        {
            for (var i = 0; i < $scope.worldTourDirection.length; i++) {
                $("#i-d" + i + "-text").html("");
                $("#i-cfield-departure-" + i).removeClass("c-field-ok");

                $("#i-a" + i + "-text").html("");
                $("#i-cfield-arrival-" + i).removeClass("c-field-ok");
            }
            $scope.worldTour.segments = [];

            if (way == 2 && $scope.actWay == 1) {
                $scope.actWay = 2;
                $scope.reverse();
            }
            if (way == 1 && $scope.actWay == 2) {
                $scope.actWay = 1;
                $scope.reverse();
            }

            $timeout(function () {
                $window.XLFormInit({ iCont: 'i-form-tour' });
            }, 0);
        }
    };
    $scope.reverse = function () {
        $scope.worldTourDirection = angular.copy($scope.worldForms[$scope.stops].reverse());
    };

    $scope.Fpop = function (oTarget,iPop) {
        var target = $("#" + oTarget).get(0);
        $window.FPop({ oTarget: target, iPop: iPop, vStyle: 'classic' });
    };

    $scope.Fselect = function (event, myObj, vName, pos, type) {
        var target = $("#" + myObj).get(0);
        if ($scope.worldTour.segments[pos] == undefined)
            $scope.worldTour.segments[pos] = {};

        $window.FSelect({ e: event, myObj: target, vName: vName });

        if (type == 'departure')
            $scope.worldTour.segments[pos].departure = $("#i-d" + pos + "-text").html();
        else
            $scope.worldTour.segments[pos].arrival = $("#i-a" + pos + "-text").html();
    };

    $scope.DateCheck = function (idTarget) {
        $(idTarget + "-div").removeClass("c-field-ko");
        $(idTarget + "-div").addClass("c-field-ok");
    };

    $scope.DateErrorMessage = function (idTarget, dateMin) {
        $(idTarget).attr('data-check', "{check:'date',date_min:'" + dateMin + "',birthday:false,nolive:true}");
        $(idTarget).attr('data-error-addon', dateMin);
        $(idTarget).attr('data-error-addon', "1");
        $window.XLFormValid({ iCont: 'i-form-tour' });
        $(idTarget+"-div").addClass("c-field-ko");
    };

    $scope.$watch('worldTour.segments', function () {
        for (var i = 0; i < $scope.worldTour.segments.length; i++)
        {
            if ($scope.worldTour.segments[i] != undefined && $scope.worldTour.segments[i].date != undefined && $scope.worldTour.segments[i].date != "")
            {
                dateMin = new Date();
                var parts = ($scope.worldTour.segments[i].date).split('/');
                var date = new Date(parts[2], parts[1] - 1, parts[0]);
                if (parts[1] > 12)
                    $scope.DateErrorMessage('#i-date-' + i, dateMin);
                else if (date < dateMin)
                {
                    dateMin = moment(dateMin).format('DD/MM/YYYY');
                    $scope.DateErrorMessage('#i-date-' + i, dateMin);
                }
                else
                {
                    if (i == 0)
                        $scope.DateCheck('#i-date-' + i);
                    else
                    {
                        if ($scope.worldTour.segments[i - 1] != undefined && $scope.worldTour.segments[i - 1].date != undefined && $scope.worldTour.segments[i - 1].date != "")
                        {
                            if ($scope.worldTour.segments[i] != undefined && $scope.worldTour.segments[i].date != undefined && $scope.worldTour.segments[i].date != "") {
                                var parts = ($scope.worldTour.segments[i].date).split('/');
                                var date = new Date(parts[2], parts[1] - 1, parts[0]);
                                var partsAux = ($scope.worldTour.segments[i - 1].date).split('/');
                                var dateAux = new Date(partsAux[2], partsAux[1] - 1, partsAux[0]);
                                var idTarget = '#i-date-' + i;

                                if (dateAux > date)
                                {
                                    dateAux = moment(dateAux).format('DD/MM/YYYY');
                                    $scope.DateErrorMessage('#i-date-' + i, dateAux);
                                }
                                else
                                    $scope.DateCheck(idTarget);
                            }
                        }
                        else
                            $scope.DateCheck('#i-date-' + i);
                    }
                }
            }
        }
    },true);

    $scope.sendRequest = function () {
        if ($window.XLFormValid({ iCont: 'i-form-tour' })) {
                $window.FLoading({ vAction: 'show' });
                $scope.apiClient.sendWorldTourForm($scope.worldTour).success(function (data) {
                    $window.FLoading({ vAction: 'hide' });
                    if (data.status == 0) {
                        $scope.sent = 1;
                    }
                    else {
                        $window.FLoading({ vAction: 'hide' });
                        $window.XLConfirm({
                            vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                            vTextYes: 'OK Merci',
                            vHideNo: true
                        });
                    }
                }).error(function () {
                    $window.FLoading({ vAction: 'hide' });
                    $window.XLConfirm({
                        vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                        vTextYes: 'OK Merci',
                        vHideNo: true
                    });
                });
        }
    };

    if ($scope.stops == 3 || $scope.stops == 5 || $scope.stops == 6) {
        $timeout(function () {
            $window.XLFormInit({ iCont: 'i-form-tour' });
            $("#i-way-a").prop("checked", true);
        }, 500);
    }
}]);
