window.onpageshow = function (evt) {
    if (evt.persisted) {
        document.body.style.display = "none";
        location.reload();
    }
};
window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
    loader.push(function () {
        errorMsg = errorMsg || "";
        url = url || "";
        lineNumber = lineNumber || 0;
        column = column || 0;
        errorObj = errorObj || null;
        var stack = "";
        if (errorObj)
            stack = errorObj.stack;
        else if (/firefox/i.test(navigator.userAgent) === false) {
            var getStackTrace = function (caller) {
                while (caller) {
                    var callerFunction = (caller.toString().match(/function ([^\(]+)/) === null) ? 'Document Object Model' : caller.toString().match(/function ([^\(]+)/)[1];
                    stack += callerFunction + "\r\n";
                    caller = caller.caller;
                }
            }
            getStackTrace(arguments.callee.caller);
        }
        var errQuery = "m=" + encodeURI(errorMsg) + "&l=" + encodeURI(lineNumber) + "&c=" + encodeURI(column) + "&s=" + encodeURI(stack);
        var imgUrl = "{0}/errorjs/pic?{1}".format(window.location.origin, errQuery);
        var img = document.createElement("img");
        img.src = imgUrl;
        img.width = 1;
        img.height = 1;
        document.body.appendChild(img);
    });
};
moment.locale(lng.substring(0, 2));

//Add to enable the html5 mode to prevent angularJs to add / after # in urls
var webClient = angular.module('webClient', ['tseModule', 'ngSanitize', 'jm.i18next']);
webClient.controller('SearchFormCtrl', ['$scope',
    function ($scope) {
    }]);

webClient.config(['$sceDelegateProvider', function ($sceDelegateProvider) { 
    $sceDelegateProvider.resourceUrlWhitelist([
        // Allow same origin resource loads.
        'self',
        // Allow loading from our assets domain.  Notice the difference between * and **.
        'https://cdn.mrvol.com/**',
        'https://cdn.misterfly.com/**',
        'https://cdn.h24travel.com/**',
        'http://cdn.mrvol.com/**',
        'http://cdn.misterfly.com/**',
        'http://cdn.h24travel.com/**'

    ]);
}]);

webClient.filter('capitalize', function () { return function (input) { return input.toString().capitalize(); }; });
webClient.filter('html', ['$sce', function ($sce) { return function (text) { return $sce.trustAsHtml(text); }; }]);
webClient.directive('simpleHtml', function () { return function (scope, element, attr) { scope.$watch(attr.simpleHtml, function (value) { element.html(scope.$eval(attr.simpleHtml)); }); }; });
webClient.run(['$window', '$templateCache', '$http', function ($window, $templateCache, $http) {
    if ($window.isDefined($window.angularTemplates) && $window.angularTemplates.items) {
        var templates = $window.angularTemplates;
        for (var i = 0; i < templates.items.length; i++) {
            var item = templates.items[i];
            $templateCache.put(item.id + templates.version, item.content);
        }
    }
    $window.$(function () {
        try {
            var util = angular.element(document.body).injector().get('tseUtil');
            if (util.Cookies.get("MFdid") == null) {
                try {
                    new Fingerprint2({ excludeSessionStorage: true, excludeLocalStorage: true, excludeWebGL: true }).get(function (result) {
                        util.Cookies.set("MFdid", result, 18250);
                    });
                }
                catch (e) { }
            }
        } catch (e) { }
    });
}]);