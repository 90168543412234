webClient.directive('ngRequestSummary', ['$window', '$i18next', 'templateService', function ($window,  $i18next, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        request: "=",
        searchbutton: "=",
        bookingsummary: "=",
        currentstep: "=",
        bookingdata: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_RequestSummary.html');

        $scope.getUrl = $window.getUrl;
        $scope.siteType = $window.site.type;

        $scope.SendInspecletUsage = function () {
            //Send inspectlet tag of use Modifier Button
            $window.sendInspectlet('tagSession', { UseModifierButton: true });
        };
        //Traduction
        $scope.getT = function (key) {
            var res = $i18next.t(key.toLowerCase());
            return res;
        };
    };
    return directive; 
}]);
