function basketHelper() { }

basketHelper.ptcToDico = {}
basketHelper.ptcToDico["ADT"] = 'widgets.common.adult';
basketHelper.ptcToDico["CNN"] = 'widgets.common.child';
basketHelper.ptcToDico["CHD"] = 'widgets.common.child';
basketHelper.ptcToDico["INF"] = 'widgets.common.baby';
basketHelper.ptcToDico["ITX"] = 'widgets.common.adult';
basketHelper.ptcToDico["INN"] = 'widgets.common.child';
basketHelper.ptcToDico["ITF"] = 'widgets.common.baby';
basketHelper.ptcToDico[0] = 'widgets.common.adult';
basketHelper.ptcToDico[1] = 'widgets.common.child';
basketHelper.ptcToDico[2] = 'widgets.common.baby';

basketHelper.ptcAgeToDico = {}
basketHelper.ptcAgeToDico["ADT"] = 'mrfly-widget-accommodation-price-detail.ageadult';
basketHelper.ptcAgeToDico["CNN"] = 'mrfly-widget-accommodation-price-detail.agechild';
basketHelper.ptcAgeToDico["CHD"] = 'mrfly-widget-accommodation-price-detail.agechild';
basketHelper.ptcAgeToDico["INF"] = 'mrfly-widget-accommodation-price-detail.agebaby';
basketHelper.ptcAgeToDico["ITX"] = 'mrfly-widget-accommodation-price-detail.ageadult';
basketHelper.ptcAgeToDico["INN"] = 'mrfly-widget-accommodation-price-detail.agechild';
basketHelper.ptcAgeToDico["ITF"] = 'mrfly-widget-accommodation-price-detail.agebaby';
basketHelper.ptcAgeToDico[0] = 'mrfly-widget-accommodation-price-detail.ageadult';
basketHelper.ptcAgeToDico[1] = 'mrfly-widget-accommodation-price-detail.agechild';
basketHelper.ptcAgeToDico[2] = 'mrfly-widget-accommodation-price-detail.agebaby';

basketHelper.ptcToTicketDico = {}
basketHelper.ptcToTicketDico["ADT"] = 'mrfly-widget-accommodation-price-detail.ticketpriceadult';
basketHelper.ptcToTicketDico["CNN"] = 'mrfly-widget-accommodation-price-detail.ticketpricechild';
basketHelper.ptcToTicketDico["CHD"] = 'mrfly-widget-accommodation-price-detail.ticketpricechild';
basketHelper.ptcToTicketDico["INF"] = 'mrfly-widget-accommodation-price-detail.ticketpricebaby';
basketHelper.ptcToTicketDico["ITX"] = 'mrfly-widget-accommodation-price-detail.ticketpriceadult';
basketHelper.ptcToTicketDico["INN"] = 'mrfly-widget-accommodation-price-detail.ticketpricechild';
basketHelper.ptcToTicketDico["ITF"] = 'mrfly-widget-accommodation-price-detail.ticketpricebaby';
basketHelper.ptcToTicketDico[0] = 'mrfly-widget-accommodation-price-detail.ticketpriceadult';
basketHelper.ptcToTicketDico[1] = 'mrfly-widget-accommodation-price-detail.ticketpricechild';
basketHelper.ptcToTicketDico[2] = 'mrfly-widget-accommodation-price-detail.ticketpricebaby';

basketHelper.ptcToFeeDico = {}
basketHelper.ptcToFeeDico["ADT"] = 'mrfly-widget-accommodation-price-detail.feepriceadult';
basketHelper.ptcToFeeDico["CNN"] = 'mrfly-widget-accommodation-price-detail.feepricechild';
basketHelper.ptcToFeeDico["CHD"] = 'mrfly-widget-accommodation-price-detail.feepricechild';
basketHelper.ptcToFeeDico["INF"] = 'mrfly-widget-accommodation-price-detail.feepricebaby';
basketHelper.ptcToFeeDico["ITX"] = 'mrfly-widget-accommodation-price-detail.feepriceadult';
basketHelper.ptcToFeeDico["INN"] = 'mrfly-widget-accommodation-price-detail.feepricechild';
basketHelper.ptcToFeeDico["ITF"] = 'mrfly-widget-accommodation-price-detail.feepricebaby';

basketHelper.ConvertPtcToTicketDico = function (ptc) {
    console.log("ConvertPtcToTicketDico:" + ptc);
    console.log(basketHelper.ptcToTicketDico[ptc]);
    if (basketHelper.ptcToTicketDico[ptc] == 'undefined')
        return ptc;

    return getT(basketHelper.ptcToTicketDico[ptc]);
}

basketHelper.ConvertPtcToFeeDico = function (ptc) {
    console.log("ConvertPtcToTicketDico:" + ptc);
    console.log(basketHelper.ptcToFeeDico[ptc]);
    if (basketHelper.ptcToFeeDico[ptc] == 'undefined')
        return ptc;

    return getT(basketHelper.ptcToFeeDico[ptc]);
}

basketHelper.ConvertPtcToLabel = function (ptc) {
    console.log("ConvertPtcToLabel:" + ptc);
    console.log(basketHelper.ptcToDico[ptc]);
    if (basketHelper.ptcToDico[ptc] == 'undefined')
        return ptc;

    return getT(basketHelper.ptcToDico[ptc]).toLowerCase();
}

basketHelper.ConvertAgePtcToLabel = function (ptc) {
    console.log("ConvertAgePtcToLabel:" + ptc);
    console.log(basketHelper.ptcAgeToDico[ptc]);
    if (basketHelper.ptcAgeToDico[ptc] == 'undefined')
        return ptc;

    return getT(basketHelper.ptcAgeToDico[ptc]);
}

basketHelper.getPrice = function (price) {
    if (typeof (price) == 'number')
        return price;
    if (typeof (price.value) == 'number')
        return price.value;

    return price;
}

//done
basketHelper.getInsurancePriceDetailsSimple = function (siteType, selectedInsurance, insurances) {
    var ret = [];

    if (typeof (selectedInsurance) == 'undefined' || selectedInsurance == null)
        return [];

    var inssurance = insurances.filter((item) => item.insuranceId == selectedInsurance.id)[0];
    console.log("Inssurance:");
    console.log(inssurance);
    var insuranceInfo = {
        description: getT('widgets.common.insurance'),
        totalPrice: basketHelper.getPrice(inssurance.totalPrice),
        details: [],
        type: 'INS',
        order: 10
    }

    ret.push(insuranceInfo);

    return ret;
}

//done
basketHelper.getSeatMapPriceDetailsSimple = function (siteType, seatmap) {
    var ret = [];
    var price = 0;
    
    console.log("-- seatmap --");
    console.log(seatmap);
    Object.values(seatmap).forEach(itn => { Object.values(itn).forEach(pax => { price += pax.price }) })
    //Flight infos
    var seatmapInfo = {
        description: getT('mrfly-widget-accommodation-price-detail.seatmapsimple'),
        totalPrice: price,
        details: [],
        type: 'SEAT',
        order: 9
    };

    console.log(seatmapInfo);
    ret.push(seatmapInfo);

    return ret;
}

//done
basketHelper.getAdditionalBagsPriceDetailsSimple = function (siteType, passengers) {
    var ret = [];
    var siteTypeIsB2b = siteType === 2;
    var bagTotalPrice = 0;
    passengers.forEach(function (pax) {
        if (typeof (pax.baggages) != 'undefined' && pax.baggages.any()) {
            pax.baggages.forEach(function (bag) {
                bagTotalPrice += bag.totalPrice.value;
            });
        }
    });
    var bagInfo = {
        description: getT('mrfly-widget-accommodation-price-detail.baggagesimple'),
        totalPrice: bagTotalPrice,
        details: [],
        type: 'BAG',
        order: 8
    };

    console.log(bagInfo);
    ret.push(bagInfo);
    return ret;
}

basketHelper.getPriceDetailsSimple = function (siteType, proposal, accommodations, agencyMarginHotel, extraDetails, productType) {
    var ret = [];
    var siteTypeIsB2b = siteType === 2;
    var siteTypeIsAirbus = window.partnerName == 'airbus';
    
    console.log("---- getPriceDetails extraDetails ----");
    console.log(extraDetails);

    //Hotel
    var totalAccommodationsWithMargin = siteTypeIsB2b ? agencyMarginHotel.value : 0;
    var totalAccommodationsWithoutMargin = 0;
    accommodations.forEach(function (accommodation) {
        totalAccommodationsWithMargin += siteTypeIsB2b ? accommodation.totalPriceRacknet : accommodation.totalPriceForcedPrice;
        totalAccommodationsWithoutMargin += siteTypeIsB2b ? accommodation.totalPriceRacknet : accommodation.totalPriceForcedPrice;
    });

    //Flight
    var totalFlightPrice = proposal.totalAmount.value + proposal.totalAgencyMargin.value;

    //Flight infos
    var flightInfo = {
        description: siteTypeIsAirbus ? getT('mrfly-widget-accommodation-price-detail.flighthotelsimple-airbus') : getT('mrfly-widget-accommodation-price-detail.flighthotelsimple'),
        totalPrice: totalFlightPrice + totalAccommodationsWithMargin,
        details: [],
        type: 'FLIGHT',
        order: 1
    };


    ret.push(flightInfo)
    
    extraDetails.forEach(extra => { if (extra.totalPrice != 0) ret.push(extra)});
    ret.sort((a,b) => a.order - b.order);
    console.log("---- getPriceDetails ----");
    console.log(ret);
    return ret;

}

basketHelper.getInsurancePriceDetailsDetailed = function (siteType, selectedInsurance, insurances) {
    var ret = [];
    var siteTypeIsB2b = siteType === 2;

    if (typeof (selectedInsurance) == 'undefined' || selectedInsurance == null)
        return [];

    var inssurance = insurances.filter((item) => item.insuranceId == selectedInsurance.id)[0];
    console.log("Inssurance:");
    console.log(inssurance);
    var insuranceInfo = {
        description: getT('widgets.common.insurance'),
        totalPrice: basketHelper.getPrice(inssurance.totalPrice),
        details: [],
        type: 'INS'
    }

    inssurance.prices.forEach(function (ins) {

        var detail = {
            description: inssurance.insuranceDescription + " " + basketHelper.ConvertPtcToLabel(ins.ptc) + " (" + basketHelper.ConvertAgePtcToLabel(ins.ptc) + ")",
            totalPrice: basketHelper.getPrice(ins.totalInsuranceAmount),
            unitPrice: basketHelper.getPrice(ins.priceAmount),
            quantity: ins.quantity,
            subDetails: []
        };
        insuranceInfo.details.push(detail);
    });

    ret.push(insuranceInfo);

    return ret;
}

basketHelper.getSeatMapPriceDetailsDetailed = function (siteType, seatmap) {
    var ret = [];
    //Flight infos
    var seatmapInfo = {
        description: "",
        totalPrice: 0,
        details: [],
        type: 'FLIGHT'
    };
    var price = 0;
    Object.values(seatmap).forEach(itn => { Object.values(itn).forEach(pax => { price += pax.price }) })
    var detail = {
        description: getT('mrfly-widget-accommodation-price-detail.seatmap'),
        totalPrice: price,
        unitPrice: -1,
        quantity: -1,
        subDetails: []
    };
    seatmapInfo.details.push(detail);
 
    console.log(seatmapInfo);
    ret.push(seatmapInfo);
    return ret;
}

basketHelper.getAdditionalBagsPriceDetailsDetailed = function (siteType, passengers) {
        var ret = [];
        var siteTypeIsB2b = siteType === 2;
        //Flight infos
        var bagInfo = {
            description: "",
            totalPrice: 0,
            details: [],
            type: 'FLIGHT'
        };

        var bagTotalPrice = 0;
        var bagTotalQuantity = 0;
        passengers.forEach(function (pax) {
            if (typeof (pax.baggages) != 'undefined' && pax.baggages.any()) {
                pax.baggages.forEach(function (bag) {
                    bagTotalPrice += bag.totalPrice.value;
                    bagTotalQuantity += bag.quantity;

                });
            }
        });

        if (bagTotalQuantity == 0)
            return [];

        var detail = {
            description: getT('airbooking.luggage.cargoadded'),
            totalPrice: bagTotalPrice,
            unitPrice: -1,
            quantity: -1,
            subDetails: []
        };

        //Get all bags
        var bags = [];
        passengers.forEach(function (pax) {
            if (pax.baggages.any()) {
                Array.prototype.push.apply(bags, pax.baggages);
            }
        });

        //Group by kg
        var baggrouped = objectToArray(bags.groupByKey('weight'));
        console.log("-- baggrouped --");
        console.log(baggrouped);
        window.baggrouped = baggrouped;
        baggrouped.forEach(function (gbag) {
            var gTotalPrice = 0;
            var gQuantity = 0;
            if (!Array.isArray(gbag.value))
                gbag.value = [gbag.value];

            gbag.value.forEach(function (bagu) {
                gTotalPrice += bagu.totalPrice.value;
                gQuantity += bagu.quantity;
            });

            detail.subDetails.push({
                description: getT('mrfly-widget-accommodation-price-detail.luggagesize').replace('{0}', gbag.key),
                totalPrice: gTotalPrice,
                unitPrice: gTotalPrice / gQuantity,
                quantity: gQuantity,
            })
        });

        bagInfo.details.push(detail);
        console.log("---- bagInfo ----");
        console.log(bagInfo);
        ret.push(bagInfo);
        return ret;
    }


basketHelper.getInsurancePriceDetails = function (siteType, selectedInsurance, insurances) {

    var siteTypeIsB2b = siteType === 2;
    var siteIsAirbus = window.partnerName == 'airbus';
    if (siteTypeIsB2b || siteIsAirbus) {
		return basketHelper.getInsurancePriceDetailsDetailed(siteType, selectedInsurance, insurances);
	} else {
		return basketHelper.getInsurancePriceDetailsSimple(siteType, selectedInsurance, insurances);
	}
    return ret;
}

basketHelper.getSeatMapPriceDetails = function (siteType, seatmap) {
    var siteTypeIsB2b = siteType === 2;
    var siteIsAirbus = window.partnerName == 'airbus';
    if (siteTypeIsB2b || siteIsAirbus) {
		return basketHelper.getSeatMapPriceDetailsDetailed(siteType, seatmap);
	} else {
		return basketHelper.getSeatMapPriceDetailsSimple(siteType, seatmap);
	}

}

basketHelper.getAdditionalBagsPriceDetails = function (siteType, passengers) {
    var siteTypeIsB2b = siteType === 2;
    var siteIsAirbus = window.partnerName == 'airbus';
    if (siteTypeIsB2b || siteIsAirbus) {
		return basketHelper.getAdditionalBagsPriceDetailsDetailed(siteType, passengers);
	} else {
		return basketHelper.getAdditionalBagsPriceDetailsSimple(siteType, passengers);
	}
}

basketHelper.getPriceDetailsDetailedFromBooking = function (siteType, booking, agencyMarginHotel, extraDetails, productType) {
    ret = [];
    var siteTypeIsB2b = siteType === 2;
    var accommodations = booking.accommodations;

    var isTrain = productType == "RAIL";
        
    console.log("---- getPriceDetails extraDetails ----");
    console.log(extraDetails);

    var flightTickets = booking.fares.filter(f => f.type == 10);
    var totalFlightTicketsAmount = 0;
    for (var fare of flightTickets) {
        totalFlightTicketsAmount += fare.amount * fare.quantity;
    }

    var flightMargins = booking.fares.filter(f => f.type == 70 && f.subtype == 900);
    var flightMarginAmount = 0;
    for (var fare of flightMargins) {
        flightMarginAmount += fare.amount * fare.quantity;
    }


    var seats = booking.fares.filter(f => f.type == 20 && f.subtype == 24);
    var totalSeatAmount = 0;
    for (var fare of seats) { totalSeatAmount += fare.amount * fare.quantity; }
    var bags = booking.fares.filter(f => f.type == 20 && f.subtype == 20);
    var totalbagAmount = 0;
    for (var fare of bags) { totalbagAmount += fare.amount * fare.quantity; }
    //Flight infos
    var flightInfo = {
        description: isTrain ? getT('airbooking.trainprice') : getT('airbooking.flightprice'),
        totalPrice: totalFlightTicketsAmount + totalSeatAmount + totalbagAmount + flightMarginAmount,
        details: [],
        type: 'FLIGHT'
    };

    flightTickets.forEach(function (fare) {
        var detail = {
            description: basketHelper.ConvertPtcToTicketDico(fare.paxType),
            totalPrice: basketHelper.getPrice(fare.amount) * fare.quantity,
            unitPrice: basketHelper.getPrice(fare.amount),
            quantity: fare.quantity,
            subDetails: []
        };

        //Handle sub details
        detail.subDetails.push({
            description: getT('airbooking.taxexcludedprice'),
            totalPrice: basketHelper.getPrice(fare.ticket) * fare.quantity,
            unitPrice: basketHelper.getPrice(fare.ticket),
            quantity: fare.quantity,
        });
        detail.subDetails.push({
            description: getT('airbooking.taxfuel'),
            totalPrice: basketHelper.getPrice(fare.surchargeTax) * fare.quantity,
            unitPrice: basketHelper.getPrice(fare.surchargeTax),
            quantity: fare.quantity,
        });
        detail.subDetails.push({
            description: getT('airbooking.airporttaxe'),
            totalPrice: basketHelper.getPrice(fare.airportTax) * fare.quantity,
            unitPrice: basketHelper.getPrice(fare.airportTax),
            quantity: fare.quantity,
        });

        flightInfo.details.push(detail);
    });

    if (seats.any()) {
        
        flightInfo.details.push({
            description: getT('mrfly-widget-accommodation-price-detail.seatmap'),
            totalPrice: totalSeatAmount,
            unitPrice: -1,
            quantity: -1,
            subDetails: []
        });
    }

    if (bags.any()) {
        var info = {
            description: getT('airbooking.luggage.cargoadded'),
            totalPrice: totalbagAmount,
            unitPrice: -1,
            quantity: -1,
            subDetails: []
        };
        

        var baggrouped = objectToArray(bags.groupByKey('amount'));
        baggrouped.forEach(function (gbag) {
            var gTotalPrice = 0;
            var gQuantity = 0;

            if (!Array.isArray(gbag.value))
                gbag.value = [gbag.value];

            gbag.value.forEach(function (bagu) {
                gTotalPrice += bagu.amount * bagu.quantity;
                gQuantity += bagu.quantity;
            });

            info.subDetails.push({
                description: getT('mrfly-widget-accommodation-price-detail.luggagesize').replace('{0}', 0),
                totalPrice: gTotalPrice,
                unitPrice: gTotalPrice / gQuantity,
                quantity: gQuantity,
            })
        });

        flightInfo.details.push(info);
    }

    flightInfo.details.push({
        description: getT('mrfly-widget-accommodation-price-detail.totalflghtpricetaxecluded'),
        totalPrice: totalFlightTicketsAmount + totalSeatAmount + totalbagAmount,
        unitPrice: 0,
        quantity: 0,
        subDetails: []
    });
    if (flightMargins.any()) {

        flightInfo.details.push({
            description: getT('mrfly-widget-accommodation-price-detail.flightagencymarging'),
            totalPrice: flightMarginAmount,
            unitPrice: -1,
            quantity: -1, 
            subDetails: []
        });
    }

    ret.push(flightInfo);

    //Hotel infos
    var totalAccommodationsWithMargin = siteTypeIsB2b ? agencyMarginHotel.value : 0;
    var totalAccommodationsWithoutMargin = 0;
    accommodations.forEach(function (accommodation) {
        totalAccommodationsWithMargin += siteTypeIsB2b ? accommodation.totalPriceRacknet : accommodation.totalPriceForcedPrice;
        totalAccommodationsWithoutMargin += siteTypeIsB2b ? accommodation.totalPriceRacknet : accommodation.totalPriceForcedPrice;
    });

    var hotelInfo = {
        description: getT('mrfly-widget-accommodation-info.hotelprice'),
        totalPrice: totalAccommodationsWithMargin,
        details: [],
        type: 'ACCOMMODATION'
    };
    if (siteTypeIsB2b) {
        hotelInfo.details.push({
            description: getT('mrfly-widget-accommodation-price-detail.totalhotelpricetaxecluded'),
            totalPrice: totalAccommodationsWithoutMargin,
            unitPrice: 0,
            quantity: 0,
            subDetails: []
        });
        hotelInfo.details.push({
            description: getT('mrfly-widget-accommodation-price-detail.hotelagencymarging'),
            totalPrice: agencyMarginHotel.value,
            unitPrice: 0,
            quantity: 0,
            subDetails: []
        });

    } else {
        hotelInfo.details.push({
            description: getT('mrfly-widget-accommodation-price-detail.totalhotelprice'),
            totalPrice: totalAccommodationsWithMargin,
            unitPrice: 0,
            quantity: 0,
            subDetails: []
        });
    }

    ret.push(hotelInfo);

    //Inssurance
    var inssurances = booking.fares.filter(f => f.type == 40 || f.type == 43);
    if (inssurances.any()) {
        var totalInssuranceAmount = 0;
        for (var fare of inssurances) { totalInssuranceAmount += fare.amount * fare.quantity; }
        var inssuranceInfo = {
            description: getT('widgets.common.insurance'),
            totalPrice: totalInssuranceAmount,
            details: [],
            type: 'INS',
            order: 10
        }


        inssurances.forEach(function (inssurance) {
            inssuranceInfo.details.push({
                description: getT('widgets.common.insurance') + " " + basketHelper.ConvertPtcToLabel(inssurance.paxType) + " (" + basketHelper.ConvertAgePtcToLabel(inssurance.paxType) + ")",
                totalPrice: inssurance.amount * inssurance.quantity,
                unitPrice: inssurance.amount,
                quantity: inssurance.quantity,
                subDetails: []
            })
        });

        ret.push(inssuranceInfo);
    }

    console.log("---- getPriceDetails ----");
    console.log(ret);

    return ret;
};


basketHelper.getPriceDetailsDetailed = function (siteType, proposal, accommodations, agencyMarginHotel, extraDetails, productType) {
    //alert("getPriceDetailsDetailed");debugger;
    ret = [];
    var siteTypeIsB2b = siteType === 2;
    var isTrain = productType == "RAIL";
    console.log("---- getPriceDetails extraDetails ----");
    console.log(extraDetails);

    //Flight infos
    var flightInfo = {
        description: isTrain ? getT('airbooking.trainprice') : getT('airbooking.flightprice'),
        totalPrice: siteTypeIsB2b ? proposal.totalAmount.value : proposal.totalAmount.value + proposal.totalAgencyMargin.value,
        details: [],
        type: 'FLIGHT'
    };
   
    if (siteTypeIsB2b) {
        proposal.fares.forEach(function (fare) {
            var detail = {
                description: basketHelper.ConvertPtcToTicketDico(fare.ptc),
                totalPrice: basketHelper.getPrice(fare.total),
                unitPrice: basketHelper.getPrice(fare.totalAmount),
                quantity: fare.quantity,
                subDetails: []
            };

            //Handle sub details
            detail.subDetails.push({
                description: getT('airbooking.taxexcludedprice'),
                totalPrice: basketHelper.getPrice(fare.amount) * fare.quantity,
                unitPrice: basketHelper.getPrice(fare.amount),
                quantity: fare.quantity,
            });
            detail.subDetails.push({
                description: getT('airbooking.taxfuel'),
                totalPrice: basketHelper.getPrice(fare.totalTaxSummary.fuel),
                unitPrice: basketHelper.getPrice(fare.taxSummary.fuel),
                quantity: fare.quantity,
            });
            detail.subDetails.push({
                description: getT('airbooking.airporttaxe'),
                totalPrice: basketHelper.getPrice(fare.totalTaxSummary.airport),
                unitPrice: basketHelper.getPrice(fare.taxSummary.airport),
                quantity: fare.quantity,
            });

            flightInfo.details.push(detail);
        });
    }
    else {
        proposal.fares.forEach(function (fare) {
            var detail = {
                description: basketHelper.ConvertPtcToTicketDico(fare.ptc),
                totalPrice: basketHelper.getPrice(fare.total),
                unitPrice: basketHelper.getPrice(fare.totalAmount),
                quantity: fare.quantity,
                subDetails: []
            };

            //Handle sub details
            detail.subDetails.push({
                description: getT('airbooking.taxexcludedprice'),
                totalPrice: basketHelper.getPrice(fare.amount) * fare.quantity,
                unitPrice: basketHelper.getPrice(fare.amount),
                quantity: fare.quantity,
            });
            detail.subDetails.push({
                description: getT('airbooking.taxfuel'),
                totalPrice: basketHelper.getPrice(fare.totalTaxSummary.fuel),
                unitPrice: basketHelper.getPrice(fare.taxSummary.fuel),
                quantity: fare.quantity,
            });
            detail.subDetails.push({
                description: getT('airbooking.airporttaxe'),
                totalPrice: basketHelper.getPrice(fare.totalTaxSummary.airport),
                unitPrice: basketHelper.getPrice(fare.taxSummary.airport),
                quantity: fare.quantity,
            });

            flightInfo.details.push(detail);
        });
    }

    //Handle bag en soute
    var totalExtraPrice = 0;
    if (extraDetails && extraDetails.any()) {
        var flightExtraInfo = extraDetails.filter((item) => item.type == 'FLIGHT');
        if (flightExtraInfo.any()) {
            flightExtraInfo.forEach(ex => {
                Array.prototype.push.apply(flightInfo.details, ex.details);
                ex.details.map(x => totalExtraPrice += x.totalPrice);
            });
        }
    }

    //TOREVIEW
    if (siteTypeIsB2b) {
        flightInfo.totalPrice = basketHelper.getPrice(proposal.totalAmount) + totalExtraPrice;
    }
    else {
        flightInfo.totalPrice = basketHelper.getPrice(proposal.totalAmount) + totalExtraPrice;
    }
    ret.push(flightInfo);

    //Hotel infos
    var totalAccommodationsWithMargin = siteTypeIsB2b ? agencyMarginHotel.value : 0;
    var totalAccommodationsWithoutMargin = 0;
    accommodations.forEach(function (accommodation) {
        totalAccommodationsWithMargin += siteTypeIsB2b ? accommodation.totalPriceRacknet : accommodation.totalPriceForcedPrice;
        totalAccommodationsWithoutMargin += siteTypeIsB2b ? accommodation.totalPriceRacknet : accommodation.totalPriceForcedPrice;
    });

    var hotelInfo = {
        description: getT('mrfly-widget-accommodation-info.hotelprice'),
        totalPrice: siteTypeIsB2b ? totalAccommodationsWithoutMargin : totalAccommodationsWithMargin,
        details: [],
        type: 'ACCOMMODATION'
    };
    if (!siteTypeIsB2b) {
        hotelInfo.details.push({
            description: getT('mrfly-widget-accommodation-price-detail.totalhotelprice'),
            totalPrice: totalAccommodationsWithMargin,
            unitPrice: 0,
            quantity: 0,
            subDetails: []
        });
    }
    ret.push(hotelInfo);

    if (siteTypeIsB2b) {
        agencyMarginHotel.margin = parseFloat(agencyMarginHotel.margin);
        if (agencyMarginHotel.margin === 0) {
            console.log("basketHelper.getPriceDetailsDetailed agencyMarginHotel A " + agencyMarginHotel);
        }
        else {
            console.log("basketHelper.getPriceDetailsDetailed agencyMarginHotel B " + agencyMarginHotel);
            //TO REVIEW
            var totalAmount = parseFloat(flightInfo.totalPrice) + parseFloat(totalAccommodationsWithoutMargin);
            //var totalAmount = parseFloat(flightInfo.totalPrice) + parseFloat(totalExtraPrice) + parseFloat(totalAccommodationsWithoutMargin);
            agencyMarginHotel.value = getAmountFromMargin(totalAmount, agencyMarginHotel.margin);
        }
    }
    else {
        flightInfo.totalPrice = basketHelper.getPrice(proposal.totalAmount) + totalExtraPrice;
    }

    if (siteTypeIsB2b) {
        var dpkMarginInfo = {
            description: getT('mrfly-widget-accommodation-price-detail.dpkagencymarging'),
            totalPrice: agencyMarginHotel.value,
            details: [],
            type: 'DYNAMICPACKAGE'
        };
        ret.push(dpkMarginInfo);
    }

    //Inssurance
    if (extraDetails && extraDetails.any()) {
        var insuranceExtraInfo = extraDetails.filter((item) => item.type == 'INS');
        Array.prototype.push.apply(ret, insuranceExtraInfo);
    }

    if (proposal.mandatoryFees.any()) {
        proposal.mandatoryFees.forEach(function (fee) {
            var mandatoryFeesInfo = {
                description: fee.flexyDescription,
                totalPrice: fee.totalPrice,
                details: [],
            };
            fee.prices.forEach(function (fare) {
                var detail = {
                    description: basketHelper.ConvertPtcToFeeDico(fare.ptc),
                    totalPrice: fare.totalFlexyAmount,
                    unitPrice: fare.priceAmount,
                    quantity: fare.quantity,
                    subDetails: []
                };
                mandatoryFeesInfo.details.push(detail)
            });
            ret.push(mandatoryFeesInfo);
        });
    }

    console.log("---- getPriceDetails ----");
    console.log(ret);
    return ret;
};

basketHelper.getPriceDetails = function (siteType, proposal, accommodations, agencyMarginHotel, extraDetails, productType) {
    var siteTypeIsB2b = siteType === 2;
    var siteIsAirbus = window.partnerName == 'airbus';

    if (siteTypeIsB2b || siteIsAirbus) {
        return basketHelper.getPriceDetailsDetailed(siteType, proposal, accommodations, agencyMarginHotel, extraDetails, productType);
	} else {
        return basketHelper.getPriceDetailsSimple(siteType, proposal, accommodations, agencyMarginHotel, extraDetails, productType);
	}
};
