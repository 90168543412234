webClient.controller('AirProductsMultiDestination', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'trackingService', 'gtmProduct',
    function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper, trackingService, gtmProduct) {
        //Initialize Variables
        $window.$scope = $scope;
        $scope.MeanMap = null;
        $scope.resultCO2 = null;
        $scope.CO2Map = null;
        $scope.isloadingco2 = null;
        $scope.nbCallCo2 = 0;
        $scope._util = tseUtil;
        $scope.site = $window.site;
        $scope.enableOption = $window.enableOption && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');
        $scope.getText = function (key) { return window.culture.texts[key]; };
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        $scope.requestSummary = $window.site.currentAirRequest.summary;
        $scope.searchForm = {};
        $scope.searchForm.type = $scope.requestSummary.roundTrip ? 0 : $scope.requestSummary.oneWay ? 1 : 2;
        $scope.searchForm.cabin = $scope.requestSummary.cabinClass;
        $scope.searchForm.origin = $scope.requestSummary.origin.name;
        $scope.searchForm.origin_value = $scope.requestSummary.origin.value;
        $scope.searchForm.destination = $scope.requestSummary.destination.name;
        $scope.searchForm.destination_value = $scope.requestSummary.destination.value;
        $scope.searchForm.goDate = $scope.requestSummary.goDate.dateString;
        $scope.searchForm.adults = $scope.requestSummary.adults;
        $scope.searchForm.childrens = $scope.requestSummary.childs;
        $scope.searchForm.infants = $scope.requestSummary.infants;
        $scope.currentCabinClass = $window.site.currentAirRequest.summary.cabinClass;
        $scope.hideFlexy = window.enableFlexy === false;
        $scope.enableFreeFlex = window.enableFreeFlex === true;
        $scope.market = window.market;
        $scope.hideAirportFilter = $window.site.partnerId === "europa";
        $scope.partnerName = $window.partnerName;
        $scope.editSearch = true;
        //RoundTrip
        if ($scope.requestSummary.roundTrip)
            $scope.searchForm.returnDate = $scope.requestSummary.goDate2.dateString;
        //OpenJaw
        if ($scope.requestSummary.openJaw) {
            $scope.searchForm.origin2 = $scope.requestSummary.origin2.name;
            $scope.searchForm.origin2_value = $scope.requestSummary.origin2.value;
            $scope.searchForm.destination2 = $scope.requestSummary.destination2.name;
            $scope.searchForm.destination2_value = $scope.requestSummary.destination2.value;
            $scope.searchForm.goDate2 = $scope.requestSummary.goDate2.dateString;
        }

        //MultiDestination
        if ($scope.requestSummary.multiDestination) {
            for (var i = 2; i <= $scope.requestSummary.itineraries.length; i++) {
                $scope.searchForm["origin" + i] = $scope.requestSummary.itineraries[i - 1].origin.name;
                $scope.searchForm["origin" + i + "_value"] = $scope.requestSummary.itineraries[i - 1].origin.value;
                $scope.searchForm["goDate" + i] = $scope.requestSummary.itineraries[i - 1].goDate.dateString;
                $scope.searchForm["destination" + i] = $scope.requestSummary.itineraries[i - 1].destination.name;
                $scope.searchForm["destination" + i + "_value"] = $scope.requestSummary.itineraries[i - 1].destination.value;
            }
        }

        $scope.hasToggleAllAndNoneMarketingCarriersFilters = false;
        $scope.isAllAndNoneToggleChecked = false;

        $scope.SendInspecletUsage = function () {
            //Send inspectlet tag of use Modifier Button
            $window.sendInspectlet('tagSession', { UseModifierButton: true });
        };

        $scope.toggleEdit = function () {
            $scope.editSearch = !$scope.editSearch;
        };

        $scope.UpdateCo2Results = function () {
            $scope.nbCallCo2++;
            if ($scope.resultCO2 == null)
                return;
            $scope.CO2Map = new Map();
            $scope.MeanMap = new Map();
            for (var i = 0; i < $scope.view.data.request.itineraries.length; i++) {
                var meanValues = {
                    counter: 0,
                    co2Average: 0,
                    co2Business: 0,
                    co2Economy: 0,
                    co2PremiumEconomy: 0,
                    co2First: 0
                }

                $scope.MeanMap.set($scope.view.data.request.itineraries[i].origin + $scope.view.data.request.itineraries[i].destination, meanValues);
            }
            for (var i = 0; i < $scope.resultCO2.length; i++) {
                $scope.CO2Map.set($scope.resultCO2[i].id, $scope.resultCO2[i]);
            }

            var initProductCO2 = function (product) {
                product.co2Data = {
                    co2Average: 0,
                    co2Business: 0,
                    co2Economy: 0,
                    co2PremiumEconomy: 0,
                    co2First: 0,
                    hasAllSegmentsData: true
                }
            };

            var AddCO2Values = function (product, segment) {
                if (typeof segment.co2Data === 'undefined') {
                    product.co2Data.hasAllSegmentsData = false;
                    return null;
                }
                product.co2Data.co2Average += segment.co2Data.co2Average;
                product.co2Data.co2Business += segment.co2Data.co2Business;
                product.co2Data.co2Economy += segment.co2Data.co2Economy;
                product.co2Data.co2PremiumEconomy += segment.co2Data.co2PremiumEconomy;
                product.co2Data.co2First += segment.co2Data.co2First;
                return segment.co2Data;
            };

            var SumCO2Products = function (meanReference, product) {
                if (product.co2Data.co2Average === 0)
                    return;
                var referenceValue = $scope.MeanMap.get(meanReference);
                referenceValue.co2Average += product.co2Data.co2Average;
                referenceValue.co2Business += product.co2Data.co2Business;
                referenceValue.co2Economy += product.co2Data.co2Economy;
                referenceValue.co2PremiumEconomy += product.co2Data.co2PremiumEconomy;
                referenceValue.co2First += product.co2Data.co2First;
                referenceValue.counter++;
                $scope.MeanMap.set(meanReference, referenceValue);
            }

            var ComputeCO2Mean = function (meanReference) {
                var referenceValue = $scope.MeanMap.get(meanReference);
                referenceValue.co2Average = referenceValue.co2Average / referenceValue.counter;
                referenceValue.co2Business = referenceValue.co2Business / referenceValue.counter;
                referenceValue.co2Economy = referenceValue.co2Economy / referenceValue.counter;
                referenceValue.co2PremiumEconomy = referenceValue.co2PremiumEconomy / referenceValue.counter;
                referenceValue.co2First = referenceValue.co2First / referenceValue.counter;
                $scope.MeanMap.set(meanReference, referenceValue);
            }

            var ComputeDeviation = function (meanReference, product) {
                if (product.co2Data.co2Average === 0) {
                    return;
                }
                var referenceValue = $scope.MeanMap.get(meanReference);
                product.co2Data.meanCo2Average = referenceValue.co2Average;
                product.co2Data.co2AverageDeviationRaw = product.co2Data.co2Average - referenceValue.co2Average;
                product.co2Data.co2AverageDeviation = product.co2Data.co2AverageDeviationRaw * 100 / referenceValue.co2Average;
                product.co2Data.co2BusinessDeviation = (product.co2Data.co2Business - referenceValue.co2Business) * 100 / referenceValue.co2Business;
                product.co2Data.co2EconomyDeviation = (product.co2Data.co2Economy - referenceValue.co2Economy) * 100 / referenceValue.co2Economy;
                product.co2Data.co2PremiumEconomyDeviation = (product.co2Data.co2PremiumEconomy - referenceValue.co2PremiumEconomy) * 100 / referenceValue.co2PremiumEconomy;
                product.co2Data.co2FirstDeviation = (product.co2Data.co2First - referenceValue.co2First) * 100 / referenceValue.co2First;
            }
            for (var i = 0; i < $scope.view.data.products.length; i++) {
                var itineraries = $scope.view.data.products[i].itineraries;
                for (var j = 0; j < itineraries.length; j++) {
                    var itinerariesOptions = itineraries[j].itinerariesOptions;
                    for (var k = 0; k < itinerariesOptions.length; k++) {
                        var itineraryChoice = itinerariesOptions[k];
                        var product = $scope.resultsView.itineraries[itineraryChoice.key];

                        initProductCO2(product);

                        var out_seg = product.segments;
                        for (var l = 0; l < out_seg.length; l++) {
                            var segmentId = out_seg[l].airline + out_seg[l].departure.airport + out_seg[l].arrival.airport + out_seg[l].equipment;
                            out_seg[l].co2Data = $scope.CO2Map.get(segmentId);
                            AddCO2Values(product, out_seg[l]);
                        }

                        if (product.co2Data.hasAllSegmentsData) {
                            SumCO2Products(product.origin + product.destination, product);
                        }
                    }
                }
            }

            for (var i = 0; i < $scope.view.data.request.itineraries.length; i++) {
                ComputeCO2Mean($scope.view.data.request.itineraries[i].origin + $scope.view.data.request.itineraries[i].destination);
                //$scope.MeanMap.set($scope.view.data.request.itineraries.origin + $scope.view.data.request.itineraries.destination, meanValues);
            }

            //ComputeCO2Mean(meanOutbound);
            //ComputeCO2Mean(meanInbound);

            //$scope.MeanInbound = meanInbound;
            //$scope.MeanOutbound = meanOutbound;


            for (var i = 0; i < $scope.view.data.products.length; i++) {
                var itineraries = $scope.view.data.products[i].itineraries;
                for (var j = 0; j < itineraries.length; j++) {
                    var itinerariesOptions = itineraries[j].itinerariesOptions;
                    for (var k = 0; k < itinerariesOptions.length; k++) {
                        var itineraryChoice = itinerariesOptions[k];
                        var product = $scope.resultsView.itineraries[itineraryChoice.key];
                        ComputeDeviation(product.origin + product.destination, product)
                    }
                }
            }

            $scope.isloadingco2 = false;
            $scope.$apply();
        }

        $scope.RequestCo2Data = function (results) {
            var segmentsMap = new Map();
            for (var i = 0; i < results.products.length; i++) {
                var itineraries = results.products[i].itineraries;
                for (var j = 0; j < itineraries.length; j++) {
                    var itinerariesOptions = itineraries[j].itinerariesOptions;
                    for (var k = 0; k < itinerariesOptions.length; k++) {
                        var itineraryChoice = itinerariesOptions[k];
                        var product = results.itineraries[itineraryChoice.key];
                        var out_seg = product.segments;
                        for (var l = 0; l < out_seg.length; l++) {
                            var segmentId = out_seg[l].airline + out_seg[l].departure.airport + out_seg[l].arrival.airport + out_seg[l].equipment;
                            segmentsMap.set(segmentId, true);
                        }
                    }
                }
            }

            var allSegments = Array.from(segmentsMap.keys());

            var bodyRequest = {
                segments: allSegments
            }

            $scope.isloadingco2 = true;
            $window.Typescript.CO2.Services.CO2Service.getCO2(bodyRequest).then(function (axiosResponse) {
                var responseJson = JSON.parse(axiosResponse.data);
                $scope.resultCO2 = responseJson.body;
                $scope.UpdateCo2Results();
            }, function (err) {
                console.error(err);
            });
        }

        getBundleWidget("mrfly-widget-request-recap.js").done(function () {
            console.log("Loading done");
            //set params do mapping

            var airSearchRequest = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequest']();

            airSearchRequest.adultNumber = window.site.currentAirRequest.summary.adults;
            airSearchRequest.childNumber = window.site.currentAirRequest.summary.childs;
            airSearchRequest.infantNumber = window.site.currentAirRequest.summary.infants;
            airSearchRequest.isOneWay = window.site.currentAirRequest.summary.itineraries.length == 1;
            airSearchRequest.isRoundTrip = window.site.currentAirRequest.summary.itineraries.length == 2 && window.site.currentAirRequest.summary.itineraries[0].origin.cityIATA.localeCompare(window.site.currentAirRequest.summary.itineraries[1].destination.cityIATA) === 0;
            airSearchRequest.isOpenJaw = window.site.currentAirRequest.summary.itineraries.length === 2 && window.site.currentAirRequest.summary.itineraries[0].origin.cityIATA.localeCompare(window.site.currentAirRequest.summary.itineraries[1].destination.cityIATA) !== 0;
            airSearchRequest.isMultiDestination = window.site.currentAirRequest.summary.itineraries.length > 2;
            airSearchRequest.cabinClass = window.site.currentAirRequest.summary.cabinClass;

            //Map itineraries
            airSearchRequest.itineraries = [];
            window.site.currentAirRequest.summary.itineraries.forEach(function (itinerary) {
                var ins = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequestItinerary']();

                ins.date = itinerary.goDate.date;

                ins.departureAirportIata = itinerary.origin.value;
                ins.departureCityIata = itinerary.origin.cityIATA;
                ins.departureName = itinerary.origin.cityName;

                ins.arrivalAirportIata = itinerary.destination.value;
                ins.arrivalCityIata = itinerary.destination.cityIATA;
                ins.arrivalName = itinerary.destination.cityName;

                airSearchRequest.itineraries.push(ins);
            });
            mrflyWidgets['mrfly-widget-request-recap'].airSearchRequest = airSearchRequest;
            mrflyWidgets['mrfly-widget-request-recap'].locale = $window.lng.substring(0, 2);
            mrflyWidgets['mrfly-widget-request-recap'].language = $window.lng;
            mrflyWidgets['mrfly-widget-request-recap'].resources = i18next.store.data.dev.translation;
        });

        getBundleWidget("mrfly-widget-banners.js").done(function () {
            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.productType = "Flight";
            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.enableFreeFlex = $scope.enableFreeFlex;
        });

        getBundleWidget("mrfly-widget-wait.js").done(function () {

            var airSearchRequest = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequest']();

            airSearchRequest.adultNumber = window.site.currentAirRequest.summary.adults;
            airSearchRequest.childNumber = window.site.currentAirRequest.summary.childs;
            airSearchRequest.infantNumber = window.site.currentAirRequest.summary.infants;
            airSearchRequest.isOneWay = window.site.currentAirRequest.summary.itineraries.length == 1;
            airSearchRequest.isRoundTripOrOpenJaw = window.site.currentAirRequest.summary.itineraries.length == 2;
            airSearchRequest.isMultiDestination = window.site.currentAirRequest.summary.itineraries.length > 2;
            airSearchRequest.cabinClass = window.site.currentAirRequest.summary.cabinClass;

            //Map itineraries
            airSearchRequest.itineraries = [];
            window.site.currentAirRequest.summary.itineraries.forEach(function (itinerary) {
                var ins = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequestItinerary']();

                ins.date = itinerary.goDate.date;

                ins.departureAirportIata = itinerary.origin.value;
                ins.departureCityIata = itinerary.origin.cityIATA;
                ins.departureName = itinerary.origin.cityName;

                ins.arrivalAirportIata = itinerary.destination.value;
                ins.arrivalCityIata = itinerary.destination.cityIATA;
                ins.arrivalName = itinerary.destination.cityName;

                airSearchRequest.itineraries.push(ins);
            });


            mrflyWidgets['mrfly-widget-wait'].airSearchRequest = airSearchRequest;
            mrflyWidgets['mrfly-widget-wait'].siteType = window.site.type;
            mrflyWidgets['mrfly-widget-wait'].sitePartnerId = window.site.partnerId;
            mrflyWidgets['mrfly-widget-wait'].enableFreeFlex = window.enableFreeFlex;
            mrflyWidgets['mrfly-widget-wait'].resources = i18next.store.data.dev.translation;

            if (typeof window.market !== undefined && window.market !== null) {
                mrflyWidgets['mrfly-widget-wait'].market = window.market;
            }
            else {
                mrflyWidgets['mrfly-widget-wait'].market = '';
            }
        }).fail(function (jqxhr, settings, exception) {
            console.log(exception);
        });
        $timeout(function () {
            var currentSearch = $scope.searchForm;
            var lastSearch = $window.flightHistory.loadLastSearch();
            if (lastSearch) {
                if (lastSearch.origin_value == currentSearch.origin_value)
                    currentSearch.origin = lastSearch.origin;
                if (lastSearch.destination_value == currentSearch.destination_value)
                    currentSearch.destination = lastSearch.destination;
                for (var i = 2; i <= $scope.requestSummary.itineraries.length; i++) {
                    if (lastSearch['origin' + i + '_value'] == currentSearch['origin' + i + '_value'])
                        currentSearch['origin' + i] = lastSearch['origin' + i];
                    if (lastSearch['destination' + i + '_value'] == currentSearch['destination' + i + '_value'])
                        currentSearch['destination' + i] = lastSearch['destination' + i];
                }
            }
            $window.flightHistory.bindView(currentSearch);
            //$window.flightHistory.saveSearch(currentSearch);
        }, 100);

        $scope.requestSummary.listOrigins = $scope.requestSummary.itineraries.select(function (x) { return x.origin.cityIATA });
        $scope.requestSummary.listDestinations = $scope.requestSummary.itineraries.select(function (x) { return x.destination.cityIATA });
        $scope.requestSummary.listCities = $scope.requestSummary.listOrigins.concat($scope.requestSummary.listDestinations);
        $scope.requestSummary.listCities = $scope.requestSummary.listCities.distinct();
        var swatch = tseUtil.StopWatch.createNew();

        //Product Visible Filter
        $scope.productVisibleFilter = function (item) {
            return item.visible;
        };

        $scope.getT = function(key){
            return getT(key)
        }

        /**
        * Get the fares results
        * @param {requestId} requestId
        */
        $scope.apiClient.getAirFaresSearchResults($scope.requestSummary.requestId).success(function (results) {
            swatch.stop();
            if (results) {
                //If any error
                if (results.messages.any(function (mi) { return mi.category == 2; })) {
                    if (results.messages.any(function (mi) { return mi.text == "NO RESPONSE FROM PROVIDER OR IS NOT AUTHORIZED."; }))
                        $window.location.href = $window.site.errorsUrls.NoResponseFromProviderOrNotAuthorized;
                    else
                        $window.location.href = $window.site.errorsUrls.GeneralError;
                }
                else {
                    //If no products
                    if (results.products && results.products.length == 0) {
                        $window.location.href = $window.site.errorsUrls.NoProducts;
                        return;
                    }
                    else {
                        $scope.rawResults = results;

                        //Prepare results object format
                        if (results.itineraries && results.products) {
                            airHelper.getViewForFligthResultsPage(results);
                            $scope.resultsView = {};
                            $scope.resultsView.products = results.products;
                            $scope.resultsView.itineraries = results.itineraries;
                            $scope.resultsView.airlines = results.airlines;
                            $scope.resultsView.airports = results.airports;
                            $scope.resultsView.cities = results.cities;
                            $scope.resultsView.equipment = results.equipment;
                            $scope.resultsView.cabinclasses = results.cabinClasses;
                        }

                        //$scope.RequestCo2Data(results);
                        var firstRender = true;
                        //Filters
                        //workaround Add the option 0 baggages in filters if not exist
                        if (!results.summary.baggages.contains(0)) {
                            results.summary.baggages.unshift(0);
                        }
                        var initialFilters = $window.objectClone(results.summary);


                        airHelper.prepareSummaryMultiDestination(initialFilters);
                        for (var it = 0; it < $scope.requestSummary.itineraries.length; it++) {
                            if ($scope.requestSummary.itineraries[it].origin.airportIATA && ($scope.requestSummary.itineraries[it].origin.airportIATA != $scope.requestSummary.itineraries[it].origin.cityIATA)) {
                                if (initialFilters.itinerariesSummary[it] != null && initialFilters.itinerariesSummary[it].departure != null) {
                                    var outVal = initialFilters.itinerariesSummary[it].departure.contains($scope.requestSummary.itineraries[it].origin.airportIATA);
                                    if (outVal)
                                        initialFilters.itinerariesSummary[it].departure = [$scope.requestSummary.itineraries[it].origin.airportIATA];
                                }
                                if (initialFilters.itinerariesSummary[it] != null && initialFilters.itinerariesSummary[it].arrival != null) {
                                    var inVal = initialFilters.itinerariesSummary[it].arrival.contains($scope.requestSummary.itineraries[it].origin.airportIATA);
                                    if (inVal)
                                        initialFilters.itinerariesSummary[it].arrival = [$scope.requestSummary.origin.airportIATA];
                                }
                            }

                            if ($scope.requestSummary.itineraries[it].destination.airportIATA && ($scope.requestSummary.itineraries[it].destination.airportIATA != $scope.requestSummary.itineraries[it].destination.cityIATA)) {
                                if (initialFilters.itinerariesSummary[it] != null && initialFilters.itinerariesSummary[it].arrival) {
                                    var outVal = initialFilters.itinerariesSummary[it].arrival.contains($scope.requestSummary.itineraries[it].destination.airportIATA);
                                    if (outVal)
                                        initialFilters.itinerariesSummary[it].arrival = [$scope.requestSummary.itineraries[it].destination.airportIATA];
                                }
                                if (initialFilters.itinerariesSummary[it] != null && initialFilters.itinerariesSummary[it].departure) {
                                    var inVal = initialFilters.itinerariesSummary[it].departure.contains($scope.requestSummary.itineraries[it].destination.airportIATA);
                                    if (inVal)
                                        initialFilters.itinerariesSummary[it].departure = [$scope.requestSummary.itineraries[it].destination.airportIATA];
                                }
                            }
                        }

                        $scope.loadContext();
                        var initialOrder = ordersBy[0];
                        if ($scope.currentContext && $scope.currentContext.appliedFiltersValue)
                            initialFilters = $scope.currentContext.appliedFiltersValue;
                        if ($scope.currentContext && $scope.currentContext.appliedOrderBy)
                            initialOrder = ordersBy[$scope.currentContext.appliedOrderBy];

                        $scope.airResults = airHelper.createNonPagedHelperMultiDestination(results, function (view) {

                            //****************************************************************************************************************
                            //workarround to remove the waiting div+css
                            angular.element("#waitingDiv").remove();
                            //****************************************************************************************************************
                            $scope.view = view;

                            //***********************************************************************************************************************
                            //TO Filter 1==TO               
                            $scope.displayFareTypeFilter = $scope.enableOption && site.type == 2 && $scope.view.data.products.any(function (item) { return item.to });

                            //Get all cabin classes
                            $scope.displayAlertCabinClass = false;
                            $scope.allCabinClassesInbound = $scope.view.data.products.select(function (prod) {
                                if (prod.inbounds == null) return [];
                                return prod.inbounds.select(function (inb) {
                                    return inb.segments.select(function (seg) { return seg.cabinClass });
                                });
                            }).toSimpleArray().distinct();

                            //$scope.allCabinClassesOutbound = $scope.view.data.products.select(function (prod) {
                            //    return prod.outbound.segments.select(function (seg) {
                            //        return seg.cabinClass
                            //    });
                            //}).toSimpleArray().distinct();

                            $scope.allCabinClasses = $scope.allCabinClassesInbound.concat($scope.allCabinClassesOutbound).toSimpleArray().distinct();

                            if ($scope.view.data.request.cabinClass != 'Y') {
                                $.each($scope.allCabinClasses, function (index, value) {
                                    if (value != $scope.view.data.request.cabinClass) {
                                        if (!$scope.displayAlertCabinClass) {
                                            if ($scope.view.data.request.cabinClass == "C" && value == "J")
                                                $scope.displayAlertCabinClass = false;
                                            else if ($scope.view.data.request.cabinClass == "F" && value == "P")
                                                $scope.displayAlertCabinClass = false;
                                            else
                                                $scope.displayAlertCabinClass = true;
                                        }
                                    }
                                });
                            }
                            //end get all cabin classes
                            //**************************************************************************************************************************

                            $scope.UpdateCo2Results();
                            view.data._lateProducts = [];

                            var loadPbyP = function (pIdx) {
                                if (view.data.products.length <= 10) {
                                    view.data._lateProducts = view.data.products;
                                    $window.sendInspectlet('virtualPage');
                                    $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Result'));
                                    //Add InspecLet Cabin Class and Flight Type tag
                                    $window.sendInspectlet('tagSession', { CabinClass: $scope.searchForm.cabin });
                                    if ($scope.requestSummary.oneWay)
                                        $window.sendInspectlet('tagSession', { SearchType: "Go Flight" });
                                    if ($scope.requestSummary.openJaw)
                                        $window.sendInspectlet('tagSession', { SearchType: "Multi Flight" });
                                    if ($scope.requestSummary.roundTrip)
                                        $window.sendInspectlet('tagSession', { SearchType: "Return Flight" });
                                    if ($scope.requestSummary.childs > 0)
                                        $window.sendInspectlet('tagSession', { Childs: true });
                                    if ($scope.requestSummary.infants > 0)
                                        $window.sendInspectlet('tagSession', { Infants: true });
                                }
                                else {
                                    $timeout(function () {
                                        if (view.data.products.length > pIdx) {
                                            view.data._lateProducts.push(view.data.products[pIdx]);
                                            loadPbyP(++pIdx);
                                        }
                                        else {
                                            //Add InspecLet Cabin Class and Flight Type tag
                                            $window.sendInspectlet('virtualPage');
                                            $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Result'));
                                            $window.sendInspectlet('tagSession', { CabinClass: $scope.searchForm.cabin });
                                            if ($scope.requestSummary.oneWay)
                                                $window.sendInspectlet('tagSession', { SearchType: "Go Flight" });
                                            if ($scope.requestSummary.openJaw)
                                                $window.sendInspectlet('tagSession', { SearchType: "Multi Flight" });
                                            if ($scope.requestSummary.roundTrip)
                                                $window.sendInspectlet('tagSession', { SearchType: "Return Flight" });
                                            if ($scope.requestSummary.childs > 0)
                                                $window.sendInspectlet('tagSession', { Childs: true });
                                            if ($scope.requestSummary.infants > 0)
                                                $window.sendInspectlet('tagSession', { Infants: true });
                                        }
                                    });
                                }
                            };
                            loadPbyP(0);

                            //Refresh SearchBox
                            $window.FSelectMPG_Refresh();

                            FFlightsUpdated({
                                vFlightsCount: view.productsCount,
                                callback: function () {
                                    $scope.applyContextForScroll();
                                },
                                itemRender: function (item) { $scope.applyContextOpenProduct(item); }
                            }); // NICO ADDED 15-04-08

                            if (!firstRender)
                                $scope.saveContext(true);


                            //Calculate warning messages for cheaper products
                            if (view.minPriceProduct) {
                                var minPriceProductOutbound = view.minPriceProduct;
                                //$scope.minPriceOnOutboundDeparture = !view.appliedFiltersValue.outbound.departure.contains(minPriceProductOutbound.departure);
                                //$scope.minPriceOnOutboundArrival = !view.appliedFiltersValue.outbound.arrival.contains(minPriceProductOutbound.arrival);
                                //$scope.minPriceAirportIATA = ($scope.minPriceOnOutboundDeparture) ? minPriceProductOutbound.departure : minPriceProductOutbound.arrival;
                                //$scope.minPriceOnOutbound = $scope.minPriceOnOutboundDeparture || $scope.minPriceOnOutboundArrival;
                            }

                            if (firstRender) {
                                //Calculate if Airport IATAS requested has results.
                                //$scope.containsOriginIATA = $scope.view.data.products.any(function (p) {
                                //    return $scope.requestSummary.origin.airportIATA ? p.outbound.departure == $scope.requestSummary.origin.airportIATA : true;
                                //});
                                //$scope.containsDestinationIATA = $scope.view.data.products.any(function (p) {
                                //    return $scope.requestSummary.destination.airportIATA ? p.outbound.arrival == $scope.requestSummary.destination.airportIATA : true;
                                //});
                                //$scope.containsOrigin2IATA = true;
                                //if ($scope.requestSummary.origin2) {
                                //    $scope.containsOrigin2IATA = $scope.view.data.products.any(function (p) {
                                //        return $scope.requestSummary.origin2.airportIATA ? p.inbounds.any(function (i) { return i.departure == $scope.requestSummary.origin2.airportIATA; }) : true;
                                //    });
                                //}
                                //$scope.containsDestination2IATA = true;
                                //if ($scope.requestSummary.destination2) {
                                //    $scope.containsDestination2IATA = $scope.view.data.products.any(function (p) {
                                //        return $scope.requestSummary.destination2.airportIATA ? p.inbounds.any(function (i) { return i.arrival == $scope.requestSummary.destination2.airportIATA; }) : true;
                                //    });
                                //}
                                //$scope.containsIATAS = $scope.containsOriginIATA && $scope.containsDestinationIATA && $scope.containsOrigin2IATA && $scope.containsDestination2IATA;

                                $timeout(function () {
                                    FPopNoFee(); //Or Call The Function
                                    $scope.restoreViewFromContext();
                                }, 25);

                            }
                            $timeout(function () {
                                $scope.$broadcast("onFilterApplied");
                            }, 500);
                            firstRender = false;
                            //Tracking
                            try {
                                //GTM
                                if (trackingService.isActivated()) {
                                    trackingService.initDataLayer('detail');
                                    trackingService.addProduct(gtmProduct.parseRequest($scope.requestSummary).applyFilter(view));
                                    trackingService.parseScope($scope);
                                    trackingService.pushDataLayer('list', 'Search');

                                    if (trackingService.pageViewEvent) {
                                        trackingService.pageViewEvent("search_result_page", "airresults");
                                    }

                                    if (trackingService.interactionEvent) {
                                        trackingService.interactionEvent("view_search_results", null, null);
                                    }
                                }

                                if (window.site.partnerId == 'cdiscountvol') {
                                    console.log("avant  le track Air Product body multi destination. js  ===");
                                    var endDate = $scope.requestSummary.oneWay ? '' : moment($scope.requestSummary.itineraries.last().goDate.date).format("YYYY-MM-DD");
                                    console.log("END DATE IS = " + endDate);
                                    var cabinClassName = function (item) {
                                        switch (item) {
                                            case "Y":
                                                return "economy";
                                            case "S":
                                                return "premium";
                                            case "C":
                                                return "business";
                                            case "F":
                                                return "first";
                                            default:
                                                return "economy";
                                        }
                                    };
                                    fbq('track', 'Search',
                                        {
                                            type_service: 'VOYAGES',
                                            content_category: 'VOL',
                                            content_type: 'flight',
                                            content_ids: $scope.requestSummary.origin.cityIATA + '-' + $scope.requestSummary.destination.cityIATA,
                                            origin_airport: $scope.requestSummary.origin.cityIATA,
                                            destination_airport: $scope.requestSummary.destination.cityIATA,
                                            departing_departure_date: moment($scope.requestSummary.goDate.date).format("YYYY-MM-DD"),
                                            returning_departure_date: endDate,
                                            num_adults: $scope.requestSummary.adults > 0 ? $scope.requestSummary.adults : 0,
                                            num_children: $scope.requestSummary.childs > 0 ? $scope.requestSummary.adults : 0,
                                            num_infants: $scope.requestSummary.infants > 0 ? $scope.requestSummary.adults : 0,
                                            travel_class: cabinClassName($scope.requestSummary.cabinClass),
                                            price: $scope.view.minPriceProductVisible.totalFare.value
                                        });
                                    console.log("appres le track  Air Product body multidestination . js ===");
                                }
                            }
                            catch (ex) {
                                console.log(ex.message);
                                $window.trackingError = ex.message;
                            }

                        }, initialFilters, null, initialOrder);

                        //Remove waiting page
                        angular.element("#waitingDiv").remove();

                        //Victor workaround
                        setTimeout(function () {
                            if (document.getElementsByClassName("c-flight-multi").length > 0) {
                                aFlights = document.getElementsByClassName("c-segments");
                                segmentWidth = aFlights[0].getElementsByClassName("c-segment")[0].clientWidth;

                                if (aFlights[0].clientWidth < aFlights[0].getElementsByClassName("c-segment").length * segmentWidth && window.innerWidth > 979) {
                                    for (i = 0; i < aFlights.length; i++) {
                                        aFlights[i].classList.add("scroll");
                                    }
                                }
                            }
                        }, 300);

                    }
                }
            }
        })
            .error(function (data, code) {
                if (data && data.Type == "Token") return;

                $window.lastError = $window.objectToArray(arguments);
                tseUtil.Storage.set("lastError", $window.lastError);
                if (code == 410) {
                    $window.lastMessage = "Results Timeout!, we have to do another search!";
                    tseUtil.Storage.set("lastMessage", $window.lastMessage);
                    tseUtil.Debug.log($window.lastMessage);
                    $window.redoSearch();
                } else if (code == 404) {
                    $window.lastMessage = "There is not Results for this search!, first we need to create a search.";
                    tseUtil.Storage.set("lastMessage", $window.lastMessage);
                    tseUtil.Debug.log($window.lastMessage);
                    $window.redoSearch();
                }
                else if (code == 400) {
                    $window.lastMessage = "Ups! Bad Request???";
                    tseUtil.Storage.set("lastMessage", $window.lastMessage);
                    tseUtil.Debug.log($window.lastMessage);
                    $window.redoSearch();
                }
                else if (code == 0) {
                    $window.lastMessage = "Empty Response (Fortinet error)";
                    tseUtil.Storage.set("lastMessage", $window.lastMessage);
                    tseUtil.Debug.log($window.lastMessage);
                    $window.redoSearch();
                }
                else if (code == 501) {
                    $window.lastMessage = "The response never came to the restapi";
                    tseUtil.Storage.set("lastMessage", $window.lastMessage);
                    $window.location.href = $window.site.errorsUrls.NoData;
                }
                else {
                    $window.lastMessage = "Error Code: " + code;
                    tseUtil.Storage.set("lastMessage", $window.lastMessage);
                    $window.location.href = $window.site.errorsUrls.NoData;
                }
            });

        $scope.currentContext = null;
        $scope.ensureContext = function (force) {
            if ($scope.currentContext == null || force) {
                tseUtil.Debug.log("Creating new Context Object");
                $scope.currentContext = {
                    scrollX: $window.scrollX,
                    scrollY: $window.scrollY,
                    openedProducts: Array.prototype.map.call(document.getElementsByClassName("c-flight-on-back"), function (elm) { return elm.getAttribute("name"); }),
                    appliedFiltersValue: $scope.view.appliedFiltersValue,
                    appliedFilters: $scope.view.appliedFilters,
                    anyAppliedFilters: $scope.view.anyAppliedFilters,
                    appliedOrderBy: $("#i-sort-pop .c-on").index()
                };
            }
        };

        var applyContextOpenProductAlreadyScroll = false;

        //Apply Context Open Product
        $scope.applyContextOpenProduct = function (product) {
            $scope.$apply(function () {
                if ($scope.currentContext != null && product != null) {
                    var resultsContext = $scope.currentContext;
                    var productIndex = product.getAttribute("data-index");
                    var productName = product.getAttribute("name");
                    if (resultsContext.openedProducts.any(function (item) { return item == productName })) {
                        var selectedProduct = $scope.view.data.products[productIndex];
                        if (selectedProduct != null) {
                            selectedProduct._lateItineraries = selectedProduct.itineraries;
                            selectedProduct.showRetour = true;
                            $timeout(function () {
                                $window.FFBack(productIndex);
                                //Remodal workaround
                                $(document).find(".remodal").each(function (a, c) {
                                    var d = $(c)
                                        , e = d.data("remodal-options");
                                    e ? ("string" == typeof e || e instanceof String) && (e = parseOptions(e)) : e = {},
                                        d["remodal"](e)
                                });
                            }, 100, false);
                        }
                    }
                    if (product.offsetTop >= resultsContext.scrollY && !applyContextOpenProductAlreadyScroll) {
                        $window.scrollTo(resultsContext.scrollX, resultsContext.scrollY);
                        applyContextOpenProductAlreadyScroll = true;
                    }
                    //else if (!applyContextOpenProductAlreadyScroll) {
                    //    $window.scrollTo(0, product.offsetTop);
                    //}
                }
            });
        };

        //Apply Context For Scroll
        $scope.applyContextForScroll = function () {
            $scope.$apply(function () {
                if ($scope.currentContext != null && !applyContextOpenProductAlreadyScroll) {
                    var resultsContext = $scope.currentContext;

                    //Scroll down to the previous position
                    $window.scrollTo(resultsContext.scrollX, resultsContext.scrollY);
                }
            });
        };

        function parseOptions(str) {
            var obj = {};
            var arr;
            var len;
            var val;
            var i;
            // Remove spaces before and after delimiters
            str = str.replace(/\s*:\s*/g, ':').replace(/\s*,\s*/g, ',');
            // Parse a string
            arr = str.split(',');
            for (i = 0, len = arr.length; i < len; i++) {
                arr[i] = arr[i].split(':');
                val = arr[i][1];

                // Convert a string value if it is like a boolean
                if (typeof val === 'string' || val instanceof String) {
                    val = val === 'true' || (val === 'false' ? false : val);
                }

                // Convert a string value if it is like a number
                if (typeof val === 'string' || val instanceof String) {
                    val = !isNaN(val) ? +val : val;
                }

                obj[arr[i][0]] = val;
            }
            return obj;
        }

        //LoadContext
        $scope.loadContext = function () {
            $scope.currentContext = tseUtil.Session.get("resultContext_" + $scope.requestSummary.requestId);
        };

        //Restore View From Context
        $scope.restoreViewFromContext = function () {
            if ($scope.currentContext != null) {
                tseUtil.Debug.log("Restoring Filters and Sort Items.");
                //Now we update the filters view
                var status = $scope.view.appliedFilters;
                var filters = $scope.currentContext.appliedFiltersValue;

                if (filters.averageFareFeeMargin == null)
                    return;

                //Price Filters
                $("#i-slider-filter-price [name='my-slider-e']").val(Math.ceil(filters.averageFareFeeMargin.to.value));

                //Stops Filters
                $("#i-filter-stops-pop li").removeClass("c-on");
                if (status.stops) {
                    var maxStops = filters.stops.max();
                    $("[name='i-filter-stops']").val(maxStops);
                    var stopsText = $("#i-filter-stops-pop li[data-value=" + maxStops + "]").addClass("c-on").text();
                    $("#i-filter-stops-text").text(stopsText);
                }
                else {
                    $("[name='i-filter-stops']").val(-1);
                    $("#i-filter-stops-text").text(getT('resultpage.stopovers'));
                    $("#i-filter-stops-pop li").first().addClass("c-on");
                }


                //Baggages
                $("#baggagesFilter [type='checkbox']").attr("Checked", false);
                if (!filters.baggages.contains(0)) {
                    filters.baggages.each(function (bag) {
                        $("#baggagesFilter [type='checkbox'][data-value=" + bag + "]").attr("Checked", true);
                    });
                }

                //Update the sort view
                if (typeof $scope.currentContext.appliedOrderBy != 'undefined')
                    $("#i-sort-text").text($($("#i-sort-pop li").removeClass("c-on")[$scope.currentContext.appliedOrderBy]).addClass("c-on").data("text"));

                //TO Fares
                $("#productsTypesFilter [type='checkbox']").attr("Checked", false);
                $("#productsTypesFilter [type='checkbox']").each(function () {
                    if (filters.productTypes.contains($(this).data("value")))
                        $(this).attr("Checked", true);
                });
            };
        };

        //Pops
        $scope.popTimeFilter = function () {
            //Send inspectlet tag of use horaires filter
            $window.sendInspectlet('tagSession', { FilterHoraires: true });
            var appliedFiltersValue = $scope.view.appliedFiltersValue;
            var initialFiltersValue = $scope.view.initialFiltersValue;
            for (var i = 1; i <= $scope.view.initialFiltersValue.itinerariesSummary.length; i++) {
                //Time Go Slider
                $("#i-slider-filter-time-" + i).empty();
                var item = appliedFiltersValue.itinerariesSummary[i - 1];
                var itemInitial = initialFiltersValue.itinerariesSummary[i - 1];

                if (item != null && item.departureTime != null) {
                    var opts = {
                        iSlider: 'i-slider-filter-time-' + i,
                        vDual: true,
                        vDefault: Math.floor(item.departureTime.from.totalHours),
                        vDefaultDual: Math.ceil(item.departureTime.to.totalHours),
                        vMin: 0,
                        vMax: 24,
                        vMinDrag: Math.floor(itemInitial.departureTime.from.totalHours), // NICO 15-04-08
                        vMaxDrag: Math.ceil(itemInitial.departureTime.to.totalHours), // NICO 15-04-08
                        vMinShow: false,
                        vMaxShow: false,
                        vText: '{VAL}h',
                        vInputName: 'my-slider-a',
                        vInputDualName: 'my-slider-b',
                        onSlideCallBack: $scope.updateFilters
                    }
                    XLRangeSlider(opts);
                }
            }
        };

        $scope.popPriceFilter = function () {
            $("#i-slider-filter-price").empty();
            //Send inspectlet tag of use price filter
            $window.sendInspectlet('tagSession', { FilterPrice: true });
            var appliedFiltersValue = $scope.view.appliedFiltersValue;
            var initialFiltersValue = $scope.view.initialFiltersValue;
            var initialPrice = initialFiltersValue.averageFareFeeMargin;
            var currentPrice = appliedFiltersValue.averageFareFeeMargin;
            if (currentPrice != null) {
                var opts = {
                    iSlider: 'i-slider-filter-price',
                    vDefault: Math.ceil(currentPrice.to.value),
                    vMin: Math.ceil(initialPrice.from.value),
                    vMax: Math.ceil(initialPrice.to.value),
                    vMinShow: false,
                    vMaxShow: false,
                    vText: 'Moins de {VAL}€',
                    vInputName: 'my-slider-e',
                    onSlideCallBack: $scope.updateFilters
                };
                XLRangeSlider(opts);
            }
        };

        /**
        *Update Filters: Time,  Price, Stops, Airports, Airlines, Baggages, TO
        */
        $scope.updateFilters = function () {
            $scope.$apply(function () {
                tseUtil.Debug.log("Updating filters...");
                var initialFilters = $scope.view.initialFiltersValue;
                var oFilters = $window.objectClone($scope.view.appliedFiltersValue);
                var nFilters = $scope.view.appliedFiltersValue;
                var change = { type: [], o: [], n: [], prevCount: $scope.view.productsCount };

                for (var i = 1; i <= nFilters.itinerariesSummary.length; i++) {
                    var flightMinTime = parseInt(angular.element("#i-slider-filter-time-" + i + "  [name='my-slider-a']").val());
                    var flightMaxTime = parseInt(angular.element("#i-slider-filter-time-" + i + "  [name='my-slider-b']").val());

                    if (flightMinTime.toString() != "NaN")
                        nFilters.itinerariesSummary[i - 1].departureTime.from = airHelper.getTimeObject(flightMinTime * 60);
                    if (flightMaxTime.toString() != "NaN")
                        nFilters.itinerariesSummary[i - 1].departureTime.to = airHelper.getTimeObject(flightMaxTime * 60);

                    if (nFilters.itinerariesSummary[i - 1].departureTime.from.value != oFilters.itinerariesSummary[i - 1].departureTime.from.value) {
                        change.type.push("DepartureTimeFromVol" + i);
                        change.o.push(oFilters.itinerariesSummary[i - 1].departureTime.from.value);
                        change.n.push(nFilters.itinerariesSummary[i - 1].departureTime.from.value);
                    }
                    if (nFilters.itinerariesSummary[i - 1].departureTime.to.value != oFilters.itinerariesSummary[i - 1].departureTime.to.value) {
                        change.type.push("DepartureTimeToVol" + 1);
                        change.o.push(oFilters.itinerariesSummary[i - 1].departureTime.to.value);
                        change.n.push(nFilters.itinerariesSummary[i - 1].departureTime.to.value);
                    }
                }


                //Price
                var minPrice = parseInt(angular.element("#i-slider-filter-price [name='my-slider-e']").val());
                if (minPrice.toString() != "NaN") {
                    nFilters.averageFareFeeMargin.to = airHelper.getNumberObject(Math.ceil(minPrice));
                }

                if (nFilters.averageFareFeeMargin.to.value != oFilters.averageFareFeeMargin.to.value) {
                    change.type.push("MaxPrice");
                    change.o.push(oFilters.averageFareFeeMargin.to.value);
                    change.n.push(nFilters.averageFareFeeMargin.to.value);
                }

                //Stops
                var stops = parseInt(angular.element("[name='i-filter-stops']").val());
                if (stops == -1) nFilters.stops = initialFilters.stops;
                else {
                    var stp = [];
                    for (var i = 0; i <= stops; i++) stp.push(i);
                    nFilters.stops = stp;
                }

                var ostp = nFilters.stops.intersect(oFilters.stops);
                if (nFilters.stops.length != oFilters.stops.length || ostp.length != oFilters.stops.length) {
                    change.type.push("Stops");
                    change.o.push(oFilters.stops.join(","));
                    change.n.push(nFilters.stops.join(","));
                }

                //Airports
                var airports = [];
                var cities = [];
                angular.element("#airportFilter [type='checkbox']:checked").each(function () {
                    airports.push($(this).data("value"));
                    cities.push($(this).data("city"));
                });
                cities = cities.distinct();

                //if some city is missing
                if (cities.length != $scope.requestSummary.listCities.length) {
                    //let's check which one
                    for (var i = 0; i < $scope.requestSummary.listCities.length; i++) {
                        var city = $scope.requestSummary.listCities[i];
                        if (!cities.contains(city)) // is this city
                        {
                            if ($scope.view.data.airportsByCity[city].length == 1) //only when is the only proposed airport, this mimic the other site
                                airports.push($scope.view.data.airportsByCity[city][0].iata);
                        }
                    }
                }
                nFilters.airports = airports;


                //Airlines
                var airlines = [];

                //Check all or None Airlines
                if ($scope.hasToggleAllAndNoneMarketingCarriersFilters) {
                    var allAndNoneToggle = document.getElementById('i-check-allandnone-marketing-carriers');
                    airlines = $scope.view.initialFiltersValue.marketingCarriers;
                    if (allAndNoneToggle != null) {
                        if (allAndNoneToggle.checked) {
                            $scope.isAllAndNoneToggleChecked = true;
                        } else {
                            $scope.isAllAndNoneToggleChecked = false;
                        }
                    }
                    $scope.hasToggleAllAndNoneMarketingCarriersFilters = false;
                } else {
                    angular.element("#airlinesFilter [type='checkbox']:checked").each(function () { airlines.push($(this).data("value")); });
                    if (airlines.length == 0) {
                        airlines = $scope.view.initialFiltersValue.marketingCarriers;
                        $scope.isAllAndNoneToggleChecked = false;
                    }
                    else if (airlines.length == $scope.view.initialFiltersValue.marketingCarriers.length) {
                        $("#i-check-allandnone-marketing-carriers").attr("checked", true);
                        $scope.isAllAndNoneToggleChecked = true;
                    }
                    else {
                        $("#i-check-allandnone-marketing-carriers").removeAttr("checked");
                        $scope.isAllAndNoneToggleChecked = false;
                    }
                }

                nFilters.marketingCarriers = airlines;

                var iSecMkt = nFilters.marketingCarriers.intersect(oFilters.marketingCarriers);
                if (nFilters.marketingCarriers.length != oFilters.marketingCarriers.length || iSecMkt.length != oFilters.marketingCarriers.length) {
                    change.type.push("Airlines");
                    change.o.push(oFilters.marketingCarriers.join(","));
                    change.n.push(nFilters.marketingCarriers.join(","));
                }

                //Baggages
                var baggages = [];
                angular.element("#baggagesFilter [type='radio']:checked").each(function () { baggages.push($(this).data("value")); });
                if (baggages.length == 0) {
                    nFilters.baggages = initialFilters.baggages;
                }
                else {
                    nFilters.baggages = baggages;
                }

                var iSecBags = nFilters.baggages.intersect(oFilters.baggages);
                if (nFilters.baggages.length != oFilters.baggages.length || iSecBags.length != oFilters.baggages.length) {
                    change.type.push("Baggages");
                    change.o.push(oFilters.baggages.join(","));
                    change.n.push(nFilters.baggages.join(","));
                }

                ////TO
                //var productTypes = [];
                //var i=0;
                //for (i = 1 ; i <= 2  ; i++){
                //    if ($('#i-check-t'+i).is(':checked')){
                //        var elem = document.getElementById('i-check-t'+i);
                //        productTypes.push(parseInt(elem.dataset.value));
                //    }
                //}
                //if (productTypes.length == 0)
                //    nFilters.productTypes = $scope.view.initialFiltersValue.productTypes;
                //else 
                //    nFilters.productTypes = productTypes;

                $scope.airResults.applyFilters(nFilters, null, null, function (_view) {
                    setTimeout(function () {
                        $scope.$broadcast("onFilterApplied");
                    }, 500);
                });

                $scope.UpdateCo2Results();
            });
        }.getBuffered(200);

        //Update Airlines
        $scope.updateAirlines = function () {
            $scope.updateFilters();
        };

        $scope.toggleAllAndNoneMarketingCarriersFilter = function () {
            $scope.hasToggleAllAndNoneMarketingCarriersFilters = true;
            $scope.updateFilters();
        };

        //Sort stuff
        var sType = ["Price", "FlightTime", "DepartureTime", "ArrivalTime"];
        $scope.sortResults = function () {
            var sortIdx = $("#i-sort-pop .c-on").index();
            $scope.airResults.orderBy(ordersBy[sortIdx]);
        };

        var orderByPrix = function (product) { return product.averageFareFeeMargin.value; };
        var orderByDeparture = function (product) { return $scope.rawResults.itineraries[product.itineraries[0].itinerariesOptions[0].key].departureTime.value };
        var orderByArrival = function (product) { return $scope.rawResults.itineraries[product.itineraries[0].itinerariesOptions[0].key].arrivalTime.value };
        var orderByTotalTime = function (product) { return $scope.rawResults.itineraries[product.itineraries[0].itinerariesOptions[0].key].totalTime.value };
        var ordersBy = [
            [orderByPrix, orderByDeparture, orderByArrival, orderByTotalTime], //price
            [orderByTotalTime, orderByPrix, orderByDeparture, orderByArrival], //flightTime
            [orderByDeparture, orderByPrix, orderByArrival, orderByTotalTime], //departureTime
            [orderByArrival, orderByPrix, orderByTotalTime, orderByDeparture]  //arrivalTime
        ];


        //Save Context
        $scope.saveContext = function (newContext) {
            $scope.ensureContext(newContext);
            tseUtil.Debug.log("Saving Context");
            tseUtil.Session.set("resultContext_" + $scope.requestSummary.requestId, $scope.currentContext);
        }

        //Clear Filters
        $scope.clearFilters = function () {
            $("[name='i-filter-stops']").val(-1);
            $("#i-filter-stops-pop li").removeClass("c-on");
            $("#i-filter-stops-pop li").first().addClass("c-on");

            var nFilters = $scope.view.initialFiltersValue;
            angular.element("#i-filters-menu li").removeClass("c-on");
            $scope.popTimeFilter();

            var initCount = $scope.view.productsCount;
            $scope.airResults.applyFilters(nFilters, null, null, function (_view) {
                setTimeout(function () {
                    $("#i-filter-airports-pop [type='checkbox']:not(:checked)").each(function () {
                        $(this).click();
                    })
                }, 100);
            });
        };

        //Booking Event
        $scope.$on("onProductBookingClick", function (event, args) {
            $window.FLoading({ vAction: 'show' });
            $scope.bkgEvent = event;
            $scope.bkgArgs = { purchaseRequest: args.purchaseRequest };
            $scope.bkgDataKey = 'bkgData_' + $scope.requestSummary.requestId;
            $scope.saveContext(true); //To check

            //var itineraries = args.merovingioInfo.itineraries;
            //var co2Data = {};
            //for (var i = 0; i < itineraries.length; i++) {
            //    co2Data[itineraries[i].origin + itineraries[i].destination] = itineraries[i].co2Data;
            //}

            //Save information in session storage
            var bookingData = {
                key: $scope.bkgDataKey,
                value: {
                    purchaseRequest: $scope.bkgArgs.purchaseRequest,
                    request: $scope.requestSummary,
                    cabinclasses: $scope.view.data.cabinClasses
                }
            };

            //Airbus
            if ($scope.site.type === 6 && $scope.site.loginInfo != null && $scope.site.loginInfo && $scope.site.loginInfo.Matricule != null) {
                bookingData.value.purchaseRequest.ceUser = $scope.site.loginInfo.Matricule;
            }

            var submit = function () {
                //Save bookingData in Session Storage    
                if ($window.site.onSecureScheme) {
                    tseUtil.Session.set(bookingData.key, bookingData.value);
                    tseUtil.Debug.log("BookingSession created!");
                    window.location.href = $window.site.currentAirRequest.bookingUrl;
                } else {
                    $window.sslFrame.sendCmd("setSessionStorage", bookingData,
                        function (data) {
                            tseUtil.Debug.log("BookingSession created!");
                            window.location.href = $window.site.currentAirRequest.bookingUrl;
                        });
                }
            }

            submit();
        });
    }]);