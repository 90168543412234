function forbiddenNames () { }

forbiddenNames.getRules = function () { 
    return [
        'tests?'
    ]; 
};

forbiddenNames.isForbidden = function (input, siteType) {
    
    //Only check for B2B
    if (siteType !== 2) {
        return false;
    }

    var pattern = '^(';
    var values = forbiddenNames.getRules();

    for(var i = 0; i < values.length; i++) {
        pattern += values[i];
                    
        if (i < values.length - 1) {
            pattern += '|';
        }
    }
                
    pattern += ')$';

    var rg = RegExp(pattern, 'i');

    console.log(pattern);

    return rg.test(input);
};

