/*
    A service used to trigger GTM pushes
*/
webClient.factory('trackingService', ['$window', 'gtmModel', 'gtmProduct', 'fbqModel',
    function ($window, gtmModel, gtmProduct, fbqModel) {
        return {

            isActivated: function () {
                return (typeof $window.dataLayer !== 'undefined' && $window.dataLayer != null);
            },

            initDataLayer: function (name) {

                $scope.gtm = new gtmModel(name);

                var _this = this;

                //sort reuslts
                $(document).on('click', '#i-sort-pop ul li', function () {
                    console.log($(this).text());
                    _this.saveContentSquareProperty('sort_by', $(this).text());
                });

                //multi desti
                $(document).on("bookingSelected", function (e, args) { 

                    try {

                        //console.log(args);
                        
                        //MULTI
                        if (args.itineraries) {

                            var index = args.productIndex + 1;
                            var firstIti = args.itineraries[0];
                            var lastIti = args.itineraries[args.itineraries.length - 1];
                            var airlines = $window.$scope.view.data.airlines;

                            _this.saveContentSquareProperty('position_final_goflight', index);
                            _this.saveContentSquareProperty('goflight_nbstops', firstIti.stopsCount);
                            _this.saveContentSquareProperty('goflight_included_luggage_pax', firstIti.baggage.quantity);
                            _this.saveContentSquareProperty('goflight_airport_only_luggage', firstIti.noLuggageAlert ? 1 : 0);
                            _this.saveContentSquareProperty('goflight_departureTime', gtmProduct.formatTime(firstIti.departureTime));
                            _this.saveContentSquareProperty('goflight_arrivalTime', gtmProduct.formatTime(firstIti.arrivalTime));
                            _this.saveContentSquareProperty('goflight_duration', gtmProduct.formatTime(firstIti.totalTime));
                            _this.saveContentSquareProperty('goflight_marketingCarrier', airlines[firstIti.marketingCarrier].name);
                            _this.saveContentSquareProperty('price', args.product.averageFareFeeMargin.value);
                            _this.saveContentSquareProperty('round_price', Number.parseFloat(args.product.averageFareFeeMargin.value).toFixed(2));

                            _this.saveContentSquareProperty('position_final_return', 0);
                            _this.saveContentSquareProperty('price_pax_return_extra', 0);
                            _this.saveContentSquareProperty('returnflight_nbstops', lastIti.stopsCount);
                            _this.saveContentSquareProperty('returnflight_included_luggage_pax', lastIti.baggage.quantity);
                            _this.saveContentSquareProperty('returnflight_airport_only_luggage', lastIti.noLuggageAlert ? 1 : 0);
                            _this.saveContentSquareProperty('returnflight_departureTime', gtmProduct.formatTime(lastIti.departureTime));
                            _this.saveContentSquareProperty('returnflight_arrivalTime', gtmProduct.formatTime(lastIti.arrivalTime));
                            _this.saveContentSquareProperty('returnflight_duration', gtmProduct.formatTime(lastIti.totalTime));
                            _this.saveContentSquareProperty('returnflight_marketingCarrier', airlines[lastIti.marketingCarrier].name);
                        }
                        else { 

                            var index = args.index;
                            var airlines = $window.$scope.view.data.airlines;

                            _this.saveContentSquareProperty('position_final_goflight', index);
                            _this.saveContentSquareProperty('goflight_nbstops', args.product.outbound.stopsCount);
                            _this.saveContentSquareProperty('goflight_included_luggage_pax', args.product.outbound.baggage.quantity);
                            _this.saveContentSquareProperty('goflight_airport_only_luggage', args.product.noLuggageAlert ? 1 : 0);
                            _this.saveContentSquareProperty('goflight_departureTime', gtmProduct.formatTime(args.product.outbound.departureTime));
                            _this.saveContentSquareProperty('goflight_arrivalTime', gtmProduct.formatTime(args.product.outbound.arrivalTime));
                            _this.saveContentSquareProperty('goflight_duration', gtmProduct.formatTime(args.product.outbound.totalTime));
                            _this.saveContentSquareProperty('goflight_marketingCarrier', airlines[args.product.outbound.marketingCarrier].name);
                            _this.saveContentSquareProperty('price', args.product.minAverageFareFeeMargin.value);
                            _this.saveContentSquareProperty('round_price', Number.parseFloat(args.product.minAverageFareFeeMargin.value).toFixed(2));

                            if (args.inbound) {
                                var retIndex = args.retIndex;
                                _this.saveContentSquareProperty('position_final_return', retIndex);
                                _this.saveContentSquareProperty('price_pax_return_extra', args.inbound.proposal.supplementAverageFareFeeMargin.value);
                                _this.saveContentSquareProperty('price', args.inbound.proposal.averageFareFeeMargin.value);
                                _this.saveContentSquareProperty('returnflight_nbstops', args.inbound.stopsCount);
                                _this.saveContentSquareProperty('returnflight_included_luggage_pax', args.inbound.baggage.quantity);
                                _this.saveContentSquareProperty('returnflight_airport_only_luggage', args.inbound.noLuggageAlert ? 1 : 0);
                                _this.saveContentSquareProperty('returnflight_departureTime', gtmProduct.formatTime(args.inbound.departureTime));
                                _this.saveContentSquareProperty('returnflight_arrivalTime', gtmProduct.formatTime(args.inbound.arrivalTime));
                                _this.saveContentSquareProperty('returnflight_duration', gtmProduct.formatTime(args.inbound.totalTime));
                                _this.saveContentSquareProperty('returnflight_marketingCarrier', airlines[args.inbound.marketingCarrier].name);
                            }
                        }

                    }
                    catch (ex) {
                        console.log(ex.message);
                        window.trackingError = ex.message;
                    }
                });
            },

            /*
                Reload data using JSON stored in window.sessionStorage
                The action name / value is used as key 
            */
            loadSessionData: function (action, value) {
                return $scope.gtm.loadSessionData(action, value);
            },

            /*
                Use to send page views. Distinct gtm object in order not to mess with ecommerce tunnel
            */
            pushPageData: function (page, title, language, fireEvent, args) {

                if ($window.dataLayer) {

                    //needed to be resused in contat square events
                    $window.gtmPageName = title;

                    var gtm = {
                        event: 'Pageview',
                        page: page,
                        page_name: page,
                        title: title,
                        language: language
                    };

                    if (title.toLowerCase() === 'search home') {
                        this.resetContentSquareProperties();

                        //don't send 'home search' on error
                        if (/\/error\//i.test(document.location)) {
                            return;
                        }

                    }

                    if (title.toLowerCase() === 'error') {
                        var message = $('.c-warn-msg .c-npad').text().replace(/\s+/g, ' ');
                        gtm.error_code = $window.location.pathname.match(/[0-9]+/g)[0];
                        gtm.error_message = message;
                    }

                    if (title.toLowerCase() === 'wait') {
                        this.resetContentSquareProperties();

                        var product = gtmProduct.parseRequest($scope.requestSummary);
                        var r = $scope.requestSummary;

                        var firstIti = r.itineraries[0];
                        var lastIti = r.itineraries[r.itineraries.length - 1];

                        var firstOriginIATA = firstIti.origin.airportIATA !== "" ? firstIti.origin.airportIATA : firstIti.origin.cityIATA;
                        var firstDestiIATA = firstIti.destination.airportIATA !== "" ? firstIti.destination.airportIATA : firstIti.destination.cityIATA;

                        var lastOriginIATA = lastIti.origin.airportIATA !== "" ? lastIti.origin.airportIATA : lastIti.origin.cityIATA;
                        var lastDestiIATA = lastIti.destination.airportIATA !== "" ? lastIti.destination.airportIATA : lastIti.destination.cityIATA;

                        this.saveContentSquareProperty('id_request', $scope.requestSummary.requestId);

                        this.saveContentSquareProperty('name', product.name);
                        this.saveContentSquareProperty('product', product.category);

                        var variant = product.variant;

                        if (variant === 'multi') {
                            variant = 'multi desti';
                        }
                        else if (variant === 'aller') {
                            variant = 'aller simple';
                        }

                        this.saveContentSquareProperty('product_variant', variant);
                        this.saveContentSquareProperty('goflight_departure_date', product.dimension1);
                        if (product.dimension2) {
                            this.saveContentSquareProperty('returnflight_departure_date', product.dimension2);
                        }
                        else {
                            this.saveContentSquareProperty('returnflight_departure_date', '');
                        }
                        this.saveContentSquareProperty('goflight_origin_IATA', firstOriginIATA);
                        this.saveContentSquareProperty('goflight_destination_IATA', firstDestiIATA);

                        if (r.itineraries.length > 1) {
                            this.saveContentSquareProperty('returnflight_origin_IATA', lastOriginIATA);
                            this.saveContentSquareProperty('returnflight_destination_IATA', lastDestiIATA);
                        }
                        else {
                            this.saveContentSquareProperty('returnflight_origin_IATA', '');
                            this.saveContentSquareProperty('returnflight_destination_IATA', '');
                        }
                        
                        this.saveContentSquareProperty('cabin_class', product.dimension7);
                        this.saveContentSquareProperty('passengers_typologies', product.dimension8);
                        this.saveContentSquareProperty('passengers_nb', product.quantity);

                        this.saveContentSquareProperty('num_adults', r.adults);
                        this.saveContentSquareProperty('num_children', r.childs);
                        this.saveContentSquareProperty('num_infants', r.infants);
                        this.saveContentSquareProperty('num_passagers', product.quantity);
                        this.saveContentSquareProperty('cabin_class_us', gtmProduct.getClassUs(r.cabinClass));
                    }

                    if (title.toLowerCase() === 'result') {
                        if ($window.$scope.currentContext === null) {//don't reset sort filter when coming back from P1
                            //console.log('SORT BY RESET');
                            this.saveContentSquareProperty('sort_by', '');
                        }
                    }

                    if (title.toLowerCase() === 'purchase2') {

                        var insurances = $scope.bkgData.purchaseResponse.proposalsRequested.insurances;
                        var flexy = $scope.bkgData.bookingRequest.flexyFare;

                        //var annulation = insurances.any(function (i) { return i.insuranceReference === '00001' && i.propose === 0; }) && !flexy;
                        var multirisques = insurances.any(function (i) { return i.insuranceReference === '00002' && i.propose === 0; }) && !flexy;
                        var repatriement = insurances.any(function (i) { return i.insuranceReference === '00003' && i.propose === 1; }) && flexy;
                        

                        this.saveContentSquareProperty('Insurance_multirisques_displayed', multirisques > 0 ? 1 : 0);
                        this.saveContentSquareProperty('Insurance_repatriation_displayed', repatriement > 0 ? 1 : 0);
                    }

                    if (title.toLowerCase() === 'purchase3') {
                        if ($scope.bkgData.insurancesSelected) {
                            var insurancesSelected = $scope.bkgData.insurancesSelected;

                            var isAnnul = insurancesSelected.isAnnul != undefined && insurancesSelected.isAnnul.quantity > 0;
                            var isAnnulRepatriament = insurancesSelected.isAnnulRepatriament != undefined && insurancesSelected.isAnnulRepatriament.quantity > 0;
                            var isRepatriament = insurancesSelected.isRepatriament != undefined && insurancesSelected.isRepatriament.quantity > 0;

                            this.saveContentSquareProperty('Insurance_cancellation_selected', isAnnul.quantity ? 1 : 0);
                            this.saveContentSquareProperty('Insurance_multirisques_selected', isAnnulRepatriament ? 1 : 0);
                            this.saveContentSquareProperty('Insurance_repatriation_selected', isRepatriament ? 1 : 0);

                            var assAmount = 0;

                            if (isAnnul) {
                                assAmount = insurancesSelected.isAnnul.amount / insurancesSelected.isAnnul.quantity;
                            }
                            if (isAnnulRepatriament) {
                                assAmount = insurancesSelected.isAnnulRepatriament.amount / insurancesSelected.isAnnulRepatriament.quantity;
                            }
                            if (isRepatriament) {
                                assAmount = insurancesSelected.isRepatriament.amount / insurancesSelected.isRepatriament.quantity;
                            }

                            this.saveContentSquareProperty('price_pax_Insurance', assAmount);
                        }
                    }

                    if (/^payment\s/i.test(title)) {

                        this.saveContentSquareProperty('id_booking', $scope.bkgData.bookingResponse.bookingId);

                        this.saveContentSquareProperty('payment_method_code', $scope.bkgData.bookingResponse.paymentMethod.paymentMethodReference);
                        this.saveContentSquareProperty('payment_method_label', $scope.bkgData.bookingResponse.paymentMethod.paymentMethodName);


                        if ($scope.bkgData.discountInfo) {
                            this.saveContentSquareProperty('price_total_discount', $scope.bkgData.discountInfo.applicableAmount);
                        }
                        else {
                            this.saveContentSquareProperty('price_total_discount', 0);
                        }

                        this.saveContentSquareProperty('chequesvacances_selected', $scope.bkgData.bookingRequest.holidayVoucher === true ? 1 : 0);
                    }

                    if (title.toLowerCase() === 'booking warning' || title.toLowerCase() === 'accommodation warning'){
                        this.saveContentSquareProperty('error_message', $('.c-title:eq(0)').text());
                       
                        if (title.toLowerCase() === 'booking warning') {
                             $(document).trigger('booking-confirmation-error'); 
                        }

                    }

                    var csVars = this.getContentSquareProperties();
                    
                    //add contentsquare vars
                    for(var key in csVars) {
                        gtm[key] = csVars[key];
                    }

                    //add optional vars
                    if (typeof args !== 'undefined' && args != null) {
                        for(var key in args) {
                            gtm[key] = args[key];
                        }
                    }

                    var datalayer = JSON.stringify(gtm);

                    console.log(datalayer);

                    $window.dataLayer.push(gtm);

                    $(document).trigger('datalayer-pushed');                
                }
            },

            resetContentSquareProperties: function () {
                var csVars = {};
                $window.sessionStorage.setItem('csVars', JSON.stringify(csVars));
            },

            saveContentSquareProperty: function (name, value) {

                var csVars = this.getContentSquareProperties();

                csVars[name] = value;

                $window.sessionStorage.setItem('csVars', JSON.stringify(csVars));

            },

            getContentSquareProperties: function () {

                var session = $window.sessionStorage.getItem('csVars');

                var csVars = {};

                if (session) {
                     csVars = JSON.parse(session);
                }

                return csVars;
            },

            addProduct: function (product) {
                try {
                    $scope.gtm.addProduct(product);
                } catch (ex) {

                }
            },

            removeProduct: function (id) {
                try {
                    $scope.gtm.removeProduct(id);
                }
                catch (ex) {
                    //avoid log in Safari
                }
            },

            setOption: function (value) {
                $scope.gtm.setAction('option', value);
            },

            setAction: function (name, value) {
                $scope.gtm.setAction(name, value);
            },

            parseScope: function (value) {

                if ($scope.view) {

                    var p = new gtmProduct();
                    p.applyFilter($scope.view);
                    console.log('product');
                    console.log(p);

                    if (p.dimension9 === 'Filtered') {
                        this.saveContentSquareProperty('filters_used', p.dimension9);
                    }
                    else {
                        this.saveContentSquareProperty('filters_used', '');
                    }

                    if (p.dimension10) {
                        this.saveContentSquareProperty('filter_nbstops', p.dimension10);
                    }
                    else {
                        this.saveContentSquareProperty('filter_nbstops', '');
                    }

                    if (p.dimension11) {
                        this.saveContentSquareProperty('filter_airports', p.dimension11);
                    }
                    else {
                        this.saveContentSquareProperty('filter_airports', '');
                    }

                    if (p.dimension12) {
                        this.saveContentSquareProperty('filter_included-luggage', p.dimension12);
                    }
                    else {
                        this.saveContentSquareProperty('filter_included-luggage', '');
                    }

                    if (p.dimension13) {
                        this.saveContentSquareProperty('filter_airlines', p.dimension13);
                    }
                    else {
                        this.saveContentSquareProperty('filter_airlines', '');
                    }

                    //this.saveContentSquareProperty('sort_by', $("#i-sort-pop li.c-on").text());
                    
                    var filterPrice = $scope.view.appliedFilters.averageFareFeeMargin == true || $scope.view.appliedFilters.totalPrice == true;
                    var filterTime = $scope.view.appliedFilters.departureTime == true || $scope.view.appliedFilters.arrivalTime == true;
                    
                    this.saveContentSquareProperty('filter_price', filterPrice ? 1 : '');
                    this.saveContentSquareProperty('filter_schedule', filterTime ? 1 : '');
                    this.saveContentSquareProperty('nb_product_initial', $scope.view.totalProductsCount);
                    this.saveContentSquareProperty('nb_product_final', $scope.view.productsCount);

                    if ($scope.view.productsCount === 0) {
                        $(document).trigger('no-result-filter-error');
                    }

                    //currentContext.appliedOrderBy
                }

            },

            /*
                Serializes the GTM model and pushes the datalayer
            */
            pushDataLayer: function (action, value) {
                try {
                    if ($window.dataLayer) {

                        var overrideAction = action != null && value != null;

                        if (overrideAction) {
                            $scope.gtm.setAction(action, value);
                        }

                        if ($scope.gtm.ecommerce && $scope.gtm.ecommerce.purchase && $scope.gtm.ecommerce.purchase.actionField) {

                            var af = $scope.gtm.ecommerce.purchase.actionField;

                            window._uxa = window._uxa || [];
                            window._uxa.push(['ecommerce:addTransaction', {
                                'id': af.id,               // obligatoire  -  ID de transaction
                                'revenue': af.revenue,   // obligatoire  -  Montant total de la transaction
                                'shipping': '',       // optionnel    -  Montant de la livraison
                                'tax': ''            // optionnel    -  Montant des taxes
                            }]);
                            window._uxa.push(['ecommerce:send']);

                        }

                        if ($window.GtmPageEvent) { //if dtata has been stored, add them to datalayer before sending it
                            $scope.gtm['event'] = $window.GtmPageEvent.event;
                            $scope.gtm['page'] = $window.GtmPageEvent.page;
                            $scope.gtm['title'] = $window.GtmPageEvent.title;
                            $scope.gtm['language'] = $window.GtmPageEvent.language;
                        }

                        //remove properties prefixed by _
                        var datalayer = JSON.stringify($scope.gtm).replace(/("_[^"]+":"[^"]+",?)/, "");

                        console.log(datalayer);

                        if (overrideAction) {
                            $window.sessionStorage.setItem('gtmModel-' + action + '-' + value, JSON.stringify($scope.gtm));
                        }
                        $window.dataLayer.push(JSON.parse(datalayer));
                    }

                    if (typeof fbq !== 'undefined' && fbq != null) {

                        //search results
                        if (action == 'list' && value == 'Search') {

                            fbqModel.trackSearchResults();

                        }
                        //airbooking step 1
                        if (action == 'step' && value == 1) {
                            fbqModel.trackAibooking();
                        }
                        //payment confirmation
                        if (action == null && value == null) {
                            fbqModel.trackPaymentConfirmation();
                        }
                    }

                } catch (ex) {

                }
                // depends on <Item Type="Inline" Id="googleTagManager"> in xml file: if empty, no datalayer is declared and nothing will be pushed
            }
        };
    }]);
