/*
    A model used to embed GTM product, inside datalayer.
    It also deals with NG scope object parsing 
*/
webClient.factory('gtmProduct', function () {

    function gtmProduct() { }

    /* 
        Replace enum values by names: F => Première, C => Affaires, S => Economique Premium, Y => Economique
    */
    function getClass(value) {

        var classe = 'Economique';
        switch (value) {
            case 'F': classe = 'Première'; break;
            case 'C': classe = 'Affaires'; break;
            case 'S': classe = 'Economique Premium'; break;
            case 'Y': classe = 'Economique'; break;
        }
        return classe;
    }


    gtmProduct.getClassUs = function (value) {

        var classe = 'economy';
        switch (value) {
            case 'F': classe = 'first'; break;
            case 'C': classe = 'business'; break;
            case 'S': classe = 'premium'; break;
            case 'Y': classe = 'economy'; break;
        }
        return classe;
    }

    /*
        Builds a list of passengers such as 1 Adult, 2 Enfant, 1 Bébé
    */
    function getPassengers(r) {

        var passengers = '';
        if (r.adults > 0) {
            passengers += r.adults + " Adulte, ";
        }
        if (r.childs > 0) {
            passengers += r.childs + " Enfant, ";
        }
        if (r.infants > 0) {
            passengers += r.infants + " Bébé";
        }
        return passengers.replace(/,\s$/, "");
    }

    function getPassengersAmount(r) {

        var passengers = 0;
        if (r.adults > 0) {
            passengers += r.adults;
        }
        if (r.childs > 0) {
            passengers += r.childs;
        }
        if (r.infants > 0) {
            passengers += r.infants;
        }
        return passengers;
    }

    /*
       Builds a list of airports with their IATA and name
    */
    function getAirports(inbound, airports, isMulti) {

        var filter = '';

        if (isMulti) {
            angular.forEach(inbound, function (value, key) {
                filter += value + " - " + airports[value].name + ", ";
            });
        }
        else {
            angular.forEach(inbound.arrival, function (value, key) {
                filter += value + " - " + airports[value].name + ", ";
            });

            angular.forEach(inbound.departure, function (value, key) {
                filter += value + " - " + airports[value].name + ", ";
            });
        }

        return filter.replace(/,\s$/, "");
    }

    function getAirlines(inbound, airlines) {

        var filter = '';

        angular.forEach(inbound, function (value, key) {
            filter += value + " - " + airlines[value].name + ", ";
        });

        return filter.replace(/,\s$/, "");
    }

    function getBaggages(inbound) {

        var filter = '';

        angular.forEach(inbound, function (value, key) {
            if (value == 1) {
                filter += value + " Bagage inclus dans le tarif, ";
            }
            else {
                filter += value + " Bagages inclus dans le tarif, ";
            }
        });

        return filter.replace(/,\s$/, "");
    }

    function getStops(inbound) {

        var max = inbound.max();

        var filter = '';

        if (max == 0) {
            filter = 'Direct';
        }
        else if (max == 1) {
            filter = max + ' escale max';
        }
        else {
            filter = max + " escales max";
        }

        return filter;
    }

    gtmProduct.prototype.set = function (name, value) {

        this[name] = value;
    }

    /*
        Stores and format a date from dd/MM/yyyy to yyyyMMdd
    */
    gtmProduct.prototype.setDate = function (name, value) {

        this[name] = value.substring(6, 10) + value.substring(3, 5) + value.substring(0, 2);
    }

    /*
        Build a product from a requestSummary
    */
    gtmProduct.parseRequest = function (r) {

        var isMulti = r.multiDestination;
        var lastIti = r.itineraries[r.itineraries.length - 1];
        var lastDate = isMulti ? lastIti.goDate : r.goDate2;
        var o = r.origin;
        var d = isMulti ? lastIti.destination : r.destination;
        var variant = isMulti ? 'multi' : r.oneWay ? 'aller' : 'aller-retour';

        var p = new gtmProduct();

        p.set('name', o.cityName + ' (' + o.cityIATA + ') - ' + d.cityName + ' (' + d.cityIATA + ')');
        p.set('id', o.value.toLowerCase() + '-' + d.value.toLowerCase());
        p.set('category', 'Flight');
        p.set('variant', variant);
        p.setDate('dimension1', r.goDate.dateString);

        if (!r.oneWay) { // no return date in one way
            p.setDate('dimension2', lastDate.dateString);
        }

        p.set('dimension3', o.airportIATA != "" ? o.airportIATA : o.cityIATA);
        p.set('dimension4', d.airportIATA != "" ? d.airportIATA : d.cityIATA);
        p.set('dimension5', o.cityIATA);
        p.set('dimension6', d.cityIATA);
        p.set('dimension7', getClass(r.cabinClass));
        p.set('dimension8', getPassengers(r));

        var nbPaxes = getPassengersAmount(r);

        p.set('quantity', nbPaxes);

        return p;
    }

    gtmProduct.parseFlexy = function (proposal) {

        var p = new gtmProduct();
        p.set('id', 'flexy');
        p.set('name', 'Billet Flexy');
        p.set('category', 'Billet Flexy');
        p.set('quantity', proposal.flexies[0].quantity);
        p.set('price', proposal.totalFlexyAmount.value / proposal.flexies[0].quantity);
        return p;
    }

    gtmProduct.parseBaggage = function (price) {

        var p = new gtmProduct();
        p.set('id', 'BAGGAGE');
        p.set('name', 'Baggage');
        p.set('category', 'Baggage');
        p.set('quantity', 1);//always 1, as explained in th PDF documentation
        p.set('price', price);
        return p;
    }

    gtmProduct.parseInsurance = function (insurance) {

        var p = new gtmProduct();
        p.set('id', 'Insurance11');
        p.set('name', 'Insurance');
        p.set('category', 'Insurance');

        var ins = null

        if (insurance.isAnnul.quantity) {
            ins = insurance.isAnnul;
        }
        else if (insurance.isAnnulRepatriament.quantity) {
            ins = insurance.isAnnulRepatriament;
        }
        else if (insurance.isRepatriament.quantity) {
            ins = insurance.isRepatriament;
        }

        if (ins !== null) {
            p.set('quantity', ins.quantity);
            p.set('price', ins.amount / ins.quantity);
        }
        else {
            p.set('quantity', 0);
            p.set('price', 0);
        }        
        return p;
    };

    
    gtmProduct.formatTime = function(dateTime) {
        if (dateTime.HH) {
            return dateTime.HH + 'h' + dateTime.mm;
        }
        else {
            return dateTime.hh + 'h' + dateTime.mm;
        }
    };


    /*
        Adds airports sets in the filter to datalayer
    */
    gtmProduct.prototype.applyFilter = function (view) {

        var filter = view.appliedFilters;

        var isMulti = view.appliedFiltersValue.inbound == null;

        var inbound = isMulti ? view.appliedFiltersValue.airports : view.appliedFiltersValue.inbound;
        var marketingCarriers = view.appliedFiltersValue.marketingCarriers;

        var airports = view.data.airports;
        var airlines = view.data.airlines;

        var hasFilter = view.appliedFilters.stops == true || filter.departureIATA == true || filter.arrivalIATA == true || view.appliedFilters.baggages == true || view.appliedFilters.marketingCarrier == true;
        this.set('dimension9', hasFilter ? 'Filtered' : 'Original');

        if (view.appliedFilters.stops == true) {
            var filterStops = getStops(view.appliedFiltersValue.stops);
            this.set('dimension10', filterStops);
        }
        if (filter.departureIATA == true || filter.arrivalIATA == true) {
            var filterAiprorts = getAirports(inbound, airports, isMulti);
            this.set('dimension11', filterAiprorts);
        }
        if (view.appliedFilters.baggages == true) {
            var filterBaggages = getBaggages(view.appliedFiltersValue.baggages);
            this.set('dimension12', filterBaggages);
        }
        if (view.appliedFilters.marketingCarrier == true) {
            var filterAirlines = getAirlines(marketingCarriers, airlines);
            this.set('dimension13', filterAirlines);
        }


        return this;
    };

    gtmProduct.prototype.applyPrice = function (price) {
        var p = price.value / this.quantity
        this.set('price', p);
        return this;
    };

    return gtmProduct;

});