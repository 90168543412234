webClient.controller('AirPaymentResponseAccommodationWarning', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper',
    function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper, bookingRequest, insurancesSelected) {
        $scope.getText = function (key) { return window.culture.texts[key]; };
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        $scope.bkDataMock = tseUtil.Session.get('bkDataMock');
        var searchUrl = "/";
        var bkgData;
        if (!$scope.bkDataMock) {
            searchUrl = $window.site.currentAirRequest.searchUrl + "?" + tseUtil.Location.getQueryString();
            bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);
            var keyGenerated = "";
            keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;
            bkgData.bookingRequest = tseUtil.Session.get("bookingRequest_" + keyGenerated);
            var _insuranceSelected = tseUtil.Session.get("insurancesSelected_" + keyGenerated);
            bkgData.banqueCasinoSchedule = tseUtil.Session.get("banqueCasino" + keyGenerated);
        }
        else {
            bkgData = $scope.bkDataMock.value;
        }


        $scope.bkgData = bkgData;
        $window.$scope = $scope;
        window.displayBrandedFaresSummary = true;
        $scope.enableUpSellProducts = $window.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true';
        $scope.enableUpSellProducts = $scope.bkgData.accommodations ?.any() || $scope.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';
        if ($scope.bkgData.accommodations?.any()) $window.bookingContainAccommodation = true;

        console.log("BKGDATA");

        //Add P5 Stats
        try {
            window.Typescript.Services.OrderServiceService.StoreP5StatInfo($window.forceLemmingsHost, $scope.bkgData.request.requestId,
                $scope.bkgData.bookingResponse.bookingId, $scope.bkgData.bookingResponse.numbers[0].bookingNumber,
                "P5KO", $window.partnerName, $window.location.href);
        } catch (ex) {
            console.log(ex.message);
            $window.trackingError = ex.message;
        }


        if (bkgData) {
            $scope.numbers = bkgData.bookingResponse.numbers;
            //Add InspecLet dossier tag
            //$window.sendInspectlet('tagSession', { BookingNumber: $scope.dossierNumber.substr(-7) });

            //var keyGenerated = "";
            //keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;
            //bkgData.bookingRequest = tseUtil.Session.get("bookingRequest_" + keyGenerated);

            if (bkgData.bookingRequest != null && bkgData.bookingRequest.requestId == bkgData.request.requestId) {
                //var _insuranceSelected = tseUtil.Session.get("insurancesSelected_" + keyGenerated);
                //bkgData.insurancesSelected = _insuranceSelected;
                //bkgData.banqueCasinoSchedule = tseUtil.Session.get("banqueCasino" + keyGenerated);
                if (bkgData.banqueCasinoSchedule != undefined)
                    bkgData.banqueCasino = true;
                $scope.$broadcast("onBookingSummaryUpdate");
            }
            else {
                $window.FLoading({ vAction: 'show' });
                $window.location.href = searchUrl;
            }
        }
        else {
            $window.FLoading({ vAction: 'show' });
            $window.location.href = searchUrl;
        }

        //Taxes details click
        $scope.$on("displayTaxesDetailsClick", function (event, args) {
            $scope.displayTaxesDetailModal = true;
            $scope.modalTaxesKey = args.key.replaceAll(":", "-");
            $scope.modalTaxesKey = $scope.modalTaxesKey.replaceAll("\\|", "-");
            $scope.modalTaxesKeyAux = $scope.modalTaxesKey + "-" + args.paxFares.paxType;
            $scope.modalTaxesPaxType = args.paxType;
            $scope.modalTaxesPaxFares = args.paxFares;
            $scope.modalPaxNumber = args.paxNumber;

            $scope.modalShowMargin = false;

            var airlines = JSON.parse(window.sessionStorage.getItem("airlines"));

            args.marketingCarriers.each(function (value, key) {

                if (airlines != null && airlines[value].showMargin == true || value === 'AF') {
                    $scope.modalShowMargin = true;
                }
            });

            $scope.$broadcast("updateTaxesDetailModalData", { key: $scope.modalTaxesKey, paxType: $scope.modalTaxesPaxType, fares: $scope.modalTaxesPaxFares, paxnumber: $scope.modalPaxNumber });

            //Remodal workaround
            $(document).find(".remodal").each(function (a, c) {
                var d = $(c), e = d.data("remodal-options");
                e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                    d["remodal"](e)
            });

            $timeout(function () {
                $("[data-remodal-id='taxes-detail-modal-" + $scope.modalTaxesKeyAux + "']").remodal().open();
            },
                200, false);

        });

        //Luaggage details click
        $scope.$on("displayLuggageDetailsClick", function (event, args) {
            $scope.displayLuggageDetailModal = true;
            $scope.modalLuggageBagType = args.bagType;

            $scope.$broadcast("updateLuggageDetailModalData", { bagType: $scope.modalLuggageBagType });

            $scope.modalShowMargin = false;

            var airlines = JSON.parse(window.sessionStorage.getItem("airlines"));

            args.marketingCarriers.each(function (value, key) {

                if (airlines != null && airlines[value].showMargin == true || value === 'AF') {
                    $scope.modalShowMargin = true;
                }
            });

            //Remodal workaround
            $(document).find(".remodal").each(function (a, c) {
                var d = $(c), e = d.data("remodal-options");
                e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                    d["remodal"](e)
            });

            $timeout(function () {
                $('[data-remodal-id="luggage-detail-modal"]').remodal().open();
            },
                200, false);
        });
    }]);