/**
* @desc the ngBookingSummary provides support for displaying the fligth details, warnings and branded fares details in templates. This can be used on any page
* @example <div ng-booking-summary bookingdata="bkgData" showpaxes="true"></div>
*/

webClient.directive('ngBookingSummary', ['$window', '$i18next', 'templateService', 'tseAirResultsHelper', 'tseApiClient', 
    function ($window, $i18next, templateService, tseAirResultsHelper, tseApiClient) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        bookingdata: "=",
        showpaxes: "=",
        showprint: "=",
        newversion: "=",
        fullalertcabinmessage: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_BookingSummary.html');

        $scope.getUrl = $window.getUrl;
        $scope.siteType = $window.site.type;
        $scope.getStaticFolder = $window.getStaticFolder;
        $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
        $scope.getTypeStaticFolder = $window.getTypeStaticFolder;
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.currentCabinClass = $window.site.currentAirRequest.summary.cabinClass;
        $scope.enableOption = $window.enableOption && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');
        $scope.hideMessageTO = $window.hideMessageTO;
        $scope.enableBrandedFares = $window.enableBrandedFares;
        $scope.displayBrandedFaresSummary = $window.displayBrandedFaresSummary && $scope.bookingdata.brandedFares && $scope.bookingdata.brandedFares.brands && $scope.bookingdata.brandedFares.brands.length > 0 && $scope.bookingdata.brandedFares.services && $scope.bookingdata.brandedFares.services.length > 0;
        if ($window.isWonderBox) $scope.isWonderBox = $window.isWonderBox;

        $scope.isServair = window.site.partnerId === "servair";

        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        window.airHelper = tseAirResultsHelper;
        $scope.lng = $window.lng;
        $scope.locale = $window.lng.substring(0, 2);   

        $scope.paxtype = [$i18next.t('airbooking.insurance.adult'), $i18next.t('airbooking.insurance.child'), $i18next.t('airbooking.insurance.infant')];
        $scope.paxamount = [$scope.bookingdata.request.adults, $scope.bookingdata.request.childs, $scope.bookingdata.request.infants];

        $scope.hasFlexies = false;
        try {
            var flexies = $scope.bookingdata.purchaseResponse.proposals[0].flexies;
            Object.keys(flexies).forEach(function (key) {
                if (/^00004|00005|00006$/i.test(flexies[key].flexyReference)) {
                    $scope.hasFlexies = true;
                }
            });
        }
        catch (e) {
            $scope.hasFlexies = false;
        }

        //Traduction
        $scope.getT = function (key) {
            var res = $i18next.t(key.toLowerCase());
            return res;
        };

        $scope.selection = $scope.bookingdata.selection;
        var productKey = $scope.selection.outbound.key;
        var outboundNotes = $scope.selection.outbound.fareNotesAvailable;
        var inboundNotes = false;
        if ($scope.selection.inbound) {
            inboundNotes = $scope.selection.inbound.fareNotesAvailable;
            productKey = productKey + "+" + $scope.selection.inbound.key;
        }
        else
            inboundNotes = true;
        var showNotes = outboundNotes && inboundNotes;
        $scope.showNotes = showNotes;
        $scope.productKey = productKey;
        $scope.outboundNotes = outboundNotes;
        $scope.inboundNotes = inboundNotes;

      
        getBundleWidget("mrfly-widget-request-recap.js").done(function () {
            console.log("Loading done");
            //set params do mapping

            var airSearchRequest = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequest']();

            airSearchRequest.adultNumber = $scope.bookingdata.request.adults;
            airSearchRequest.childNumber = $scope.bookingdata.request.childs;
            airSearchRequest.infantNumber = $scope.bookingdata.request.infants;
            airSearchRequest.isOneWay = $scope.bookingdata.request.itineraries.length == 1;
            airSearchRequest.isRoundTrip = $scope.bookingdata.request.itineraries.length == 2 && $scope.bookingdata.request.itineraries[0].origin.cityIATA.localeCompare($scope.bookingdata.request.itineraries[1].destination.cityIATA) === 0;
            airSearchRequest.isOpenJaw = $scope.bookingdata.request.itineraries.length === 2 && $scope.bookingdata.request.itineraries[0].origin.cityIATA.localeCompare($scope.bookingdata.request.itineraries[1].destination.cityIATA) !== 0;
            airSearchRequest.isMultiDestination = $scope.bookingdata.request.itineraries.length > 2;
            airSearchRequest.cabinClass = $scope.bookingdata.request.cabinClass;

            //Map itineraries
            airSearchRequest.itineraries = [];
            $scope.bookingdata.request.itineraries.forEach(function (itinerary) {
                var ins = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequestItinerary']();

                ins.date = itinerary.goDate.date;

                ins.departureAirportIata = itinerary.origin.value;
                ins.departureCityIata = itinerary.origin.cityIATA;
                ins.departureName = itinerary.origin.cityName;

                ins.arrivalAirportIata = itinerary.destination.value;
                ins.arrivalCityIata = itinerary.destination.cityIATA;
                ins.arrivalName = itinerary.destination.cityName;

                airSearchRequest.itineraries.push(ins);
            });
            mrflyWidgets['mrfly-widget-request-recap'].airSearchRequest = airSearchRequest;
            mrflyWidgets['mrfly-widget-request-recap'].locale = $window.lng.substring(0, 2);
            mrflyWidgets['mrfly-widget-request-recap'].language = $window.lng;
            mrflyWidgets['mrfly-widget-request-recap'].resources = i18next.store.data.dev.translation;
        });

        //Taxes details
        $scope.createTaxesDetailModal = function (key, paxType, paxFares, paxNumber) {
            $scope.$emit("displayTaxesDetailsClick", { key: key.toString(), paxType: paxType, paxFares: paxFares, paxNumber: paxNumber });
        };

        //Prepare the object to display in the Taxes Modal
        $scope.prepareTaxesDetailsFromBrandedFares = function(key, paxType, fareItem, quantity) {
            $scope.displayTaxesDetailModal = false;

            if (fareItem) {
                var faresTaxes = airHelper.prepareTaxesObjectForBkgDetails(fareItem);

                if (faresTaxes) {

                    var taxesDetailsObject = {
                        paxtype: paxType,
                        key: key,
                        paxnumber: quantity,
                        fares: faresTaxes
                    };

                    //Notificar al controlador
                    $scope.$emit("displayTaxesDetailsClick", { key: taxesDetailsObject.key.toString(), paxType: taxesDetailsObject.paxtype, paxFares: taxesDetailsObject.fares, paxNumber: taxesDetailsObject.paxnumber });
                }
            }
        };

        $scope.updateTemplate = function () {
            $scope.cards = [];
            if ($scope.bookingdata.airlines[$scope.selection.outbound.marketingCarrier].fidelityCards != null)
                $scope.cards = $scope.bookingdata.airlines[$scope.selection.outbound.marketingCarrier].fidelityCards;

            if ($scope.bookingdata.request.oneWay) {
                $scope.proposal = $scope.selection.outbound.proposal;
            }
            else {
                $scope.proposal = $scope.selection.inbound.proposal;
            }

            if (!$scope.bookingdata.brandedFares || (!$scope.bookingdata.bookingRequest.brandFareSelected) || (!$scope.bookingdata.bookingRequest.brandFareSelected.includeBaggage))
                var bagsByType = $scope.bookingdata.bookingRequest.paxes.map(function (pax) { return pax.baggageByItinerary; }).toSimpleArray().removeNulls().grep(function (bag) { return bag.quantity > 0; }).groupByKey("key");

            //Control para mostrar u ocultar showNotes cuando se cambia de brandfare
            if ($scope.bookingdata.brandedFares && $scope.bookingdata.brandedFares.brands && $scope.bookingdata.bookingRequest.brandFareSelected && $scope.bookingdata.bookingRequest.brandFareSelected.brandKey)
            {
                if ($scope.bookingdata.brandedFares.brands[0] && $scope.bookingdata.brandedFares.brands[0].brandKey)
                    if ($scope.bookingdata.bookingRequest.brandFareSelected.brandKey != $scope.bookingdata.brandedFares.brands[0].brandKey)
                        $scope.showNotes = false;
                    else
                        $scope.showNotes = showNotes;
            }

            $scope.addBaggages = [];
            for (var key in bagsByType) {

                var bgs = bagsByType[key];
                var origin = "";
                var destination = "";
                if ($window.isArray(bgs))
                {
                    var bgsItinerary = bgs.groupByKey('itineraryKey');
                    for (var key in bgsItinerary) {
                        if (!$window.isArray(bgsItinerary[key]))
                            fbgs = bgsItinerary[key];
                        else
                            fbgs = bgsItinerary[key][0];

                        var quantity = bgsItinerary[key].length != undefined ? bgsItinerary[key].length : 1;

                        if (key == $scope.selection.outbound.key) {
                            origin = $scope.selection.outbound.origin;
                            destination = $scope.selection.outbound.destination;
                        }
                        else if (key == $scope.selection.inbound.key) {
                            origin = $scope.selection.inbound.origin;
                            destination = $scope.selection.inbound.destination;
                        }

                        var bgType = {
                                price: fbgs.price,
                                quantity: fbgs.quantity,
                                weight: fbgs.weight,
                                text: fbgs.text,
                                key: key,
                                typeQuantity: bgsItinerary[key].length!=undefined ? bgsItinerary[key].length:1,
                                totalQuantity: quantity * fbgs.quantity,
                                totalPrice: quantity * fbgs.price,
                                origin: origin,
                                destination: destination
                        };
                        $scope.addBaggages.push(bgType);
                    }
                }
                else
                {
                    if (bgs.itineraryKey == $scope.selection.outbound.key) {
                        origin = $scope.selection.outbound.origin;
                        destination = $scope.selection.outbound.destination;
                    }
                    else if (bgs.itineraryKey == $scope.selection.inbound.key) {
                        origin = $scope.selection.inbound.origin;
                        destination = $scope.selection.inbound.destination;
                    }

                    quantity = 1;
                    var bgType = {
                        price: bgs.price,
                        quantity: bgs.quantity,
                        weight: bgs.weight,
                        text: bgs.text,
                        key: key,
                        typeQuantity: quantity,
                        totalQuantity: quantity * bgs.quantity,
                        totalPrice: quantity * bgs.price,
                        origin: origin,
                        destination: destination
                    };
                    $scope.addBaggages.push(bgType);
                }

                //if (!$window.isArray(bgs))
                //    bgs = [bgs];
                //var fbgs = bgs.first();
                //var bgType = {
                //    price: fbgs.price,
                //    quantity: fbgs.quantity,
                //    weight: fbgs.weight,
                //    text: fbgs.text,
                //    key: key,
                //    typeQuantity: bgs.length,
                //    totalQuantity: bgs.length * fbgs.quantity,
                //    totalPrice: bgs.length * fbgs.price
                //};
                //$scope.addBaggages.push(bgType);
            }

            $scope.updateTotal();
            $scope.$emit("onBookingSummaryUpdateCompleted", $scope.bookingdata);
        };
        $scope.GetNotes = function (obj, itIdx) {
            if (obj && obj.iPop) {
                $window.FLoading({ vAction: "show" });
                var iPropPre = $("#" + obj.iPop + " pre");
                iPropPre.text("");

                var requestId = $scope.bookingdata.request.requestId;
                var productKey = $scope.productKey;
                var itineraryKey = $scope.bookingdata.selection.outbound.key;
                if (itIdx == 1)
                    itineraryKey = $scope.bookingdata.selection.inbound.key;

                var errorNotFound = function () { $window.XLConfirm({ vTitle: $i18next.t('airbooking.sorrywecouldnot'), vTextYes: "OK", vHideNo: true }); };

                $scope.apiClient.B2B.getFareNotes({ requestId: requestId, productKey: productKey, itineraryKey: itineraryKey, paragraphs: [16] }).success(function (data) {
                    if (data && data.fareNotesKeys && data.fareNotesKeys.length > 0) {
                        var note = data.fareNotesKeys[0];
                        if (note.paragraphs[0].note != "") {
                            iPropPre.text(note.paragraphs[0].note);
                            $scope.FPop(null, obj);

                        }
                        else
                            errorNotFound();

                        console.log(data);
                    }
                    else {
                        errorNotFound();
                    }
                    $window.FLoading({ vAction: "hide" });
                }).error(function (err) {
                    $window.FLoading({ vAction: "hide" });
                });
            }
        };
        $scope.FPop = function (_event, obj) {
            if (_event) {
                var value = _event.target;
                obj.oTarget = value;
            }
            $window.FPop(obj);
        };
        $scope.updateTotal = function () {
            debugger;
            var rsTotals = $scope.updateTotals($scope.proposal.totalFare.value);
            $scope.prixTotal = rsTotals[0];
            $scope.prixTotalWithoutMargin = rsTotals[1];
            $scope.bookingdata.prixTotal = $scope.prixTotal;
            $scope.bookingdata.prixTotalWithoutMargin = $scope.prixTotalWithoutMargin;

            //Predictions Totals
            if ($scope.bookingdata.pricePredictions)
            {
                var pred = $scope.bookingdata.pricePredictions;
                for(var i=0;i<pred.days.length; i++)
                {
                    var totalPrice = rsTotals[0].value + pred.days[i].change;
                    pred.days[i].price = tseAirResultsHelper.getNumberObject(totalPrice);
                }
            }
        };
        $scope.updateTotals = function (baseFare) {
            var counter = baseFare + $scope.proposal.totalFee.value + $scope.proposal.totalMargin.value;

            //Start: Branded Fares
            if ($scope.bookingdata.bookingRequest.brandKey)
            {
                if ($scope.bookingdata.brandedFares && $scope.bookingdata.brandedFares.brands) {
                    $scope.bookingdata.brandedFares.brands.select(function(bf) {
                        if (bf.brandKey == $scope.bookingdata.bookingRequest.brandKey) {
                            counter = bf.totalAmount +
                                $scope.proposal.totalFee.value +
                                $scope.proposal.totalMargin.value;

                            //Si hay flexy
                            if ($scope.bookingdata.bookingRequest.flexyFare) {
                                counter += bf.totalAmountFlexy.value;
                            }

                            //Si best price
                            if ($scope.bookingdata.bookingRequest.bestPriceFare) {
                                counter += bf.totalBestPrice.value;
                            }

                            //Si estamos en P5  o si solo hay 1 brand en el array de brandedFares
                            if ($scope.displayBrandedFaresSummary) {
                                $scope.branFareServicesIncluded = [];
                                bf.services.each(function(bfService) {
                                    if (bfService.servicePricing == 0) {
                                        $scope.branFareServicesIncluded.push(bfService);
                                    }
                                });

                                if ($scope.branFareServicesIncluded) {
                                    $scope.branFareServicesIncluded.each(function(sIncluded) {
                                        $scope.bookingdata.brandedFares.services.any(function(sItem) {
                                            if (sIncluded.index == sItem.index) {
                                                sIncluded.cssClass = sItem.cssClass;
                                                sIncluded.description = sItem.description;
                                                sIncluded.descriptionHtml = sItem.descriptionHtml;
                                                sIncluded.displayName = sItem.displayName;
                                                return sIncluded;
                                            }
                                        });
                                    });
                                }
                            }
                        }
                    });
                }
            }
            //End

            //Flexy
            if ($scope.bookingdata.bookingRequest.flexyFare && !$scope.bookingdata.bookingRequest.brandKey)
                counter += $scope.proposal.totalFlexy.value;

            //BestPrice
            if ($scope.bookingdata.bookingRequest.bestPriceFare && !$scope.bookingdata.bookingRequest.brandKey)
                counter += $scope.proposal.totalBestPrice.value;

            for (var i = 0; i < $scope.addBaggages.length; i++) {
                var bagType = $scope.addBaggages[i];
                counter += bagType.totalPrice;
            }
            if ($scope.bookingdata.insurancesSelected.isAnnul)
                counter += $scope.bookingdata.insurancesSelected.isAnnul;
            if ($scope.bookingdata.insurancesSelected.isRepatriament)
                counter += $scope.bookingdata.insurancesSelected.isRepatriament;
            if ($scope.bookingdata.insurancesSelected.isAnnulRepatriament)
                counter += $scope.bookingdata.insurancesSelected.isAnnulRepatriament;
            if ($scope.bookingdata.discountInfo != null && $scope.bookingdata.discountInfo.applicableAmount != 0) {
                if ($scope.bookingdata.discountInfo.discountAmount > counter) {
                    $scope.bookingdata.discountInfo.applicableAmount = - counter;
                } else {
                    $scope.bookingdata.discountInfo.applicableAmount = - $scope.bookingdata.discountInfo.discountAmount;
                }
                console.log("Update $scope.bookingdata.discountInfo.applicableAmount");
                console.log($scope.bookingdata.discountInfo);

                counter += $scope.bookingdata.discountInfo.applicableAmount;
            }
                

            var val0 = tseAirResultsHelper.getNumberObject(counter);
            var val1 = tseAirResultsHelper.getNumberObject(val0.value - $scope.proposal.totalMargin.value);
            return [val0, val1];
        };
        $scope.updateTemplate();
        $scope.$on("onBookingSummaryUpdate", function (event, args) {
            $scope.updateTemplate();
        });

        //Get city Name or Iata
        $scope.getCityNameOrIata=function(airportIata)
        {
            if (airportIata != undefined && airportIata!=null) {
                if ($scope.bookingdata.airports[airportIata].cityIata) {
                    if ($scope.bookingdata.cities[$scope.bookingdata.airports[airportIata].cityIata])
                    {
                        if($scope.bookingdata.cities[$scope.bookingdata.airports[airportIata].cityIata].length>1)
                            return $scope.bookingdata.cities[$scope.bookingdata.airports[airportIata].cityIata][0].name;
                        else
                            return $scope.bookingdata.cities[$scope.bookingdata.airports[airportIata].cityIata].name;
                    }
                }
                else {
                    if ($scope.bookingdata.airports[airportIata].name)
                        return $scope.bookingdata.airports[airportIata].name;
                    else
                        return airportIata;
                }
            }
        }

        $scope.hasWarning = false;
        $scope.productTO = false;
        $scope.warnings = function () {
            if ($window.enableFlightWarnings)
            {
                //RoundTrip
                $scope.isSameOutboundOriginInboundDestination = $scope.bookingdata.selection.inbound && ($scope.bookingdata.selection.outbound.origin == $scope.bookingdata.selection.inbound.destination);
                $scope.isSameOutboundDestinationInboundOrigin = $scope.bookingdata.selection.inbound && ($scope.bookingdata.selection.outbound.destination == $scope.bookingdata.selection.inbound.origin);

                //Day Change
                $scope.warn_dayChangeOutbound = ($scope.bookingdata.selection.outbound.dayChange > 0);
                $scope.warn_dayChangeInboud = (($scope.bookingdata.selection.inbound) && $scope.bookingdata.selection.inbound.dayChange > 0);

                //Different Departure Arrival Airport on Origin City
                $scope.warn_diffDepartureArrivalAirportOrigin = (($scope.isSameOutboundOriginInboundDestination && $scope.bookingdata.selection.inbound) && ($scope.bookingdata.selection.outbound.departure != $scope.bookingdata.selection.inbound.arrival));

                //Different Departure Arrival Airport on DestinationCity
                $scope.warn_diffDepartureArrivalAirportDestination = (($scope.isSameOutboundDestinationInboundOrigin && $scope.bookingdata.selection.inbound) && ($scope.bookingdata.selection.outbound.arrival != $scope.bookingdata.selection.inbound.departure));

                //City proposed is different to city requested on destination
                if ($scope.bookingdata.selection.inbound && $scope.bookingdata.airports[$scope.bookingdata.selection.outbound.arrival].cityIata) {
                    //Aux variables
                    var auxAirportCityIata = $scope.bookingdata.airports[$scope.bookingdata.selection.outbound.arrival].cityIata;
                    var auxOutCityIata = "";

                    if ($scope.bookingdata.cities[auxAirportCityIata].length > 1)
                        auxOutCityIata = $scope.bookingdata.cities[auxAirportCityIata][0].iata;
                    else
                        auxOutCityIata = $scope.bookingdata.cities[auxAirportCityIata].iata;

                    $scope.warn_cityProposedDiffCityRequested = ($scope.bookingdata.request.destination.cityIATA != auxOutCityIata);
                }

                //City proposed is different to city requested on origin
                if ($scope.bookingdata.selection.inbound && $scope.bookingdata.airports[$scope.bookingdata.selection.outbound.departure].cityIata) {
                    //Aux variables
                    var auxAirportCityIata = $scope.bookingdata.airports[$scope.bookingdata.selection.outbound.departure].cityIata;
                    var auxCityIata = "";

                    if($scope.bookingdata.cities[auxAirportCityIata].length>1)
                        auxCityIata = $scope.bookingdata.cities[auxAirportCityIata][0].iata;
                    else
                        auxCityIata = $scope.bookingdata.cities[auxAirportCityIata].iata;

                    $scope.warn_cityProposedDiffCityRequestedOrigin = ($scope.bookingdata.request.origin.cityIATA != auxCityIata);
                }

                //Alert for flight only Aller Simple
                if (!$scope.bookingdata.selection.inbound && $scope.bookingdata.airports[$scope.bookingdata.selection.outbound.departure].cityIata)
                {
                    var auxAirportCityIata = $scope.bookingdata.airports[$scope.bookingdata.selection.outbound.departure].cityIata;
                    var auxCityIataDeparture = "";

                    if ($scope.bookingdata.cities[auxAirportCityIata].length > 1)
                        auxCityIataDeparture = $scope.bookingdata.cities[auxAirportCityIata][0].iata;
                    else
                        auxCityIataDeparture = $scope.bookingdata.cities[auxAirportCityIata].iata;

                    $scope.warn_cityProposedDiffCityRequestedOriginFlightAllerSimple = ($scope.bookingdata.request.origin.cityIATA != auxCityIataDeparture);
                }

                if (!$scope.bookingdata.selection.inbound && $scope.bookingdata.airports[$scope.bookingdata.selection.outbound.arrival].cityIata) {
                    var auxAirportCityIata = $scope.bookingdata.airports[$scope.bookingdata.selection.outbound.arrival].cityIata;
                   var auxCityIataArrival = "";

                    if ($scope.bookingdata.cities[auxAirportCityIata].length > 1)
                        auxCityIataArrival = $scope.bookingdata.cities[auxAirportCityIata][0].iata;
                    else
                        auxCityIataArrival = $scope.bookingdata.cities[auxAirportCityIata].iata;

                    $scope.warn_cityProposedDiffCityRequestedFlightAllerSimple = ($scope.bookingdata.request.destination.cityIATA != auxCityIataArrival);
                }
                //End Alert for flight only Aller Simple

                //Technical Stop
                $scope.warn_technicalStopOutbound = [];
                $scope.bookingdata.selection.outbound.segments.each(function (seg) {
                    if (seg.stops > 0) {
                        $scope.warn_technicalStopOutbound.push({ departure: seg.departure.airport, arrival: seg.arrival.airport });
                    }
                });

                $scope.warn_technicalStopInbound = [];
                if ($scope.bookingdata.selection.inbound) {
                    $scope.bookingdata.selection.inbound.segments.each(function (seg) {
                        if (seg.stops > 0) {
                            $scope.warn_technicalStopInbound.push({ departure: seg.departure.airport, arrival: seg.arrival.airport });
                        }
                    });
                }
                //Long Transit Time more of 6 h
                $scope.warnLongTransitTimeOutbound = $scope.bookingdata.selection.outbound.stops.any(function (stop) { return (stop.time.totalHours > 6) });
                $scope.warnLongTransitTimeInbound = (($scope.bookingdata.selection.inbound) && $scope.bookingdata.selection.inbound.stops.any(function (stop) { return (stop.time.totalHours > 6) }));

                //Segment in Train or Bus Outbound
                $scope.warnSegmentTrainOrBusOutbound = $scope.bookingdata.selection.outbound.segments.any(function (seg) {
                    if (seg.equipment)
                        return ($scope.bookingdata.equipments[seg.equipment].equipmentType == 2 || $scope.bookingdata.equipments[seg.equipment].equipmentType == 3);
                    else
                        return false;
                });

                //Segment in Train or Bus Inbound
                $scope.warnSegmentTrainOrBusInbound = $scope.bookingdata.selection.inbound && $scope.bookingdata.selection.inbound.segments.any(function (seg) {
                    if (seg.equipment)
                        return ($scope.bookingdata.equipments[seg.equipment].equipmentType == 2 || $scope.bookingdata.equipments[seg.equipment].equipmentType == 3);
                    else
                        return false;
                });

                //Segment with Cabin luggage to pay Outbound
                $scope.warnSegmentCabinLuggageOutbound = $scope.bookingdata.selection.outbound.segments.any(function (seg) {
                    if (seg.airline)
                        return ($scope.bookingdata.equipments[seg.airline].iata.toLowerCase() === "fr" && moment(itSegment.departureDateTime, "YYYY-MM-DD").isAfter('2018-11-01', 'day'));
                    else
                        return false;
                });

                //Segment with Cabin luggage to pay Inbound
                $scope.warnSegmentCabinLuggageInbound = $scope.bookingdata.selection.inbound && $scope.bookingdata.selection.inbound.segments.any(function (seg) {
                    if (seg.airline)
                        return ($scope.bookingdata.equipments[seg.airline].iata.toLowerCase() === "fr" && moment(itSegment.departureDateTime, "YYYY-MM-DD").isAfter('2018-11-01', 'day'));
                    else
                        return false;
                });

                //Airport change during the transit outbound
                $scope.warnAirportChangeDuringTheTransitOutbound = ($scope.bookingdata.selection.outbound.segments.length > 1) && ($scope.bookingdata.selection.outbound.segments.any(function (item, i) {
                    if (i + 1 < $scope.bookingdata.selection.outbound.segments.length) {
                        if ($scope.bookingdata.selection.outbound.segments[i + 1] != undefined && item.arrival.airport != $scope.bookingdata.selection.outbound.segments[i + 1].departure.airport) {
                            return true;
                        }
                    }
                }));

                //Airport change during the transit inbound
                $scope.warnAirportChangeDuringTheTransitInbound = (($scope.bookingdata.selection.inbound) && $scope.bookingdata.selection.inbound.segments.length > 1) && ($scope.bookingdata.selection.inbound.segments.any(function (item, i) {
                    if (i + 1 < $scope.bookingdata.selection.inbound.segments.length) {
                        if ($scope.bookingdata.selection.inbound.segments[i + 1]!=undefined && item.arrival.airport != $scope.bookingdata.selection.inbound.segments[i + 1].departure.airport) {
                            return true;
                        }
                    }
                }));

                //Is Cabin Class Upgrade
                $scope.warnCabinClassUpgraded = $scope.bookingdata.selection.outbound.segments.any(function (seg) {
                    if ($scope.bookingdata.cabinclasses[seg.cabinClass].sort > $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                        return true;
                    }
                });

                //Get List of the Cabin Class Upgrade Outbound
                $scope.listCabinClassUpgradedOutbound = $scope.bookingdata.selection.outbound.segments.select(function (seg) {
                    if ($scope.bookingdata.cabinclasses[seg.cabinClass].sort > $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                        return seg.cabinClass;
                    }
                }).toSimpleArray().distinct();

                //Is Cabin Class Downgraded Outbound
                $scope.warnCabinClassDowngraded = $scope.bookingdata.selection.outbound.segments.any(function (seg) {
                    if ($scope.bookingdata.cabinclasses[seg.cabinClass].sort < $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                        return true;
                    }
                });

                //Get List of the Cabin Class Downgraded Outbound
                $scope.listCabinClasDowngradedOutbound = $scope.bookingdata.selection.outbound.segments.select(function (seg) {
                    if ($scope.bookingdata.cabinclasses[seg.cabinClass].sort < $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                        return seg.cabinClass;
                    }
                }).toSimpleArray().distinct();


                //Check if all cabin classes are the same and Upgraded
                if ($scope.listCabinClassUpgradedOutbound.length != undefined && $scope.listCabinClassUpgradedOutbound.length == 1 && $scope.listCabinClassUpgradedOutbound[0]!=null)
                {
                    if($scope.bookingdata.cabinclasses[$scope.listCabinClassUpgradedOutbound[0]].sort>$scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort)
                    {
                        $scope.completeUpgradeCabinClassOutbound = true;
                        $scope.completeUpgradeCabinClassOutboundName = $scope.bookingdata.cabinclasses[$scope.listCabinClassUpgradedOutbound[0]].name;
                    }
                }

                //Check if all cabin classes are the same and Dowgraded
                if ($scope.listCabinClasDowngradedOutbound != undefined && $scope.listCabinClasDowngradedOutbound.length == 1 && $scope.listCabinClasDowngradedOutbound[0] != null)
                {
                    if ($scope.bookingdata.cabinclasses[$scope.listCabinClasDowngradedOutbound[0]].sort < $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                        $scope.completeDowngradeCabinClassOutbound = true;
                        $scope.completeDowngradeCabinClassOutboundName = $scope.bookingdata.cabinclasses[$scope.listCabinClasDowngradedOutbound[0]].name;
                    }
                }

                //Check Inbound
                if ($scope.bookingdata.selection.inbound)
                {
                    //Is Cabin Class Upgrade
                    $scope.warnCabinClassUpgradedInbound = $scope.bookingdata.selection.inbound.segments.any(function (seg) {
                        if ($scope.bookingdata.cabinclasses[seg.cabinClass].sort > $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                            return true;
                        }
                    });

                    //Is Cabin Class Downgraded
                    $scope.warnCabinClassDowngradedInbound = $scope.bookingdata.selection.inbound.segments.any(function (seg) {
                        if ($scope.bookingdata.cabinclasses[seg.cabinClass].sort > $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                            return true;
                        }
                    });

                    //Get List of the Cabin Class Upgraded Inbound
                    $scope.listCabinClassUpgradedInbound = $scope.bookingdata.selection.inbound.segments.select(function (seg) {
                        if ($scope.bookingdata.cabinclasses[seg.cabinClass].sort > $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                            return seg.cabinClass;
                        }
                    }).toSimpleArray().distinct();

                    //Get List of the Cabin Class Downgraded Inbound
                    $scope.listCabinClassDowngradedInbound = $scope.bookingdata.selection.inbound.segments.select(function (seg) {
                        if ($scope.bookingdata.cabinclasses[seg.cabinClass].sort < $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                            return seg.cabinClass;
                        }
                    }).toSimpleArray().distinct();

                    //Check if all cabin classes are the same and Upgraded
                    if ($scope.listCabinClassUpgradedInbound.length != undefined && $scope.listCabinClassUpgradedInbound.length == 1 && $scope.listCabinClassUpgradedInbound[0] != null) {
                        if ($scope.bookingdata.cabinclasses[$scope.listCabinClassUpgradedInbound[0]].sort > $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                            $scope.completeUpgradeCabinClassInbound = true;
                            $scope.completeUpgradeCabinClassInboundName = $scope.bookingdata.cabinclasses[$scope.listCabinClassUpgradedOutbound[0]].name;
                        }
                    }

                    //Check if all cabin classes are the same and Dowgraded
                    if ($scope.listCabinClassDowngradedInbound != undefined && $scope.listCabinClassDowngradedInbound.length == 1 && $scope.listCabinClassDowngradedInbound[0] != null) {
                        if ($scope.bookingdata.cabinclasses[$scope.listCabinClassDowngradedInbound[0]].sort < $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                            $scope.completeDowngradeCabinInboundClass = true;
                            $scope.completeDowngradeCabinInboundClassName = $scope.bookingdata.cabinclasses[$scope.listCabinClassDowngradedInbound[0]].name;
                        }
                    }
                }

                $scope.hasWarning = $window.enableFlightWarnings && ($scope.warn_dayChangeOutbound || $scope.warn_dayChangeInboud || $scope.warn_diffDepartureArrivalAirportOrigin
                                    || $scope.warn_diffDepartureArrivalAirportDestination || $scope.warn_cityProposedDiffCityRequested || ($scope.warn_technicalStopOutbound.length > 0)
                                    || ($scope.warn_technicalStopInbound.length > 0) || $scope.warnLongTransitTimeOutbound || $scope.warnLongTransitTimeInbound || $scope.warnSegmentTrainOrBusOutbound
                                    || $scope.warnAirportChangeDuringTheTransitOutbound || $scope.warnAirportChangeDuringTheTransitInbound || $scope.warn_cityProposedDiffCityRequestedOrigin)
                                    || $scope.warnCabinClassUpgraded || $scope.warnCabinClassDowngraded || $scope.warnCabinClassUpgradedInbound || $scope.warnCabinClassDowngradedInbound || $scope.warnSegmentTrainOrBusInbound
                    || $scope.warn_cityProposedDiffCityRequestedOriginFlightAllerSimple || $scope.warn_cityProposedDiffCityRequestedFlightAllerSimple || $scope.warnSegmentCabinLuggageOutbound || $scope.warnSegmentCabinLuggageInbound;

                //Check if is TO Product
                $scope.isProductTO = $scope.siteType == 2 && $scope.bookingdata.selection.outbound && $scope.bookingdata.selection.outbound.productType == 1;
                if( $scope.isProductTO)
                {
                    if($scope.bookingdata.selection.inbound && $scope.bookingdata.selection.inbound.proposal){
                        $scope.ticketing = moment(($scope.bookingdata.selection.inbound.proposal.ticketing),'DD-MM-YYYY').toDate();
                        $scope.ticketingDays = $scope.bookingdata.selection.inbound.proposal.ticketingDays;
                        $scope.optionEnabled = $scope.bookingdata.selection.inbound.proposal.optionEnabled;
                    }
                    if (!$scope.bookingdata.selection.inbound && $scope.bookingdata.selection.outbound.proposal)
                    {
                        $scope.ticketing = moment(($scope.bookingdata.selection.outbound.proposal.ticketing), 'DD-MM-YYYY').toDate();
                        $scope.ticketingDays = $scope.bookingdata.selection.outbound.proposal.ticketingDays;
                        $scope.optionEnabled = $scope.bookingdata.selection.outbound.proposal.optionEnabled;
                    }
                }
            }
        };
        $scope.warnings();


        //Function CheckBrandedFares: Esta funcion obtiene los datos de los servicios incluidos cuando solo hay 1 branded fare
        $scope.checkBrandedFares = function (len) {
            $scope.onlyOneBrandFare = $scope.enableBrandedFares && ($scope.bookingdata.brandedFares && $scope.bookingdata.brandedFares.brands && $scope.bookingdata.brandedFares.brands.length == 1 && $scope.bookingdata.brandedFares.brands[0] && $scope.bookingdata.brandedFares.brands[0].services && $scope.bookingdata.brandedFares.brands[0].services.length > 0);
            if ($scope.onlyOneBrandFare && !$scope.displayBrandedFaresSummary) {
                $scope.bookingdata.brandedFares.brands.select(function(bf) {
                    if (bf.brandKey == $scope.bookingdata.bookingRequest.brandKey) {

                        //Add only include services
                        $scope.branFareServicesIncluded = [];
                        bf.services.each(function(bfService) {
                            if (bfService.servicePricing == 0) {
                                $scope.branFareServicesIncluded.push(bfService);
                            }
                        });

                        if ($scope.branFareServicesIncluded.length > 0) {
                            $scope.branFareServicesIncluded.each(function(sIncluded) {
                                $scope.bookingdata.brandedFares.services.any(function(sItem) {
                                    if (sIncluded.index == sItem.index) {
                                        sIncluded.cssClass = sItem.cssClass;
                                        sIncluded.description = sItem.description;
                                        sIncluded.descriptionHtml = sItem.descriptionHtml;
                                        sIncluded.displayName = sItem.displayName;
                                        return sIncluded;
                                    }
                                });
                            });
                        }
                    }
                });
            }
        };

        //Si ya tenemos datos de branded fares comprobamos si la longitud es 1 para mostrar la informacion en bookingSummary
        //Sino hacemos el $on para obtener los datos
        if($scope.bookingdata.brandedFares && $scope.bookingdata.brandedFares.brands && $scope.bookingdata.brandedFares.brands.length == 1)
            $scope.checkBrandedFares();
        else
        {
            $scope.$on("getBrandedFaresCallback", function (event, args) {
                $scope.checkBrandedFares();
            });
        }

        //This routine gets the booking details in order to retrieve the baggages information with the new decoding and display it in AirPaymentResponseBody
        //newversion only is true en AirPaymentResponseBody
        if ($scope.newversion) {
            if ($scope.bookingdata && $scope.bookingdata.bookingResponse && $scope.bookingdata.bookingResponse.bookingId) {
                $scope.apiClient.AIR.getBookingDetails([$scope.bookingdata.bookingResponse.bookingId]).success(function (data) {
                    if (data && data.status == 0) {
                        $scope.showDetail = true;
                        $scope.bkgDetails = tseAirResultsHelper.getViewForBookingDetailsEntityVersion2(data);
                        
                    }
                });
            }
        }
   };
    return directive;
}]);
