//*****************************************************************************************************************************************************************************************************************************************
var BaseStorageClass = function () {
    var self = this;
    this.key = "_xsto" + window.site.partnerId;
    this.data = {};
    this.loaded = false;
    var loadHandlers = [];
    var saveHandlers = [];

    //Load/Save Methods
    this.loadData = function () {
        try { self.data = JSON.parse(localStorage.getItem(self.key)); }
        catch (e) { }
        self.data = self.data || {};
        self.loaded = true;
        fireLoadData();
    };
    this.saveData = function () {
        fireSaveData();
        try { localStorage.setItem(self.key, JSON.stringify(self.data || {})); }
        catch (e) { }
    };

    //Handlers
    var fireLoadData = function () {
        try { loadHandlers.forEach(function (item) { item(self.data); }); }
        catch (e) { }
    };
    this.onLoadData = function (_function) {
        if (_function) {
            loadHandlers.push(_function);
            if (self.loaded)
                _function(self.data);
        }
    };
    var fireSaveData = function () {
        try { saveHandlers.forEach(function (item) { item(self.data); }); }
        catch (e) { }
    };
    this.onSaveData = function (_function) {
        if (_function) {
            saveHandlers.push(_function);
        }
    };

    self.loadData();
};
window.baseStorage = new BaseStorageClass();

var FlightHistoryClass = function () {
    var self = this;
    this.key = "_fsx" + window.site.partnerId;
    this.flights = [];
    this.loaded = false;
    this.bound = false;
    this.genuinesearch = false;
    this.paxsearch = false;
    var loadHandlers = [];
    var saveHandlers = [];
    var boundHandlers = [];

    //Array Methods
    this.loadArray = function () {
        try { localStorage.removeItem("_currentSearch_" + window.site.partnerId); } //Remove old one
        catch (e) { }
        try { self.flights = JSON.parse(localStorage.getItem(self.key)); }
        catch (e) { }
        self.flights = self.flights || [];
        self.loaded = true;
        fireLoadData();
    };
    this.saveArray = function () {
        fireSaveData();
        try { localStorage.setItem(self.key, JSON.stringify(self.flights || [])); }
        catch (e) { }
    };

    this.waitForSearch = function(){

        // let targetPaxField = $("#i-travellers-text").eq(0);
        var targetPaxField = document.getElementById("i-travellers-text");
        var config = { attributes: true, childList: true, subtree: true };
        var paxcallback = function (mutationsList, observer) {
            for (var mutation = 0; mutation < mutationsList.length; mutation++) {
                if (mutationsList[mutation].type == 'childList' || mutationsList[mutation].type == 'attributes') {
                    console.log("PAX HAS CHANGED");
                    console.log($("#i-travellers-text")[0].innerText);
                    self.paxsearch = true;
                    $("#paxsearch").val(true);
                    break;
                }
            }
            //for(var mutation of mutationsList) {
            //    if (mutation.type == 'childList' || mutation.type == 'attributes') {
            //        console.log("PAX HAS CHANGED");
            //        console.log($("#i-travellers-text")[0].innerText); 
            //        self.paxsearch = true;
            //        $("#paxsearch").val(true);
            //        break;
            //    }
            //}
        };


        var datesField = document.getElementById("i-search-when-fieldset");
        var dateconfig = { attributes: true, childList: true, subtree: true };
        var datecallback = function (mutationsList, observer) {
            for (var index = 0; index < mutationsList.length; index++) {
                if (mutationsList[index].type == 'childList' || mutationsList[index].type == 'attributes') {
                    console.log("DATE HAS CHANGED");
                    console.log($("input[name='goDate']").val());
                    console.log($("input[name='returnDate']").val());
                    self.genuinesearch = true;
                    $("#genuinesearch").val(true);
                    break;
                }
            }
            //for(var mutation of mutationsList) {
            //    if (mutation.type == 'childList' || mutation.type == 'attributes') {
            //        console.log("DATE HAS CHANGED");
            //        console.log($("input[name='goDate']").val()); 
            //        console.log($("input[name='returnDate']").val()); 
            //        self.genuinesearch = true;
            //        $("#genuinesearch").val(true);
            //        break;
            //    }
            //}
        };

        var observer = new MutationObserver(paxcallback);
        var date_observer = new MutationObserver(datecallback);

        if (targetPaxField) {
            observer.observe(targetPaxField, config);
        }

        if (datesField) {
            date_observer.observe(datesField, dateconfig);
        }

        $("#i-where-origin").on("change", function() {
            console.log("ORIGIN HAS CHANGED");
            console.log($(this).val()); 
            self.genuinesearch = true;
            $("#genuinesearch").val(true);
        });

        $("#i-where-destination").on("change", function() {
            console.log("DESTINATION HAS CHANGED");
            console.log($(this).val()); 
            self.genuinesearch = true;
            $("#genuinesearch").val(true);
        });

        console.log("WAIT FOR SEARCH READY");
        return false;
    };

    //View
    this.createKey = function (model) {
        var _key = model.type + model.cabin + model.adults + model.childrens + model.infants;
        if (model.origin_value) _key += model.origin_value;
        if (model.destination_value) _key += model.destination_value;
        if (model.goDate) _key += model.goDate;
        if (model.returnDate) _key += model.returnDate;
        if (model.origin2_value) _key += model.origin2_value;
        if (model.destination2_value) _key += model.destination2_value;
        if (model.goDate2) _key += model.goDate2;
        if (model.origin3_value) _key += model.origin3_value;
        if (model.destination3_value) _key += model.destination3_value;
        if (model.goDate3) _key += model.goDate3;
        if (model.origin4_value) _key += model.origin4_value;
        if (model.destination4_value) _key += model.destination4_value;
        if (model.goDate4) _key += model.goDate4;
        if (model.origin5_value) _key += model.origin5_value;
        if (model.destination5_value) _key += model.destination5_value;
        if (model.goDate5) _key += model.goDate5;
        if (model.origin6_value) _key += model.origin6_value;
        if (model.destination6_value) _key += model.destination6_value;
        if (model.goDate6) _key += model.goDate6;
        return _key;
    };
    this.extractView = function () {
        var _searchType = $("#i-search-type-pop li.c-on").data("value");
        var _type = _searchType == "roundTrip" ? 0 : _searchType == "oneWay" ? 1 : _searchType == "openJaw" ? 2 : _searchType == "multi" ? 3 : 0;
        var _cabinClass = $("#i-search-cabinClass-pop li.c-on").data("value");
        var _origin = $(".c-search #i-where-origin").val();
        var _origin_value = $(".c-search input[name='origin']").val();
        var _destination = $(".c-search #i-where-destination").val();
        var _destination_value = $(".c-search input[name='destination']").val();
        var _goDate = $(".c-search input[name='goDate']").val();
        var _returnDate = $(".c-search input[name='returnDate']").val();
        var _origin2 = $(".c-search #i-where-origin-2").val() || "";
        var _origin2_value = $(".c-search input[name='origin-2']").val() || "";
        var _destination2 = $(".c-search #i-where-destination-2").val() || "";
        var _destination2_value = $(".c-search input[name='destination-2']").val() || "";
        var _goDate2 = $(".c-search input[name='goDate-2']").val() || "";
        var _adults = $(".c-search input[name='adults']").val();
        var _childrens = $(".c-search  input[name='childs']").val();
        var _infants = $(".c-search input[name='infants']").val();
        var _origin3 = $(".c-search #i-where-origin-3").val() || "";
        var _origin3_value = $(".c-search input[name='origin-3']").val() || "";
        var _destination3 = $(".c-search #i-where-destination-3").val() || "";
        var _destination3_value = $(".c-search input[name='destination-3']").val() || "";
        var _goDate3 = $(".c-search input[name='goDate-3']").val() || "";
        var _origin4 = $(".c-search #i-where-origin-4").val() || "";
        var _origin4_value = $(".c-search input[name='origin-4']").val() || "";
        var _destination4 = $(".c-search #i-where-destination-4").val() || "";
        var _destination4_value = $(".c-search input[name='destination-4']").val() || "";
        var _goDate4 = $(".c-search input[name='goDate-4']").val() || "";
        var _origin5 = $(".c-search #i-where-origin-5").val() || "";
        var _origin5_value = $(".c-search input[name='origin-5']").val() || "";
        var _destination5 = $(".c-search #i-where-destination-5").val() || "";
        var _destination5_value = $(".c-search input[name='destination-5']").val() || "";
        var _goDate5 = $(".c-search input[name='goDate-5']").val() || "";
        var _origin6 = $(".c-search #i-where-origin-6").val() || "";
        var _origin6_value = $(".c-search input[name='origin-6']").val() || "";
        var _destination6 = $(".c-search #i-where-destination-6").val() || "";
        var _destination6_value = $(".c-search input[name='destination-6']").val() || "";
        var _goDate6 = $(".c-search input[name='goDate-6']").val() || "";
        var _key = _type + _cabinClass + _adults + _childrens + _infants;
        if (_origin_value) _key += _origin_value;
        if (_destination_value) _key += _destination_value;
        if (_goDate) _key += _goDate;
        if (_returnDate) _key += _returnDate;
        if (_origin2_value) _key += _origin2_value;
        if (_destination2_value) _key += _destination2_value;
        if (_goDate2) _key += _goDate2;
        if (_origin3_value) _key += _origin3_value;
        if (_destination3_value) _key += _destination3_value;
        if (_goDate3) _key += _goDate3;
        if (_origin4_value) _key += _origin4_value;
        if (_destination4_value) _key += _destination4_value;
        if (_goDate4) _key += _goDate4;
        if (_origin5_value) _key += _origin5_value;
        if (_destination5_value) _key += _destination5_value;
        if (_goDate5) _key += _goDate5;
        if (_origin6_value) _key += _origin6_value;
        if (_destination6_value) _key += _destination6_value;
        if (_goDate6) _key += _goDate6;

        return [_key, _type, _cabinClass, _origin, _origin_value, _destination, _destination_value, _goDate, _returnDate, _origin2, _origin2_value, _destination2, _destination2_value, _goDate2, parseInt(_adults), parseInt(_childrens), parseInt(_infants), "", _origin3, _origin3_value, _destination3, _destination3_value, _goDate3, _origin4, _origin4_value, _destination4, _destination4_value, _goDate4,
            _origin5, _origin5_value, _destination5, _destination5_value, _goDate5, _origin6, _origin6_value, _destination6, _destination6_value, _goDate6];

    };
    this.bindView = function (model) {
        var search = null;
        if (model) {
            if (window.isArray(model))
                search = self.arrayToModel(model);
            else
                search = model;
        }
        else
            return;
        if (search) {
            var isAfter = search.goDate ? (moment(search.goDate, "DD/MM/YYYY").isAfter() || moment(search.goDate, "DD/MM/YYYY").isSame(moment(), 'day')) : true;
            //type
            var searchType = search.type == 0 ? "roundTrip" : search.type == 1 ? "oneWay" : search.type == 2 ? "openJaw" : "roundTrip";
            $("#i-search li[data-value='" + searchType + "']").click();

            //cabin
            $("#i-search li[data-value='" + search.cabin + "']").click();

            //origin
            $(".c-search #i-where-origin").val(search.origin);
            $(".c-search #i-where-origin").attr("data-edited", true);
            $(".c-search #i-where-origin").attr("data-validated", true);
            $(".c-search input[name='origin']").val(search.origin_value);

            //destination
            $(".c-search #i-where-destination").val(search.destination);
            $(".c-search #i-where-destination").attr("data-edited", true);
            $(".c-search #i-where-destination").attr("data-validated", true);
            $(".c-search input[name='destination']").val(search.destination_value);

            //goDate
            if (isAfter)
                $(".c-search input[name='goDate']").val(search.goDate);

            //returnDate
            if (search.type == 0) {
                //$(".c-search input[name='returnDate']").removeAttr("readonly");
                $(".c-search input[name='returnDate']").removeAttr("disabled");
                if (isAfter)
                    $(".c-search input[name='returnDate']").val(search.returnDate);
            }
            else {
                $(".c-search input[name='returnDate']").val("");
                $(".c-search input[name='returnDate']").attr("readonly", "true");
                $(".c-search input[name='returnDate']").attr("disabled", "disabled");
            }

            //OpenJaw
            if (search.type == 2) {
                //origin2
                $(".c-search #i-where-origin-2").val(search.origin2);
                $(".c-search #i-where-origin-2").attr("data-edited", true);
                $(".c-search #i-where-origin-2").attr("data-validated", true);
                $(".c-search input[name='origin-2']").val(search.origin2_value);

                //destination2
                $(".c-search #i-where-destination-2").val(search.destination2);
                $(".c-search #i-where-destination-2").attr("data-edited", true);
                $(".c-search #i-where-destination-2").attr("data-validated", true);
                $(".c-search input[name='destination-2']").val(search.destination2_value);

                //goDate2
                if (isAfter)
                    $(".c-search input[name='goDate-2']").val(search.goDate2);

                if (search.origin3 && search.destination3 && search.goDate3) {
                    if ($(".c-search #i-where-origin-3").length == 0)
                        window.FSWhat('openJaw', 'add');
                    $(".c-search #i-where-origin-3").val(search.origin3);
                    $(".c-search #i-where-origin-3").attr("data-edited", true);
                    $(".c-search #i-where-origin-3").attr("data-validated", true);
                    $(".c-search input[name='origin-3']").val(search.origin3_value);

                    $(".c-search #i-where-destination-3").val(search.destination3);
                    $(".c-search #i-where-destination-3").attr("data-edited", true);
                    $(".c-search #i-where-destination-3").attr("data-validated", true);
                    $(".c-search input[name='destination-3']").val(search.destination3_value);

                    $(".c-search input[name='goDate-3']").val(search.goDate3);
                }

                if (search.origin4 && search.destination4 && search.goDate4) {
                    if ($(".c-search #i-where-origin-4").length == 0)
                        window.FSWhat('openJaw', 'add');
                    $(".c-search #i-where-origin-4").val(search.origin4);
                    $(".c-search #i-where-origin-4").attr("data-edited", true);
                    $(".c-search #i-where-origin-4").attr("data-validated", true);
                    $(".c-search input[name='origin-4']").val(search.origin4_value);

                    $(".c-search #i-where-destination-4").val(search.destination4);
                    $(".c-search #i-where-destination-4").attr("data-edited", true);
                    $(".c-search #i-where-destination-4").attr("data-validated", true);
                    $(".c-search input[name='destination-4']").val(search.destination4_value);

                    $(".c-search input[name='goDate-4']").val(search.goDate4);
                }

                if (search.origin5 && search.destination5 && search.goDate5) {
                    if ($(".c-search #i-where-origin-5").length == 0)
                        window.FSWhat('openJaw', 'add');
                    $(".c-search #i-where-origin-5").val(search.origin5);
                    $(".c-search #i-where-origin-5").attr("data-edited", true);
                    $(".c-search #i-where-origin-5").attr("data-validated", true);
                    $(".c-search input[name='origin-5']").val(search.origin5_value);

                    $(".c-search #i-where-destination-5").val(search.destination5);
                    $(".c-search #i-where-destination-5").attr("data-edited", true);
                    $(".c-search #i-where-destination-5").attr("data-validated", true);
                    $(".c-search input[name='destination-5']").val(search.destination5_value);

                    $(".c-search input[name='goDate-5']").val(search.goDate5);
                }


                if (search.origin6 && search.destination6 && search.goDate6) {
                    if ($(".c-search #i-where-origin-6").length == 0)
                        window.FSWhat('openJaw', 'add');
                    $(".c-search #i-where-origin-6").val(search.origin6);
                    $(".c-search #i-where-origin-6").attr("data-edited", true);
                    $(".c-search #i-where-origin-6").attr("data-validated", true);
                    $(".c-search input[name='origin-6']").val(search.origin6_value);

                    $(".c-search #i-where-destination-6").val(search.destination6);
                    $(".c-search #i-where-destination-6").attr("data-edited", true);
                    $(".c-search #i-where-destination-6").attr("data-validated", true);
                    $(".c-search input[name='destination-6']").val(search.destination6_value);

                    $(".c-search input[name='goDate-6']").val(search.goDate6);
                }
            };
            self.setPaxView(search.adults, search.childrens, search.infants);
            self.bound = true;
            fireItemBound();
        }
    };

    this.loadFromMetaSearch = function (request) {
        var isAfter = request.goDate ? (moment(request.goDate, "DD/MM/YYYY").isAfter() || moment(request.goDate, "DD/MM/YYYY").isSame(moment(), 'day')) : true;
        //type
        var searchType = request.oneWay ? "oneWay" : request.openJaw ? "openJaw" : "roundTrip";
        //var searchType = search.type == 0 ? "roundTrip" : search.type == 1 ? "oneWay" : search.type == 2 ? "openJaw" : "roundTrip";
        $("#i-search li[data-value='" + searchType + "']").click();

        //cabin
        $("#i-search li[data-value='" + request.cabinClass + "']").click();

        //origin
        $(".c-search #i-where-origin").val(request.origin.name);
        $(".c-search #i-where-origin").attr("data-edited", true);
        $(".c-search #i-where-origin").attr("data-validated", true);
        $(".c-search input[name='origin']").val(request.origin.value);

        //destination
        $(".c-search #i-where-destination").val(request.destination.name);
        $(".c-search #i-where-destination").attr("data-edited", true);
        $(".c-search #i-where-destination").attr("data-validated", true);
        $(".c-search input[name='destination']").val(request.destination.value);

        //goDate
        if (isAfter)
            $(".c-search input[name='goDate']").val(request.goDate.dateString);

        //returnDate
        if (!request.oneWay) {
            //$(".c-search input[name='returnDate']").removeAttr("readonly");
            $(".c-search input[name='returnDate']").removeAttr("disabled");
            if (isAfter)
                $(".c-search input[name='returnDate']").val(request.goDate2.dateString);
        }
        else {
            $(".c-search input[name='returnDate']").val("");
            $(".c-search input[name='returnDate']").attr("readonly", "true");
            $(".c-search input[name='returnDate']").attr("disabled", "disabled");
        }

        //OpenJaw
        if (request.openJaw) {
            //origin2
            $(".c-search #i-where-origin-2").val(request.origin2.name);
            $(".c-search #i-where-origin-2").attr("data-edited", true);
            $(".c-search #i-where-origin-2").attr("data-validated", true);
            $(".c-search input[name='origin-2']").val(request.origin2.value);

            //destination2
            $(".c-search #i-where-destination-2").val(request.destination2.name);
            $(".c-search #i-where-destination-2").attr("data-edited", true);
            $(".c-search #i-where-destination-2").attr("data-validated", true);
            $(".c-search input[name='destination-2']").val(request.destination2.value);

            //goDate2
            if (isAfter)
                $(".c-search input[name='goDate-2']").val(request.goDate2.dateString);

            if (request.origin3 && request.destination3 && request.goDate3) {
                if ($(".c-search #i-where-origin-3").length == 0)
                    window.FSWhat('openJaw', 'add');
                $(".c-search #i-where-origin-3").val(request.origin3.name);
                $(".c-search #i-where-origin-3").attr("data-edited", true);
                $(".c-search #i-where-origin-3").attr("data-validated", true);
                $(".c-search input[name='origin-3']").val(request.origin3.value);

                $(".c-search #i-where-destination-3").val(request.destination3.name);
                $(".c-search #i-where-destination-3").attr("data-edited", true);
                $(".c-search #i-where-destination-3").attr("data-validated", true);
                $(".c-search input[name='destination-3']").val(request.destination3.value);

                $(".c-search input[name='goDate-3']").val(request.goDate3.dateString);
            }

            if (request.origin4 && request.destination4 && request.goDate4) {
                if ($(".c-search #i-where-origin-4").length == 0)
                    window.FSWhat('openJaw', 'add');
                $(".c-search #i-where-origin-4").val(request.origin4.name);
                $(".c-search #i-where-origin-4").attr("data-edited", true);
                $(".c-search #i-where-origin-4").attr("data-validated", true);
                $(".c-search input[name='origin-4']").val(request.origin4.value);

                $(".c-search #i-where-destination-4").val(request.destination4.name);
                $(".c-search #i-where-destination-4").attr("data-edited", true);
                $(".c-search #i-where-destination-4").attr("data-validated", true);
                $(".c-search input[name='destination-4']").val(request.destination4.value);

                $(".c-search input[name='goDate-4']").val(request.goDate4.dateString);
            }

            if (request.origin5 && request.destination5 && request.goDate5) {
                if ($(".c-search #i-where-origin-5").length == 0)
                    window.FSWhat('openJaw', 'add');
                $(".c-search #i-where-origin-5").val(request.origin5.name);
                $(".c-search #i-where-origin-5").attr("data-edited", true);
                $(".c-search #i-where-origin-5").attr("data-validated", true);
                $(".c-search input[name='origin-5']").val(request.origin5.value);

                $(".c-search #i-where-destination-5").val(request.destination5.name);
                $(".c-search #i-where-destination-5").attr("data-edited", true);
                $(".c-search #i-where-destination-5").attr("data-validated", true);
                $(".c-search input[name='destination-5']").val(request.destination5.value);

                $(".c-search input[name='goDate-5']").val(request.goDate5.dateString);
            }


            if (request.origin6 && request.destination6 && request.goDate6) {
                if ($(".c-search #i-where-origin-6").length == 0)
                    window.FSWhat('openJaw', 'add');
                $(".c-search #i-where-origin-6").val(request.origin6.name);
                $(".c-search #i-where-origin-6").attr("data-edited", true);
                $(".c-search #i-where-origin-6").attr("data-validated", true);
                $(".c-search input[name='origin-6']").val(request.origin6.value);

                $(".c-search #i-where-destination-6").val(request.destination6.name);
                $(".c-search #i-where-destination-6").attr("data-edited", true);
                $(".c-search #i-where-destination-6").attr("data-validated", true);
                $(".c-search input[name='destination-6']").val(request.destination6.value);

                $(".c-search input[name='goDate-6']").val(request.goDate6.dateString);
            }
        };
        self.setPaxView(request.adults, request.childs, request.infants);
        self.bound = true;
        fireItemBound();
    }

    this.setPaxView = function(adults, childrens, infants){
        var adultsMin = $(".c-select-mp[data-pax='adults']").find(".c-m");
        var childrensMin = $(".c-select-mp[data-pax='childs']").find(".c-m");
        var infantsMin = $(".c-select-mp[data-pax='infants']").find(".c-m");
        for (var i = 0; i < 9; i++) {
            adultsMin.click();
            childrensMin.click();
            infantsMin.click();
        }

        var adultsPlus = $(".c-select-mp[data-pax='adults']").find(".c-p");
        var childrensPlus = $(".c-select-mp[data-pax='childs']").find(".c-p");
        var infantsPlus = $(".c-select-mp[data-pax='infants']").find(".c-p");

        for (var i = 1; i < adults; i++)
            adultsPlus.click();
        for (var i = 0; i < childrens; i++)
            childrensPlus.click();
        for (var i = 0; i < infants; i++)
            infantsPlus.click();
    };

    this.bindViewIndex = function (index) {
        if (self.flights.length > index && index > -1)
            self.bindView(self.flights[index]);
    };

    //Model
    this.arrayToModel = function (array) {
        return {
            key: array[0], type: array[1], cabin: array[2],
            origin: array[3], origin_value: array[4], destination: array[5], destination_value: array[6], goDate: array[7], returnDate: array[8],
            origin2: array[9] || null, origin2_value: array[10] || null, destination2: array[11] || null, destination2_value: array[12] || null, goDate2: array[13] || null,
            adults: array[14], childrens: array[15], infants: array[16], requestId: array[17] || null, origin3: array[18], origin3_value: array[19], destination3: array[20], destination3_value: array[21], goDate3: array[22], origin4: array[23], origin4_value: array[24], destination4: array[25], destination4_value: array[26], goDate4: array[27], origin5: array[28], origin5_value: array[29], destination5: array[30], destination5_value: array[31], goDate5: array[32], origin6: array[33], origin6_value: array[34], destination6: array[35], destination6_value: array[36], goDate6: array[37],
        };
    };
    this.modelToArray = function (model) {
        return [model.key, model.type, model.cabin, model.origin, model.origin_value, model.destination, model.destination_value, model.goDate, model.returnDate,
        model.origin2 || "", model.origin2_value || "", model.destination2 || "", model.destination2_value || "", model.goDate2 || "",
        model.adults, model.childrens, model.infants, model.requestId || "", model.origin3 || "", model.origin3_value || "", model.destination3 || "", model.destination3_value || "", model.goDate3 || "", model.origin4 || "", model.origin4_value || "", model.destination4 || "", model.destination4_value || "", model.goDate4 || "", model.origin5 || "", model.origin5_value || "", model.destination5 || "", model.destination5_value || "", model.goDate5 || "", model.origin6 || "", model.origin6_value || "", model.destination6 || "", model.destination6_value || "", model.goDate6 || ""];
    };
    this.getAllSearches = function () {
        return self.flights.select(function (item) { return self.arrayToModel(item); });
    };

    //Other Methods
    this.saveSearch = function (array) {
        if (array && !window.isArray(array))
            array = self.modelToArray(array);
        array = array || self.extractView();
        var nFlights = self.flights.grep(function (arr) { return arr[0] != array[0]; });

        nFlights.splice(0, 0, array);
        self.flights = nFlights.take(5);

        self.saveArray();
    };
    this.loadLastSearch = function () {
        if (self.flights.length > 0)
            return self.arrayToModel(self.flights[0]);
        return null;
    };
    this.bindLastSearch = function () {
        if (window.site && window.site.preloadSearch && window.site.currentAirRequest && window.site.currentAirRequest.summary)
        {
            var rq = window.site.currentAirRequest.summary;
            var _type = rq.roundTrip ? 0 : rq.oneWay ? 1 : rq.openJaw ? 2 : rq.multi ? 3 : 0;
            var _cabinClass = rq.cabinClass;
            var _origin = rq.origin ? rq.origin.name : "";
            var _origin_value = rq.origin ? rq.origin.value : "";
            var _destination = rq.destination ? rq.destination.name : "";
            var _destination_value = rq.destination ? rq.destination.value : "";
            var _goDate = rq.goDate ? rq.goDate.dateString : "";
            var _returnDate = rq.roundTrip && rq.goDate2 ? rq.goDate2.dateString : "";
            var _origin2 = (rq.openJaw || rq.multi) && rq.origin2 ? rq.origin2.name : "";
            var _origin2_value = (rq.openJaw || rq.multi) && rq.origin2 ? rq.origin2.value : "";
            var _destination2 = (rq.openJaw || rq.multi) && rq.destination2 ? rq.destination2.name : "";
            var _destination2_value = (rq.openJaw || rq.multi) && rq.destination2 ? rq.destination2.value : "";
            var _goDate2 = (rq.openJaw || rq.multi) && rq.goDate2 ? rq.goDate2.dateString : "";
            var _adults = rq.adults;
            var _childrens = rq.childs;
            var _infants = rq.infants;
            var _key = _type + _cabinClass + _adults + _childrens + _infants;
            var _origin3 = rq.multi && rq.origin3 ? rq.origin3.name : "";
            var _origin3_value = rq.multi && rq.origin3 ? rq.origin3.value : "";
            var _destination3 = rq.multi && rq.destination3 ? rq.destination3.name : "";
            var _destination3_value = rq.multi && rq.destination3 ? rq.destination3.value : "";
            var _goDate3 = rq.multi && rq.goDate3 ? rq.goDate3.dateString : "";
            var _origin4 = rq.multi && rq.origin4 ? rq.origin4.name : "";
            var _origin4_value = rq.multi && rq.origin4 ? rq.origin4.value : "";
            var _destination4 = rq.multi && rq.destination4 ? rq.destination4.name : "";
            var _destination4_value = rq.multi && rq.destination4 ? rq.destination4.value : "";
            var _goDate4 = rq.multi && rq.goDate4 ? rq.goDate4.dateString : "";
            var _origin5 = rq.multi && rq.origin5 ? rq.origin5.name : "";
            var _origin5_value = rq.multi && rq.origin5 ? rq.origin5.value : "";
            var _destination5 = rq.multi && rq.destination5 ? rq.destination5.name : "";
            var _destination5_value = rq.multi && rq.destination5 ? rq.destination5.value : "";
            var _goDate5 = rq.multi && rq.goDate5 ? rq.goDate5.dateString : "";
            var _origin6 = rq.multi && rq.origin6 ? rq.origin6.name : "";
            var _origin6_value = rq.multi && rq.origin6 ? rq.origin6.value : "";
            var _destination6 = rq.multi && rq.destination6 ? rq.destination6.name : "";
            var _destination6_value = rq.multi && rq.destination6 ? rq.destination6.value : "";
            var _goDate6 = rq.multi && rq.goDate6 ? rq.goDate6.dateString : "";
            if (_origin_value) _key += _origin_value;
            if (_destination_value) _key += _destination_value;
            if (_goDate) _key += _goDate;
            if (_returnDate) _key += _returnDate;
            if (_origin2_value) _key += _origin2_value;
            if (_destination2_value) _key += _destination2_value;
            if (_goDate2) _key += _goDate2;
            if (_origin3_value) _key += _origin3_value;
            if (_destination3_value) _key += _destination3_value;
            if (_goDate3) _key += _goDate3;
            if (_origin4_value) _key += _origin4_value;
            if (_destination4_value) _key += _destination4_value;
            if (_goDate4) _key += _goDate4;
            if (_origin5_value) _key += _origin5_value;
            if (_destination5_value) _key += _destination5_value;
            if (_goDate5) _key += _goDate5;
            if (_origin6_value) _key += _origin6_value;
            if (_destination6_value) _key += _destination6_value;
            if (_goDate6) _key += _goDate6;

            var arr = [_key, _type, _cabinClass, _origin, _origin_value, _destination, _destination_value, _goDate, _returnDate, _origin2, _origin2_value, _destination2, _destination2_value, _goDate2, parseInt(_adults), parseInt(_childrens), parseInt(_infants), "", _origin3, _origin3_value, _destination3, _destination3_value, _goDate3, _origin4, _origin4_value, _destination4, _destination4_value, _goDate4, _origin5, _origin5_value, _destination5, _destination5_value, _goDate5, _origin6, _origin6_value, _destination6, _destination6_value, _goDate6];

            self.bindView(self.arrayToModel(arr));
        }
        else
            self.bindView(self.loadLastSearch());
    };

    //Handlers
    var fireLoadData = function () {
        try { loadHandlers.forEach(function (item) { item(self.flights); }); }
        catch (e) { }
    };
    this.onLoadData = function (_function) {
        if (_function) {
            loadHandlers.push(_function);
            if (self.loaded)
                _function(self.flights);
        }
    };
    var fireSaveData = function () {
        try { saveHandlers.forEach(function (item) { item(self.flights); }); }
        catch (e) { }
    };
    this.onSaveData = function (_function) {
        if (_function)
            saveHandlers.push(_function);
    };
    var fireItemBound = function () {
        try { boundHandlers.forEach(function (item) { item(self.flights); }); }
        catch (e) { }
    };
    this.onItemBound = function (_function) {
        if (_function) {
            boundHandlers.push(_function);
            if (self.bound)
                _function(self.flights);
        }
    };

    this.loadArray();
};
window.flightHistory = new FlightHistoryClass();

var HotelHistoryClass = function () {
    var self = this;
    this.key = "_hsx" + window.site.partnerId;
    this.hotels = [];
    this.loaded = false;
    this.bound = false;
    var loadHandlers = [];
    var saveHandlers = [];
    var boundHandlers = [];

    //Array Methods
    this.loadArray = function () {
        try { self.hotels = JSON.parse(localStorage.getItem(self.key)); }
        catch (e) { }
        self.hotels = self.hotels || [];
        self.loaded = true;
        fireLoadData();
    };
    this.saveArray = function () {
        fireSaveData();
        try { localStorage.setItem(self.key, JSON.stringify(self.hotels || [])); }
        catch (e) { }
    };

    //View
    this.createKey = function (model) {
        var key = model.destinationHotel_value + model.goDateHotel + model.returnDateHotel + model.rooms + model.adultsHotel1 + model.adultsHotel2 + model.adultsHotel3 + model.childrensHotel1 + model.childrensHotel2 + model.childrensHotel3;
        return key;
    };
    this.extractView = function () {
        var destinationHotel = $(".c-search #i-where-destination-hotel").val();
        var destinationHotel_value = $(".c-search #i-destination-hotel-input").val();
        var goDateHotel = $(".c-search input[name='goDateHotel']").val();
        var returnDateHotel = $(".c-search input[name='returnDateHotel']").val();
        var rooms = parseInt($("#i-search-room-pop input[name='rooms']").val());
        var nbAdults1 = parseInt($("#i-search-room1:visible #nbadultroom1").val());
        var nbChilds1 = parseInt($("#i-search-room1:visible #nbchildroom1").val());
        var nbAdults2 = parseInt(typeof $("#i-search-room2:visible #nbadultroom2").val() == "undefined" ? 0 : $("#i-search-room2:visible #nbadultroom2").val());
        var nbChilds2 = parseInt(typeof $("#i-search-room2:visible #nbchildroom2").val() == "undefined" ? 0 : $("#i-search-room2:visible #nbchildroom2").val());
        var nbAdults3 = parseInt(typeof $("#i-search-room3:visible #nbadultroom3").val() == "undefined" ? 0 : $("#i-search-room3:visible #nbadultroom3").val());
        var nbChilds3 = parseInt(typeof $("#i-search-room3:visible #nbchildroom3").val() == "undefined" ? 0 : $("#i-search-room3:visible #nbchildroom3").val());
        var childsAges = "";
        $("#i-search-room-pop .c-child-age select option:selected").each(function () {
            childsAges += $(this).parent().attr('id') + "=" + $(this).val() + "|";
        });
        var key = destinationHotel_value + goDateHotel + returnDateHotel + rooms + nbAdults1 + nbAdults2 + nbAdults3 + nbChilds1 + nbChilds2 + nbChilds3;
        return [key, destinationHotel, destinationHotel_value, goDateHotel, returnDateHotel, rooms, nbAdults1, nbAdults2, nbAdults3, nbChilds1, nbChilds2, nbChilds3, childsAges];
    };
    this.bindView = function (model) {
        var search = null;
        if (model) {
            if (window.isArray(model))
                search = self.arrayToModel(model);
            else
                search = model;
        }
        else
            return;
        if (search) {
            //destination hotel
            $(".c-search #i-where-destination-hotel").val(search.destinationHotel);
            $(".c-search #i-where-destination-hotel").attr("data-edited", true);
            $(".c-search #i-where-destination-hotel").attr("data-validated", true);
            $(".c-search input[name='destination-hotel']").val(search.destinationHotel_value);


            isAfter = moment(search.goDateHotel, "DD/MM/YYYY").isAfter();
            //goDate hotel
            if (isAfter)
                $(".c-search input[name='goDateHotel']").val(search.goDateHotel);

            //returnDate hotel
            if (isAfter)
                $(".c-search input[name='returnDateHotel']").val(search.returnDateHotel);


            //Paxes Hotel
            $(".c-search #nbRoom").val(search.rooms);
            $(".c-search #nbRoom").attr("data-edited", true);

            //rooms display
            for (i = 1; i < search.rooms; i++) {
                $("#i-search-room-pop input[name='rooms']").parent().find(".c-p").click();
            }


            //adult per room
            for (i = 0; i < 9; i++) {
                $("#i-search-room-pop #nbadultroom1").parent().find(".c-m").click();
                $("#i-search-room-pop #nbadultroom2").parent().find(".c-m").click();
                $("#i-search-room-pop #nbadultroom3").parent().find(".c-m").click();
            }
            for (w = 1; w <= search.adultsHotel1 - 1; w++)
                $("#i-search-room-pop #nbadultroom1").parent().find(".c-p").click();
            for (w = 1; w <= search.adultsHotel2 - 1; w++)
                $("#i-search-room-pop #nbadultroom2").parent().find(".c-p").click();
            for (w = 1; w <= search.adultsHotel3 - 1; w++)
                $("#i-search-room-pop #nbadultroom3").parent().find(".c-p").click();


            // children per room
            for (i = 0; i < 3; i++) {
                $("#i-search-room-pop #nbchildroom1").parent().find(".c-m").click();
                $("#i-search-room-pop #nbchildroom2").parent().find(".c-m").click();
                $("#i-search-room-pop #nbchildroom3").parent().find(".c-m").click();
            }
            for (x = 1; x <= search.childrensHotel1; x++)
                $("#i-search-room-pop #nbchildroom1").parent().find(".c-p").click();
            for (x = 1; x <= search.childrensHotel2; x++)
                $("#i-search-room-pop #nbchildroom2").parent().find(".c-p").click();
            for (x = 1; x <= search.childrensHotel3; x++)
                $("#i-search-room-pop #nbchildroom3").parent().find(".c-p").click();

            var aChildsAges = search.arrayChildsAges.split('|');
            aChildsAges.each(function (i, v) {
                if (i != "") {
                    var vKeyVal = i.split('=');
                    if (vKeyVal && vKeyVal.length == 2)
                        $("#i-search-room-pop #" + vKeyVal[0]).val(vKeyVal[1]);
                }

            });

            self.bound = true;
            fireItemBound();
        }
    };
    this.bindViewIndex = function (index) {
        if (self.hotels.length > index && index > -1)
            self.bindView(self.hotels[index]);
    };

    //Model
    this.arrayToModel = function (array) {
        return {
            key: array[0], destinationHotel: array[1], destinationHotel_value: array[2],
            goDateHotel: array[3], returnDateHotel: array[4], rooms: array[5], adultsHotel1: array[6], adultsHotel2: array[7], adultsHotel3: array[8],
            childrensHotel1: array[9], childrensHotel2: array[10], childrensHotel3: array[11], arrayChildsAges: array[12]
        };
    };
    this.modelToArray = function (model) {
        return [model.key, model.destinationHotel, model.destinationHotel_value, model.goDateHotel, model.returnDateHotel, model.rooms, model.adultsHotel1, model.adultsHotel2, model.adultsHotel3,
            model.childrensHotel1, model.childrensHotel2, model.childrensHotel3, model.arrayChildsAges];
    };
    this.getAllSearches = function () {
        return self.hotels.select(function (item) { return self.arrayToModel(item); });
    };

    //Other Methods
    this.saveSearch = function (array) {
        if (array && !window.isArray(array))
            array = self.modelToArray(array);
        array = array || self.extractView();
        var nHotels = self.hotels.grep(function (arr) { return arr[0] != array[0] });
        nHotels.splice(0, 0, array);
        self.hotels = nHotels.take(5);
        self.saveArray();
    };
    this.loadLastSearch = function () {
        if (self.hotels.length > 0)
            return self.arrayToModel(self.hotels[0]);
        return null;
    };
    this.bindLastSearch = function () {
        self.bindView(self.loadLastSearch());
    };

    //Handlers
    var fireLoadData = function () {
        try { loadHandlers.forEach(function (item) { item(self.hotels); }); }
        catch (e) { }
    };
    this.onLoadData = function (_function) {
        if (_function) {
            loadHandlers.push(_function);
            if (self.loaded)
                _function(self.hotels);
        }
    };
    var fireSaveData = function () {
        try { saveHandlers.forEach(function (item) { item(self.flights); }); }
        catch (e) { }
    };
    this.onSaveData = function (_function) {
        if (_function)
            saveHandlers.push(_function);
    };
    var fireItemBound = function () {
        try { boundHandlers.forEach(function (item) { item(self.hotels); }); }
        catch (e) { }
    };
    this.onItemBound = function (_function) {
        if (_function) {
            boundHandlers.push(_function);
            if (self.bound)
                _function(self.hotels);
        }
    };

    this.loadArray();
};
window.hotelHistory = new HotelHistoryClass();
//*****************************************************************************************************************************************************************************************************************************************

baseStorage.onLoadData(function (data) {
    if (data.searchTab == "hotel")
        loader.push(function () { $("#i-nav-hotels a").click(); });
});
baseStorage.onSaveData(function (data) {
    data.searchTab = $('#i-search-hotels').css("display") == "block" ? "hotel" : "flight";
});

flightHistory.onLoadData(baseStorage.loadData);
flightHistory.onSaveData(baseStorage.saveData);
hotelHistory.onLoadData(baseStorage.loadData);
hotelHistory.onSaveData(baseStorage.saveData);

//ItemBound Event Sample
flightHistory.onItemBound(toggleSwitch);
//hotelHistory.onItemBound(function () { console.log("Hotel Search has been bound to the DOM"); });