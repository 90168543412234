webClient.controller('WorldTourNZVP', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {

    //Display Form
    $scope.sent = 0;

    //DateMin by default for first itinerary
    $scope.dateMin = "23/04/2017";
    //DateMax by default for first itinerary
    $scope.dateMax = "10/04/2018";

    $window.$scope = $scope;
    $scope._util = tseUtil;
    $scope.getText = function (key) { return window.culture.texts[key]; };


    //Cities Arrays
    $scope.franceCities = ["Paris", "Bordeaux", "Lyon", "Marseille", "Nice", "Toulouse"];
    $scope.nzCities = ["Auckland", "Wellington", "Christchurch", "Queenstown"];
    $scope.auxCities = {};
    $scope.auxDates = {};

    //Rest Api Client Object
    $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);

    //WorldTour Data
    $scope.worldTour = {
        reference: '', lastName: '', email: '', bookingNumber: '', remarks: '', phoneNumber: '',
    };
    $scope.worldTour.reference = '00005';


    //Return true if arg1 is after srg2
    $scope.isDateAfter = function (arg1, arg2) {
        var mArg1, mArg2;
        mArg1 = (typeof (arg1) === "string") ? moment(arg1, "DD/MM/YYYY") : moment(arg1);
        mArg2 = (typeof (arg2) === "string") ? moment(arg2, "DD/MM/YYYY") : moment(arg2);
        return mArg1.isAfter(mArg2);
    };

    //Validate selected dates idDateCalendar: the next idate will be modify
    $scope.validateSegmentsDate = function (dateSegmentMin, idDateCalendar) {
        var dateMax = $scope.dateMax;
        dateSegmentMin = moment(dateSegmentMin, "DD/MM/YYYY");
        dateSegmentMin = moment(dateSegmentMin).format('DD/MM/YYYY');
        var nextInput = parseInt(idDateCalendar.substring(idDateCalendar.length - 1)) + 1;
        $('input', '#i-date-departure-' + nextInput).attr('data-check', "{check:'date',date_min:'" + dateSegmentMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
        if ($('input', '#i-date-departure-' + nextInput).val() != "") {
            if ($scope.isDateAfter(dateSegmentMin, $('input', '#i-date-departure-' + nextInput).val())) {
                $('input', '#i-date-departure-' + nextInput).parent().removeClass("c-field-ok");
                $('input', '#i-date-departure-' + nextInput).parent().addClass("c-field-ko");
            }
            else {
                $('input', '#i-date-departure-' + nextInput).parent().removeClass("c-field-ko");
                $('input', '#i-date-departure-' + nextInput).parent().addClass("c-field-ok");
            }
        }
    };

    //Drop
    $scope.callDrop = function (_event, _type) {
        var varIsCountry = (_type == 'country') ? 1 : 0;
        var name = 'i-' + _type;
        var value = $("#ul-" + _type).get(0);
        $window.FSelect({ e: _event, myObj: value, vName: name, isCountry: varIsCountry });
    };
    //Pop
    $scope.callTitlePop = function (_event, _type) {
        var value = _event.target;
        var name = 'i-' + _type + '-pop';
        $window.FPop({ oTarget: value, iPop: name, vStyle: 'classic' });
    };

    //Send Request To Api
    $scope.sendWordlTourRequest = function () {
        if ($window.XLFormValid({ iCont: 'i-form-tour' })) {
            $scope.worldTour.segments =
                     [{ departure: $scope.auxCities.FranceCity, arrival: "Tokyo", date: $scope.auxDates.dateDepartureFrance },
                      { departure: "Tokyo", arrival: "Auckland", date: $scope.auxDates.TokyoDate },
                      { departure: $scope.auxCities.NZDepartureCity, arrival: "San Francisco", date: $scope.auxDates.NZDate },
                      { departure: "San Francisco", arrival: $scope.auxCities.FranceCity, date: $scope.auxDates.sanFranciscodate },

                     ];

            $window.FLoading({ vAction: 'show' });

            //Call rest api transaction
            $scope.apiClient.sendWorldTourForm($scope.worldTour).success(function (response) {
                $window.FLoading({ vAction: 'hide' });
                if (response && response.status == 0) {
                    $scope.sent = 1;
                    $window.scrollTo(0, 0);
                }
                else {
                    $window.FLoading({ vAction: 'hide' });
                    $window.XLConfirm({
                        vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                        vTextYes: 'OK Merci',
                        vHideNo: true
                    });
                }
            }).error(function () {
                $window.FLoading({ vAction: 'hide' });
                $window.XLConfirm({
                    vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                    vTextYes: 'OK Merci',
                    vHideNo: true
                });
            });
        }
    };
}]);