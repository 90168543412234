webClient.controller('WorldTourFormNZ', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {
    $window.$scope = $scope;
    $scope._util = tseUtil;
    $scope.getText = function (key) { return window.culture.texts[key]; };
    $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
    $scope.sent = 0;
    $scope.worldTour = { segments: [{ departure: '', arrival: '', date: '' }] };
    $scope.worldTour.reference = '00002';

    $scope.worldTour.stopAsia = false;
    $scope.worldTour.stopLosAngeles = false;

    $scope.worldTour.segments[0].departure = 'Paris';
    $scope.worldTour.segments[0].arrival = 'Nouvelle - Zélande';

    $(document).ready(function () {
        var dateMinAux = new Date();
        var dateMin = new Date(2016, 3, 1);

        if ($scope.isDateAfter(dateMinAux, dateMin))
            dateMin = dateMinAux;

        dateMin = moment(dateMin).format('DD/MM/YYYY');
        var dateMax = new Date(new Date().setYear(new Date().getFullYear() + 1));
        dateMax = moment(dateMax).format('DD/MM/YYYY');
        for (var i = 0; i < 3; i++) { $('input', '#i-date-departure-'+i).attr('data-check', "{check:'date',date_min:'" + dateMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");}
    });

    $scope.callDrop = function (_event, type) {
        var varIsCountry = (type == 'country') ? 1 : 0;
        var name = 'i-' + type;
        var value = $("#ul-" + type).get(0);
        $window.FSelect({ e: _event, myObj: value, vName: name, isCountry: varIsCountry });
    };
    $scope.callTitlePop = function (_event, type) {
        var value = _event.target;
        var name = 'i-' + type + '-pop';
        $window.FPop({ oTarget: value, iPop: name, vStyle: 'classic' });
    };

    $scope.validateSegmentsDate = function(dateSegmentMin, idDateCalendar)
    {
        var dateMax = new Date(new Date().setYear(new Date().getFullYear() + 1));
        dateMax = moment(dateMax).format('DD/MM/YYYY');

        dateSegmentMin = moment(dateSegmentMin, "DD/MM/YYYY");
        dateSegmentMin = dateSegmentMin.add(1, 'days');
        dateSegmentMin = moment(dateSegmentMin).format('DD/MM/YYYY');
        if (idDateCalendar == '#i-date-departure-0')
        {
            if ($scope.worldTour.stopAsia) {
                $('input', '#i-date-departure-stopAsia').attr('data-check', "{check:'date',date_min:'" + dateSegmentMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");

                if($scope.worldTour.stopAsiaDate!=undefined)
                    $window.XLFormValid({ iCont: 'i-form-tour' });
            }
            else
                $('input', '#i-date-departure-1').attr('data-check', "{check:'date',date_min:'" + dateSegmentMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
        }
        if(idDateCalendar == '#i-date-departure-stopAsia')
        {
            if ($scope.worldTour.stopAsia) {
                $('input', '#i-date-departure-1').attr('data-check', "{check:'date',date_min:'" + dateSegmentMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");

                if ($scope.worldTour.NZdate!=undefined)
                    $window.XLFormValid({ iCont: 'i-form-tour' });
            }
        }
        if (idDateCalendar == '#i-date-departure-1')
        {
            $('input', '#i-date-departure-2').attr('data-check', "{check:'date',date_min:'" + dateSegmentMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
            if($scope.worldTour.pacifcDate!=undefined)
                $window.XLFormValid({ iCont: 'i-form-tour' });
        }

        if (idDateCalendar == '#i-date-departure-2')
        {
            if($scope.worldTour.stopLosAngeles)
                $('input', '#i-date-departure-stopLosAngeles').attr('data-check', "{check:'date',date_min:'" + dateSegmentMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
            if ($scope.worldTour.dateStopLosAngeles != undefined)
                $window.XLFormValid({ iCont: 'i-form-tour' });
        }
    };

    $scope.isDateAfter= function(arg1, arg2)
    {
        var mArg1, mArg2;
        mArg1 = (typeof (arg1) === "string") ? moment(arg1, "DD/MM/YYYY") : moment(arg1);
        mArg2 = (typeof (arg2) === "string") ? moment(arg2, "DD/MM/YYYY") : moment(arg2);
        return mArg1.isAfter(mArg2);
    };

    $scope.stopAsia = function( val)
    {
        if (val) {
            if (!$scope.worldTour.stopAsia)
                FSmoothOpen({ oOpener: $('#i-radio-stop1-yes')[0], iSmooth: 'i-smooth-option-1' });

            $scope.worldTour.stopAsia = true;
            var dateMin = new Date();
            dateMin = moment(dateMin).format('DD/MM/YYYY');
            var dateMax = new Date(new Date().setYear(new Date().getFullYear() + 1));
            dateMax = moment(dateMax).format('DD/MM/YYYY');

            if ($scope.worldTour.dateDepartureParis!=undefined)
            {
                dateMin = moment($scope.worldTour.dateDepartureParis, "DD/MM/YYYY");
                dateMin = dateMin.add(1, 'days');
                dateMin = moment(dateMin).format('DD/MM/YYYY');
                $('input', '#i-date-departure-stopAsia').attr('data-check', "{check:'date',date_min:'" + dateMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
            }
            else
            {
                $('input', '#i-date-departure-stopAsia').attr('data-check', "{check:'date',date_min:'" + dateMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
            }
        }
        else {
            if ($scope.worldTour.stopAsia)
            {
                $scope.worldTour.stopAsia = false;
                $scope.worldTour.segments[0].arrival = 'Nouvelle - Zélande';
                $('input', '#i-date-departure-stopAsia').removeAttr('data-check', "{check:'date',date_min:'" + dateMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
                $("#i-stopAsia-text").removeAttr('data-check', "{check:'required'}");
                FSmoothOpen({ oOpener: $('#i-radio-stop1-no')[0], iSmooth: 'i-smooth-option-1' });
            }
        }
    };

    $scope.stopLosAngeles = function (val) {
        if (val) {
            if (!$scope.worldTour.stopLosAngeles)
                FSmoothOpen({ oOpener: $('#i-radio-stop-2-yes')[0], iSmooth: 'i-smooth-option-2' });

            $scope.worldTour.stopLosAngeles = true;
            var dateMin = new Date();
            dateMin = moment(dateMin).format('DD/MM/YYYY');
            var dateMax = new Date(new Date().setYear(new Date().getFullYear() + 1));
            dateMax = moment(dateMax).format('DD/MM/YYYY');

            if ($scope.worldTour.pacifcDate != undefined)
            {
                dateMin = moment($scope.worldTour.pacifcDate, "DD/MM/YYYY");
                dateMin = dateMin.add(1, 'days');
                dateMin = moment(dateMin).format('DD/MM/YYYY');
                $('input', '#i-date-departure-stopLosAngeles').attr('data-check', "{check:'date',date_min:'" + dateMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
            }
            else {
                $('input', '#i-date-departure-stopLosAngeles').attr('data-check', "{check:'date',date_min:'" + dateMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
            }
        }
        else {
            if ($scope.worldTour.stopLosAngeles) {
                $scope.worldTour.stopLosAngeles = false;
                $('input', '#i-date-departure-stopLosAngeles').removeAttr('data-check', "{check:'date',date_min:'" + dateMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
                FSmoothOpen({ oOpener: $('#i-radio-stop2-no')[0], iSmooth: 'i-smooth-option-2' });
            }
        }
    };

    $scope.sendRequest = function () {
        $scope.worldTour.error = false;
        $scope.worldTour.segments = [{ departure: '', arrival: '', date: '' }];
        $scope.worldTour.segments[0].departure = 'Paris';
        $scope.worldTour.segments[0].date = $scope.worldTour.dateDepartureParis;

        if (!$scope.worldTour.pacificCity)
        {
            $("#i-pacific-options").addClass("c-field-ko");
            $window.XLFormValid({ iCont: 'i-form-tour' });
        }
        else {

            if ($("#i-pacific-options").hasClass("c-field-ko"))
            {
                $("#i-pacific-options").removeClass("c-field-ko");
            }

            if($scope.worldTour.stopAsia && !$scope.worldTour.cityStopAsia)
            {
                $("#i-stopAsia-text").attr('data-check', "{check:'required'}");
                $window.XLFormValid({ iCont: 'i-form-tour' });
            }
            else
            {
                //STOP ASIA
                if ($scope.worldTour.stopAsia && $scope.worldTour.cityStopAsia != undefined)
                {

                    if ($scope.isDateAfter($scope.worldTour.stopAsiaDate, $scope.worldTour.dateDepartureParis)) {
                        $scope.worldTour.segments[0].arrival = $scope.worldTour.cityStopAsia;
                        $scope.worldTour.segments.push({ departure: $scope.worldTour.cityStopAsia, arrival: "Nouvelle - Zélande", date: $scope.worldTour.stopAsiaDate });

                        if ($scope.isDateAfter($scope.worldTour.NZdate, $scope.worldTour.stopAsiaDate))
                            $scope.worldTour.segments.push({ departure: "Nouvelle - Zélande", arrival: $scope.worldTour.pacificCity, date: $scope.worldTour.NZdate });
                        else
                            $scope.worldTour.error = true;
                    }
                    else {
                        $scope.worldTour.error = true;
                    }
                }
                else
                {
                    $scope.worldTour.segments[0].arrival = "Nouvelle - Zélande";

                    if ($scope.isDateAfter($scope.worldTour.NZdate, $scope.worldTour.dateDepartureParis))
                        $scope.worldTour.segments.push({ departure: "Nouvelle - Zélande", arrival: $scope.worldTour.pacificCity, date: $scope.worldTour.NZdate });
                    else {
                        $scope.worldTour.error = true;
                    }
                }

                //STOP LOS ANGELES
                if ($scope.worldTour.stopLosAngeles && $scope.worldTour.dateStopLosAngeles != undefined) {

                    if ($scope.isDateAfter($scope.worldTour.pacifcDate, $scope.worldTour.NZdate)) {
                        $scope.worldTour.segments.push({ departure: $scope.worldTour.pacificCity, arrival: "Los Angeles", date: $scope.worldTour.pacifcDate });
                        if ($scope.isDateAfter($scope.worldTour.dateStopLosAngeles, $scope.worldTour.pacifcDate))
                            $scope.worldTour.segments.push({ departure: "Los Angeles", arrival: "Paris", date: $scope.worldTour.dateStopLosAngeles });
                        else
                        {
                            $scope.worldTour.error = true;
                        }
                    }
                    else {
                        $scope.worldTour.error = true;
                    }

                }
                else {
                    if ($scope.isDateAfter($scope.worldTour.pacifcDate, $scope.worldTour.NZdate))
                        $scope.worldTour.segments.push({ departure: $scope.worldTour.pacificCity, arrival: "Paris", date: $scope.worldTour.pacifcDate });
                    else
                    {
                        $scope.worldTour.error = true;
                    }
                }
                if (!$scope.worldTour.error) {
                    if ($window.XLFormValid({ iCont: 'i-form-tour' }) && !$scope.worldTour.error) {
                        $window.FLoading({ vAction: 'show' });
                        $scope.apiClient.sendWorldTourForm($scope.worldTour).success(function (data) {
                            $window.FLoading({ vAction: 'hide' });
                            if (data.status == 0) {
                                $scope.sent = 1;
                                $window.scrollTo(0, 0);
                            }
                            else {
                                $window.FLoading({ vAction: 'hide' });
                                $window.XLConfirm({
                                    vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                                    vTextYes: 'OK Merci',
                                    vHideNo: true
                                });
                            }
                        }).error(function () {
                            $window.FLoading({ vAction: 'hide' });
                            $window.XLConfirm({
                                vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                                vTextYes: 'OK Merci',
                                vHideNo: true
                            });
                        });
                    }
                }
                else {
                    $window.XLConfirm({
                        vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                        vTextYes: 'OK Merci',
                        vHideNo: true
                    });
                }
            }
        }
    };
}]);
