webClient.controller('BasketChangeFlight', ['$scope', '$window', '$timeout', '$location', '$i18next', '$http', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 
    function ($scope, $window, $timeout, $location, $i18next, $http, tseApiClient, tseUtil, airHelper) {
        $scope.accommodationIsReady = false;
        //Initialize Variables
        $scope.getText = function (key) { return window.culture.texts[key]; };
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        var searchUrl = $window.site.currentAirRequest.searchUrl + "?" + tseUtil.Location.getQueryString();
        var user = window.site;
        var existWonderboxCode = user && user.loginInfo && user.loginInfo.Code != null && user.loginInfo.Code != undefined;
        $scope.shouldAutoFill = !(user.isLogged && user.type == 2) ||
            parseInt(new URLSearchParams(tseUtil.Location.getQueryString()).get('autofillInfo')) == 1 ? true : false;
        $scope.siteType = $window.site.type;
        $scope.requestSummary = $window.site.currentAirRequest.summary;
        $scope.requestSummary = $window.site.currentAirRequest.summary;
        $scope.searchForm = {};
        $scope.initialPriceValue = 0;
        $scope.searchForm.type = $scope.requestSummary.roundTrip ? 0 : $scope.requestSummary.oneWay ? 1 : 2;
        $scope.searchForm.cabin = $scope.requestSummary.cabinClass;
        $scope.searchForm.origin = $scope.requestSummary.origin.name;
        $scope.searchForm.origin_value = $scope.requestSummary.origin.value;
        $scope.searchForm.destination = $scope.requestSummary.destination.name;
        $scope.searchForm.destination_value = $scope.requestSummary.destination.value;
        $scope.searchForm.goDate = $scope.requestSummary.goDate.dateString;
        $scope.searchForm.adults = $scope.requestSummary.adults;
        $scope.searchForm.childrens = $scope.requestSummary.childs;
        $scope.searchForm.infants = $scope.requestSummary.infants;
        $scope.siteIsB2b = user.isLogged && user.type == 2;
        $scope.bkgDataKey = "";
        $scope.currentBkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);
        var bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);
        var keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;

        var purchaseResponse = tseUtil.Session.get("purchaseResponse_" + keyGenerated);

        $scope.currentCabinClass = $window.site.currentAirRequest.summary.cabinClass;
        $scope.view = null;

        $scope.assistanceRequests = null;
        // $scope.requestSummary = $scope.currentBkgData.request;
        if (typeof $window.marketCode === "undefined") {
            $scope.marketCode = '';
        } else {
            $scope.marketCode = $window.marketCode.toUpperCase();
        }


        $window.$scope = $scope;
        $scope.enableFreeFlex = $window.enableFreeFlex === true;
        $scope.selectDocumentsLanguage = lng.substring(0, 2).toUpperCase();
        //Flags
        $scope.enablePaypal = $window.enablePaypal;
        $scope.enableBrandedFares = $window.enableBrandedFares;
        $scope.enableSendParameters = $window.enableSendParameters;
        if ($window.hideInsurance) $scope.hideInsurance = true;
        else $scope.hideInsurance = false;
        if ($window.isAirbus) $scope.isAirbus = $window.isAirbus;

        //block navgation if mismatch beetween paxtypes from hotel and flight
        $scope.invalidPaxTypes = false;

        //never show flexy when searching flight + hotel
        $scope.hideFlexy = $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';
        var siteTypeIsB2b = $scope.siteType === 2;

        $(".c-footer").addClass("d-none");

        //Tracking
        try {
            //GTM
            pushGtmPage('/BasketChangeFlight', 'DP Change Flight', lng, true);
            //window.sendDoubleClick("resavol", null, null, null, null);
            pushPageViewEvent("change_flight", "changeflight");
        }
        catch (ex) {
            console.error(ex.message);
            window.trackingError = ex.message;
        }


        $scope.getWidth = function () {
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            );
        }

        bkgData.totalBasketPrice = function () {
            if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {
                //never ever use totalPriceForcedPrice in B2B!
                var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
                var mandatoryOptionsFees = 0;
                if (proposal.mandatoryFees) {
                    mandatoryOptionsFees = proposal.mandatoryFees.reduce(function (accumulator, mandatoryFee) {
                        return accumulator + mandatoryFee.totalPrice;
                    }, 0);
                }
                if (siteTypeIsB2b) {
                    // mandatory Options
                    var insurance = 0;
                    if ($scope.bkgData.insurancesSelected.isRepatriament != null && $scope.bkgData.insurancesSelected.isRepatriament.amount != null) {
                        insurance = parseFloat($scope.bkgData.insurancesSelected.isRepatriament.amount);
                    }
                    var totalAmount = ($scope.bkgData.prixTotalWithoutMargin.value - insurance + $scope.bkgData.accommodations[0].totalPriceRacknet);
                    $scope.bkgData.agencyMarginHotel.value = getAmountFromMargin(totalAmount, $scope.bkgData.agencyMarginHotel.margin);
                    var basketPrice = totalAmount + $scope.bkgData.agencyMarginHotel.value + insurance + mandatoryOptionsFees;
                    console.log("FlightBookingBody.B2B.basketPrice", basketPrice);
                    return basketPrice;
                }
                else {
                    var basketPrice = $scope.bkgData.prixTotal.value + $scope.bkgData.accommodations[0].totalPriceForcedPrice + mandatoryOptionsFees;
                    console.log("FlightBookingBody.B2C.basketPrice", basketPrice);
                    return basketPrice;
                }
            } else {
                var basketPrice = $scope.bkgData.prixTotal.value;
                console.log("FlightBookingBody.Other.basketPrice", basketPrice);
                return basketPrice;
            }
        };

        $scope.bkgData = bkgData;
        if (purchaseResponse) {
            bkgData.purchaseResponse = purchaseResponse;
        }
        $(document).on('gotuserinfo', function (event, data, forceUpdate) {

            //autocomplete
            if (data && $scope.bkgData.bookingRequest) {
                $scope.bkgData.bookingRequest.buyer = data.userInfos.buyer;
                $scope.bkgData.bookingRequest.contact = data.userInfos.contact;
                if (forceUpdate) {
                    $scope.$apply();
                }
            }
        });
        angular.element(document).ready(function () {
            setTimeout(function () {
                if ($scope.getWidth() < 768) {
                    window.scrollTo({ top: document.getElementById('scroll-target-mobile').getBoundingClientRect().top + window.pageYOffset - 47 });
                } else if ($scope.getWidth() >= 768 && $scope.getWidth() <= 1140) {
                    window.scrollTo({ top: document.getElementById('scroll-target-desktop').getBoundingClientRect().top + window.pageYOffset + 32 });
                } else {
                    document.getElementById('scroll-target-desktop').scrollIntoView();
                }
            }, 500);
        });

    }]);
