webClient.controller('AirInsurancePaymentResponseBody', ['$scope', '$window', '$timeout', '$i18next', '$filter', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'trackingService', 'gtmProduct', 'omnitureService',
    function ($scope, $window, $timeout, $i18next, $filter, tseApiClient, tseUtil, airHelper, trackingService, gtmProduct, omnitureService) {
    
    $scope.booking = null;
    $window.$scope = $scope;

    $scope.init = function (bookingId, requestId, success) {
        var booking = JSON.parse($window.sessionStorage.getItem('ins_booking_' + bookingId));

        if (booking === null) {
            var apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
            window.airHelper = airHelper;
            apiClient.AIR.getBookingDetails([bookingId]).success(function (data) {

                var view = airHelper.getViewForBookingDetailsEntityVersion2(data);
                booking = view._innerData.bookings[0];
                $window.sessionStorage.setItem('ins_booking_' + bookingId, JSON.stringify(booking));
                $scope.dataBind(booking, requestId, success);
            });
        }
        else {
            $scope.dataBind(booking, requestId, success);
        }

    };

    $scope.dataBind = function (booking, requestId, success) {
        $scope.booking = booking;
        $scope.myBookingsLink = $window.getUrl('reservation/details?reference=' + $filter('bookingNumber')(booking.bookingNumber) + '&email=' + booking.client.email);
        $scope.addInsuranceUrl = $window.getUrl('addinsurance/{0}/{1}'.format(requestId, booking.bookingId));

        if (booking.insurance.promo !== null) {
            $scope.myBookingsLink += "&promo=" + booking.insurance.promo;
            $scope.addInsuranceUrl += "?promo=" + booking.insurance.promo;
        }

        //Tracking
        try {
            //GTM
            if (trackingService.isActivated() && success) {
                trackingService.initDataLayer('purchase');
                trackingService.setAction('id', '');
                trackingService.setAction('affiliation', '');
                trackingService.setAction('revenue', '');
                trackingService.addProduct({
                    name: 'Assurance Rappatriement PostBooking',
                    id: 'insrappost',
                    category: 'insurance',
                    quantity: 1,
                    price: booking.insurance.total
                });
                trackingService.pushDataLayer(null, null);
            } 
        }
        catch (ex) {
            console.log(ex.message);
            $window.trackingError = ex.message;
        }
    };

}]);