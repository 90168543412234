webClient.directive('ngBookingTaxesDetailModal', ['$window', '$timeout', '$i18next', 'templateService', 
    function ($window, $timeout, $i18next, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        key: "=",
        fares: "=",
        showmargin: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_BookingTaxesDetailModalTPL.html');

        $scope.getUrl = $window.getUrl;
        $scope.key = $scope.key.toString();

        if ($scope.showmargin) {
            $scope.pricingModel = 0;
            $scope.totalPricingModel = 0;

            $scope.fares.pricingModels.each(function (value, key) {
                $scope.pricingModel += value.amount.value;
            });

            $scope.totalPricingModel =  $scope.fares.quantity * $scope.pricingModel;

        }

        //Remove Invalid Characters ':' and '|''
        $scope.removeInvalidCharacters = function (dirtyString) {
            if (dirtyString != undefined) {
                dirtyString = dirtyString.replaceAll(":", "-");
                dirtyString = dirtyString.replaceAll("\\|", "-");
                return dirtyString;
            }
        }

        $scope.key = $scope.removeInvalidCharacters($scope.key);

        //Set Passenger type name to display
        $scope.setPaxTypeName = function (pType) {
            switch (pType) {
                case 1:
                    $scope.paxTypeName = $i18next.t('loadingpage.child');
                    break;
                case 2:
                    $scope.paxTypeName = $i18next.t('loadingpage.infant');
                    break;
                default:
                    $scope.paxTypeName = $i18next.t('loadingpage.adult');
            }
        }

        $scope.setPaxTypeName($scope.fares.paxType);

        //Close the modal
        $scope.closeModal = function () {
            $scope.$emit("closeTaxesDetailsModal");
        };

        //Update modal data
        $scope.$on("updateTaxesDetailModalData", function (event, args) {
            $scope.paxType = args.fares.paxType;
            $scope.key = args.key.toString();
            $scope.key = $scope.removeInvalidCharacters($scope.key);
            $scope.fares = args.fares;
            $scope.setPaxTypeName(args.fares.paxType);
        });

        //Refresh
        $timeout(function () {
            //Remodal workaround
            $(document).find(".remodal").each(function (a, c) {
                var d = $(c), e = d.data("remodal-options");
                e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                    d["remodal"](e)
            });
        },
            0,
            false);
    };
    return directive;
}]);