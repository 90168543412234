webClient.directive('ngTaxesDetailModal', ['$window', '$timeout', 'templateService', function ($window, $timeout, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        paxtype: "=",
        key: "=",
        fares: "=",
        paxnumber: "=",
        showmargin: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_TaxesDetailModalTPL.html');

        $scope.getUrl = $window.getUrl;
        $scope.key = $scope.key.toString();

        //Remove Invalid Characters ':' and '|''
        $scope.removeInvalidCharacters = function (dirtyString) {
            if (dirtyString != undefined) {
                dirtyString = dirtyString.replaceAll(":", "-");
                dirtyString = dirtyString.replaceAll("\\|", "-");
                return dirtyString;
            }
        }

        $scope.key = $scope.removeInvalidCharacters($scope.key);

        //Set Passenger type name to display
        $scope.setPaxTypeName = function(pType) {
            switch (pType) {
            case 1:
                $scope.paxTypeName = "enfant";
                break;
            case 2:
                $scope.paxTypeName = "bébé";
                break;
            default:
                $scope.paxTypeName = "adulte";
            }
        }

        $scope.setPaxTypeName($scope.paxtype);

        //Close the modal
        $scope.closeModal = function() {
            $scope.$emit("closeTaxesDetailsModal");
        };

        //Update modal data
        $scope.$on("updateTaxesDetailModalData", function(event, args) {
            $scope.paxType = args.paxType;
            $scope.key = args.key.toString();
            $scope.key = $scope.removeInvalidCharacters($scope.key);
            $scope.fares = args.fares;
            $scope.paxnumber = args.paxnumber;
            $scope.setPaxTypeName(args.paxType);
        });

        //Refresh
        $timeout(function () {
                //Remodal workaround
                $(document).find(".remodal").each(function (a, c) {
                    var d = $(c), e = d.data("remodal-options");
                    e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                        d["remodal"]({ hashTracking: false }) //prevent hash to be added
                });
            },
            0,
            false);
        
    };
    return directive;
}]);
