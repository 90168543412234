webClient.controller('B2B.WorldTourQuotation', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {
    $window.$scope = $scope;
    $scope._util = tseUtil;
    $scope.getText = function (key) { return window.culture.texts[key]; };

    // Stops 3 = Trip 1 -> 454
    // Stops 4 = Trip 2 -> 620
    // Stops 5 = Trip 3 -> 764
    // Stops 6 = Trip 4 -> 1130

    $scope.stops = parseInt(tseUtil.Location.getQueryStringVar("s"), 8) / 100;
    $scope.mode = tseUtil.Location.getQueryStringVar("reverse") == 1;
    //reverse trajects
    if ($scope.mode == 0) {
        // Trip 1
        $scope.trip1Path1Departure = ["Paris", "Nice", "Lyon"];
        $scope.trip1Path2Arrival = ["Tokyo", "Hong Kong", "Bangkok", "Jakarta", "Denpasar", "Kuala Lumpur"];
        $scope.trip1Path3Departure = ["Tokyo ", "Hong Kong", "Osaka", "Shanghai", "Manille", "Bangkok", "Jakarta", "Denpasar"];
        $scope.trip1Path3Arrival = ["Sydney", "Brisbane", "Melbourne"];
        $scope.trip1Path5Departure = ["Johannesbourg", "Cap Town"];

        // Trip 2
        $scope.trip2Path1Departure = ["Paris", "Nice", "Lyon", "Marseille", "Toulouse"];
        $scope.trip2Path1Arrival = ["Johannesbourg", "Cap Town", "Durban"];
        $scope.trip2Path3Departure = ["Sydney", "Brisbane", "Melbourne"];
        $scope.trip2Path3Arrival = ["Auckland", "Wellington", "Christchurch", "Queenstown"];
        $scope.trip2Path4Departure = ["Auckland", "Christchurch", "Wellington", "Queenstown"];
        $scope.trip2Path4Arrival = ["Sydney", "Brisbane", "Melbourne", "Auckland"];
        $scope.trip2Path6Departure = ["Santiago", "Sao Paulo", "Rio de Janeiro", "Lima", "Bogota", "Buenos Aires"];


        // Trip 3 & 4
        $scope.trip34Path1Departure = ["Paris", "Nice", "Lyon", "Marseille", "Toulouse"];
        $scope.trip34Path2Arrival = ["Tokyo", "Hong Kong", "Bangkok", "Jakarta", "Denpasar", "Kuala Lumpur"];
        $scope.trip34Path3Departure = ["Tokyo", "Hong Kong", "Osaka", "Shanghai", "Manille", "Bangkok", "Jakarta", "Denpasar"];
        $scope.trip34Path3Arrival = ["Sydney", "Brisbane", "Melbourne"];
        $scope.trip34Path4Departure = ["Sydney", "Brisbane", "Melbourne"];
        $scope.trip34Path4Arrival = ["Honolulu", "Los Angeles", "San Francisco", "New-York", "Dallas"];
        $scope.trip34Path5Departure = ["Honolulu", "Los Angeles", "San Francisco", "New-York", "Dallas"];
        $scope.trip34Path5Arrival = ["New-York", "Dallas", "San Francisco", "Washington", "Miami", "Las Vegas", "Boston", "Denver", "Orlando", "Nouvelle-Orléans", "Honolulu", "Phoenix", "Chicago", "Mexico", "San Diego", "Philadelphie", "Cancun", "Los Angeles", "Fort Lauderdale", "Seattle", "Detroit", "Toronto"];
        $scope.trip34Path6Departure = ["Los Angeles", "New-York", "Dallas", "San Francisco", "Washington", "Miami", "Las Vegas", "Boston", "Denver", "Orlando", "Fort Lauderdale", "Nouvelle-Orléans", "Honolulu", "Seattle", "Phoenix", "San Diego", "Chicago", "Philadelphie", "Detroit", "Vancouver", "Montréal", "Toronto", "Mexico", "Cancun", "Iles Caiman", "Costa Rica", "Bahamas", "Barbade", "Ste Lucie", "Bermudes", "Grenade"];

        // Trip 4
        $scope.trip4Path4Departure = ["Sydney", "Brisbane"];
        $scope.trip4Path5Arrival = ["Brisbane", "Sydney"];
        $scope.trip4Path6Departure = ["Sydney", "Brisbane", "Melbourne"];
        $scope.trip4Path6Arrival = ["Honolulu", "Los Angeles", "San Francisco", "New-York", "Dallas"];
        $scope.trip4Path7Departure = ["Los Angeles", "New-York", "Dallas", "San Francisco", "Honolulu"];
        $scope.trip4Path7Arrival = ["New-York", "Dallas", "San Francisco", "Washington", "Miami", "Las Vegas", "Boston", "Denver", "Orlando", "Nouvelle-Orléans", "Honolulu", "Phoenix", "Chicago", "Mexico"];
        $scope.trip4Path8Departure = ["Los Angeles", "New-York", "Dallas", "San Francisco", "Washington", "Miami", "Las Vegas", "Boston", "Denver", "Orlando", "Fort Lauderdale", "Nouvelle-Orléans", "Honolulu", "Seattle", "Phoenix", "San Diego", "Chicago", "Philadelphie", "Detroit", "Vancouver", "Montréal", "Toronto", "Mexico", "Cancun", "Iles Caiman", "Costa Rica", "Bahamas", "Barbade", "Ste Lucie", "Bermudes", "Grenade"];

    }
    else {
        // Trip 1
        $scope.trip1Path1Departure = ["Paris", "Nice", "Lyon"];
        $scope.trip1Path1Arrival = ["Johannesbourg", "Cap Town"];
        //$scope.trip1Path2Arrival = ["Johannesbourg", "Cap Town"];
        $scope.trip1Path3Departure = ["Sydney", "Brisbane", "Melbourne"];
        $scope.trip1Path3Arrival = ["Tokyo ", "Hong Kong", "Osaka", "Shanghai", "Manille", "Bangkok", "Jakarta", "Denpasar"];
        $scope.trip1Path4Departure = ["Tokyo ", "Hong Kong", "Bangkok", "Jakarta", "Denpasar", "Kuala Lumpur"];
        //$scope.trip1Path5Departure = ["Johannesbourg", "Cap Town"];

        // Trip 2
        $scope.trip2Path1Departure = ["Paris", "Nice", "Lyon", "Marseille", "Toulouse"];
        $scope.trip2Path1Arrival = ["Rio de Janeiro", "Sao Paulo", "Buenos Aires", "Lima", "Santiago", "Bogota"];
        $scope.trip2Path2Arrival = ["Sydney", "Melbourne"];
        $scope.trip2Path3Departure = ["Sydney", "Brisbane", "Melbourne"];
        $scope.trip2Path3Arrival = ["Auckland", "Wellington", "Christchurch", "Queenstown"];
        $scope.trip2Path4Departure = ["Auckland", "Christchurch", "Wellington", "Queenstown"];
        $scope.trip2Path4Arrival = ["Sydney", "Brisbane", "Melbourne", "Auckland"];
        $scope.trip2Path6Departure = ["Johannesbourg", "Cap Town", "Durban"];


        // Trip 3 & 4
        $scope.trip34Path1Departure = ["Paris", "Nice", "Lyon", "Marseille", "Toulouse"];
        $scope.trip34Path1Arrival = ["Los Angeles", "New-York", "Dallas", "San Francisco", "Washington", "Miami", "Las Vegas", "Boston", "Denver", "Orlando", "Fort Lauderdale", "Nouvelle-Orléans", "Honolulu", "Seattle", "Phoenix", "San Diego", "Chicago", "Philadelphie", "Detroit", "Vancouver", "Montréal", "Toronto", "Mexico", "Cancun", "Iles Caiman", "Costa Rica", "Bahamas", "Barbade", "Ste Lucie", "Bermudes", "Grenade"];
        $scope.trip34Path2Arrival = ["San Francisco", "Honolulu", "Los Angeles", "New-York", "Dallas"];
        $scope.trip34Path2Departure = ["Los Angeles", "New-York", "Dallas", "San Francisco", "Washington", "Miami", "Las Vegas", "Boston", "Denver", "Orlando", "Nouvelle-Orléans", "Honolulu", "Phoenix", "Chicago", "Mexico", "San Diego", "Philadelphie", "Cancun", "Fort Lauderdale", "Seattle", "Detroit", "Toronto"];
        $scope.trip34Path3Departure = ["Los Angeles", "San Francisco", "Honolulu", "New-York", "Dallas"];
        $scope.trip34Path3Arrival = ["Sydney", "Brisbane", "Melbourne"];
        $scope.trip34Path4Departure = ["Sydney", "Brisbane", "Melbourne"];
        $scope.trip34Path4Arrival = ["Tokyo ", "Hong Kong", "Osaka", "Shanghai", "Manille", "Bangkok", "Jakarta", "Denpasar"];
        $scope.trip34Path5Departure = ["Tokyo ", "Hong Kong", "Bangkok", "Jakarta", "Denpasar", "Kuala Lumpur"];
        $scope.trip34Path5Arrival = ["New-York", "Dallas", "San Francisco", "Washington", "Miami", "Las Vegas", "Boston", "Denver", "Orlando", "Nouvelle-Orléans", "Honolulu", "Phoenix", "Chicago", "Mexico", "San Diego", "Philadelphie", "Cancun", "Los Angeles", "Fort Lauderdale", "Seattle", "Detroit", "Toronto"];
        $scope.trip34Path6Departure = ["Los Angeles", "New-York", "Dallas", "San Francisco", "Washington", "Miami", "Las Vegas", "Boston", "Denver", "Orlando", "Fort Lauderdale", "Nouvelle-Orléans", "Honolulu", "Seattle", "Phoenix", "San Diego", "Chicago", "Philadelphie", "Detroit", "Vancouver", "Montréal", "Toronto", "Mexico", "Cancun", "Iles Caiman", "Costa Rica", "Bahamas", "Barbade", "Ste Lucie", "Bermudes", "Grenade"];

        // Trip 4
        $scope.trip4Path4Departure = ["Sydney", "Brisbane"];
        $scope.trip4Path5Arrival = ["Brisbane", "Sydney"];
        $scope.trip4Path6Departure = ["Sydney", "Brisbane", "Melbourne"];
        $scope.trip4Path6Arrival = ["Tokyo ", "Hong Kong", "Osaka", "Shanghai", "Manille", "Bangkok", "Jakarta", "Denpasar"];
        $scope.trip4Path7Departure = ["Tokyo ", "Hong Kong", "Bangkok", "Jakarta", "Denpasar", "Kuala Lumpur"];
        $scope.trip4Path7Arrival = ["New-York", "Dallas", "San Francisco", "Washington", "Miami", "Las Vegas", "Boston", "Denver", "Orlando", "Nouvelle-Orléans", "Honolulu", "Phoenix", "Chicago", "Mexico"];
        $scope.trip4Path8Departure = ["Los Angeles", "New-York", "Dallas", "San Francisco", "Washington", "Miami", "Las Vegas", "Boston", "Denver", "Orlando", "Fort Lauderdale", "Nouvelle-Orléans", "Honolulu", "Seattle", "Phoenix", "San Diego", "Chicago", "Philadelphie", "Detroit", "Vancouver", "Montréal", "Toronto", "Mexico", "Cancun", "Iles Caiman", "Costa Rica", "Bahamas", "Barbade", "Ste Lucie", "Bermudes", "Grenade"];

    }

    $scope.cabinClasses = [{ name: 'Economique', value: 'Y' }, { name: 'Business', value: 'C' }];
    //Agency payment mode: This parameter is used to change wordings to agencies
    if ($window && $window.site && $window.site.loginInfo && $window.site.loginInfo.Client)
        $scope.agencyPaymentMethod = $window.site.loginInfo.Client.PaymentMode;

    //DateMin by default for first itinerary
    $scope.dateMin = moment();
    $scope.dateMin = $scope.dateMin.add('15', 'days');
    $scope.dateMin = moment($scope.dateMin).format('DD/MM/YYYY');

    //DateMax by default for first itinerary
    $scope.dateMax = moment();
    $scope.dateMax = $scope.dateMax.add('1', 'year');
    $scope.dateMax = moment($scope.dateMax).format('DD/MM/YYYY');
    $scope.changeTrip3Path2 = function (selected) {
        if ($scope.mode == 1) {
            switch (selected) {
                case 'Los Angeles':
                    $scope.trip34Path2Arrival = ["San Francisco", "Honolulu"];
                    break;
                case 'New-York':
                    $scope.trip34Path2Arrival = ["Los Angeles", "Dallas", "San Francisco"];
                    break;
                case 'Dallas':
                case 'Phoenix':
                    $scope.trip34Path2Arrival = ["Los Angeles", "New-York", "San Francisco", "Honolulu"];
                    break;
                case 'San Francisco':
                case 'Las Vegas':
                case 'Orlando':
                    $scope.trip34Path2Arrival = ["Los Angeles", "New-York", "Dallas"];
                    break;
                case 'Washington':
                case 'Boston':
                case 'Denver':
                case 'Nouvelle-Orléans':
                case 'Honolulu':
                    $scope.trip34Path2Arrival = ["Los Angeles", "Dallas"];
                    break;
                case 'Miami':
                    $scope.trip34Path2Arrival = ["Los Angeles", "New-York", "San Francisco"];
                    break;
                case 'Chicago':
                    $scope.trip34Path2Arrival = ["Los Angeles", "New-York", "San Francisco", "Dallas"];
                    break;
                case 'Mexico':
                    $scope.trip34Path2Arrival = ["Dallas","Los Angeles"];
                    break
                case 'Fort Lauderdale':
                case 'Seattle':
                case 'Detroit':
                case 'Toronto':                
                    $scope.trip34Path2Arrival = ["Dallas"];
                    break
                case 'San Diego':
                case 'Cancun':
                    $scope.trip34Path2Arrival = ["New-York", "Dallas"];
                    break;
                case 'Philadelphie':
                    $scope.trip34Path2Arrival = ["New-York", "Dallas", "San Francisco"];
                    break;
                default:
                    $scope.trip34Path2Arrival = ["Los Angeles", "Dallas", "Phoenix"];
                    break;
            }
        }

    };
    $scope.changeTrip1 = function (selected) {

        if ($scope.mode == 0) {
            switch (selected) {
                case 'Tokyo':
                case 'Hong Kong':
                    $scope.trip1Path3Arrival = ["Sydney", "Brisbane", "Melbourne"];
                    break;
                default:
                    $scope.trip1Path3Arrival = ['Sydney'];
                    break;
            }
        }
        else {
            switch (selected) {
                case 'Sydney':
                    $scope.trip1Path3Arrival = ["Tokyo", "Hong Kong", "Osaka", "Shanghai", "Manille", "Bangkok", "Jakarta", "Denpasar"];
                    break;
                case 'Brisbane':
                    $scope.trip1Path3Arrival = ["Tokyo", "Hong Kong"];
                    break;
                default:
                    $scope.trip1Path3Arrival = ["Tokyo", "Hong Kong"];
                    break;
            }
        }

    };
    $scope.changeTrip2 = function (selected) {
        switch (selected) {
            case 'Sydney':
                $scope.trip2Path3Arrival = ["Auckland", "Wellington", "Christchurch", "Queenstown"];
                break;
            case 'Brisbane':
                $scope.trip2Path3Arrival = ["Auckland", "Wellington", "Christchurch"];
                break;
            case 'Melbourne':
            default:
                $scope.trip2Path3Arrival = ["Auckland", "Christchurch"];
                break;
        }
    };

    $scope.changeTrip3 = function (selected) {
        if ($scope.mode == 0) {
            switch (selected) {
                case 'Tokyo':
                case 'Hong Kong':
                    $scope.trip34Path3Arrival = ["Sydney", "Brisbane", "Melbourne"];
                    break;
                default:
                    $scope.trip34Path3Arrival = ["Sydney"];
                    break;
            }
        }
        else {
            switch (selected) {
                case 'Los Angeles':
                case 'San Francisco':
                    $scope.trip34Path3Arrival = ["Sydney", "Brisbane", "Melbourne"];
                    break;
                default:
                    $scope.trip34Path3Arrival = ["Sydney"];
                    break;
            }
        }
    };
    $scope.changeTrip2Path4 = function (selected) {

        if ($scope.mode == 0) {

            switch (selected) {
                case 'Auckland':
                case 'Christchurch':
                    $scope.trip2Path4Arrival = ["Sydney", "Brisbane", "Melbourne"];
                    break;
                case 'Wellington':
                    $scope.trip2Path4Arrival = ["Sydney", "Brisbane"];
                    break;
                default:
                    $scope.trip2Path4Arrival = ["Auckland"];
                    break;
            }
        }
        else {

            switch (selected) {
                case 'Auckland':
                case 'Christchurch':
                    $scope.trip2Path4Arrival = ["Sydney", "Brisbane", "Melbourne"];
                    break;
                case 'Wellington':
                    $scope.trip2Path4Arrival = ["Sydney", "Brisbane"];
                    break;
                default:
                    $scope.trip2Path4Arrival = ["Sydney"];
                    break;
            }
        }

    };

    $scope.changeTrip4Path6 = function (selected) {
        if ($scope.mode == 0) {
            switch (selected) {
                case 'Sydney':
                    $scope.trip4Path6Arrival = ["Honolulu", "Los Angeles", "San Francisco", "New-York", "Dallas"];
                    break;
                default:
                    $scope.trip4Path6Arrival = ["Los Angeles", "San Francisco"];
                    break;
            }
        }
        else {
            switch (selected) {
                case 'Sydney':
                    $scope.trip4Path6Arrival = ["Tokyo", "Hong Kong", "Osaka", "Shanghai", "Manille", "Bangkok", "Jakarta", "Denpensar"];
                    break;
                case 'Brisbane':
                    $scope.trip4Path6Arrival = ["Tokyo", "Hong Kong"];
                    break;
                default:
                    $scope.trip4Path6Arrival = ["Tokyo", "Hong Kong"];
                    break;
            }
        }

    };

    $scope.changeTrip4Path7 = function (selected) {
        switch (selected) {
            case 'Los Angeles':
                $scope.trip4Path7Arrival = ["New-York", "Dallas", "San Francisco", "Washington", "Miami", "Las Vegas", "Boston", "Denver", "Orlando", "Nouvelle-Orléans", "Honolulu", "Phoenix", "Chicago", "Mexico"];
                break;
            case 'New-York':
                $scope.trip4Path7Arrival = ["Dallas", "San Francisco", "Miami", "Las Vegas", "Orlando", "Phoenix", "San Diego", "Chicago", "Philadelphie", "Cancun"];
                break;
            case 'Dallas':
                $scope.trip4Path7Arrival = ["Los Angeles", "New-York", "San Francisco", "Washington", "Las Vegas", "Boston", "Denver", "Orlando", "Fort Lauderdale", "Nouvelle-Orléans", "Honolulu", "Seattle", "Phoenix", "San Diego", "Chicago", "Philadelphie", "Detroit", "Toronto", "Mexico", "Cancun"];
                break;
            case 'San Francisco':
                $scope.trip4Path7Arrival = ["Los Angeles", "New-York", "Dallas", "Miami", "Phoenix", "Chicago", "Philadelphie"];
                break;
            default:
                $scope.trip4Path7Arrival = ["Los Angeles", "Dallas", "Phoenix"];
                break;
        }
    };

    $scope.changeTrip3Path4 = function (selected) {
        if ($scope.mode == 0) {
            switch (selected) {
                case 'Sydney':
                    $scope.trip34Path4Arrival = ["Honolulu", "Los Angeles", "San Francisco", "New-York", "Dallas"];
                    break;
                default:
                    $scope.trip34Path4Arrival = ["Los Angeles", "San Francisco"];
                    break;
            }
        }
        else {
            switch (selected) {
                case 'Sydney':
                    $scope.trip34Path4Arrival = ["Tokyo", "Hong Kong", "Osaka", "Shanghai", "Manille", "Bangkok", "Jakarta", "Denpasar"];
                    break;
                default:
                    $scope.trip34Path4Arrival = ["Tokyo", "Hong Kong"];
                    break;
            }
        }
    };


    $scope.changeTrip3Path5 = function (selected) {
        switch (selected) {
            case 'Los Angeles':
                $scope.trip34Path5Arrival = ["New-York", "Dallas", "San Francisco", "Washington", "Miami", "Las Vegas", "Boston", "Denver", "Orlando", "Nouvelle-Orléans", "Honolulu", "Phoenix", "Chicago", "Mexico"];
                break;
            case 'New-York':
                $scope.trip34Path5Arrival = ["Dallas", "San Francisco", "Miami", "Las Vegas", "Orlando", "Phoenix", "San Diego", "Chicago", "Philadelphie", "Cancun"];
                break;
            case 'Dallas':
                $scope.trip34Path5Arrival = ["Los Angeles", "New-York", "San Francisco", "Washington", "Las Vegas", "Boston", "Denver", "Orlando", "Fort Lauderdale", "Nouvelle-Orléans", "Honolulu", "Seattle", "Phoenix", "San Diego", "Miami", "Chicago", "Philadelphie", "Detroit", "Toronto", "Mexico", "Cancun"];
                break;
            case 'San Francisco':
                $scope.trip34Path5Arrival = ["Los Angeles", "New-York", "Dallas", "Miami", "Phoenix", "Chicago", "Philadelphie"]
                break;
            case 'Honolulu':
                $scope.trip34Path5Arrival = ["Los Angeles", "Dallas", "Phoenix"];
                break;
            default:
                $scope.trip34Path5Arrival = ["New-York", "Dallas", "San Francisco", "Washington", "Miami", "Las Vegas", "Boston", "Denver", "Orlando", "Nouvelle-Orléans", "Honolulu", "Phoenix", "Chicago", "Mexico", "San Diego", "Philadelphie", "Cancun", "Los Angeles", "Fort Lauderdale", "Seattle", "Detroit", "Toronto"];
                break;
        }
    };


    //Cities
    $scope.auxCities = {};

    //Dates
    $scope.auxDates = {};

    //Flags
    $scope.addOnAustralia = false;
    $scope.addOnPacifique = false;

    //Rest Api Client Object
    $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);

    //WorldTour Data
    $scope.worldTourQuotationData = {
        reference: '', name: '', mail: '', phone: '', comment: '', cabinClass: 'Y',
        passengers: [{ title: '', firstName: '', lastName: '', birthDate: '' }]
    };
    $scope.worldTourQuotationData.passengers.push({ type: '', firtsName: '', lastName: '', birthDate: '' });
    $scope.worldTourQuotationData.reference = $scope.stops == 3 ? '00001' : $scope.stops == 4 ? '00002' : $scope.stops == 5 ? '00003' : $scope.stops == 6 ? '00004' : null;

    //Add Passenger
    $scope.addPassenger = function () {
        if ($scope.worldTourQuotationData.passengers.length <= 8)
            $scope.worldTourQuotationData.passengers.push({ type: '', firtsName: '', lastName: '', birthDate: '' });
    };

    //Remove Passenger
    $scope.removePassenger = function () {
        if ($scope.worldTourQuotationData.passengers.length > 1) {
            $scope.worldTourQuotationData.passengers.pop();
        }
    };

    //Return true if arg1 is after srg2
    $scope.isDateAfter = function (arg1, arg2) {
        var mArg1, mArg2;
        mArg1 = (typeof (arg1) === "string") ? moment(arg1, "DD/MM/YYYY") : moment(arg1);
        mArg2 = (typeof (arg2) === "string") ? moment(arg2, "DD/MM/YYYY") : moment(arg2);
        return mArg1.isAfter(mArg2);
    };

    //Validate selected dates idDateCalendar: the next idate will be modify
    $scope.validateSegmentsDate = function (dateSegmentMin, idDateCalendar) {
        var dateMax = $scope.dateMax;
        dateSegmentMin = moment(dateSegmentMin, "DD/MM/YYYY");
        dateSegmentMin = moment(dateSegmentMin).format('DD/MM/YYYY');

        var nextInput = parseInt(idDateCalendar.substring(idDateCalendar.length - 1)) + 1;
        $('input', '#i-date-departure-' + nextInput).attr('data-check', "{check:'date',date_min:'" + dateSegmentMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");

        if ($('input', '#i-date-departure-' + nextInput).val() != "") {
            if ($scope.isDateAfter(dateSegmentMin, $('input', '#i-date-departure-' + nextInput).val())) {
                $('input', '#i-date-departure-' + nextInput).parent().removeClass("c-field-ok");
                $('input', '#i-date-departure-' + nextInput).parent().addClass("c-field-ko");
            }
            else {
                $('input', '#i-date-departure-' + nextInput).parent().removeClass("c-field-ko");
                $('input', '#i-date-departure-' + nextInput).parent().addClass("c-field-ok");
            }
        }
        if (($scope.stops == 4 || $scope.stops == 6) && nextInput == 2) {
            $('input', '#i-date-departure-4').attr('data-check', "{check:'date',date_min:'" + dateSegmentMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
        }
    };

    //Add On Pacifique
    $scope.clickInAddOn = function (type) {
        if (type) {
            if (type == "pacifique") {
                if ($scope.addOnPacifique == false)
                    $scope.addOnPacifique = true;
                else
                    $scope.addOnPacifique = false;

            }
            if (type == "australia") {
                if ($scope.addOnAustralia == false)
                    $scope.addOnAustralia = true;
                else
                    $scope.addOnAustralia = false;
            }
        }
    };

    //Drop
    $scope.callDrop = function (_event, _type) {
        var varIsCountry = (_type == 'country') ? 1 : 0;
        var name = 'i-' + _type;
        var value = $("#ul-" + _type).get(0);
        $window.FSelect({ e: _event, myObj: value, vName: name, isCountry: varIsCountry });
    };

    //Pop
    $scope.callTitlePop = function (_event, _type) {
        var value = _event.target;
        var name = 'i-' + _type + '-pop';
        $window.FPop({ oTarget: value, iPop: name, vStyle: 'classic' });
    };

    //Validate Mandatory Information
    $scope.validateMandatoryInformation = function () {
        return ($scope.worldTourQuotationData.itineraries.length <= 14 &&
            $scope.worldTourQuotationData.passengers.length >= 1 &&
            $scope.worldTourQuotationData.passengers.length <= 9);
    };

    //Send Request To Api
    $scope.sendWordlTourRequest = function () {
        //if there is not optional stop checked -> remove data check
        if ($scope.stops == 4 && !$scope.addOnPacifique) {
            $("#opt1").removeAttr("data-check");
            $("#opt2").removeAttr("data-check");
            $("#i-pacific-arr-text").removeAttr("data-check");
            $("#i-pacific-dep-text").removeAttr("data-check");
        }

        if ($scope.stops == 6 && !$scope.addOnAustralia) {
            $("#optAustralia1").removeAttr("data-check");
            $("#optAustralia2").removeAttr("data-check");
            $("#i-australia-arr-text").removeAttr("data-check");
            $("#i-i-australia-dep-text").removeAttr("data-check");
        }

        if ($window.XLFormValid({ iCont: 'i-form-tour' })) {
            $scope.worldTourQuotationData.itineraries = [];

            if ($scope.validateMandatoryInformation()) {
                $window.FLoading({ vAction: 'show' });

                switch ($scope.stops) {
                    case 3:
                        if ($scope.mode == 0) {
                            $scope.worldTourQuotationData.itineraries.push(
                                {
                                    origin: $scope.auxCities.trip1Path1Departure,
                                    destination: 'Singapour',
                                    date: $scope.auxDates.dateDeparture0
                                },
                                {
                                    origin: 'Singapour',
                                    destination: $scope.auxCities.trip1Path2Arrival,
                                    date: $scope.auxDates.dateDeparture1
                                },
                                {
                                    origin: $scope.auxCities.trip1Path3Departure,
                                    destination: $scope.auxCities.trip1Path3Arrival,
                                    date: $scope.auxDates.dateDeparture2
                                },
                                {
                                    origin: 'Sydney',
                                    destination: 'Johannesbourg',
                                    date: $scope.auxDates.dateDeparture3
                                },
                                {
                                    origin: $scope.auxCities.trip1Path5Departure,
                                    destination: $scope.auxCities.trip1Path5Arrival,
                                    date: $scope.auxDates.dateDeparture4
                                }
                            );
                        }
                        else {
                            $scope.worldTourQuotationData.itineraries.push(
                                {
                                    origin: $scope.auxCities.trip1Path1Departure,
                                    destination: $scope.auxCities.trip1Path1Arrival,
                                    date: $scope.auxDates.dateDeparture0
                                },
                                {
                                    origin: 'Johannesbourg',
                                    destination: 'Sydney',
                                    date: $scope.auxDates.dateDeparture1
                                },
                                {
                                    origin: $scope.auxCities.trip1Path3Departure,
                                    destination: $scope.auxCities.trip1Path3Arrival,
                                    date: $scope.auxDates.dateDeparture2
                                },
                                {
                                    origin: $scope.auxCities.trip1Path3Arrival,
                                    destination: 'Singapour',
                                    date: $scope.auxDates.dateDeparture3
                                },
                                {
                                    origin: 'Singapour',
                                    destination: $scope.auxCities.trip1Path5Arrival,
                                    date: $scope.auxDates.dateDeparture4
                                }
                            );
                        }
                        break;
                    case 4:
                        if ($scope.mode == 0) {
                            $scope.worldTourQuotationData.itineraries.push(
                                {
                                    origin: $scope.auxCities.trip2Path1Departure,
                                    destination: $scope.auxCities.trip2Path1Arrival,
                                    date: $scope.auxDates.dateDeparture0
                                },
                                {
                                    origin: 'Johannesbourg',
                                    destination: 'Sydney',
                                    date: $scope.auxDates.dateDeparture1
                                },
                                {
                                    origin: $scope.auxCities.trip2Path3Departure,
                                    destination: $scope.auxCities.trip2Path3Arrival,
                                    date: $scope.auxDates.dateDeparture2
                                },
                                {
                                    origin: $scope.auxCities.trip2Path4Departure,
                                    destination: $scope.auxCities.trip2Path4Arrival,
                                    date: $scope.auxDates.dateDeparture3
                                },
                                {
                                    origin: 'Sydney',
                                    destination: 'Santiago',
                                    date: $scope.auxDates.dateDeparture4
                                },
                                {
                                    origin: $scope.auxCities.trip2Path6Departure,
                                    destination: $scope.auxCities.trip2Path6Arrival,
                                    date: $scope.auxDates.dateDeparture5
                                }
                            );
                        }
                        else {
                            $scope.worldTourQuotationData.itineraries.push(
                                {
                                    origin: $scope.auxCities.trip2Path1Departure,
                                    destination: $scope.auxCities.trip2Path1Arrival,
                                    date: $scope.auxDates.dateDeparture0
                                },
                                {
                                    origin: 'Santiago',
                                    destination: $scope.auxCities.trip2Path2Arrival,
                                    date: $scope.auxDates.dateDeparture1
                                },
                                {
                                    origin: $scope.auxCities.trip2Path3Departure,
                                    destination: $scope.auxCities.trip2Path3Arrival,
                                    date: $scope.auxDates.dateDeparture2
                                },
                                {
                                    origin: $scope.auxCities.trip2Path4Departure,
                                    destination: $scope.auxCities.trip2Path4Arrival,
                                    date: $scope.auxDates.dateDeparture3
                                },
                                {
                                    origin: 'Sydney',
                                    destination: 'Johannesbourg',
                                    date: $scope.auxDates.dateDeparture4
                                },
                                {
                                    origin: $scope.auxCities.trip2Path6Departure,
                                    destination: $scope.auxCities.trip2Path6Arrival,
                                    date: $scope.auxDates.dateDeparture5
                                }
                            );
                        }
                        break;
                    case 5:
                        if ($scope.mode == 0) {
                            $scope.worldTourQuotationData.itineraries.push(
                                {
                                    origin: $scope.auxCities.trip34Path1Departure,
                                    destination: 'Singapour',
                                    date: $scope.auxDates.dateDeparture0
                                },
                                {
                                    origin: 'Singapour',
                                    destination: $scope.auxCities.trip34Path2Arrival,
                                    date: $scope.auxDates.dateDeparture1
                                },
                                {
                                    origin: $scope.auxCities.trip34Path3Departure,
                                    destination: $scope.auxCities.trip34Path3Arrival,
                                    date: $scope.auxDates.dateDeparture2
                                },
                                {
                                    origin: $scope.auxCities.trip34Path4Departure,
                                    destination: $scope.auxCities.trip34Path4Arrival,
                                    date: $scope.auxDates.dateDeparture4
                                },
                                {
                                    origin: $scope.auxCities.trip34Path5Departure,
                                    destination: $scope.auxCities.trip34Path5Arrival,
                                    date: $scope.auxDates.dateDeparture5
                                },
                                {
                                    origin: $scope.auxCities.trip34Path6Departure,
                                    destination: $scope.auxCities.trip34Path6Arrival,
                                    date: $scope.auxDates.dateDeparture6
                                }
                            );
                        }
                        else {
                            $scope.worldTourQuotationData.itineraries.push(
                                {
                                    origin: $scope.auxCities.trip34Path1Departure,
                                    destination: $scope.auxCities.trip34Path1Arrival,
                                    date: $scope.auxDates.dateDeparture0
                                },
                                {
                                    origin: $scope.auxCities.trip34Path2Departure,
                                    destination: $scope.auxCities.trip34Path2Arrival,
                                    date: $scope.auxDates.dateDeparture1
                                },
                                {
                                    origin: $scope.auxCities.trip34Path3Departure,
                                    destination: $scope.auxCities.trip34Path3Arrival,
                                    date: $scope.auxDates.dateDeparture2
                                },
                                {
                                    origin: $scope.auxCities.trip34Path4Departure,
                                    destination: $scope.auxCities.trip34Path4Arrival,
                                    date: $scope.auxDates.dateDeparture4
                                },
                                {
                                    origin: $scope.auxCities.trip34Path5Departure,
                                    destination: 'Singapour',
                                    date: $scope.auxDates.dateDeparture5
                                },
                                {
                                    origin: 'Singapour',
                                    destination: $scope.auxCities.trip34Path6Arrival,
                                    date: $scope.auxDates.dateDeparture6
                                }
                            );
                        }
                        break;
                    case 6:
                        if ($scope.mode == 0) {
                            $scope.worldTourQuotationData.itineraries.push(
                                {
                                    origin: $scope.auxCities.trip34Path1Departure,
                                    destination: 'Singapour',
                                    date: $scope.auxDates.dateDeparture0
                                },
                                {
                                    origin: 'Singapour',
                                    destination: $scope.auxCities.trip34Path2Arrival,
                                    date: $scope.auxDates.dateDeparture1
                                },
                                {
                                    origin: $scope.auxCities.trip34Path3Departure,
                                    destination: $scope.auxCities.trip34Path3Arrival,
                                    date: $scope.auxDates.dateDeparture2
                                },
                                {
                                    origin: $scope.auxCities.trip4Path4Departure,
                                    destination: 'Nouméa',
                                    date: $scope.auxDates.dateDeparture3
                                },
                                {
                                    origin: 'Nouméa',
                                    destination: $scope.auxCities.trip4Path5Arrival,
                                    date: $scope.auxDates.dateDeparture4
                                },
                                {
                                    origin: $scope.auxCities.trip4Path6Departure,
                                    destination: $scope.auxCities.trip4Path6Arrival,
                                    date: $scope.auxDates.dateDeparture5
                                },
                                {
                                    origin: $scope.auxCities.trip4Path7Departure,
                                    destination: $scope.auxCities.trip4Path7Arrival,
                                    date: $scope.auxDates.dateDeparture6
                                },
                                {
                                    origin: $scope.auxCities.trip4Path8Departure,
                                    destination: $scope.auxCities.trip4Path8Arrival,
                                    date: $scope.auxDates.dateDeparture7
                                }
                            );
                        }
                        else {
                            $scope.worldTourQuotationData.itineraries.push(
                                {
                                    origin: $scope.auxCities.trip34Path1Departure,
                                    destination: $scope.auxCities.trip34Path1Arrival,
                                    date: $scope.auxDates.dateDeparture0
                                },
                                {
                                    origin: $scope.auxCities.trip34Path2Departure,
                                    destination: $scope.auxCities.trip34Path2Arrival,
                                    date: $scope.auxDates.dateDeparture1
                                },
                                {
                                    origin: $scope.auxCities.trip34Path3Departure,
                                    destination: $scope.auxCities.trip34Path3Arrival,
                                    date: $scope.auxDates.dateDeparture2
                                },
                                {
                                    origin: $scope.auxCities.trip4Path4Departure,
                                    destination: 'Nouméa',
                                    date: $scope.auxDates.dateDeparture3
                                },
                                {
                                    origin: 'Nouméa',
                                    destination: $scope.auxCities.trip4Path5Arrival,
                                    date: $scope.auxDates.dateDeparture4
                                },
                                {
                                    origin: $scope.auxCities.trip4Path6Departure,
                                    destination: $scope.auxCities.trip4Path6Arrival,
                                    date: $scope.auxDates.dateDeparture5
                                },
                                {
                                    origin: $scope.auxCities.trip4Path7Departure,
                                    destination: 'Singapour',
                                    date: $scope.auxDates.dateDeparture6
                                },
                                {
                                    origin: 'Singapour',
                                    destination: $scope.auxCities.trip4Path8Arrival,
                                    date: $scope.auxDates.dateDeparture7
                                }
                            );
                        }
                        break;
                }

                //Call rest api transaction
                $scope.request = { tdm: $scope.worldTourQuotationData };
                $scope.apiClient.B2B.sendRequests($scope.request).success(function (response) {
                    $window.FLoading({ vAction: 'hide' });
                    if (response && response.status == 0) {
                        $scope.sent = true;
                    }
                    else {
                        $window.XLConfirm({
                            vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                            vTextYes: 'OK Merci',
                            vHideNo: true
                        });
                    }
                }).error(function () {
                    $window.FLoading({ vAction: 'hide' });
                    $window.XLConfirm({
                        vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                        vTextYes: 'OK Merci',
                        vHideNo: true
                    });
                });
            }
        }
    };
}]);
