/*
    A model used to embed GTM datalayers
    Is stored into $window.sessionStorage to be persistent
*/
webClient.factory('fbqModel', ['$window', 'scopeParser', function ($window, scopeParser) {

    fbqModel.prototype.content_type;
    fbqModel.prototype.departing_departure_date;
    fbqModel.prototype.returning_departure_date;
    fbqModel.prototype.origin_airport;
    fbqModel.prototype.destination_airport;
    fbqModel.prototype.num_adults;
    fbqModel.prototype.travel_class;
    fbqModel.prototype.Price;
    fbqModel.prototype.value;
    fbqModel.prototype.currencey;


    function fbqModel() {
        this.content_type = 'flight';
    }

    fbqModel.prototype.track = function (eventName) {
        fbq('track', eventName, this);
        console.info("this function is called ");
        console.info(JSON.stringify(this));
    }

    fbqModel.trackSearchResults = function () {
        if (window.site.type != 7) {
            var model = new fbqModel();
            model.departing_departure_date = scopeParser.getDepartingDate();
            model.returning_departure_date = scopeParser.getReturningDate();
            model.origin_airport = scopeParser.getOriginIATA();
            model.destination_airport = scopeParser.getDestinationIATA();
            model.travel_class = scopeParser.getTravelClass();
            model.num_adults = scopeParser.getPaxNumber();
            model.track('ViewContent');
        }
    }

    fbqModel.trackAibooking = function () {
        if (window.site.type != 7) {
            var model = new fbqModel();
            model.departing_departure_date = scopeParser.getDepartingDate();
            model.returning_departure_date = scopeParser.getReturningDate();
            model.origin_airport = scopeParser.getOriginIATA();
            model.destination_airport = scopeParser.getDestinationIATA();
            model.travel_class = scopeParser.getTravelClass();
            model.num_adults = scopeParser.getPaxNumber();
            model.Price = scopeParser.getPrice();

            model.track('InitiateCheckout');
        }
    }

    fbqModel.trackPaymentConfirmation = function () {
        if (window.site.type != 7) {
            var model = new fbqModel();
            model.departing_departure_date = scopeParser.getDepartingDate();
            model.returning_departure_date = scopeParser.getReturningDate();
            model.origin_airport = scopeParser.getOriginIATA();
            model.destination_airport = scopeParser.getDestinationIATA();
            model.travel_class = scopeParser.getTravelClass();
            model.num_adults = scopeParser.getPaxNumber();
            model.Price = scopeParser.getPrice();
            model.track('Purchase');
        }
    }

    return fbqModel;

}]);