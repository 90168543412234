webClient.controller('AirPreBooking', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'trackingService',
    function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper, trackingService) {
    $window.writePre("Starting AirPreBooking controller...");
    $window.$scope = $scope;
    $scope._util = tseUtil;

    $scope.getText = function (key) { return window.culture.texts[key]; };
    $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);

    $window.writePre("Creating request object...");
    $scope.requestSummary = $window.site.currentAirRequest.summary;
    $scope.searchForm = {};
    $scope.searchForm.type = $scope.requestSummary.roundTrip ? 0 : $scope.requestSummary.oneWay ? 1 : 2;
    $scope.searchForm.cabin = $scope.requestSummary.cabinClass;
    $scope.searchForm.origin = $scope.requestSummary.origin.name;
    $scope.searchForm.origin_value = $scope.requestSummary.origin.value;
    $scope.searchForm.destination = $scope.requestSummary.destination.name;
    $scope.searchForm.destination_value = $scope.requestSummary.destination.value;
    $scope.searchForm.goDate = $scope.requestSummary.goDate.dateString;
    $scope.searchForm.adults = $scope.requestSummary.adults;
    $scope.searchForm.childrens = $scope.requestSummary.childs;
    $scope.searchForm.infants = $scope.requestSummary.infants;
    if ($scope.requestSummary.roundTrip)
        $scope.searchForm.returnDate = $scope.requestSummary.goDate2.dateString;
    if ($scope.requestSummary.openJaw) {
        $scope.searchForm.origin2 = $scope.requestSummary.origin2.name;
        $scope.searchForm.origin2_value = $scope.requestSummary.origin2.value;
        $scope.searchForm.destination2 = $scope.requestSummary.destination2.name;
        $scope.searchForm.destination2_value = $scope.requestSummary.destination2.value;
        $scope.searchForm.goDate2 = $scope.requestSummary.goDate2.dateString;
    }

    $window.writePre("Processing response...");
    $scope.view = null;
    var results = $window.preBook.responseData;
    $scope.rawResults = results;
    $window.preBook.responseData = null;

    $scope.airResults = airHelper.createNonPagedHelper(results, function (view) {
        $scope.view = view;
        $window.writePre("Response processed.");

        $window.writePre("Looking for product selection.");

        $scope.selection = {};
        var product = view.data.products.first(function (p) { return p.outbound.key == $window.preBook.outboundKey });
        if (product != null) {
            $scope.selection.outbound = product.outbound;
            if ($scope.requestSummary.oneWay == false && product.inbounds != null && product.inbounds.length > 0) {
                $scope.selection.inbound = product.inbounds.first(function (it) { return it.key == $window.preBook.inboundKey });
                if ($scope.selection.inbound == null)
                    $scope.selection = null;
            }
        }
        else
            $scope.selection = null;

        tseUtil.Debug.log("Selection is set:");
        tseUtil.Debug.log($scope.selection);

        $window.writePre("Product selection setted. Creating booking object...");

        var index = ($scope.selection.inbound != null && $scope.selection.inbound.proposal && $scope.selection.inbound.proposal.index != null) ? $scope.selection.inbound.proposal.index : $scope.selection.outbound.proposal ? $scope.selection.outbound.proposal.index : null;
        var group = $scope.selection.inbound != null && $scope.selection.inbound.proposal && $scope.selection.inbound.proposal.groupName ? $scope.selection.inbound.proposal.groupName : $scope.selection.outbound.proposal.groupName;
        var auxPurchaseRequest = { index: index, group: group, proposal: 0, brandedFares: true, requestId: $scope.requestSummary.requestId }

        $scope.booking = {
            key: 'bkgData_' + $scope.requestSummary.requestId,
            value: {
                purchaseRequest: auxPurchaseRequest,
                request: $scope.requestSummary,
                cabinclasses: $scope.view.data.cabinClasses,
                preBook: $window.preBook
            }
        };

        $window.writePre("Booking Object Created.");
        tseUtil.Debug.log("Booking Request Data");
        tseUtil.Debug.log($scope.booking);

        if ($scope.selection == null) {
            $window.writePre("Booking product selection is invalid.");

            tseUtil.Debug.log("Invalid Booking Selection");
            //$window.sendAnalytics('/Flightsearch/PreBookingWait-JSError-Selection', 'Invalid Booking Selection');

            //Tracking
            try {
                //GTM
                if (trackingService.isActivated()) {
                    trackingService.pushPageData('/Flightsearch/PreBookingWait-JSError-Selection', 'Invalid Booking Selection', lng, false);
                }
            }
            catch (ex) {
                console.log(ex.message);
                $window.trackingError = ex.message;
            }

            $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/PreBookingWait-JSError-Selection'));
            $window.location.href = $window.site.errorsUrls.InvalidProductSelection;
        }
        else {
            $window.writePre("Saving booking object to the session storage...");

            tseUtil.Session.set($scope.booking.key, $scope.booking.value);
            tseUtil.Debug.log("BookingSession created!");

            $window.writePre("Booking object saved. Redirecting to Booking Funnel");
            $window.location.replace($window.site.currentAirRequest.bookingUrl);
        }
    }, null, null, null);
}]);
