webClient.controller('B2B.headController', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {
    $window.$headScope = $scope;
    $scope.site = $window.site;
    $scope.isLoginRequired = $window.site.isLoginRequired;
    $scope.isLogged = $window.site.isLogged;
    $scope.loginInfo = $window.site.loginInfo;
    $scope.enableWorldTourB2b = $window.enableWorldTourB2b;

    var partnerIdKey = $scope.site.partnerId;
    $scope.clientName = "";
    $scope.firstName = "";
    $scope.lastName = "";

    var menuStatus = tseUtil.Session.get('menuStatus_' + partnerIdKey);

    console.log(menuStatus);

    //If we're not on the home page, we close the menu anyway
    if (!$('body').hasClass("homePage")) {
        menuStatus = false;
    }

    if ($scope.isLogged) {
        $scope.clientName = $scope.loginInfo.Client.CompanyName;
        $scope.firstName = $scope.loginInfo.Agent.FirstName;
        $scope.lastName = $scope.loginInfo.Agent.LastName;
        $scope.b_bookings = $window.site.controllerName == "Flight";
        $scope.b_profile = $window.site.controllerName == "Account" && $window.site.controllerAction == "AccountAgence";
        $scope.b_tracking = $window.site.controllerName == "Account" && $window.site.controllerAction == "AccountReservations";
        $scope.b_um = $window.site.controllerName == "Account" && $window.site.controllerAction == "AccountUMRequest";
        $scope.b_emission = $window.site.controllerName == "Account" && $window.site.controllerAction == "AccountEmission";
        $scope.b_help = $window.site.controllerName == "Account" && $window.site.controllerAction == "AccountHelp";

        $window.accountNumber = $scope.loginInfo.Client.AccountNumber;

    }
    $scope.homeUrl = $window.site.loginUrl;
    $scope.logout = function () {
        tseUtil.Cookies.remove($window.site.loginCookie);
        document.location.href = $window.site.loginUrl;
    };
    if (menuStatus != undefined)
        $scope.menuOpen = menuStatus;
    else if ($window.innerWidth > 1366)
        $scope.menuOpen = true;

    if ($scope.menuOpen) {
        $('#i-header-nav').addClass('c-show');
    }

    $scope.menuClick = function () {

        console.log('click');

        !$scope.menuOpen ? $scope.menuOpen = true : $scope.menuOpen = false;
        tseUtil.Session.set("menuStatus_" +partnerIdKey, $scope.menuOpen);
    };
}]);