/**
* @desc the ngBookingAddBaggages provides support for collect the information of additional baggages in step 1 of the booking process
* @example <div ng-if="hasAdditionalBaggages" ng-booking-add-baggages request="request" paxinfo="paxinfo" addbaggage="proposal.additionalBaggages" selection="selection"></div>
*/

webClient.directive('ngBookingAddBaggages', ['$window', '$timeout', '$i18next', 'templateService', 
    function ($window, $timeout, $i18next, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        request: "=",
        paxinfo: "=",
        addbaggage: "=",
        selection: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_BookingAddBaggages.html');

        $scope.siteType = $window.site.type;
        $scope.getUrl = $window.getUrl;

        //Traduction
        $scope.getT = function (key) {
            var res = $i18next.t(key.toLowerCase());
            return res;
        };

        var adultCount = 0;
        var childCount = 0;
        var noBaggage = {
            key: null,
            description: $i18next.t('airbooking.luggage.noluggage'),
            quantity: 0,
            price: 0,
            weight: 0
        };

        var addBaggages = $window.objectClone($scope.addbaggage);
        addBaggages.each(function (it) {
            it.baggages.unshift($window.objectClone(noBaggage));
            it.baggages.each(function (bg) {
                bg.itineraryKey = it.key;
            });
        });

        $scope.bagPaxInfo = $scope.paxinfo.grep(function (pax) { return pax.paxType == "0" || pax.paxType == "1"; }).map(function (pax) {
            var text = null;
            if (pax.paxType == "0")
                text = $i18next.t('airbooking.luggage.adult') +" " + (++adultCount);
            if (pax.paxType == "1")
                text = $i18next.t('airbooking.luggage.child')+" " + (++childCount);

            if (!pax.baggageByItinerary)
                pax.baggageByItinerary = [];

            addBaggages.each(function (it) {
                it.baggages.each(function (bg) {
                    if (bg.quantity == 0)
                        bg.text = $i18next.t('airbooking.luggage.noluggage')+" : 0 €";
                    else if (bg.quantity == 1)
                        bg.text = "{0} {3} ({0} x {1} kg) : {2} €".format(bg.quantity, bg.weight, bg.price, $i18next.t('airbooking.luggage.luggage'));
                    else
                        bg.text = "{0} {3} ({0} x {1} kg) : {2} €".format(bg.quantity, bg.weight, bg.price, $i18next.t('airbooking.luggage.luggages'));
                });
                if (!pax.baggageByItinerary[it.idx])
                    pax.baggageByItinerary[it.idx] = it.baggages[0];
            });

            return { text: text, inner: pax }
        });

        $scope.baggages = addBaggages.groupByKey("idx");

        $scope.FPop = function (textField, popDiv, style) {
            var target = $("#" + textField).get(0);
            $window.FPop({ oTarget: target, iPop: popDiv, vStyle: style });
        };
        $scope.FSelect = function (name, target, _event) {
            var obj = $("#" + name + "-pop").find("ul").get(0);
            if (target)
                $window.FSelect({ e: { target: target }, myObj: obj, vName: name });
            else
                $window.FSelect({ e: _event, myObj: obj, vName: name });
        };
        var innerBaggageSelected = function (pax, bag, itIdx) {
            if (typeof (CurrencyManager) !== "undefined" && CurrencyManager.isEnabled() === true) {
                if (!bag.eurodescription) {
                    bag.eurodescription = bag.description;
                }
                bag.description = CurrencyManager.getPriceItemSpecificBaggage(bag.eurodescription);
            }
            pax.inner.baggageByItinerary[itIdx] = bag;

            if (typeof (CurrencyManager) !== "undefined" && CurrencyManager.isEnabled() === true) {
                CurrencyManager.updatePriceItems();
            }
        };
        $scope.baggageSelected = function (pax, bag, itIdx) {
            innerBaggageSelected(pax, bag, itIdx);
            if ($scope.checkIfSelected())
                $window.FUpselTog('i-upsel-b', 1);
            else
                $window.FUpselTog('i-upsel-b', 0);
            $scope.updatePrice();
        }
        $scope.checkIfSelected = function () {
            var selected = false;
            for (var i = 0; i < $scope.bagPaxInfo.length; i++) {
                var pax = $scope.bagPaxInfo[i];
                if (pax.inner.baggageByItinerary) {
                    for (var j = 0; j < pax.inner.baggageByItinerary.length; j++) {
                        var bag = pax.inner.baggageByItinerary[j];
                        if (bag && bag.key) {
                            selected = true;
                            break;
                        }
                    }
                }
                if (selected)
                    break;
            }
            return selected;
        };

        $scope.totalPrice = 0;
        $scope.updatePrice = function () {
            var nPrice = 0;
            var nQuantity = 0;
            var nOutbound = 0;
            var nOutboundQ = 0;
            var nInbound = 0;
            var nInboundQ = 0;

            var bagsByType = $scope.bagPaxInfo.map(function (pax) { return pax.inner.baggageByItinerary; }).toSimpleArray().removeNulls().grep(function (bag) { return bag.quantity > 0; }).groupByKey("key");
            var baggagesByType = [];
            for (var key in bagsByType) {
                var bgs = bagsByType[key];
                if (!$window.isArray(bgs))
                    bgs = [bgs];

                var fbgs = bgs.first();
                var bgType = {
                    price: fbgs.price,
                    quantity: fbgs.quantity,
                    weight: fbgs.weight,
                    text: fbgs.text,
                    key: key,
                    typeQuantity: bgs.length,
                    totalQuantity: bgs.length * fbgs.quantity,
                    totalPrice: bgs.length * fbgs.price
                };
                baggagesByType.push(bgType);
            }
            $scope.totalPrice = baggagesByType.sum(function (btype) { return btype.totalPrice; });
            $scope.$emit("addBaggagesChanged", {
                baggages: baggagesByType
            });
        };

        $timeout(function () {
            var pIndex = 0;
            $scope.bagPaxInfo.each(function (pax) {
                addBaggages.each(function (bagByIt) {
                    var bag = pax.inner.baggageByItinerary[bagByIt.idx];
                    innerBaggageSelected(pax, bag, bagByIt.idx);
                    var key = bag.key ? bag.key : "";
                    var target = $('#pax-' + pIndex + '-' + key + '-' + bagByIt.idx).get(0);
                    $scope.FSelect('pax-' + pIndex + '-i-flight-' + (bagByIt.idx + 1), target);
                });
                pIndex++;
            });
            if ($scope.checkIfSelected())
                $window.FUpselTog('i-upsel-b', 1);
            else
                $window.FUpselTog('i-upsel-b', 0);
            $scope.updatePrice();
        });


        //Clean additional baggages selected by the user if the user has selected a branded fares that includes baggages
        //This is called in Purchase1TPL
        $scope.$on("onNoAddBaggagesSelection", function (event, args) {
            $scope.bagPaxInfo.each(function (pax) {
                if ($scope.baggages) {
                    for (var prop in $scope.baggages) {
                        if ($scope.baggages.hasOwnProperty(prop)) {
                            if ($scope.baggages[prop].baggages) {
                                var bag = $scope.baggages[prop].baggages[0];
                                if (!isNaN(prop))
                                    $scope.baggageSelected(pax, bag, prop);
                            }
                        }
                    }
                }
            })
        });

    };
    return directive;
}]);
