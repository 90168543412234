webClient.directive('ngBookingDetailsFlightAlertsRoundtrip', ['$window', 'templateService', 'tseApiClient', 
    function ($window, templateService, tseApiClient) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        itineraries: "=",
        airports: "=",
        cities: "=",
        equipments: "=",
        isproductto: "=",
        charter: "=",
        itinerariescount: "=",
        request: "=",
        istrainonly: "=",
        showaccomodationinfo: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_BookingDetailsFlightAlertsRoundTripTPL.html');

        //Initialize Variables
        $scope.siteType = $window.site.type;
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.hasWarning = false;        

        /**
        * Get City Name or Iata
        * @desc this function returns the city name or airport name. In case of name undefined it returns airportIata
        * @param {string} airportIata to find
        * @returns {string} airportIata
        */
        $scope.getCityNameOrIata = function (airportIata) {
            if ($scope.airports[airportIata].cityIata) {
                if ($scope.cities[$scope.airports[airportIata].cityIata])
                    return $scope.cities[$scope.airports[airportIata].cityIata].name;
            }
            else {
                if ($scope.airports[airportIata].name)
                    return $scope.airports[airportIata].name;
                else
                    return airportIata;
            }
        }

        if ($window.enableFlightWarnings && $scope.itineraries != null) {
            
            if ($scope.itineraries.length <= 2) {

                $scope.dayChange = ($scope.itineraries.any(function (it) { return it.dayChange > 0 }));

                $scope.isSameOutboundOriginInboundDestination = ($scope.itineraries.length > 1 && $scope.itineraries[1] != null) && ($scope.itineraries[0].origin == $scope.itineraries[1].destination);
                $scope.isSameOutboundDestinationInboundOrigin = ($scope.itineraries.length > 1 && $scope.itineraries[1] != null) && ($scope.itineraries[0].destination == $scope.itineraries[1].origin);

                $scope.diffDepartureArrivalAirportOrigin = (($scope.isSameOutboundOriginInboundDestination && $scope.itineraries.length > 1) && ($scope.itineraries[0].departure != $scope.itineraries[1].arrival));
                $scope.diffDepartureArrivalAirportDestination = (($scope.isSameOutboundDestinationInboundOrigin && $scope.itineraries.length > 1) && ($scope.itineraries[0].arrival != $scope.itineraries[1].departure));

                if ($scope.itineraries.length > 1 && $scope.airports[$scope.itineraries[0].arrival].cityIata)
                    $scope.cityProposedDiffCityRequested = ($scope.request.destination.cityIATA != $scope.cities[$scope.airports[$scope.itineraries[0].arrival].cityIata].iata);

                if ($scope.itineraries.length > 1 && $scope.airports[$scope.itineraries[0].departure].cityIata)
                    $scope.cityProposedDiffCityRequestedOrigin = ($scope.request.origin.cityIATA != $scope.cities[$scope.airports[$scope.itineraries[0].departure].cityIata].iata);

                //Technical Stop                    
                $scope.technicalStopOutbound = [];
                $scope.itineraries[0].segments.each(function (seg) {
                    if (seg.stops > 0) {
                        $scope.technicalStopOutbound.push({ departure: seg.departure.airport, arrival: seg.arrival.airport });
                    }
                });

                $scope.technicalStopInbound = [];
                if ($scope.itineraries[1]) {
                    $scope.itineraries[1].segments.each(function (seg) {
                        if (seg.stops > 0) {
                            $scope.technicalStopInbound.push({ departure: seg.departure.airport, arrival: seg.arrival.airport });
                        }
                    });
                }

                $scope.longTransitTimeOutbound = $scope.itineraries[0].stops.any(function (stop) { return (stop.time.totalHours > 6) });
                $scope.longTransitTimeInbound = (($scope.itineraries.length > 1) && $scope.itineraries[1].stops.any(function (stop) { return (stop.time.totalHours > 6) }));

                if (!$scope.istrainonly) {
                    $scope.segmentTrainOrBusOutbound = $scope.itineraries[0] != null && $scope.itineraries[0].segments.any(function (seg) {
                        if (seg.equipment)
                            return ($scope.equipments[seg.equipment].equipmentType == 2 || $scope.equipments[seg.equipment].equipmentType == 3);
                        else
                            return false;
                    })

                    $scope.segmentTrainOrBusInbound = $scope.itineraries.length > 1 && $scope.itineraries[1] != null && $scope.itineraries[1].segments.any(function (seg) {
                        if (seg.equipment)
                            return ($scope.equipments[seg.equipment].equipmentType == 2 || $scope.equipments[seg.equipment].equipmentType == 3);
                        else
                            return false;
                    })
                }

                $scope.airportChangeDuringTheTransitOutbound = ($scope.itineraries[0].segments.length > 1) && ($scope.itineraries[0].segments.any(function (item, i) {
                    if (i + 1 < $scope.itineraries[0].segments.length) {
                        if ($scope.itineraries[0].segments[i + 1] != undefined && item.arrival.airport != $scope.itineraries[0].segments[i + 1].departure.airport) {
                            return true;
                        }
                    }
                }));

                $scope.hasWarning = $scope.dayChange ||
                    $scope.diffDepartureArrivalAirportOrigin ||
                    $scope.diffDepartureArrivalAirportDestination ||
                    $scope.technicalStopOutbound.length > 0 ||
                    $scope.technicalStopInbound.length > 0 ||
                    $scope.longTransitTimeOutbound ||
                    $scope.longTransitTimeInbound ||
                    $scope.segmentTrainOrBusOutbound ||
                    $scope.segmentTrainOrBusInbound ||
                    $scope.airportChangeDuringTheTransitOutbound ||
                    $scope.cityProposedDiffCityRequested ||
                    $scope.cityProposedDiffCityRequestedOrigin;
            }          
        }
    };
    return directive;
}]);