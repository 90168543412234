/**
* @desc the ngPassengerInfoResume provides support for displaying the information resumed associated with a passenger in the purchase funnel
* @example <div ng-repeat="pax in view.paxes" ng-passenger-info-resume paxinfo="pax" cards="cards" outboundbaggage="view.itineraries[0].baggages" inboundbaggage="view.itineraries[1].baggages" request="view.request"></div>
*/

webClient.directive('ngPassengerInfoResume', ['$window', 'templateService', function ($window, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        paxinfo: "=",
        cards: "=",        
        request: "=",
        triptype: "=",
        itineraries: "=",
        proposalsrequested: "=",
        response: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_BookingPaxsResume.html');
        $scope.hasFlightHotelFormSummary = hasCookie("FlightHotelFormSummary") && $window.getUrlVars()['hotelSummary'] === 'true';
        //Initialize Variables
        $scope.getUrl = $window.getUrl;
        $scope.siteType = $window.site.type;
        $scope.enableBlockAgencyRemarks = $window.enableBlockAgencyRemarks;
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.enableSeatMap = !(typeof $window.accountNumber !== 'undefined' && typeof $window.seatMapAccountNumber !== 'undefined' && $window.accountNumber != $window.seatMapAccountNumber);
        $scope.seatSelection = null;

        $scope.updateTemplate = function () {
            $scope.cardName = "";

            if ($scope.paxinfo.fidelityCardType && $scope.cards != null)
            {
                $scope.cards = $scope.cards.groupByKey('reference');
                $scope.cardName = $scope.cards[$scope.paxinfo.fidelityCardType].name;
            }

            //Include Baggages:
            //Dictionary [itineraryKey+PaxType]
            $scope.baggagesbyItinerary = {};

            if ($scope.proposalsrequested.fares) {
                $scope.proposalsrequested.fares.each(function (f) {
                    if (f.paxType == $scope.paxinfo.paxType) {
                        f.itineraries.each(function (fareItinerary) {
                            if (fareItinerary.baggageQuantity > 0) {
                                $scope.baggagesbyItinerary[fareItinerary.key + f.paxType] = {
                                    itineraryKey: fareItinerary.key,
                                    baggages: []
                                };
                                $scope.baggagesbyItinerary[fareItinerary.key + f.paxType].baggages.push({
                                    baggageQuantity: fareItinerary.baggageQuantity,
                                    baggageWeight: fareItinerary.baggageWeight,
                                    paxType: f.paxType,
                                    include: true
                                });
                            }
                        })
                    }
                })
            }

            //Additional baggages:            
            if ($scope.paxinfo.baggageByItinerary) {
                if ($scope.paxinfo.baggageByItinerary.length == undefined) {
                    for (baggageItineraryKey in $scope.paxinfo.baggageByItinerary) {
                        if ($scope.paxinfo.baggageByItinerary[baggageItineraryKey].quantity > 0) {
                            if ($scope.baggagesbyItinerary[baggageItineraryKey + $scope.paxinfo.paxType]) {
                                $scope.baggagesbyItinerary[baggageItineraryKey + $scope.paxinfo.paxType].baggages.push({
                                    baggageQuantity: $scope.paxinfo.baggageByItinerary[baggageKey].quantity,
                                    baggageWeight: $scope.paxinfo.baggageByItinerary[baggageKey].weight,
                                    paxType: $scope.paxinfo.paxType,
                                    include: false,
                                    location: $scope.paxinfo.baggageByItinerary[baggageKey].location,
                                    description : $scope.paxinfo.baggageByItinerary[baggageKey].description,
                                    description2 : $scope.paxinfo.baggageByItinerary[baggageKey].description2,
                                    cabinQuantity: $scope.paxinfo.baggageByItinerary[baggageKey].quantity,
                                    checkedQuantity: $scope.paxinfo.baggageByItinerary[baggageKey].quantity,
                                    cabinWeight: $scope.paxinfo.baggageByItinerary[baggageKey].weight,
                                    checkedWeight: $scope.paxinfo.baggageByItinerary[baggageKey].weight,
                                })
                            } else {
                                $scope.baggagesbyItinerary[baggageItineraryKey + $scope.paxinfo.paxType] = {
                                    itineraryKey: baggageItineraryKey,
                                    baggages: []
                                };
                                $scope.baggagesbyItinerary[baggageItineraryKey + $scope.paxinfo.paxType].baggages.push({
                                    baggageQuantity: $scope.paxinfo.baggageByItinerary[baggageItineraryKey].quantity,
                                    baggageWeight: $scope.paxinfo.baggageByItinerary[baggageItineraryKey].weight,
                                    paxType: $scope.paxinfo.paxType,
                                    include: false,
                                    location: $scope.paxinfo.baggageByItinerary[baggageItineraryKey].location,
                                    description: $scope.paxinfo.baggageByItinerary[baggageItineraryKey].description,
                                    description2: $scope.paxinfo.baggageByItinerary[baggageItineraryKey].description2,
                                    cabinQuantity: $scope.paxinfo.baggageByItinerary[baggageItineraryKey].quantity,
                                    checkedQuantity: $scope.paxinfo.baggageByItinerary[baggageItineraryKey].quantity,
                                    cabinWeight: $scope.paxinfo.baggageByItinerary[baggageItineraryKey].cabinWeight,
                                    checkedWeight: $scope.paxinfo.baggageByItinerary[baggageItineraryKey].checkedWeight,
                                });
                            }
                        }
                    }
                }           
            }

            if ($scope.enableSeatMap) {
                //Seats
                var purchaseId = $window.$scope.bkgData.purchaseResponse.purchaseId;
                var selectedSeatsKey = 'selectedSeats_' + purchaseId;
            
                var sessionSeats = window.sessionStorage.getItem(selectedSeatsKey);
                if (sessionSeats != null) {
                    var selection = JSON.parse(sessionSeats);
                    $scope.seatSelection = selection;
                }
            }
            
        };

        $scope.updateTemplate();

        $scope.$on("onBookingSummaryUpdate", function (event, args) {
            $scope.updateTemplate();
        });
    };
    return directive;
}]);
