webClient.controller('AirInsurancePaymentBodyBlue', ['$scope', '$window', '$timeout', '$i18next', '$filter', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'trackingService', 'gtmProduct', 'omnitureService',
    function ($scope, $window, $timeout, $i18next, $filter, tseApiClient, tseUtil, airHelper, trackingService, gtmProduct, omnitureService) {

    //don't show anything untill we're ready
    $('#form-container').hide();

        $scope.init = function (bookingId, promo) {

        $scope.loginInfo = $window.site.loginInfo;
        if ($scope.loginInfo != null && $scope.loginInfo.Agent) {
            $scope.agentId = $scope.loginInfo.Agent.AgentId;
        } else {
            $scope.agentId = null;
        }


            getBundleWidget("mrfly-widget-loader.js").done(function () {  
            $window.mrflyWidgets['airbooking-process-loader'].$store.commit("showLoader");
        });

        var apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        window.airHelper = airHelper;
        apiClient.AIR.getBookingDetails([bookingId]).success(function (data) {
    
            var view = airHelper.getViewForBookingDetailsEntityVersion2(data);
            var booking = view._innerData.bookings[0];

            $scope.myBookingsLink = '/reservation/details?reference=' + $filter('bookingNumber')(booking.bookingNumber) + '&email=' + booking.client.email;

            if (promo !== '') {
                $scope.myBookingsLink += "&promo=" + promo;
            }
            
            getBundleWidget("mrfly-widget-insurance-payment.js").done(function () {

                mrflyWidgets['mrfly-widget-insurance-payment'].$refs.payment.adtNumber = view.paxAmount[0];
                mrflyWidgets['mrfly-widget-insurance-payment'].$refs.payment.cnnNumber = view.paxAmount[1];
                mrflyWidgets['mrfly-widget-insurance-payment'].$refs.payment.infNumber = view.paxAmount[2];
                if ($scope.agentId != null)
                    mrflyWidgets['mrfly-widget-insurance-payment'].$refs.payment.agentId = $scope.agentId;
                mrflyWidgets['mrfly-widget-insurance-payment'].$refs.payment.dataBind();

                mrflyWidgets['mrfly-widget-insurance-payment'].$refs.payment.$on('data-binded', function (insurance, pdf) {

                    if ($window.BluePaymentWidget) { // blue is loaded first
                        mrflyWidgets['mrfly-widget-insurance-payment'].$refs.payment.displayWidgetForm($window.BluePaymentWidget);
                    }

                    //save booking, insurance and pdf url for confirmation pages
                    booking.insurance = insurance;
                    booking.insurance.pdf = pdf;
                    booking.insurance.promo = promo !== '' ? promo : null;
                    $window.sessionStorage.setItem('ins_booking_' + bookingId, JSON.stringify(booking));
                });

                 mrflyWidgets['mrfly-widget-insurance-payment'].$refs.payment.$on('widget-loaded', function (insurance, pdf) {
                    $window.mrflyWidgets['airbooking-process-loader'].$store.commit("hideLoader");
                    $('#form-container').fadeIn(); //show controls
                 });

                //the query has been send, show loder while waitin for redirection
                mrflyWidgets['mrfly-widget-insurance-payment'].$refs.payment.$on('insurance-added', function () {
                    //$window.site.pushNoBackOnHistory();
                    $window.mrflyWidgets['airbooking-process-loader'].$store.commit("showLoader");
                });
            });
        });
    };
}]);