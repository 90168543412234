webClient.directive('ngFlightAlertsMultiDestination', ['$window', 'templateService', 
    function ($window, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        bookingdata: "=",
        fullalertcabinmessage: "=",
        hidealertmessageto: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_FlightAlertsMultiDestination.html');

        //Initialize Variables
        $scope.siteType = $window.site.type;
        $scope.getStaticFolder = $window.getStaticFolder;
        $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
        $scope.getTypeStaticFolder = $window.getTypeStaticFolder;
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.isProductTO = $scope.siteType === 2 && $scope.bookingdata.purchaseResponse.isProductTo;
        var flightTypeIsRoundTrip = $scope.bookingdata.purchaseResponse.tripType === 2;
        var flightTypeIsOneWay = $scope.bookingdata.purchaseResponse.tripType === 1;
        $scope.adultFare = $scope.bookingdata.purchaseResponse.proposalsRequested.fares.first(function (x) { return x.paxType == 0 });
        $scope.auxItineraries = $window.objectClone($scope.bookingdata.purchaseResponse.itineraries);
        $scope.auxItineraries = $scope.auxItineraries.groupByKey("key");
        $scope.ryanair = false;
        $scope.norwegian = false;

        // List the iatas of all segments, contains duplicated values but does not impact performance
        $scope.iatasList = $scope.bookingdata.purchaseResponse.itineraries.flatMap(it => it.segments).map(s => s.airline.iata.toLowerCase());
        // Filter in the companies with alert Cabin Luggages that should be displayed 
        $scope.companiesAlertsCabinLuggage = $window.companiesWithAlertCabinLuggages.filter(c => $scope.iatasList.contains(c.Iata.toLowerCase()));
        // Reduce to one dimension array, easier to search on. 
        $scope.companiesAlertsCabinLuggageIatasOnly = [];
        $scope.companiesAlertsCabinLuggage.each(function (elem) {
            $scope.companiesAlertsCabinLuggageIatasOnly.push(elem.Iata);
        });

        $scope.getWarningsFlights = function () {
                $scope.technicalStops = [];
                $scope.longStops = []; 
                $scope.segmentsInTrain = [];
                $scope.segmentsInBus = [];
                $scope.listCabinClassDown = [];
                $scope.listCabinClassUp = [];
                $scope.itinerariesDifferentCabinClass = [];
                $scope.listItineraryCabinClass = {};
                $scope.airportsThatChangeDuringTransit = [];
                $scope.segmentsCabinLuggage = [];

                $scope.dayChange = ($scope.bookingdata.purchaseResponse.itineraries.any(function (it) { return it.dayChange > 0 }));
                        
                $scope.bookingdata.purchaseResponse.itineraries.each(function (it, index) {
                    it.segments.each(function (itSegment) {

                        //Transit time greater than 6 hours
                        if (itSegment.stopTime.hh >= 6 && itSegment.stopTime.mm >= 0) {
                            $scope.longStops.push({
                                arrival: itSegment.arrival.cityName,
                                stopTime: itSegment.stopTime,
                                originCityIata: it.origin.cityIata,
                                destinationCityIata: it.destination.cityIata,
                                originAirportIata: it.origin.airportIata,
                                destinationAirportIata: it.destination.airportIata,
                                itineraryIndex: index
                            });
                        }

                        //Technical Stop
                        if (itSegment.stops > 0) {
                            $scope.technicalStops.push({
                                departure: itSegment.departure.airportIata,
                                arrival: itSegment.arrival.airportIata,
                                originCityIata: it.origin.cityIata,
                                destinationCityIata: it.destination.cityIata,
                                originAirportIata: it.origin.airportIata,
                                destinationAiportIata: it.destination.airportIata,
                                itTotalSegments: it.segments.length
                            });
                        }

                        //Segment in train
                        if (itSegment.equipment.type === 3) {
                            $scope.segmentsInTrain.push({
                                duration: { hh: itSegment.flightTime.hh, mm: itSegment.flightTime.mm },
                                cityNameDeparture: itSegment.departure.cityName,
                                cityNameArrival: itSegment.arrival.cityName
                            });
                        }

                        //Segment in bus
                        if (itSegment.equipment.type === 2) {
                            $scope.segmentsInBus.push({
                                duration: { hh: itSegment.flightTime.hh, mm: itSegment.flightTime.mm },
                                cityNameDeparture: itSegment.departure.cityName,
                                cityNameArrival: itSegment.arrival.cityName
                            });
                        };
                        //Segment with Cabin luggage to pay
                        if (itSegment.airline.iata.toLowerCase() === "fr" && moment(itSegment.departureDateTime, "YYYY-MM-DD").isAfter('2018-11-01', 'day')) {
                            $scope.segmentsCabinLuggage.push({
                                airlineIata: itSegment.airline.iata,
                                airlineName: itSegment.airline.name
                            });
                            $scope.ryanair = true;
                        }

                        if ($scope.companiesAlertsCabinLuggageIatasOnly.contains(itSegment.airline.iata.toLowerCase())) {
                            $scope.segmentsCabinLuggage.push({
                                airlineIata: itSegment.airline.iata,
                                airlineName: itSegment.airline.name
                            });
                        }

                        var proposals = $scope.bookingdata.purchaseResponse.proposals;
                        var baggageQuantity = 0;

                        try {
                            baggageQuantity = proposals[0].fares[0].itineraries[index].baggageQuantity;
                        }
                        catch(e){
                            baggageQuantity = 0;
                        }

                        //Segment with Cabin luggage to pay
                        if (itSegment.airline.iata.toLowerCase() === "dy" && baggageQuantity == 0) {
                            $scope.segmentsCabinLuggage.push({
                                airlineIata: itSegment.airline.iata,
                                airlineName: itSegment.airline.name
                            });
                            $scope.norwegian = true;
                        }

                    });
                });

                $scope.warnLongTransitTime = $scope.longStops.any();

                $scope.warnSegmentTrainOrBusOutbound = $scope.segmentsInTrain.any() || $scope.segmentsInBus.any();

                $scope.warnSegmentCabinLuggage = $scope.segmentsCabinLuggage.any();
            
                $scope.airportChangeDuringTheTransit = $scope.bookingdata.purchaseResponse.itineraries.each(
                    function (it, itIndex) {
                        if (it.segments.length > 1) {
                            return it.segments.any(function (seg, i) {
                                if (i + 1 < it.segments.length) {
                                    if (it.segments[i + 1] != undefined &&
                                        (it.segments[i + 1].departure.airportIata != seg.arrival.airportIata)) {
                                        $scope.airportsThatChangeDuringTransit.push({
                                            segmentArrivalCityName: seg.arrival.cityName,
                                            segmentArrivalAirportName: seg.arrival.airportName,
                                            nextSegmentDepartureAirportName: it.segments[i + 1].departure.airportName,
                                            originCityIata: it.origin.cityIata,
                                            destinationCityIata: it.destination.cityIata,
                                            originAirportIata: it.origin.airportIata,
                                            destinationAirportIata: it.destination.airportIata,
                                            itineraryTitle: itIndex === 0 ? "aller" : itIndex === 1 ? 'retour' : ''
                                        });
                                        return true;
                                    }
                                }
                            });
                        }
                    });
            
                //Cabin Class alerts                          
                $scope.adultFare.itineraries.each(function (it) {
                    $scope.listItineraryCabinClass[it.key] = { cabinClassSort: [] };
                    it.segments.each(function (seg) {
                        $scope.listItineraryCabinClass[it.key].cabinClassSort.push(seg.cabinClass.sort);

                        if (seg.cabinClass.sort <
                            $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                            $scope.listCabinClassDown.push({
                                itineraryOrigin: $scope.auxItineraries[it.key].origin.cityIata,
                                itineraryDestination: $scope.auxItineraries[it.key].destination.cityIata,
                                itineraryKey: it.key,
                                cabinClassName: seg.cabinClass.name
                            });
                        } else if (seg.cabinClass.sort >
                            $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort)
                            $scope.listCabinClassUp.push({
                                itineraryOrigin: $scope.auxItineraries[it.key].origin.cityIata,
                                itineraryDestination: $scope.auxItineraries[it.key].destination.cityIata,
                                itineraryKey: it.key,
                                cabinClassName: seg.cabinClass.name
                            });
                    });
                });

                $scope.cabinClassUpOrDown = $scope.listCabinClassDown.any() || $scope.listCabinClassUp.any();

                //Cabin class upgrade or downgrade total
                $scope.adultFare.itineraries.each(function (it) {
                    var distinctCabinClass = $scope.listItineraryCabinClass[it.key].cabinClassSort.distinct();
                    if (distinctCabinClass.length == 1) {
                        if (distinctCabinClass[0] > $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                            $scope.listItineraryCabinClass[it.key].upgradeTotal = true;
                            $scope.itinerariesDifferentCabinClass.push({
                                itineraryOrigin: $scope.auxItineraries[it.key].origin.airportIata,
                                itineraryDestination: $scope.auxItineraries[it.key].destination.airportIata,
                                itineraryKey: it.key,
                                cabinClassName: it.segments[0].cabinClass.name,
                                up: true
                            });
                        }

                        if (distinctCabinClass[0] < $scope.bookingdata.cabinclasses[$scope.bookingdata.request.cabinClass].sort) {
                            $scope.listItineraryCabinClass[it.key].downgradeTotal = true;
                            $scope.itinerariesDifferentCabinClass.push({
                                itineraryOrigin: $scope.auxItineraries[it.key].origin.airportIata,
                                itineraryDestination: $scope.auxItineraries[it.key].destination.airportIata,
                                itineraryKey: it.key,
                                cabinClassName: it.segments[0].cabinClass.name,
                                down: true
                            });
                        }

                    }
                });


                //Same origin and last itinerary destination
                $scope.isSameCityOriginLastItineraryDestination = ($scope.bookingdata.purchaseResponse.itineraries[$scope.bookingdata.purchaseResponse.itineraries.length - 1] != null) && ($scope.bookingdata.purchaseResponse.itineraries[0].origin.cityIata == $scope.bookingdata.purchaseResponse.itineraries[$scope.bookingdata.purchaseResponse.itineraries.length - 1].destination.cityIata);
                if ($scope.isSameCityOriginLastItineraryDestination) {
                    $scope.sameCityDifferentAeroport = $scope.bookingdata.purchaseResponse.itineraries[0].origin.airportIata != $scope.bookingdata.purchaseResponse.itineraries[$scope.bookingdata.purchaseResponse.itineraries.length - 1].destination.airportIata;

                    if ($scope.sameCityDifferentAeroport) {
                        var airportsItinerariesInformation = {
                            originCityName: $scope.bookingdata.purchaseResponse.itineraries[0].origin.cityName,
                            originAirportName: $scope.bookingdata.purchaseResponse.itineraries[0].origin.airportName,
                            destinationAirportName: $scope.bookingdata.purchaseResponse.itineraries[$scope.bookingdata.purchaseResponse.itineraries.length - 1].destination.airportName
                        };
                        $scope.airportsItinerariesInformation = airportsItinerariesInformation;
                    }
                }
                return $scope.dayChange
                    || $scope.longTransitTime
                    || $scope.technicalStops.length > 0
                    || $scope.airportsThatChangeDuringTransit.length > 0
                    || $scope.warnSegmentTrainOrBusOutbound
                    || $scope.warnSegmentCabinLuggage
                    || $scope.diffDepartureArrivalAirportOrigin
                    || $scope.diffDepartureArrivalAirportDestination
                    || $scope.cityProposedDiffCityRequestedOriginFlightOneWay
                    || $scope.cityProposedDiffCityRequestedFlightOneWay
                    || $scope.cityProposedDiffCityRequested
                    || $scope.cityProposedDiffCityRequestedOrigin
                    || $scope.cabinClassUpOrDown
                    || $scope.itinerariesDifferentCabinClass.length > 0
                    || $scope.sameCityDifferentAeroport;

        };

        $scope.hasWarningMultidestination = $scope.getWarningsFlights();
   };
    return directive;
}]);
