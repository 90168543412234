/*
    A service used to trigger Omniture events
    This file is just an empty shell since each partner must know the 'omnitureService' provider
*/
webClient.factory('omnitureService', ['$window',
    function ($window) {
        return {
            isActivated: function () { return false; },
            sendCdtm: function (pageName) { },
            sendEvent: function (pageName, prop7, data) { }
        }
    }]);

