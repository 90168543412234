/**
* @desc the ngResponseCalendar provides support for response calendars.
* @example <div ng-response-calendar view="view"></div>
*/

webClient.directive('ngResponseCalendar', ['$window', '$i18next', 'templateService', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 
    function ($window, $i18next, templateService, tseApiClient, tseUtil, airHelper) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        view: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_ResponseCalendar.html');

        $scope.getUrl = $window.getUrl;
        $window.$calendarScope = $scope;
        $scope.getStaticFolder = $window.getStaticFolder;
        $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
        $scope.getTypeStaticFolder = $window.getTypeStaticFolder;
        $scope.site = $window.site;
        $scope._util = tseUtil;
        $scope.getText = function (key) { return window.culture.texts[key]; };
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        $scope.requestSummary = $window.site.currentAirRequest.summary;
        $scope.calendarData = null;
        $scope.containCalendar = false;

        $scope.getCalendar = function (index) {
            //Prepare calendar request
            var request = $scope.requestSummary;
            var calRef = index == 1 ? "001" : "002";
            var calRequest = {
                adults: request.adults,
                childrens: request.childs,
                infants: request.infants,
                calendarReference: calRef,
                includeResponse: false,
                itineraries: [
                ],
                cabinClass: request.cabinClass
            };
            calRequest.itineraries.push({ origin: request.origin.cityIATA, destination: request.destination.cityIATA, date: request.goDate.year + "-" + request.goDate.month + "-" + request.goDate.day });
            if (request.origin2) {
                calRequest.itineraries.push({ origin: request.origin2.cityIATA, destination: request.destination2.cityIATA, date: request.goDate2.year + "-" + request.goDate2.month + "-" + request.goDate2.day });
            }

            //Send request
            $scope.containCalendar = null;
            $window.FLoading({ vAction: 'showNow' });
            $scope.apiClient.setAirFaresSearch(calRequest).success(function (data) {
                //Getting the response
                $scope.apiClient.getAirFaresSearchResults(data).success(function (response) {
                    $window.FLoading({ vAction: 'hide' });
                    $scope.processCalendarResponse(response);
                }).error(function (errData) {
                    $window.FLoading({ vAction: 'hide' });
                    $window.XLConfirm({ vTitle: $i18next.t("calendar.error"), vTextYes: "OK", vHideNo: true });
                    $scope.containCalendar = false;
                });
            }).error(function (data) {
                $window.FLoading({ vAction: 'hide' });
                $window.XLConfirm({ vTitle: $i18next.t("calendar.error"), vTextYes: "OK", vHideNo: true });
                $scope.containCalendar = false;
            });
        }

        $scope.processCalendarResponse = function (data) {
            if (data.calendarProducts && data.calendarProducts.length > 0) {
                var goOffsetMin = 0;
                var goOffsetMax = 0;
                var returnOffsetMin = 0;
                var returnOffsetMax = 0;

                var request = data.request;
                var cheapestPrice = $scope.view.data.summary.averageFareFeeMargin.from.value;
                data.products = data.calendarProducts.select(function (i) { return i.product; });
                data = airHelper.prepareRawResultsData(data);
                $scope.calendarData = data;

                for (var i = 0; i < data.calendarProducts.length; i++) {
                    var cProduct = data.calendarProducts[i];
                    if (cProduct.offsetGo < goOffsetMin) goOffsetMin = cProduct.offsetGo;
                    if (cProduct.offsetGo > goOffsetMax) goOffsetMax = cProduct.offsetGo;
                    if (cProduct.offsetReturn < returnOffsetMin) returnOffsetMin = cProduct.offsetReturn;
                    if (cProduct.offsetReturn > returnOffsetMax) returnOffsetMax = cProduct.offsetReturn;
                }

                $scope.calendarLowestPrice = null;
                $scope.calendarLowestProduct = null;
                $scope.calendarMatrix = [];
                var currency = "EUR";
                var vector = [];
                for (var goOffset = goOffsetMin; goOffset <= goOffsetMax; goOffset++) {
                    var row = [];
                    $scope.calendarMatrix.push(row);
                    for (var returnOffset = returnOffsetMin; returnOffset <= returnOffsetMax; returnOffset++) {
                        var item = data.calendarProducts.first(function (i) { return i.offsetGo == goOffset && (i.offsetReturn == null || i.offsetReturn == returnOffset); });
                        if (item == null) {
                            item = {
                                offsetGo: goOffset,
                                offsetReturn: returnOffset,
                                product: null,
                                currency: currency,
                                averagePrice : goOffset == 0 && returnOffset == 0 ? airHelper.getNumberObject(cheapestPrice) : null
                            };
                            if (request.itineraries) {
                                item.offsetGoDate = $window.moment(request.itineraries[0].date).add(goOffset, "d").toDate();
                                if (request.itineraries.length > 1)
                                    item.offsetReturnDate = $window.moment(request.itineraries[1].date).add(returnOffset, "d").toDate();
                            }
                        }
                        else {
                            item.currency = currency;
                            item.offsetGoDate = $window.moment(item.offsetGoDate).toDate();
                            if (item.offsetReturnDate)
                                item.offsetReturnDate = $window.moment(item.offsetReturnDate).toDate();
                        }

                        item.proposal = null;
                        if (item.product) {
                            currency = item.product.currency;
                            if (item.product.inbounds && item.product.inbounds.length > 0)
                                item.proposal = item.product.inbounds[0].proposal;
                            else
                                item.proposal = item.product.outbound.proposal;
                            if (!item.averagePrice)
                                item.averagePrice = item.product.minAverageFareFeeMargin;
                        }

                        if (item.averagePrice && ($scope.calendarLowestPrice == null || item.averagePrice.value < $scope.calendarLowestPrice.value))
                            $scope.calendarLowestPrice = item.averagePrice;
                        item.isToday = (goOffset == 0 && returnOffset == 0);
                        row.push(item);
                        vector.push(item);
                    }
                }
                if ($scope.calendarLowestPrice) {
                    vector.where(function(i) {
                        if (i.averagePrice)
                            return i.averagePrice.value == $scope.calendarLowestPrice.value;
                        return false;
                    }).each(function(i) { i.isCheapest = true; });
                    $scope.calendarLowestProduct = vector.first(function(i) { return i.isCheapest; });
                    $scope.calendarDiscount = null;
                    if (cheapestPrice > $scope.calendarLowestPrice.value)
                        $scope.calendarDiscount =
                            airHelper.getNumberObject(cheapestPrice - $scope.calendarLowestPrice.value);
                }
                $scope.containCalendar = true;
            } else {
                $window.XLConfirm({ vTitle: $i18next.t("calendar.noflight"), vTextYes: "OK", vHideNo: true });
            }
        };

        $scope.loadResult = function (column) {
            if (!column) return;
            if (column.offsetGo == 0 && (column.offsetReturn == null || column.offsetReturn == 0)) return;
            if (column.product == null) return;
            if ($scope.calendarData == null) return;

            //Create the search url
            var location = $window.location.href;
            var baseUrl = location.substring(0, location.lastIndexOf("airresults/") + 11);
            var request = $window.site.currentAirRequest.summary;
            var parameters = [];
            if (request.origin)                             parameters.push("origin=" + request.origin.value);
            if (request.openJaw && request.origin2)         parameters.push("origin2=" + request.origin2.value);
            if (request.destination)                        parameters.push("destination=" + request.destination.value);
            if (request.openJaw && request.destination)     parameters.push("destination2=" + request.destination2.value);
            if (request.goDate)                             parameters.push("godate=" + $window.moment(request.goDate.date).add(column.offsetGo, "d").format("YYYY-MM-DD"));
            if (request.roundTrip && request.goDate2)       parameters.push("returndate=" + $window.moment(request.goDate2.date).add(column.offsetReturn, "d").format("YYYY-MM-DD"));
            if (request.openJaw && request.goDate2)         parameters.push("godate2=" + $window.moment(request.goDate2.date).add(column.offsetReturn, "d").format("YYYY-MM-DD"));
            if (request.roundTrip)                          parameters.push("searchtype=roundtrip");
            if (request.openJaw)                            parameters.push("searchtype=openjaw");
            if (request.oneWay)                             parameters.push("searchtype=oneway");
            if (request.adults)                             parameters.push("adults=" + request.adults);
            if (request.childs)                             parameters.push("childs=" + request.childs);
            if (request.infants)                            parameters.push("infants=" + request.infants);
            if (request.cabinClass)                         parameters.push("cabinclass=" + request.cabinClass);
            if (column.requestId)                           parameters.push("forcerequestid=" + column.requestId);
            var query = "?" + parameters.join("&").toLowerCase();
            var url = baseUrl + query;
            $window.location.assign(url);
        };

        if ($scope.view.data.calendarProducts && $scope.view.data.calendarProducts.length > 0)
            $scope.processCalendarResponse($scope.view.data);
    };
    return directive;
}]);
