webClient.controller('Club.headController', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {
    $window.$headScope = $scope;
    $scope.site = $window.site;
    $scope.isLoginRequired = $window.site.isLoginRequired;
    $scope.isLogged = $window.site.isLogged;
    $scope.loginInfo = $window.site.loginInfo;
    $scope.homeUrl = $window.site.loginUrl;

    $scope.logout = function () {
        tseUtil.Cookies.remove($window.site.loginCookie);
        document.location.href = $window.site.loginUrl;
    };
}]);