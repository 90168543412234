webClient.controller('B2B.WorldTourQuotationOndemand', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {
    $window.$scope = $scope;
    $scope._util = tseUtil;
    $scope.getText = function (key) { return window.culture.texts[key]; };

    //Rest Api Client Object
    $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);

    //WorldTour Data: 3 itineraries by default and 2 passengers by default
    $scope.worldTourOndemand = {
        reference:'', name: '', mail: '', phone: '', comment: '',cabinClass: 'Y',
        passengers: [{ type: '', firtsName: '', lastName: '', birthDate: '' },{ type: '', firtsName: '', lastName: '', birthDate: '' }],
        itineraries: [{ origin: '', destination: '', date: '' },{ origin: '', destination: '', date: '' },{ origin: '', destination: '', date: '' }]
    };

    //Agency payment mode: This parameter is used to change wordings to agencies
    if ($window && $window.site && $window.site.loginInfo && $window.site.loginInfo.Client)
        $scope.agencyPaymentMethod = $window.site.loginInfo.Client.PaymentMode;

    $scope.worldTourOndemand.reference = '00000';

    //DateMin by default for first itinerary
    $scope.dateMin = moment();
    $scope.dateMin = $scope.dateMin.add('15', 'days');
    $scope.dateMin = moment($scope.dateMin).format('DD/MM/YYYY');

    //DateMax by default for first itinerary
    $scope.dateMax = moment();
    $scope.dateMax = $scope.dateMax.add('1', 'year');
    $scope.dateMax = moment($scope.dateMax).format('DD/MM/YYYY');

    //Add Passenger
    $scope.addPassenger = function () {
        if ($scope.worldTourOndemand.passengers.length <= 8) {
            $scope.worldTourOndemand.passengers.push({ type: '', firtsName: '', lastName: '', birthDate: '' });
        }
    };

    //Remove Passenger
    $scope.removePassenger = function () {
        if ($scope.worldTourOndemand.passengers.length > 1) {
            $scope.worldTourOndemand.passengers.pop();
        }
    };

    //Return true if arg1 is after srg2
    $scope.isDateAfter = function (arg1, arg2) {
        var mArg1, mArg2;
        mArg1 = (typeof (arg1) === "string") ? moment(arg1, "DD/MM/YYYY") : moment(arg1);
        mArg2 = (typeof (arg2) === "string") ? moment(arg2, "DD/MM/YYYY") : moment(arg2);
        return mArg1.isAfter(mArg2);
    };

    //Validate selected dates idDateCalendar: the next idate will be modify
    $scope.validateSegmentsDate = function (dateSegmentMin, idDateCalendar) {

        //Date Max
        var dateMax = $scope.dateMax;

        //Date Min
        dateSegmentMin = moment(dateSegmentMin, "DD/MM/YYYY");
        dateSegmentMin = moment(dateSegmentMin).format('DD/MM/YYYY');

        var nextInput = parseInt(idDateCalendar.replace(/^\D+/g, '')) + 1;
        if (nextInput) {
            $('input', '#i-date-departure-' + nextInput).attr('data-check', "{check:'date',date_min:'" + dateSegmentMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");

            if ($('input', '#i-date-departure-' + nextInput).val() != "") {
                if ($scope.isDateAfter(dateSegmentMin, $('input', '#i-date-departure-' + nextInput).val())) {
                    $('input', '#i-date-departure-' + nextInput).parent().removeClass("c-field-ok");
                    $('input', '#i-date-departure-' + nextInput).parent().addClass("c-field-ko");
                }
                else {
                    $('input', '#i-date-departure-' + nextInput).parent().removeClass("c-field-ko");
                    $('input', '#i-date-departure-' + nextInput).parent().addClass("c-field-ok");
                }
            }
        }
    };

    //Add Itinerary
    $scope.addItinerary = function (){
        if ($scope.worldTourOndemand.itineraries.length <= 14) {
            $scope.worldTourOndemand.itineraries.push({ origin: '', destination: '', date: '' });
        }
    };

    //Remove Itinerary (Number min of itineraries is 3)
    $scope.removeItinerary = function () {
        if ($scope.worldTourOndemand.itineraries.length > 3) {
            $scope.worldTourOndemand.itineraries.pop();
        }
    };

    //Send Request To Api
    $scope.sendWordlTourRequest = function () {
        if ($scope.worldTourOndemand.itineraries.length > 1 && $scope.worldTourOndemand.itineraries.length <= 14 && $scope.worldTourOndemand.passengers.length >= 1 && $scope.worldTourOndemand.passengers.length <=9) 
        {
            if ($window.XLFormValid({ iCont: 'i-form-tour' })) {
                $scope.request = { tdm: $scope.worldTourOndemand };
                $window.FLoading({ vAction: 'show' });
                $scope.apiClient.B2B.sendRequests($scope.request).success(function (response) {
                    $window.FLoading({ vAction: 'hide' });
                    if (response && response.status == 0) {
                        $scope.sent = true;
                    }
                    else {
                        $window.XLConfirm({
                            vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                            vTextYes: 'OK Merci',
                            vHideNo: true
                        });
                    }
                }).error(function () {
                    $window.FLoading({ vAction: 'hide' });
                    $window.XLConfirm({
                        vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                        vTextYes: 'OK Merci',
                        vHideNo: true
                    });
                });
            }
            else
                return;
        }
    };

}]);
