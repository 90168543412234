webClient.controller('WorldTourFormQZ', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {
    $window.$scope = $scope;
    $scope._util = tseUtil;
    // 4 -> 620
    // 5 -> 764
    // 6 -> 1130
    $scope.stops = parseInt(tseUtil.Location.getQueryStringVar("s"), 8) / 100;
    $scope.getText = function (key) { return window.culture.texts[key]; };
    $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
    $scope.sent = 0;
    $scope.worldTour = { segments: [{ departure: '', arrival: '', date: '' }] };
    $scope.worldTour.reference = '00003';

    $scope.worldTour.segments[0].departure = 'Paris';
    $scope.worldTour.segments[0].arrival = 'Bangkok';
    $scope.worldTour.AsianCity = "Bangkok";
    $scope.worldTour.AustralianArrivalCity = "Melbourne";
    $scope.worldTour.AustralianDepartureCity = "Melbourne";

    $(document).ready(function () {
        $scope.init();
    });

    $scope.init = function () {
        var dateMinAux = new Date();
        var dateMin = new Date(2016, 6, 21);

        if ($scope.isDateAfter(dateMinAux, dateMin))
            dateMin = dateMinAux;

        dateMin = moment(dateMin).format('DD/MM/YYYY');
        var dateMax = new Date(new Date().setYear(new Date().getFullYear() + 1));
        dateMax = moment(dateMax).format('DD/MM/YYYY');
        for (var i = 0; i <= $scope.stops; i++) {
            $('input', '#i-date-departure-' + i).parent().removeClass("c-field-ok");
            $('input', '#i-date-departure-' + i).parent().removeClass("c-field-ko");
            $('input', '#i-date-departure-' + i).attr('data-check', "{check:'date',date_min:'" + dateMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");
        }
        $scope.worldTour.dateDepartureParis = undefined;
        $scope.worldTour.asiaDate = undefined;
        $scope.worldTour.AustralianDate = undefined;
        $scope.worldTour.hawaiDate = undefined;
        $scope.worldTour.losAngelesDate = undefined;
        $scope.worldTour.newYorkDate = undefined;
        $scope.worldTour.londoncDate = undefined;
        $scope.worldTour.africaDate = undefined;
    };

    $scope.callDrop = function (_event, type) {
        var varIsCountry = (type == 'country') ? 1 : 0;
        var name = 'i-' + type;
        var value = $("#ul-" + type).get(0);
        $window.FSelect({ e: _event, myObj: value, vName: name, isCountry: varIsCountry });
    };
    $scope.callTitlePop = function (_event, type) {
        var value = _event.target;
        var name = 'i-' + type + '-pop';
        $window.FPop({ oTarget: value, iPop: name, vStyle: 'classic' });
    };

    $scope.validateSegmentsDate = function(dateSegmentMin, idDateCalendar)
    {
        var dateMax = new Date(new Date().setYear(new Date().getFullYear() + 1));
        dateMax = moment(dateMax).format('DD/MM/YYYY');

        dateSegmentMin = moment(dateSegmentMin, "DD/MM/YYYY");
        dateSegmentMin = dateSegmentMin.add(1, 'days');
        dateSegmentMin = moment(dateSegmentMin).format('DD/MM/YYYY');

        var nextInput = parseInt(idDateCalendar.substring(idDateCalendar.length - 1)) +1;
        if (nextInput <= $scope.stops) {
            $('input', '#i-date-departure-' + nextInput).attr('data-check', "{check:'date',date_min:'" + dateSegmentMin + "',date_max:'" + dateMax + "',birthday:false,nolive:true}");

            if ($('input', '#i-date-departure-' + nextInput).val() != ""){
                if ($scope.isDateAfter(dateSegmentMin, $('input', '#i-date-departure-' + nextInput).val())) {
                    $('input', '#i-date-departure-' + nextInput).parent().removeClass("c-field-ok");
                    $('input', '#i-date-departure-' + nextInput).parent().addClass("c-field-ko");
                }
                else {
                    $('input', '#i-date-departure-' + nextInput).parent().removeClass("c-field-ko");
                    $('input', '#i-date-departure-' + nextInput).parent().addClass("c-field-ok");
                }
            }
        }
    };

    $scope.isDateAfter= function(arg1, arg2)
    {
        var mArg1, mArg2;
        mArg1 = (typeof (arg1) === "string") ? moment(arg1, "DD/MM/YYYY") : moment(arg1);
        mArg2 = (typeof (arg2) === "string") ? moment(arg2, "DD/MM/YYYY") : moment(arg2);
        return mArg1.isAfter(mArg2);
    };

    $scope.passAustralia = function (val)
    {
        if (val) {
            if (!$scope.worldTour.passAustralia)
                FSmoothOpen({ oOpener: $('#i-radio-stop1-yes')[0], iSmooth: 'i-smooth-option-1' });
        }
        else {
            if ($scope.worldTour.passAustralia)
                FSmoothOpen({ oOpener: $('#i-radio-stop1-no')[0], iSmooth: 'i-smooth-option-1' });
        }
        $scope.worldTour.passAustralia = val;
    };

    $scope.sendRequest = function () {
        $scope.worldTour.error = !$window.XLFormValid({ iCont: 'i-form-tour' });
        if ($scope.worldTour.error)
            return;

        $scope.worldTour.segments = [{ departure: '', arrival: '', date: '' }];
        $scope.worldTour.segments[0].departure = 'Paris';
        $scope.worldTour.segments[0].arrival = $scope.worldTour.AsianCity;
        $scope.worldTour.segments[0].date = $scope.worldTour.dateDepartureParis;

        $scope.worldTour.segments.push(
            {
                departure: $scope.worldTour.AsianCity,
                arrival: $scope.worldTour.AustralianArrivalCity,
                date: $scope.worldTour.asiaDate,
                option: $scope.worldTour.passAustralia,
                optionComment: $scope.worldTour.passAustralia ? $scope.worldTour.passAustraliaComment : null
            });

        if ($scope.stops == 4) {
            $scope.worldTour.segments.push({ departure: $scope.worldTour.AustralianDepartureCity, arrival: "Afrique du Sud", date: $scope.worldTour.AustralianDate });
            $scope.worldTour.segments.push({ departure: "Afrique du Sud", arrival: "Londres", date: $scope.worldTour.africaDate });
        }
        else {
            if ($scope.stops == 5) {
                $scope.worldTour.segments.push({ departure: $scope.worldTour.AustralianDepartureCity, arrival: "Los Angeles", date: $scope.worldTour.AustralianDate });
            }
            if ($scope.stops == 6) {
                $scope.worldTour.segments.push({ departure: $scope.worldTour.AustralianDepartureCity, arrival: "Hawaï", date: $scope.worldTour.AustralianDate });
                $scope.worldTour.segments.push({ departure: "Hawaï", arrival: "Los Angeles", date: $scope.worldTour.hawaiDate });
            }
            $scope.worldTour.segments.push({ departure: "Los Angeles", arrival: "New York", date: $scope.worldTour.losAngelesDate });
            $scope.worldTour.segments.push({ departure: "New York", arrival: "Londres", date: $scope.worldTour.newYorkDate });
        }

        $scope.worldTour.segments.push({ departure: "Londres", arrival: "Paris", date: $scope.worldTour.londoncDate });

        if (!$scope.worldTour.error) {
            if ($window.XLFormValid({ iCont: 'i-form-tour' }) && !$scope.worldTour.error) {
                $window.FLoading({ vAction: 'show' });
                $scope.apiClient.sendWorldTourForm($scope.worldTour).success(function (data) {
                    $window.FLoading({ vAction: 'hide' });
                    if (data.status == 0) {
                        $scope.sent = 1;
                        $window.scrollTo(0, 0);
                    }
                    else {
                        $scope.init();
                        $window.FLoading({ vAction: 'hide' });
                        $window.XLConfirm({
                            vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                            vTextYes: 'OK Merci',
                            vHideNo: true
                        });
                    }
                }).error(function () {
                    $scope.init();
                    $window.FLoading({ vAction: 'hide' });
                    $window.XLConfirm({
                        vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                        vTextYes: 'OK Merci',
                        vHideNo: true
                    });
                });
            }
        }
        else {
            $scope.init();
            $window.XLConfirm({
                vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                vTextYes: 'OK Merci',
                vHideNo: true
            });

        }
    };
}]);
