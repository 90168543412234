webClient.controller('B2B.GroupsQuotation', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {
    $window.$scope = $scope;
    $scope._util = tseUtil;
    $scope.getText = function (key) { return window.culture.texts[key]; };
    $scope.paxNumberValid = null;
    //Rest Api Client Object
    $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);

    //GroupQuotation Object
    $scope.groupsQuotationRQ = {
        groupName: '',
        typology: '',
        numberOfPassangers: '',
        budgetMax: '',
        name: '',
        mail: '',
        phone: '',
        comment: '',
        cabinClass: '',
        preferenceAirlines: '',
        preferenceFligthsAndTimetables: '',
        fexibleDays: false,
        baggageIncluded: true,
        itineraries: [{ origin: '', destination: '', date: '' }]
    };

    //Agency payment mode: This parameter is used to change wordings to agencies
    if ($window && $window.site && $window.site.loginInfo && $window.site.loginInfo.Client)
        $scope.agencyPaymentMethod = $window.site.loginInfo.Client.PaymentMode;

    //DateMin by default for first itinerary
    $scope.dateMin = moment();
    $scope.dateMin = $scope.dateMin.add('15', 'days');
    $scope.dateMin = moment($scope.dateMin).format('DD/MM/YYYY');

    //DateMax by default for first itinerary
    $scope.dateMax = moment();
    $scope.dateMax = $scope.dateMax.add('1', 'year');
    $scope.dateMax = moment($scope.dateMax).format('DD/MM/YYYY');

    //Return true if arg1 is after srg2
    $scope.isDateAfter = function (arg1, arg2) {
        var mArg1, mArg2;
        mArg1 = (typeof (arg1) === "string") ? moment(arg1, "DD/MM/YYYY") : moment(arg1);
        mArg2 = (typeof (arg2) === "string") ? moment(arg2, "DD/MM/YYYY") : moment(arg2);
        return mArg1.isAfter(mArg2);
    };

    //Flexible days
    $scope.flexibleDaysClick = function () {
        if (!$scope.groupsQuotationRQ.fexibleDays)
            $scope.groupsQuotationRQ.fexibleDays = true;
        else
            $scope.groupsQuotationRQ.fexibleDays = false;
    };

    //Baggage included
    $scope.baggageIncludedClick = function () {
        if (!$scope.groupsQuotationRQ.baggageIncluded)
            $scope.groupsQuotationRQ.baggageIncluded = true;
        else
            $scope.groupsQuotationRQ.baggageIncluded = false;
    };

    //Validate Number of Passengers
    $scope.validateNumberOfPassengers = function () {

        if ($scope.groupsQuotationRQ.numberOfPassangers < 10 || $scope.groupsQuotationRQ.numberOfPassangers > 150 || typeof $scope.groupsQuotationRQ.numberOfPassangers === 'undefined') {
            aVS = new Array();
            aVS['oObj'] = document.getElementById('i-pax-num');
            aVS['vError'] = true;
            aVS['vErrorCode'] = '';
            aVS['vErrorCustom'] = 'Min 10 et max 150';
            XLFormValidStyle(aVS);
            $scope.paxNumberValid = false;
        }
        else
            $scope.paxNumberValid = true;
    };

    //Validate Budget by Passenger
    $scope.validateBudgetByPassenger = function () {

        console.log($scope.groupsQuotationRQ.budgetMax)

        $scope.paxBudgetEmpty = !($scope.groupsQuotationRQ.budgetMax !== null && $scope.groupsQuotationRQ.budgetMax !== "");

        if ($scope.groupsQuotationRQ.budgetMax != "") {

            if ($scope.groupsQuotationRQ.budgetMax < 1 || typeof $scope.groupsQuotationRQ.budgetMax === 'undefined') {
                aVS = new Array();
                aVS['oObj'] = document.getElementById('i-pax-budget');
                aVS['vError'] = true;
                aVS['vErrorCode'] = '';
                aVS['vErrorCustom'] = 'Min 1';
                XLFormValidStyle(aVS);
                $scope.paxBudgetValid = false;
            }
            else
                $scope.paxBudgetValid = true;
        }
    };

    //Send Request To Api
    $scope.sendGroupsQuotationRQ= function () {
            if ($window.XLFormValid({ iCont: 'i-groups-quotation' })) {
                var _origin = $("input[name='origin'").val();
                var _destination = $("input[name='destination'").val();
                var _goDate = $("input[name='goDate']").val();
                var _returnDate = $("input[name='returnDate']").val();

                if (_origin && _destination && _goDate) {
                    $scope.groupsQuotationRQ.itineraries[0].origin = _origin;
                    $scope.groupsQuotationRQ.itineraries[0].destination = _destination;
                    $scope.groupsQuotationRQ.itineraries[0].date = _goDate;

                    if (_returnDate != null) {
                        $scope.groupsQuotationRQ.itineraries.push({
                            origin: $scope.groupsQuotationRQ.itineraries[0].destination,
                            destination: $scope.groupsQuotationRQ.itineraries[0].origin,
                            date: _returnDate
                        });
                    }
                    $window.FLoading({ vAction: 'show' });

                    $scope.request = { group: $scope.groupsQuotationRQ };
                    $scope.apiClient.B2B.sendRequests($scope.request).success(function (response) {
                        $window.FLoading({ vAction: 'hide' });
                        if (response && response.status == 0) {
                            $scope.sent = true;
                        }
                        else {
                            $window.XLConfirm({
                                vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                                vTextYes: 'OK Merci',
                                vHideNo: true
                            });
                        }
                    }).error(function () {
                        $window.FLoading({ vAction: 'hide' });
                        $window.XLConfirm({
                            vTitle: "Erreur dans l'enregistrement, veuillez recommencer",
                            vTextYes: 'OK Merci',
                            vHideNo: true
                        });
                    });
                }
                else
                {
                    if (_origin == '') {
                        aVS = new Array();
                        aVS['oObj'] = document.getElementById('i-where-origin');
                        aVS['vError'] = true;
                        aVS['vErrorCode'] = '';
                        aVS['vErrorCustom'] = getT('airbooking.pleasecompletethisfield');
                    }
                    if (_destination == '') {
                        aVS = new Array();
                        aVS['oObj'] = document.getElementById('i-where-destination');
                        aVS['vError'] = true;
                        aVS['vErrorCode'] = '';
                        aVS['vErrorCustom'] = getT('airbooking.pleasecompletethisfield');
                    }
                    XLFormValidStyle(aVS);
                    return false;
                }
            }
            else
                return;
    };

    setTimeout(function () {
        XLFormInit({iCont: 'i-groups-quotation' });
    }, 200);
}]);
