/**
* @desc the ngBrandedFaresTPL provides support for collect and displaying information of Branded Fares in Purchase 1
* @example:
* <div ng-booking-page-one request="bkgData.request" flexy="bkgData.bookingRequest.flexyFare" proposal="proposal" passengers="paxAmount" paxname="paxType" paxinfo="bkgData.bookingRequest.paxes" datelastarrival="lastDate" cards="cards" time="newProcess" selection="bkgData.selection"></div>
*/
webClient.directive('ngBrandedFares', ['$window', 'templateService', 
    function ($window, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        proposalslist: "=",
        brandedfaresservices: "=",
        brandkey: "=",
        productistrain: '='
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_BrandedFaresTPL.html');

        //Initialize Variables
        $scope.getUrl = $window.getUrl;
        $scope.severalProposals = $scope.proposalslist.length > 1;
        $scope.brandKey = $scope.brandkey != undefined ? $scope.brandkey : $scope.proposalslist[0].brandKey;

        /**
        * SelectBranded
        * Funcion para seleccionar otro proposal (Branded Fare).
        * Esta funcion hace un emit brandClick para notificar en
        * FligthBookingBody que se ha elegido otro proposal.
        * @param {string} fare fare name
        * @param {string} brandKey code
        * @param {int} proposalIndex to select
        */
        $scope.selectBranded = function (fare, brandKey, proposalIndex) {
            $scope.brandKey = brandKey;
            var brandedFaresSelected = { brandKey: brandKey, proposalIndex: proposalIndex, fareWithLugage: $scope.proposalslist[proposalIndex].fareWithLugage, fareWithoutBaggage: $scope.proposalslist[proposalIndex].fareWithoutBaggage }
            $scope.$emit("brandClick", brandedFaresSelected);

            console.log(brandedFaresSelected);
        }

        //Check if there is a branded fare selected 
        if ($scope.brandkey != undefined) {
            if ($scope.proposalslist[0].brandKey === $scope.brandKey)
                $scope.selectBranded("basic", $scope.proposalslist[0].brandKey, $scope.proposalslist[0].index);

            if ($scope.proposalslist[1].brandKey === $scope.brandKey)
                $scope.selectBranded("smart", $scope.proposalslist[1].brandKey, $scope.proposalslist[1].index);

            if ($scope.proposalslist[2].brandKey === $scope.brandKey)
                $scope.selectBranded("flex", $scope.proposalslist[2].brandKey, $scope.proposalslist[2].index);
        }
    };
    return directive;
}]);