webClient.factory('templateService', ['$window', '$http', '$compile', '$timeout',
    function ($window, $http, $compile, $timeout) {
        return {
            loadAsync: function (url) {
                return $http({
                    method: 'GET',
                    withCredentials: false,
                    headers: {
                        'mappingname': undefined,
                        'authtoken': undefined
                    },
                    url:$window.getTemplateUrl(url)
                });
            },
            load: function (url) {

                var request = new XMLHttpRequest();
                var fullUrl = $window.getTemplateUrl(url);
                request.open('GET', fullUrl, false);

                request.send(null);

                var ret = null;

                if (request.status === 200) {
                    ret = request.responseText;
                }

                return ret;
            },

            compile: function ($scope, $element, url) {
            
                var html = this.load(url);
                var template = angular.element(html);
                $element.append(template);
                
                if (navigator.userAgent.search("Firefox") > 0) {
                    //fix for FF low compile perf
                    $element.hide();
                    $timeout(function () {
                        $compile(template)($scope);
                        $element.show();
                    }, 0);
                }
                else {
                    $compile(template)($scope);
                }
            },

            //for test purpose only
            compileAsync: function ($scope, $element, url) {
            
                this.loadAsync(url).success(function (html) {
                    var template = angular.element(html);
                    $element.append(template);
                    $compile(template)($scope);
                });
            }
        };
    }]);

