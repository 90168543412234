webClient.controller('AirUpsellProducts', ['$scope', '$window', '$timeout', '$location', '$i18next', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'bookingRequest', 'insurancesSelected', 'bookingRequestKey', 'insuranceOnce', 'purchaseResponse', 'trackingService', 'gtmProduct', 'userInfoResult', 'additionalData', 'omnitureService',
    function ($scope, $window, $timeout, $location, $i18next, tseApiClient, tseUtil, airHelper, bookingRequest, insurancesSelected, bookingRequestKey, insuranceOnce, purchaseResponse, trackingService, gtmProduct, userInfoResult, additionalData, omnitureService) {

        //Initialize Variables
        $scope.getText = function (key) { return window.culture.texts[key]; };
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        var searchUrl = $window.site.currentAirRequest.searchUrl + "?" + tseUtil.Location.getQueryString();
        var user = window.site;
        $scope.siteType = $window.site.type;
        $scope.siteIsB2b = user.isLogged && user.type == 2;
        $window.$scope = $scope;

        //Flags
        var siteTypeIsB2b = $scope.siteType == 2;

        $window.FLoading({ vAction: 'show' });

        //Get data from session storage
        var bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);
        $scope.bkgData = bkgData;

        var upsellParam = $window.getUrlVars()['upsell'];
        $scope.skipUpsell = typeof upsellParam !== 'undefined' && upsellParam === 'true';


        if (bkgData) {

            purchaseResponse = { value: "" };
          
            //New Purchase Transaction
            $scope.apiClient.PURCHASE.new(bkgData.purchaseRequest).success(function (data) {

                //console.log("PURCHASE");

                if (data && data.message == null) {
                    //If not hotelpropose

                    //HAVE TO BE REMOVED Force upsell
                    //data.proposeHotel = true;

                    if (data.proposeHotel == false || data.proposeHotel == undefined) {
                        $window.location.href = $window.site.currentAirRequest.bookingUrl;
                        return;
                    }

                    airHelper.preparePurchaseData(data);
                    bkgData.purchaseResponse = data;
                    purchaseResponse.value = data;
                    bkgData.severalProposals = bkgData.purchaseResponse.proposals.length > 1;
                    //bkgData.bookingRequest.proposalIndex = bkgData.purchaseResponse.proposals[0].index;
                    $scope.lastDate = bkgData.purchaseResponse.itineraries[bkgData.purchaseResponse.itineraries.length - 1].arrivalDateTime;

                    keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;
                    tseUtil.Session.set("purchaseResponse_" + keyGenerated, data);
                    //else
                    
                    if (!$scope.skipUpsell) {
                        $window.FLoading({ vAction: 'hide' });
                    }

                    //Key Generated: requestId_purchaseRequestIndex_purchaseRequestGroup_purchaseRequestProposal
                    var keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;
                    console.log(keyGenerated);
                    // BookingRequest  Object to send to API
                   
                    bkgData.bookingRequest = {
                        proposalIndex: "",
                        bagChanged: false,
                        requestId: bkgData.request.requestId,
                        flexyFare: false,
                        insurance: null,
                        passengers: [],
                        additionalData: [],
                        buyer: {},
                        contact: { mail: '', phone: '' },
                        bestPriceFare: false
                    };

                    //Pax Init
                    var paxType = ["Adulte", "Enfant", "Bébé"];
                    var paxAmount = [bkgData.request.adults, bkgData.request.childs, bkgData.request.infants];
                    var keyGenerated = "";
                    var init = true;

                    for (var ptc in paxAmount) {
                        if (paxAmount.hasOwnProperty(ptc)) {
                            for (var i = 0; i < paxAmount[ptc]; i++)
                            bkgData.bookingRequest.passengers.push({ paxType: ptc });
                        }
                    }

                    if (init)
                        airHelper.preparePassengersData(bkgData.bookingRequest.passengers, bkgData.purchaseResponse);
                     
                    if ($scope.skipUpsell) {
                        $(document).trigger("redirect-to-koedia");
                    }
                }
                else {
                    $window.location.href = $window.site.errorsUrls.GeneralError;
                }
            }).error(function (data) {
                $window.location.href = $window.site.errorsUrls.GeneralError;
            });

        }

        //Taxes details click
        $scope.$on("displayTaxesDetailsClick", function (event, args) {
            $scope.displayTaxesDetailModal = true;
            $scope.modalTaxesKey = args.key.replaceAll(":", "-");
            $scope.modalTaxesKey = $scope.modalTaxesKey.replaceAll("\\|", "-");
            $scope.modalTaxesKeyAux = $scope.modalTaxesKey + "-" + args.paxFares.paxType;
            $scope.modalTaxesPaxFares = args.paxFares;

            $scope.$broadcast("updateTaxesDetailModalData", { key: $scope.modalTaxesKey, fares: $scope.modalTaxesPaxFares });

            $scope.modalShowMargin = false;

            var airlines = JSON.parse(window.sessionStorage.getItem("airlines"));

            args.marketingCarriers.each(function (value, key) {

                if (airlines != null && airlines[value].showMargin == true || value === 'AF') {
                    $scope.modalShowMargin = true;
                }
            });

            //Remodal workaround
            $(document).find(".remodal").each(function (a, c) {
                var d = $(c), e = d.data("remodal-options");
                e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                    d["remodal"](e)
            });

            $timeout(function () {
                $("[data-remodal-id='taxes-detail-modal-" + $scope.modalTaxesKeyAux + "']").remodal().open();
            },
                200, false);
        });

    }]);