webClient.directive('ngBookingDetailsFlightAlertsMultiDestination', ['$window', 'templateService', 'tseApiClient', 
    function ($window, templateService, tseApiClient) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        itineraries: "=",
        airports: "=",
        cities: "=",
        equipments: "=",
        isproductto: "=",
        charter: "=",
        itinerariescount: "=",
        request: "=",
        showaccomodationinfo: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_BookingDetailsFlightAlertsMultiDestinationTPL.html');

        //Initialize Variables
        $scope.siteType = $window.site.type;
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.hasWarning = false;        

        /**
        * Get City Name or Iata
        * @desc this function returns the city name or airport name. In case of name undefined it returns airportIata
        * @param {string} airportIata to find
        * @returns {string} airportIata
        */
        $scope.getCityNameOrIata = function (airportIata) {
            if ($scope.airports[airportIata].cityIata) {
                if ($scope.cities[$scope.airports[airportIata].cityIata])
                    return $scope.cities[$scope.airports[airportIata].cityIata].name;
            }
            else {
                if ($scope.airports[airportIata].name)
                    return $scope.airports[airportIata].name;
                else
                    return airportIata;
            }
        }

        if ($window.enableFlightWarnings && $scope.itineraries != null && $scope.itineraries.length > 2) {
            
            
                $scope.dayChange = ($scope.itineraries.any(function (it) { return it.dayChange > 0 }));

                
                $scope.longStops = []; 
                $scope.technicalStops = [];
                $scope.segmentsInTrain = [];
                $scope.segmentsInBus = []

                $scope.itineraries.each(function (it) {

                    it.stops.each(function (itStop) {
                        if (itStop.time.totalHours >= 6) {
                            $scope.longStops.push({
                                arrival: itStop.key,
                                stopTime: itStop.time,
                                originCityIata: it.origin,
                                destinationCityIata: it.destination,
                                originAirportIata: it.departure,
                                destinationAirportIata: it.arrival
                            });
                        }
                    });

                    if (it.segments) {
                        it.segments.each(function (seg) {
                            if (seg.stops > 0) {
                                $scope.technicalStops.push({
                                    departure: seg.departure.airport,
                                    arrival: seg.arrival.airport,
                                    originCityIata: it.origin,
                                    destinationCityIata: it.destination,
                                    originAirportIata: it.departure,
                                    destinationAirportIata: it.arrival,
                                    itTotalSegments: it.segments.length
                                });
                            }

                            //Train
                            if ($scope.equipments[seg.equipment].equipmentType == 3) {
                                $scope.segmentsInTrain.push({
                                    duration: { hh: seg.flightTime.HH, mm: seg.flightTime.mm },
                                    cityNameDeparture: $scope.cities[$scope.airports[seg.departure.airport].cityIata].name,
                                    cityNameArrival: $scope.cities[$scope.airports[seg.arrival.airport].cityIata].name
                                })
                            }
                            //Bus
                            if ($scope.equipments[seg.equipment].equipmentType == 2) {
                                $scope.segmentsInBus.push({
                                    duration: { hh: seg.flightTime.HH, mm: seg.flightTime.mm },
                                    cityNameDeparture: $scope.cities[$scope.airports[seg.departure.airport].cityIata].name,
                                    cityNameArrival: $scope.cities[$scope.airports[seg.arrival.airport].cityIata].name
                                })
                            }
                        });
                    }           
                });               
             
                

                //Same origin and last itinerary destination
                $scope.isSameCityOriginLastItineraryDestination = ($scope.itineraries[$scope.itineraries.length - 1] != null) && ($scope.itineraries[0].origin == $scope.itineraries[$scope.itineraries.length - 1].destination);

                if ($scope.isSameCityOriginLastItineraryDestination) {
                    $scope.sameCityDifferentAeroport = $scope.itineraries[0].departure != $scope.itineraries[$scope.itineraries.length - 1].arrival;
                    if ($scope.sameCityDifferentAeroport) {
                        var airportsItinerariesInformation = {
                            originCityName: $scope.cities[$scope.itineraries[0].origin].name,
                            originAirportName: $scope.airports[$scope.itineraries[0].departure].name,
                            destinationAirportName: $scope.airports[$scope.itineraries[$scope.itineraries.length - 1].arrival].name
                        };
                        $scope.airportsItinerariesInformation = airportsItinerariesInformation;
                    }
                }

                $scope.hasWarning = $scope.dayChange ||
                    $scope.longStops.length > 0 ||
                    $scope.technicalStops.length > 0 ||
                    $scope.segmentsInTrain.length > 0 ||
                    $scope.segmentsInBus.length > 0 ||
                    $scope.sameCityDifferentAeroport;         
        }
    };
    return directive;
}]);