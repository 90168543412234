webClient.controller('CE.headController', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper) {
    $window.$headScope = $scope;
    $scope.site = $window.site;
    $scope.isLoginRequired = $window.site.isLoginRequired;
    $scope.isLogged = $window.site.isLogged;
    $scope.loginInfo = $window.site.loginInfo;

    $scope.companyName = "";
    $scope.tradeName = "";

    if ($scope.isLogged) {
        $scope.companyName = $scope.loginInfo.CompanyName;
        $scope.tradeName = $scope.loginInfo.TradeName;
    }
    $scope.homeUrl = $window.site.loginUrl;
    $scope.logout = function () {
        tseUtil.Cookies.remove($window.site.loginCookie);
        document.location.href = $window.site.loginUrl;
    };
}]);