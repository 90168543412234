function hotelHelper() { }



hotelHelper.getPassangerHotelType = function (passengers, returnDate) {

    passengers.forEach(function (pax) {
        if (pax.birthDate) {
            pax.age = Math.floor(moment(returnDate).diff(moment(pax.birthDate, "DD/MM/YYYY"), 'years', true));
            if (pax.age >= 18)
                pax.paxHotelType = "0";
            if ((pax.age >= 2) && (pax.age < 18))
                pax.paxHotelType = "1";

            if (pax.age < 2)
                pax.paxHotelType = "2";
        } else {
            pax.paxHotelType = pax.paxType;
        }
    })

}

