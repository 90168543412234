function mockPaymentResponse() {}

mockPaymentResponse.init = function (data) {
    webClient.value('bkDataMock', { value: data });
    webClient.run(['$window', 'tseUtil', 'bkDataMock',
        function ($window, tseUtil, bkDataMock) {
            tseUtil.Session.set("bkDataMock", bkDataMock);
            var bkgData = bkDataMock.value;
            var keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;
            tseUtil.Session.set("insurancesSelected_" + keyGenerated, bkgData.insurancesSelected);
        }
    ]);
}

mockPaymentResponse.mockBasket = function () {

    var path = "/Views/misterfly_umbraco/js/data/bkDataBasketMock.json";
    $.ajax({ url: path, async: false })
        .done(function (response) {
            //required to avoid error logs
            window.site.currentAirRequest = response.currentAirRequest;
            mockPaymentResponse.init(response.bkgData);
        })
        .fail(function () { console.log("error on mocking getting " + path); });

    path = "/Views/misterfly_umbraco/js/data/bookingDetailBasket.json";

    $.ajax({ url: path, async: false })
        .done(function (data) {
            window.sessionStorage.setItem("bookinkDetailMock", JSON.stringify(data));
        })
        .fail(function () { console.log("error on mocking getting " + path); });
} 

mockPaymentResponse.mock = function () {

    var path = "/Views/misterfly_umbraco/js/data/bkDataMock.json";
    $.ajax({ url: path, async: false })
    .done(function (response) {
        //required to avoid error logs
        window.site.currentAirRequest = response.currentAirRequest;
        mockPaymentResponse.init(response.bkgData);
    })
    .fail(function () {console.log("error on mocking getting " + path); }); 
    
    path = "/Views/misterfly_umbraco/js/data/bookingDetail.json";

    $.ajax({ url: path, async: false })
    .done(function (data) {
        window.sessionStorage.setItem("bookinkDetailMock", JSON.stringify(data));
    })
    .fail(function () {console.log("error on mocking getting " + path); }); 
} 
mockPaymentResponse.mockBasketB2B = function () {

    var path = "/Views/misterfly_umbraco/js/data/bkDataBasketB2BMock.json";
    $.ajax({ url: path, async: false })
        .done(function (response) {
            //required to avoid error logs
            window.site.currentAirRequest = response.currentAirRequest;
            mockPaymentResponse.init(response.bkgData);
        })
        .fail(function () { console.log("error on mocking getting " + path); });

    path = "/Views/misterfly_umbraco/js/data/bookingDetailB2BBasket.json";

    $.ajax({ url: path, async: false })
        .done(function (data) {
            window.sessionStorage.setItem("bookinkDetailMock", JSON.stringify(data));
        })
        .fail(function () { console.log("error on mocking getting " + path); });
}
mockPaymentResponse.mockBasketKO = function () {

    var path = "/Views/misterfly_umbraco/js/data/bkDataBasketMockKO.json";
    $.ajax({ url: path, async: false })
        .done(function (response) {
            //required to avoid error logs
            window.site.currentAirRequest = response.currentAirRequest;
            mockPaymentResponse.init(response.bkgData);
        })
        .fail(function () { console.log("error on mocking getting " + path); });

    path = "/Views/misterfly_umbraco/js/data/bookingDetailBasketKO.json";

    $.ajax({ url: path, async: false })
        .done(function (data) {
            window.sessionStorage.setItem("bookinkDetailMock", JSON.stringify(data));
        })
        .fail(function () { console.log("error on mocking getting " + path); });
} 